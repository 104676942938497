.ui.dropdownForm {
  width: 100%;
  float: left;
  background: #fff;
  font: 400 15px "Rubik", sans-serif;
  color: #222;
  border-bottom: solid 1px #cbd6e2;
  cursor: pointer;
  min-height: 20px;
}

.ui.scrolling.dropdown .menu::-webkit-scrollbar {
  width: 5.6px;
}

.ui.dropdown>img {
  position: absolute;
  top: 6px;
  right: 0;
}

.ui.dropdown>i.icon {
  position: absolute;
  right: 0;
}

.ui.dropdown .menu {
  width: 100%;
  border-radius: 0;
  margin: -1px 0 0;
  background: #fff;
  border: solid 1px #cbd6e2;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  max-height: 250px;
  overflow-y: auto;
}

.ui.dropdown .menu>.item {
  border-bottom: solid 1px #cbd6e2;
  font: 400 13px "Rubik", sans-serif;
  padding: 10px;
  word-break: break-all;
  white-space: normal
}

.ui.dropdown .menu>.item:last-child {
  border: none;
}

.ui.dropdown .menu>.item.active {
  font-weight: 500;
}

.inputGroup {
  width: auto;
  display: inline-flex;
}

.inputGroup .ui.dropdown {
  padding: 0 10px;
}

.inputGroup .ui.dropdown,
.inputGroup .ui.button {
  height: 30px;
  border-radius: 0;
  background: #fff;
  border: solid 1px #d3d2de;
  border-left: none;
  border-right: none;
  position: relative;
  color: #000;
  display: flex;
  align-items: center;
  text-decoration: none !important;
}

.inputGroup .ui.dropdown:first-child,
.inputGroup .ui.button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left: solid 1px #d3d2de;
}

.inputGroup .ui.dropdown:last-child,
.inputGroup .ui.button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right: solid 1px #d3d2de;
}

.inputGroup .ui.dropdown:after,
.inputGroup .ui.button:after {
  content: "";
  width: 1px;
  height: 15px;
  border-right: solid 1px #d3d2de;
  position: absolute;
  right: 0;
}

.inputGroup .ui.dropdown:last-child:after,
.inputGroup .ui.button:last-child:after {
  display: none;
}

.ui.dropdownForm .message {
  padding-right: 34px !important;
  white-space: initial;
}
