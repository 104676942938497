.dropdownPopup {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: -1px;
  align-items: center;
  border-bottom: solid 1px #d3d2de !important;
}

.dropdownTitle {
  width: max-content;
  cursor: pointer;
  text-indent: 2px;
}

.dropdown {
  width: 100%;
  position: relative;
  color: #000;
  font: 400 14px "Rubik", sans-serif;
}

.dropdownList {
  position: absolute;
  top: 103%;
  background-color: white;
  width: 100%;
  left: 0;
  z-index: 8888;
  border-radius: 3px;
  box-shadow: 0 2px 3px 0 rgb(34 36 38 / 15%);
  display: flex;
  flex-direction: column;
}

.dropdownItem {
  padding: 8px 16px;
  cursor: pointer;
  /* word-break: break-all; */
}

.selected {
  font-weight: 500;
}

.dropdownPopup button {
  color: inherit !important;
}

.dropdownDisabled {
  pointer-events: none;
  color: #d3d2de !important;
}
