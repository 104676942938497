.empty-gallery-container {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: #f3f2f6;
  padding: 12px;
  font: 600 13px "Rubik", sans-serif;
  color: #727aff;
}

.empty-gallery-image{
    margin-right: 16px;
}