.icons-management-colors-filters-content
{
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #000;
}

.icons-management-colors-filters-label-bx
{
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
}

.icons-management-colors-filters-label-color{
    width: 16px;
    height: 16px;
    border-radius: 4px;
}


.icons-management-colors-filters-color-bx{
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}