.ui.modal.addInventoryModal{
    max-width:576px!important;
    padding:0 40px;
}
.ui.modal.addInventoryModal .aligner{
    padding:0;
}

.ui.addInventoryModal .inputGroupWrap > p{
    color:#000;
}

.inventoryFlex{
    width:100%; float:left;
    display:flex;
    align-items:flex-start;
}
.inventoryFlex .inputGroupWrap:last-child{
    max-width:195px;
    padding-left:25px;
}