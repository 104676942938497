.listView-csv-modal {
    /* padding: 0 24px !important; */
    width: 470px !important;
}

.listView-csv-modal .error-box {
    margin-top: 32px;
}

.listView-csv-modal .error-msg {
    text-align: left;
    margin-left: 10px;
}

.languageSelectorDropdown.textGroup > b, .csvUploadModalText {
    text-align: left;
}

.fileUploader {
    margin-top: 83px;
}