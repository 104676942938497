
.tooltipMarker{
    height:34px;
    padding:0 10px;
    margin:-16px 0 0 2px;
    background:#fff;
    font:500 13px 'Rubik',sans-serif;
    letter-spacing: -0.5px;
    color:#000;
    border-radius:3px;
    box-shadow:0 6px 20px rgba(0,0,0,0.2);
    display:flex;
    align-items:center;
}

.buttonClose{
    width:17px;
    height:17px;
    border-radius:17px;
    padding:0;
    border:none;
    background:#000;
    position: absolute;
    top:-23px;
    right:-8px;
}
.buttonClose img{
    width:9px;
    filter: brightness(2);
}