.transparentIcon {
    background-image: url(/img/icon-color-transparent.svg);
    height: 20px;
    width: 20px;
    display: inline-block;
    margin-left: 17px;
}

.opacityPreview {
    position: absolute;
    height: 20px;
    width: 20px;
}

.opacityRange {
    width: 70%;
}

.opacityRange>input {
    padding: 0 !important;
}

.opacityRange input[type=range] {
    overflow:hidden;
    height: 18px;
    -webkit-appearance: none;
    margin:0;
    width: 100%;
    border:none!important;
}
.opacityRange input[type=range]:focus {
    outline: none;
    border:none!important;
}
.opacityRange input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 1.6px;
    cursor: pointer;
    background: #D3D2DE;
    border-radius: 0px;
    border:none!important;
}
.opacityRange input[type=range]::-webkit-slider-thumb {
    border: 0px solid #2497E3;
    height: 12px;
    width: 12px;
    border-radius: 25px;
    background: #727AFF;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5px;
}
.opacityRange input[type=range]::-webkit-slider-thumb::before{
    content:'';
    width:20px;
    height:20px;
    background:#000;
}
.opacityRange input[type=range]:focus::-webkit-slider-runnable-track {
    background: #D3D2DE;
}
.opacityRange input[type=range]::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #000000;
    background: #D3D2DE;
    border-radius: 0px;
    border: 0px solid #000000;
}
.opacityRange input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #2497E3;
    height: 12px;
    width: 12px;
    border-radius: 25px;
    background: #727AFF;
    cursor: pointer;
}
.opacityRange input[type=range]::-ms-track {
    width: 100%;
    height: 1.5px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}
.opacityRange input[type=range]::-ms-fill-lower {
    background: #D3D2DE;
    border: 0px solid #000000;
    border-radius: 0px;
    box-shadow: 0px 0px 0px #000000;
}
.opacityRange input[type=range]::-ms-fill-upper {
    background: #D3D2DE;
    border: 0px solid #000000;
    border-radius: 0px;
    box-shadow: 0px 0px 0px #000000;
}
.opacityRange input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #2497E3;
    height: 12px;
    width: 12px;
    border-radius: 25px;
    background: #727AFF;
    cursor: pointer;
}
.opacityRange input[type=range]:focus::-ms-fill-lower {
    background: #D3D2DE;
}
.opacityRange input[type=range]:focus::-ms-fill-upper {
    background: #D3D2DE;
}

.ui.popup.transition.visible.opacityTextPopup {
    left: 5px !important;
}