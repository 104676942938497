.mainSectionHeading {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.manageCategory-tab {
    width: 212px !important;
    height: 30px !important;
    margin: 0px -1px !important;
    font-family: 'Rubik' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12.2533px !important;
    line-height: 15px !important;
    color: #B5B5B5 !important;
    background-color: #FFFFFF !important;
    border: 1px solid #B5B5B5 !important;
}

.manageCategory-tab.active {
    color: #FFFFFF !important;
    background-color: #E75330 !important;
}

.mainSectionHeading .heading-rightSide {
    display: flex;
    gap: 10px;
    position: absolute;
    right: 40px;
}

.ui.button.manageCategoryAddCategory {
    min-width: 90px;
    height: 30px;
    background: #F15944 !important;
    font: 500 13px 'Rubik', sans-serif;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    white-space: nowrap;
}

.ui.button.manageCategoryAddCategory img {
    margin: 0 0 0 12px;
    padding: 5px 0 5px 8px;
    border-left: solid 1px #fff;
    line-height: 20px;
}

.ui.popup.addNewCategoryPopup {

    box-sizing: border-box;

    width: 106px;
    height: 67px;

    background: #FFFFFF;
    border: 1px solid #D3D2DE;
    box-shadow: inset 0px 0px 0px 1px rgba(34, 36, 38, 0.15);
    border-radius: 4px;
}

.ui.popup.addNewCategoryPopup > div {
    display: flex !important;
    flex-direction: column;
    gap: 10px
}

.ui.popup.addNewCategoryPopup.hide {
    visibility: hidden !important;
}

.ui.popup.addNewCategoryPopup .addNewCategoryItem {

    height: 15px;

    cursor: pointer;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;

    color: #000000;


}