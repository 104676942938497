.checkbox-dropdown .ui.checkbox.checkboxGroup {
  margin: 0 !important;
}

.checkbox-dropdown.ui.dropdown {
  padding: 8px !important;
  position: relative;
  border-radius: 6px;
  border: 0 !important;
}

.checkbox-dropdown.ui.dropdown label:before{
    top:0;
}

.checkbox-dropdown.ui.dropdown label:before{
    width:16px;
    height:16px;
    border-radius:2px;
    border-color:#D3D2DE!important;
    opacity:1!important
}

.checkbox-dropdown.ui.dropdown label:hover:before{
    border-color:#B1B0BF!important;
}

.checkbox-dropdown.ui.dropdown label:after{
    display:none;
}
.checkbox-dropdown.ui.dropdown input:checked~label:before{
    background:url(/img/icon-check-orange.svg) no-repeat center;
    border:solid 1px #F15944!important;
}

.checkbox-dropdown.ui.dropdown input:indeterminate~.box:before, 
.checkbox-dropdown.ui.dropdown input:indeterminate~label:before{
    border-color:#F15944!important;
}

.checkbox-dropdown.ui.dropdown input:indeterminate~.box:after, 
.checkbox-dropdown.ui.dropdown input:indeterminate~label:after{
    display:block;
    font-size: 11px;
    color: #F15944!important;
    top: .5px;
    left: -2px;
}

.checkbox-dropdown.ui.dropdown.radio label{
    font-size:15px;
    padding-left:23px;
}
.checkbox-dropdown.ui.dropdown.radio label:before{
    width:15px;
    height:15px;
    border-radius:20px;
    border-color:#D3D2DE!important;
    opacity:1!important
}

.checkbox-dropdown.ui.dropdown.radio label:hover:before{
    border-color:#B1B0BF!important;
}
.checkbox-dropdown.ui.dropdown.radio input:checked~label:before{
    background:#727AFF;
    box-shadow:inset 0 0 0 2px #fff;
    border-color:#D3D2DE!important;
}

.checkbox-dropdown.ui.dropdown.fluid{
    width:100%!important;
    margin:0 0 15px!important;
}

.checkbox-dropdown.ui.toggle.checkbox .box:before,
.checkbox-dropdown.ui.toggle.checkbox label:before {
  background-color: grey;
}

.checkbox-dropdown.ui.dropdown img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px !important;
}
.checkbox-dropdown.ui.dropdown .menu .item {
  padding: 12px !important;
  width: max-content !important;
  border-bottom: 0px !important;
}

.checkbox-dropdown.ui.dropdown .menu .item:hover{
    background-color: transparent !important;
}

.checkbox-dropdown.ui.dropdown .menu {
  border-radius: 6px !important;
  border: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.checkbox-dropdown.ui.dropdown .menu .item .ui.checkbox label:before {
  width: 18px !important;
  height: 18px !important;
}
