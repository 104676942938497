.divideBorderLine{
    width:100%; float:left;
    margin:15px 0;
    border-bottom:solid 11px #F4F7FC;
}

.h6{
    /* width: 100%; */
    float: left;
    margin: 0 0 10px;
    font: 400 13px 'Rubik',sans-serif;
}
.small{
    width: 100%;
    float: left;
    margin: 0 0 10px;
    font: 400 13px 'Rubik',sans-serif;
}

.ui.button.mapLayerTrigger img{
    width:17px;
}

.mapstyleSidebar{
    width:250px;
    height:calc(100vh - 109px);
    background:#fff;
    border-right:solid 1px #C0CBDA;
    position:fixed;
    top:109px;
    left:0;
}

.ui.header.headingbold{
    width:100%; float:left;
    height: 47px;
    margin: 0;
    font: 400 14px 'Rubik',sans-serif;
    background: #F4F7FC;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ui.header.headingbold > p {
    margin-left: 15px;
    margin-top: 15px;
}

.mapstyleContent{
    width:100%; float:left;
    padding:10px 15px;
    height: 575px;
}
.ui.dropdown.dropdownSelectTheme{
    padding:5px 0;
    color:#7F7F7F;
    width: 82%;
    margin-right: 8px !important;
}

.ui.dropdown.dropdownSelectTheme--mod{
    width: 87%;
}
.ui.dropdown.dropdownSelectTheme > img{
    top:13px;
}
.ui.dropdown.dropdownSelectTheme .menu{
    width:219px;
    border:none;
    border-top:solid 1px #667581;
    background:#fff;
    box-shadow:0 3px 8px rgba(0,0,0,.3);
    margin-top:0;
    max-height: 200px;
    overflow: auto;
}


.themeSettings{
    width:100%; float:left;
}

.checkboxPopupTrigger{
    width:100%;
    float:left;
    position: relative;
}

.mapstyleContent{
    width:100%; float:left;
}


.sectionMapStyle{
    width:100%; float:left;
}
.tgMapStyles{
    width:100%; float:left;
    margin:0 0 20px;
}

.mapStyleItemElem{
    width:100%; float:left;
    padding:5px 0;
    display:flex;
    align-items:center;
    /* border-bottom:solid 1px #D3D2DE; */
    position:relative;
}
.mapStyleItemElem:before,
.mapStyleItemElem:after{
    content:'';
    width:15px;
    height:100%;
    background:#F4F7FC;
    position:absolute;
    top:0;
    left:-15px;
    display:none;
}
.mapStyleItemElem:after{
    left:auto;
    right:-15px;
}
.mapStyleItemElem:hover{
    background:#F4F7FC;
    cursor: pointer;
}

.mapStyleItemElem.disabled {
    pointer-events: none;
}

.mapStyleItemElem.selected {
    background-color: #F4F7FC;
}

.mapStyleItemElem.disabled p {
    opacity: 0.5;
}

.styleItemIcon{
    margin:0 10px 0 0;
    padding:0;
    float:left;
    min-width:20px;
    width:20px;
    height:20px;
    border-radius:2px;
    border:solid 1px #D3D2DE;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.styleItemIcon img{
    width:13px;
    filter:saturate(0);
}

.styleItemIcon:hover {
    cursor: pointer;
}

.ui.popup.popupLabel{
    padding:8px 10px;
    border-radius:2px;
    border:none;
    font:400 14px 'Rubik',sans-serif;
}

.mapStyleItemElem p{
    margin:0;
    font:400 15px 'Rubik',sans-serif;
}

.ui.buttonIcon.triggerMapLayer{
    position:absolute;
    right:0;
    display:none;
}
.ui.popup.popupMapLayer{
    border:none;
    box-shadow:0 0 12px rgba(0,0,0,.2);
    border-radius:4px;
    padding:6px 12px;
    font:400 15px 'Rubik',sans-serif;
}
.ui.popup.popupMapLayer:before{
    box-shadow:none!important;
}

.mapLayersActions{
    width:100%; float:left;
    position:absolute;
    bottom:10px;
    left:0;
    padding:0 15px;
}
.mapLayersActions .ui.button.primary{
    height:34px;
    line-height:34px;
    min-width:80px;
}
.mapLayersActions .ui.button.primary.grey{
    background:#667581;
}


.mapStyleItemElem:hover .ui.buttonIcon.triggerMapLayer{display:block;}

.publishStatusMode{
    width:100%; float:left;
    padding:0 0 15px;
    margin:0 0 20px;
    border-bottom:solid 1px #D3D2DE;
}
.publishStatusMode .ui.radio.checkbox.checkboxGroup{
    margin:0 30px 0 0;
}

.layerMapStylesSidebar{
    width:350px;
    height:calc(100vh - 109px);
    background:#fff;
    border-right:solid 1px #C0CBDA;
    position:fixed;
    top:109px;
    left:250px;
}
.layerMapStylesSidebar .ui.checkbox.checkboxGroup{
    margin:0 0 15px;
}
.layerMapStylesSidebar .ui.checkbox.checkboxGroup label{
    font-size:14px;
}

.layerMapStylesSidebar .ui.headingbold{
    justify-content:space-between;
}
.layerMapStylesSidebar .ui.headingbold img{
    width:23px;
    margin:0 10px 0 15px;
}

.mapLayersTab .ui.secondary.pointing.menu{
    margin-bottom:10px;
}
.mapLayersTab .ui.secondary.pointing.menu .active.item, 
.mapLayersTab .ui.secondary.pointing.menu .active.item:hover{
    border-bottom:solid 3px #727aff!important;
    margin-bottom:-1px;
    padding-bottom:2px;
}
.layerStyleSec{
    width:100%; float:left;
    display:flex;
    align-items:center;
}
.layerStyleSec p{
    margin:0 10px 0 0;
}
.layerStyleSec .ui.dropdown.dropdownForm{
    width:60px;
}

.layerMapStylesSidebar .ui.header.headingbold .cancelButton {
    margin-right: 10px;
}

.layerMapStylesSidebar .ui.header.headingbold .cancelButton img {
    width: 15px;
    margin: 0px;
}

.colorAndOpacitySetter {
    margin-top: 10px;
}

.ui.button.mapLayerTrigger.inactive {
    color: rgba(0,0,0,.6) !important;
}

.styleEditorHeading {
    margin-top: 1px;
    font-weight: 500;
}

.ui.button.defaultReverBtn {
    min-width: 100px;
    height: 36px;
    text-align: center;
    padding: 0 20px;
    font: 500 13px/36px 'Rubik',sans-serif;
}

.textSizeDropdown {
    width: 100%;
}

.textFontDropdown {
    width: 100%;
}

.textFontDropdown .styleEditorColumn.styleEditorDropdownInput {
    width: 195px;
}

.ui.button.buttonIcon.themeAddBtn {
    float: right;
    margin-top: 3px;
    margin-right: 4px;
}

.ui.modal.themeCreateEditModal {
    width: 750px;
    padding: 35px 30px 25px;
}

.baseMapSelector{
    width:215px;
}
.baseMapSelector img {
    width: 100%;
    border:solid 1px #D3D2DE;
    border-radius:2px;
}

.baseMapSelector .ui.radio.checkbox.checkboxGroup.baseMapSelectorCheckbox {
    width: 100%;
    margin-bottom: 10px;
}

.baseMapSelectorGroup {
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
}

.ui.input.themeName {
    width: 250px;
}

.ui.button.buttonIcon.themeEditBtn {
    float: right;
    margin-top: 11px;
    margin-right: 5px;
}

.mapStyleItem {
    display: flex;
    align-items: center;
    border-bottom: solid 1px #D3D2DE;
}

.themeActivationToggle {
    margin-top: 7px;
    margin-left: 13px;
}

.editorDropdown {
    margin-top: 10px;
}

.themeSettingsRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ui.checkbox.themeTypeCheckbox {
    width: 70%;
}

.ui.radio.checkbox.checkboxGroup .themeTypeDarkCheckbox {
    margin-left: 10px;
}

.propertyMapType {
    margin-left: 20px;
    width: 70%;
}

.themeClassificationTypeHeading {
    font: 500 15px 'Rubik',sans-serif;
}

.styleEditorDropdownInput .ui.dropdown .menu {
    width:60px;
    height: 200px;
    overflow: auto;
}

.styleEditorDropdownInput .ui.dropdown .menu > .item{
    padding:10px!important;
}

.ui.dropdown.dropdownSelectTheme .item{
    display: flex;
    align-items: center;
    padding:10px 10px!important;
}
.ui.dropdown.dropdownSelectTheme .item:hover,
.ui.dropdown.dropdownSelectTheme .menu .selected.item, 
.ui.dropdown.dropdownSelectTheme.selected{
    background:#F4F7FC!important;
}

.ui.dropdown.dropdownSelectTheme.themePageDropdown .item > span{
    display: inline-flex;
    align-items: center;
    position: relative;
    font-size:12px;
}
.ui.dropdown.dropdownSelectTheme.themePageDropdown .item > span:before{
    content:'';
    min-width:8px;
    width:8px;
    height:8px;
    border-radius:8px;
    background:#F15944;
    margin:0 5px 0 0;
}
.ui.dropdown.dropdownSelectTheme.themePageDropdown .item > span.activeTheme:before{
    background:#29BD50;
}
.themeOptionInfo {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
}
.themeOptionInfo span{
    background:#F2F3F4;
    height:20px;
    padding:0 3px;
    font:400 11px/18px 'Rubik',sans-serif;
    color:#828E97;
    border:solid 1px #828E97;
    border-radius:3px;
    margin:0 0 0 3px;
}
.themeOptionInfo span.primary{
    border-color:#7a82ff;
    background:#7a82ff;
    color:#fff;
    padding:0 2px;
}

.ui.tooltipPopup.themeSidebarPopup {
    text-transform: none;
}

.stylesEditorActions{
    height: 100px;
}
.themeCreateEditModal .actions{
    display: flex;
    gap: 12px;
    justify-content: flex-end;
}

.themeCreateEditModal .actions .button{
    width: 120px;
    margin: 0px !important;
    margin-top: 2px !important;
}

.themeCreateEditModal .error-box{
    margin-bottom: 0 !important;
    margin-top: 6px;
}

.theme-delete-button.ui.button{
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    padding: 0;
    font-weight: bold;
    color:#E75330;
}

.theme-actions-bx{
    display: flex;
    gap: 6px;
    height: 27px;
}

.theme-actions-bx .delete-icon-btn.button.buttonIcon img{
    width: 14px;
}