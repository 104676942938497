.buttons-wrap {
    position: absolute;
    right: 20px;
    top: 180px;
    z-index: 2;
}
.maintenance-map .buttons-wrap .buttons-group{
    margin:0 0 20px;
}
.maintenance-map .buttons-wrap .buttons-group:last-child{
    margin:0;
}

.buttons-edit-active{
    right:auto;
    left:20px;
    z-index:10;
}

.buttons-edit-active .buttons-group:not(.staysActive){
    display:none;
}

.buttons-group {
    min-width: 34px;
    width: 34px;
    margin: 0 0 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.buttons-group .ui.button,
.buttons-group > div > .ui.button {
    width: 100%;
    height: 31px;
    padding: 0;
    float: left;
    border-radius: 0;
    border-bottom: solid 1px #d3d2de;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    font: 500 13px 'Rubik', sans-serif;
    color: #000;
}

.buttons-group .ui.button:hover,
.buttons-group > div> .ui.button:hover {
    background: #f5f5f5;
}

.buttons-group .ui.button:active,
.buttons-group >div> .ui.button:active {
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.buttons-group:not(.buttonGroupEmergency) .ui.button img  {
    width: 13px;
    filter: brightness(0);
}

.buttons-group .ui.button:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.buttons-group .ui.button:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: none;
}

.buttons-group .ui.button.active,
.buttons-group >div> .ui.button.active {
    color: #fff;
    background: #757dff;
}

.buttons-group .ui.button.active img {
    filter: contrast(100) brightness(0%) invert(1);
}

.buttons-group .ui.button span {
    width: 12px;
    height: 12px;
    position: relative;
}

.buttons-group .ui.button span:before, .buttons-group .ui.button span:after {
    content: '';
    background: #000;
    position: absolute;
    top: 0;
    left: 0;
}

.buttons-group .ui.button span:before {
    width: 12px;
    height: 2px;
    top: 5px;
}

.buttons-group .ui.button span:after {
    width: 2px;
    height: 12px;
    left: 5px;
}

.buttons-group .ui.button span.zoomOut:after {
    display: none;
}

.searchMap{
    position:relative;
    width:100%;
}

.buttons-group.searchMap .ui.button{
    border-radius:4px!important;
    color:#fff!important;
    background:#fff!important;
    z-index:2;
    border:none;
}

.ui.searchDropdown {
    position: absolute!important;
    left:auto;
    right:0;
    width:200px;
    right:32px;
    z-index:1;
}

.ui.search.dropdown.searchDropdown input {
    width: 200px!important;
    height: 31px!important;
    padding: 0 10px!important;
    border-radius: 4px 0 0 4px!important;
    background: #fff!important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3)!important;
}

.ui.search.dropdown.searchDropdown>.text{
    left:0;
    padding:7px 0 0 10px;
}

.ui.searchDropdown .menu {
    width: 233px;
    left: auto!important;
    right: -33px!important;
    top: 34px!important;
}
