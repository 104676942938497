.container {
  margin-left: 30px;
}

.header {
  font-size: 13px;
  margin-bottom: 2px;
  font-weight: 600;
}
.inner_container {
  display: flex;
  align-items: center;
}

.selection_box {
  display: flex;
  margin-right: 12px;
}

.radio_btn {
  margin-right: 4px !important;
}


