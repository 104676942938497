.container{
    display: flex;
    font-size: 14px;
    color: black;
    font-weight: 400;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    margin-bottom: 20px;
    position: relative;
    z-index: 8888;
}

.item{
     padding-bottom: 8px;
     position: relative;
     cursor: pointer;
}

.item::after{
    content: "";
    position: absolute;
    width: 10%;
    height: 1.8px;
    opacity: 0;
    bottom: 0;
    left: 0;
    background-color: #727AFF;
    transition: all .19s ease-in;
}

.item:not(:last-child){
    margin-right: 20px;
}

.selected{
    font-weight: bold;
}

.selected::after{
    opacity: 1;
    width: 100%;
}