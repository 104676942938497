.ui.statusProcessingPopup{
    max-width:870px !important;
    width:870px !important;
}
.processingStatus{
    width:100%; float:left;
    margin:15px 0 0;
    display:flex;
    align-items:flex-start;
    justify-content:space-between;
}
.processingStatus p{
    flex:1;
    width:20%;
    font:400 13px 'Rubik',sans-serif;
    color:#99A9C4;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:flex-start;
    position:relative;
}
.processingStatus p:after{
    content:'';
    width:56%;
    height:1px;
    border-bottom:dashed 1px #D3D2DE;
    position:absolute;
    top:9px;
    right:72%;
}
.processingStatus p:first-child:after{
    display:none;
}
.processingStatus .isCompleted:after,
.processingStatus .isProcessing:after{
    border-bottom:solid 1px #727AFF;
}
.processingStatus p span{
    width:21px;
    height:21px;
    border-radius:22px;
    border:solid 1px #D3D2DE;
    margin:0 0 6px;
    position:relative;
}
.isCompleted span{
    background:#727AFF;
    border:none!important;
}
.isCompleted span:after{
    content:'';
    background:url(/img/icon-check-orange.svg) no-repeat center;
    background-size:9px auto;
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    filter:saturate(0) brightness(100);
}

.processingStatus .ui.small.loader{
    margin:0 0 3px;
}
.processingStatus .ui.small.loader:after{
    width: 23px;
    height: 23px;
    border-color: #fff transparent transparent;
    border-width: 5px;
    margin: -1px 0px 0 -13px
}
.processingStatus .ui.loader:before{
    width:21px;
    height:21px;
    border:2px solid #727AFF;
}
.isProcessing{
    color:#727aff!important;
}
.isProcessing > span{
    display:none;
}
.isProcessing:last-child .ui.loader{
    display:none;
}

.isProcessing:last-child{
    color:#29AD73!important;
}
.isProcessing:last-child > span{
    display:block;
    background:#29AD73;
    border:none;
}
.isProcessing:last-child span:after{
    content:'';
    background:url(/img/icon-check-orange.svg) no-repeat center;
    background-size:9px auto;
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    filter:saturate(0) brightness(100);
}