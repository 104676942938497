.container{
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    background-color: black;
    height: max-content;
    justify-content: space-around;
    padding: 5px;
    align-items: center;
    transition: opacity .17s ease-in;
}
