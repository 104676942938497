.container {
  vertical-align: middle;
}

.heading {
  font: 400 13px "Rubik", sans-serif;
  color: #000;
}

.inner_container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr)) max-content;
  grid-column-gap: 22px;
  height: 27px;
  align-items: center;
  position: relative;
  width: 100%;
  margin: 18.5px 0;
}


.inner_container_mod{
    grid-template-columns: .6fr max-content;
    justify-content: space-between;
}
.dropdown_container {
  display: flex;
  align-items: center;
}

.dropdown_title {
  flex-shrink: 0;
  margin-right: 4px;
  font:400 14px 'Rubik',sans-serif;
}
