.ui.popup.logoTypePopup{
    width:227px;
    border:none!important;
    box-shadow:0 3px 12px rgba(0,0,0,.2);
    border-radius:0!important;
    padding:0;
}
.ui.popup.logoTypePopup:before{
    display:none!important;
}
.logoTypeMode{
    width:100%; float:left;
    display:flex;
    align-items:center;
    border-bottom:solid 1px #d3d2de;
    padding:11px;
    font:400 14px 'Rubik',sans-serif;
    color:#000;
    cursor:pointer;
}
.logoTypeMode:hover{
    background:#efefef;
    font-weight:500;
}
.logoTypePlaceholder{
    min-width:43px;
    width:43px;
    height:43px;
    display:flex;
    align-items:center;
    justify-content:center;
    background:#f2f2f2;
    border:solid 1px #d3d2de;
    margin:0 13px 0 0;
}

.logoTypeContainer{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.btn-container{
    display: flex;
    justify-content: space-between;
}
.contentLogoType{
    width: max-content;
}
.logoTypePlaceholder img{
    max-width:100%;
}
.logoTypeMode .ui.button.buttonIcon{
    margin-left:auto;
}

.logoTypeMode.filledPlaceholder:nth-child(4) .logoTypePlaceholder{
    background:#fcead6;
}
.logoTypeMode.filledPlaceholder:last-child .logoTypePlaceholder{
    background:#152034;
}


.logoTypeCover .ui.header{
    width:100%; float:left;
    background:#f6f6f6;
    font:400 13px 'Rubik',sans-serif;
    text-align:center;
    border-bottom:solid 1px #dcdcdc;
    margin:0!important;
    padding:5px 10px!important;
}

.logoTypeCover{
    width:100%; float:left;
    display:flex;
    flex-direction:column;
}
