.icons-management-selected-icon-image{
    width: 70px;
    height: 70px;
    border: 1px solid #d3d2de;
    border-radius: 4px;
    overflow: hidden;
    padding: 4px;
}

.icons-management-selected-icon-image .image-component-img-bx{
    border: none;
}