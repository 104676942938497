.icons-management-section
{
    display: flex;
    flex-direction: column;
}

.icons-management-section-title
{
    margin-bottom: 10px;
    font: 400 13px "Rubik", sans-serif;
    color: #667581;
    text-transform: uppercase;
}

.icons-management-section-content{
    color: #000 !important;
        font: 400 15px "Rubik", sans-serif;

}
