.ui.positive.button,
.ui.positive.button:focus{
    min-height:30px;
    height:30px;
    font:500 13px 'Rubik',sans-serif;
    color:#fff;
    background:#727AFF!important;
}
.ui.positive.button:hover{
    background:#545CE3!important;
}

.ui.secondary.button,
.ui.secondary.button:focus{
    min-height:36px;
    height:36px;
    font:500 13px 'Rubik',sans-serif;
    color:#fff;
    background:#29AD73!important;
}
.ui.secondary.button:hover{
    background:#18945E!important;
}

.ui.buttonIcon.backButton{
    margin:0 0 10px;
    align-items:center;
    font-size:14px;
}
.ui.buttonIcon.backButton img{
    width:14px;
}

.brandingListingWrapper{
    width:100%; float:left;
    min-height:calc(100vh - 150px);
    padding:20px;
}
.ui.header.pageHeader{
    font:500 20px 'Rubik',sans-serif;
    margin:10px 0 15px;
}

.brandingListingWrapper .buttons-wrap{
    transform: translateY(0);
    top:180px;
}


.inventorySubheader{
    width:100%; float:left;
    display:flex;
    align-items:center;
    margin:0 0 20px;
}
.inventorySubheaderLeft{
    flex:1;
    display:flex;
    align-items:center;
    flex-wrap:wrap;
}
.inventorySubheaderRight{
    margin-left:auto;
    padding-left:20px;
    display:flex;
    align-items:center;
}
.emptyInventoryMessage{
    width:100%; float:left;
    background:#E6E9FC;
    font:400 13px 'Rubik',sans-serif;
    color:#727AFF;
    padding:10px 15px;
}
.inventorySubheaderRight .ui.button.positive{
    margin-left:15px;
}

.ui.button.positive.exportButton{
    display:flex;
    padding-right:10px;
}
.ui.button.positive.exportButton .icon{
    order:2;
    margin:0 0 0 15px!important;
    position:relative;
}
.ui.button.positive.exportButton .icon:after{
    content:'';
    width:1px;
    height:15px;
    border-right:solid 1px #fff;
    position:absolute;
    left:-5px;
    top:0;
}

.ui.inputForm.searchDashboard{
    width:410px;
    margin:0 15px 0 0;
}
.ui.inputForm.searchDashboard > input{
    height:30px;
    background:#fff;
    border-radius:4px;
    border:none;
    font:400 13px 'Rubik',sans-serif;
    padding-left:10px;
}

.ui.input.searchDashboard{
    width:410px;
    margin:10px 15px 10px 0;
}
.ui.input.searchDashboard > input{
    height:30px;
    background:#fff;
    border-radius:4px;
    border:none;
    font:400 13px 'Rubik',sans-serif;
    padding-left:10px;
}

.inventoryFilePlaceholder{
    max-width:300px;
    height:30px;
    border:dashed 1px #727AFF;
    padding:0 65px 0 10px;
    position:relative;
    font:400 13px 'Rubik',sans-serif!important;
    display:flex;
    align-items:center;
}
.inventoryFilePlaceholder span{
    max-width:100%;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
}
.inventoryFilePlaceholder .ui.button.basic{
    border:none;
    box-shadow:none;
    padding:0;
    position:absolute;
    right:10px;
    color:#727AFF!important;
}
.inventoryFilePlaceholder .ui.button.basic:hover{
    text-decoration:underline;
}

.listingMain .dataListingTableColumn{
    min-width:200px;
    max-width:500px;
}

.ui.button.buttonIcon.listingUpload{
    background:#F3F6FB!important;
    width:26px;
    height:26px;
    border-radius:2px;
    margin:0 5px 0 0;
    display:flex;
    align-items:center;
    justify-content:center;
}

.popup.defaultPopup{
    width:auto;
    min-height:34px;
    border:none;
    border-radius:4px;
    box-shadow:2px 1px 12px rgba(0,0,0,0.2);
    padding:10px 20px 10px 12px;
    font:400 13px 'Rubik',sans-serif;
}
.ui.popup.defaultPopup:before{
    box-shadow:none!important;
}

.fullListingTableBox{
    margin-top:5px;
}
.fullListingTableBox .dataListingTableColumn{
    min-width:165px;
    max-width:165px;
}

.fullListingTableBox .dataListingTableColumn:last-child{
    width:60px;
    min-width:60px;
    padding:0 20px 0 0;
    position:sticky;
    right:0;
    background:#fff;
    border-left:solid 1px #F3F6FB!important;
}
.fullListingTableBox .dataListingTableColumn:last-child .ui.button.buttonIcon{
    padding:15px 10px;
    border-radius:0;
}

.dataListingTableColumn.narrow{
    min-width:100px;
    max-width:100px;
}
.dataListingTableColumn.regular{
    min-width:190px;
    max-width:190px;
}
.dataListingTableColumn.medium{
    min-width:230px;
    max-width:230px;
}
.dataListingTableColumn.wide{
    min-width:270px;
    max-width:270px;
}

.storeFlexbox{
    width:100%;
    display:flex;
    align-items:center;
    position:relative;
}

.storeFlexbox,
.storeFlexbox p{
    max-width:100%;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}

.storeFlexbox > span{
    min-width:29px;
    width:29px;
    height:29px;
    position:relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.storeFlexbox > span > img{
    max-width:100%;
    border-radius:2px;
}
.storeFlexbox > span .ui.checkbox,
.storeFlexbox > span .ui.checkbox label,
.storeFlexbox > span .ui.checkbox label:before,
.storeFlexbox > span .ui.checkbox label:after{
    width:100%;
    height:100%;
}

.storeFlexbox > span .ui.checkbox{
    position:absolute;
    top:0;
    left:0;
    display:flex;
    padding:0;
}
.storeFlexbox > span .ui.checkbox label{
    line-height:100%;
    padding:0;
}
.storeFlexbox > span .ui.checkbox label:before{
    background:none;
    border:solid 1px #BABBCB;
    border-radius:2px;
}
.storeFlexbox > span .ui.checkbox input:checked~label:before{
    background: url(/img/icon-checkbox.svg) no-repeat center rgba(41,173,115,.7);
    background-size:12px auto;
    border-color:#29AD73;
}
.storeFlexbox > span .ui.checkbox label:after{
    display:none;
}
.storeFlexbox p{
    flex:1;
    padding-left:15px;
}
.storeFlexbox i.flag{
    margin:0;
}

.ui.button.buttonLink{
    background:none!important;
    border-radius:0!important;
    padding:0;
    margin:0 0 -1px;
    font:400 15px 'Rubik',sans-serif;
    color:#99A9C4;
}
.ui.button.buttonLink.active{
    color:#727AFF;
    box-shadow:inset 0 -2px 0 0 #727AFF;
}


.languageGroup{
    width:100%; float:left;
    display:flex;
    border-bottom:solid 1px #D3D2DE;
}
.languageGroup .ui.button.buttonLink{
    margin:0 20px 0 0;
}
.languageGroup .ui.button.buttonLink:nth-last-child(2){
    margin-right:5px;
}
.languageGroup .ui.dropdown{
    border:none!important;
    padding:0;
}


.previewLanguageGroup{
    width:100%; float:left;
    margin:0 0 20px;
    border-bottom:solid 1px #D3D2DE;

}
.previewLanguageGroup p{
    width:100%; float:left;
    margin:0 0 12px;
    font:400 15px 'Rubik', sans-serif;
    color:#667581;
}
.previewLanguageContent{
    width:100%; float:left;
    display:flex;
    flex-wrap:wrap;
}
.ui.button.btnDropdownPopup{
    flex:1;
    background:none;
    border-radius:0;
    border:none;
    padding:0;
    height:25px;
    text-align:right;
}
.previewLanguageContent .ui.button:not(.btnDropdownPopup){
    padding:0;
    background:none;
    border-radius:0;
    font:400 15px 'Rubik',sans-serif;
    color:#99A9C4;
    margin:0 15px -1px 0;
}
.previewLanguageContent .ui.button.active:not(.btnDropdownPopup){
    font-weight:500;
    color:#727AFF;
    box-shadow:inset 0 -2px 0 0 #727AFF;
}
.previewLanguageContent .ui.button:nth-last-child(2){
    margin-right:5px;
}

.ui.popup.previewLanguagePopup{
    width:245px;
    max-width:245px;
    max-height:303px;
    overflow-y:auto;
    border-radius:2px;
    box-shadow:0 3px 12px rgba(0,0,0,.2);
    padding:20px 15px 15px;
}
.ui.bottom.center.popup.previewLanguagePopup:before{
    box-shadow:-1px -1px 0 0 rgb(0,0,0,.1);
}
.ui.popup.previewLanguagePopup > div{
    width:100%; float:left;
    margin:0 0 10px;
    align-items:center;
    font:400 14px 'Rubik',sans-serif;
}
.ui.popup.previewLanguagePopup > div .ui.checkbox{
    margin:0 10px 0 0;
    line-height:20px;
}
.ui.popup.previewLanguagePopup > div > div:last-child{
    padding:2px 0 0;
}
.ui.popup.previewLanguagePopup > div strong{
    font-weight:500;
}
/**/



.storeContentFlex{
    width:100%; float:left;
    display:flex;
}
.storeContentFlex .inputGroupWrap:last-child{
    padding-left:25px;
    max-width:98px;
}
.storeContentFlex .inputGroupWrap{
}

.textSizeGroup{
    width:100%; float:left;
    border-bottom:solid 1px #D3D2DE;
    display:flex;
    justify-content:space-between;
}
.textSizeGroup .ui.button.buttonLink{
    font-size:12px;
    padding:0 0 2px;
}
.textSizeGroup .ui.button.buttonLink:nth-child(2){
    font-size:14px;
    font-weight:500;
}
.textSizeGroup .ui.button.buttonLink:last-child{
    font-size:15px;
    font-weight:600;
}

.phoneDropGroup{
    width:100%; float:left;
    display:flex;
}
.phoneDropGroup .ui.dropdown{
    max-width:32px;
    height:23px;
}
.phoneDropGroup .ui.dropdown:after{
    content:'';
    width:1px;
    height:17px;
    border-right:solid 1px #D3D2DE;
    position:absolute;
    right:0;
    top:1px;
}
.phoneDropGroup .ui.input input{
    height:23px;
    padding:0 0 0 10px;
}

.keywordsGroup{
    width:100%; float:left;
}
.keywordsGroup .ui.label{
    padding:1px 10px 2px!important
}


.workingDetailItem{
    width:100%; float:left;
    display:flex;
    align-items:flex-start;
    border-bottom:solid 1px #D3D2DE;
    font:400 14px 'Rubik',sans-serif;
    padding:13px 0;
    position:relative;
}
.workingTitle{
    margin:0;
    min-width:150px;
}
.workingDetailBody{
    flex:1;
}
.workingStatus{
    margin-left:auto;
    color:#17C0AC;
}
.workingDetailItem.closed .workingStatus{
    color:#99A9C4;
}

.workingDetailItem .ui.buttonIcon{
    width:auto;
    font-size:14px;
    display:inline-block;
    margin:3px 0 0!important;
    padding:0!important;

}

.workingTime{
    width:100%; float:left;
    display:flex;
    align-items:center;
}
.workingTime > span{
    width:14px;
    height:1px;
    border-bottom:solid 1px #000;
    margin:0 10px;
}

.hoursCheckbox{
    width:100%; float:left;
    padding:12px 0 0;
}
.hoursCheckbox .ui.checkbox{
    width:auto;
    margin:0 30px 0 0;
}
.hoursCheckbox .ui.checkbox label{
    font:400 14px/20px 'Rubik',sans-serif;
}

.workingTime .ui.dropdown{
    max-width:90px;
    height:24px;
    border-bottom:solid 1px #D3D2DE;
}
.workingTime .ui.dropdown img{
    float:right;
    margin:8px 0 0;
}

.brandingListingWrapper .buttons-wrap .buttons-group{
    flex-direction:row;
    width:auto;
    height:31px;
    border-radius:4px;
}
.brandingListingWrapper .buttons-wrap .buttons-group .ui.button{
    width:34px;
    min-width:34px;
    height:31px;
    border-bottom:none;
    border-radius:0;
    border-right:solid 1px #d3d2de;
}
.brandingListingWrapper .buttons-wrap .buttons-group .ui.button:first-child{
    border-top-left-radius:4px;
    border-bottom-left-radius:4px;
}
.brandingListingWrapper .buttons-wrap .buttons-group .ui.button:last-child{
    border-top-right-radius:4px;
    border-bottom-right-radius:4px;
}

.brandingListingWrapper .inventorySubheaderRight{
    position:relative;
    margin:0!important;
}
.brandingListingWrapper .inventorySubheaderRight .ui.buttonIcon{
    position:absolute;
    bottom:-40px;
    right:0;
    white-space:nowrap;
    z-index:1000;
}

.inventorySubheaderRight .listViewUploadButton.ui.buttonIcon {
    z-index: 0;
}