.ui.popup.previewLangDropdownPopup{
    max-width:237px;
    width:100%;
    border-radius:0;
    border:none;
    border-top:solid 1px #7d7d7d;
    box-shadow:0 2px 5px rgba(0,0,0,.2);
    right:auto!important;
    top:-10px!important;
    left:-14px!important;
}
.previewLangItem{
    width:100%; float:left;
    display:flex;
    align-items:center;
    cursor:pointer;
    margin:0 0 10px;
}
.previewLangItem strong{font-weight:600;}

.checkboxLangTitle{
    flex:1;
    display:flex;
    align-items:center;
    font:400 15px 'Rubik',sans-serif;
    margin:0 0 0 10px;
}
.ui.checkbox.checkboxLang label:before{
    top:1px;
}

.ui.checkbox.checkboxLang label:before{
    width:16px;
    height:16px;
    border-radius:2px;
    border-color:#D3D2DE!important;
    opacity:1!important
}

.ui.checkbox.checkboxLang label:hover:before{
    border-color:#B1B0BF!important;
}

.ui.checkbox.checkboxLang label:after{
    display:none;
}
.ui.checkbox.checkboxLang input:checked~label:before{
    background:url(/img/icon-check-orange.svg) no-repeat center;
    border:solid 1px #F15944!important;
}

.ui.checkbox.checkboxLang input:indeterminate~.box:before, 
.ui.checkbox.checkboxLang input:indeterminate~label:before{
    border-color:#F15944!important;
}

.ui.checkbox.checkboxLang:not(:checked) label{
    cursor: pointer;
}


