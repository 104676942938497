.images-recycle-bin-modal.ui.modal.visible{
    width: 750px;
    height: 600px;
    padding: 30px 34px;
    color: #000000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
}

.images-recycle-bin-modal.ui.modal.visible .header{
    padding: 0px !important;
    padding-bottom: 12px !important;
    font: 500 18px "Rubik", sans-serif;
}

.images-recycle-bin-modal.ui.modal.visible .content{
    padding: 0;
    height: 89%;
}

.images-recycle-bin-modal.ui.modal.visible .actions{
    margin-bottom: 0 !important;
}