.inputGroupWrap{
    width:100%; float:left;
    margin:0 0 25px;
}

.inputGroupWrap > p{
    width:100%; float:left;
    font:400 15px 'Rubik',sans-serif;
    color:#667581;
    margin:0 0 5px;
}

.inputGroupWrap .content,
.inputGroupWrap .ui.input,
.ui.dropdown{
    width:100%; float:left;
    margin:0;
}

.inputGroupWrap .content{
    position:relative;
}

.inputGroupWrap .ui.input>input,
.inputGroupWrap .ui.dropdown{
    width:100%; float:left;
    outline:0;
    font:400 15px "Rubik",sans-serif;
    background:#fff;
    color:#000;
    border:none;
    border-radius:0;
    border-bottom:1px solid #d3d2de!important;
    margin:0;
    padding:0 0 1px;
    position: relative;
}
.inputGroupWrap .ui.input>input::placeholder{
    opacity:1!important;
    color:#7f7f7f;
}
.inputGroupWrap .ui.input>input:focus{
    border-bottom:1px solid #737aff!important;
}

.inputGroupWrap .ui.input>input.error{
    border-color: red!important;
}

.inputGroupWrap .ui.search.dropdown .menu {
    border-radius: 2px;
    margin-top: 0!important;
    min-width: 100%;
    border-color: #d3d2de;
    box-shadow:0 3px 12px rgba(0,0,0,0.2);
    position: absolute;
    top: 100% !important;
    height: max-content;
}

.inputGroupWrap .ui.search.dropdown .menu:after{
    display:none;
}

.inputGroupWrap .ui.dropdown .menu>.item {
    font: 400 15px 'Rubik', sans-serif;
}

.inputGroupWrap .ui.dropdown .menu .selected.item, 
.inputGroupWrap .ui.dropdown.selected {
    font-weight: 500;
}

.inputGroupWrap .ui.dropdown>i.icon {
    color: #939ca3;
    float:right;
    margin-right:0;
}