.infinite-scroll-grid{
 display: grid;
  grid-template-columns: repeat(auto-fill, minmax(0, 100px));
  grid-auto-rows: 100px;
  gap: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
  border: 1px solid #d3d2de;
  border-radius: 4px;
}

.infinite-scroll-grid::-webkit-scrollbar {
  width: 4px;
}

/* .infinite-scroll-next-set-trigger{
    width: 100%;
    height: 100%;
} */