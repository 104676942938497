.ui.dropdown.manageCategoryLangSelector {
    box-sizing: border-box;
    width: 59px;
    height: 30px;
    padding: 5px 0px 0px 9px;
    background: #FFFFFF;
    border: 1px solid #D3D2DE;
    box-shadow: inset 0px 0px 0px 1px rgba(34, 36, 38, 0.15);
    border-radius: 4px;

}