.archibus-trigger {
    border: #d2d2d2;
    border-width: 0.5px;
    border-style: solid;
    height: 53px;
    margin-top: 5px;
    cursor: pointer;
}

.archibus-trigger img {
    padding-top: 3px;
    margin-left: 11px;
    float: left;
}

.archibus-trigger p {
    float: right;
    margin-right: 37px;
    margin-top: 15px;
}