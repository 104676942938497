.map-editor-map {
    width: 100%;
    float: left;
    height: 100%;
    overflow: hidden;
    z-index: 0;
    position: fixed;
}

.subheaderMapEditor {
    display: flex;
    align-items: center;
}

.activeUsersGroup {
    width: auto;
    display: inline-flex;
    align-items: center;
}

.activeUsersGroup p {
    margin: 0 10px 0 0;
    font: 400 13px 'Rubik', sans-serif;
}

.search-radio-checkbox {
    padding-right: 10px;
}

.activeUsersGroup>span {
    min-width: 20px;
    width: 20px;
    height: 20px;
    margin: 0 0 0 5px;
    background: #D3D2DE;
    border-radius: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font: 400 10px 'Rubik', sans-serif;
    color: #667581;
    cursor: pointer;
}

.featureSearchBar {
    padding-top: 10px;
}

.subheaderEditorRight {
    margin-left: auto;
    display: inline-flex;
    align-items: center;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.undoGroup {
    display: inline-flex;
    margin: 0 20px 0 0;
    position: relative;
}

.undoGroup .ui.buttonIcon {
    min-width: 30px;
    width: 30px;
    height: 30px;
    border: solid 1px #D3D2DE;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.undoGroup .ui.buttonIcon img {
    margin: 0;
}

.undoGroup .ui.buttonIcon:first-child {
    border-radius: 3px 0 0 3px;
}

.undoGroup .ui.buttonIcon:last-child {
    border-left: none;
    border-radius: 0 3px 3px 0;
}

.undoGroup .ui.buttonIcon:last-child img {
    transform: scaleX(-1);
}

.ui.popup.popupToolButtonEditor {
    padding: 5px 10px;
    border: none;
}

.ui.popup.popupToolButtonEditor:before {
    box-shadow: none;
}

.ui.toolButtonEditor {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px !important;
}

.ui.toolButtonEditor img {
    width: 15px !important;
}

.ui.toolButtonEditor:not(.active) img {
    filter: saturate(0.6) contrast(.5) hue-rotate(-13deg) brightness(1.15);
}

.ui.toolButtonEditor.active img {
    filter: saturate(2);
}

.unlockWrap {
    margin: 0 8px 0 12px;
    display: flex;
    align-items: center;
}

.ui.buttonIcon.lockSize img {
    width: 14px;
}

.ui.button.buttonLocked {
    background: none;
    border: solid 1px #c0cbda !important;
    border-radius: 2px;
    padding: 8px 10px;
    font: 400 13px 'Rubik', sans-serif;
    margin: 0 10px 0 10px;
}


.mapEditorLeftbar {
    width: 50px;
    height: calc(100vh - 109px);
    background: #fff;
    border-right: solid 1px #D3D2DE;
    position: fixed;
    top: 109px;
    left: 0;
    z-index: 3;
}

.ui.buttonIcon.triggerEditor {
    width: 100%;
    height: 30px;
    float: left;
    padding: 0;
    border-radius: 0;
    background: #F4F7FC !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.ui.buttonIcon.triggerEditor.isActive img {
    transform: rotate(180deg);
}

.mapEditorSidebarMenu {
    width: 100%;
    float: left;
    padding: 10px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ui.button.mapEditorSidebarMenuItem {
    width: 30px;
    height: 30px;
    padding: 0 !important;
    margin: 0 0 10px;
    background: #fff;
    border: solid 1px #D3D2DE;
    border-radius: 3px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.ui.mapEditorSidebarMenuItem:focus {
    background: #fff;
}

.ui.mapEditorSidebarMenuItem:last-child {
    padding: 5px 0 0 5px;
}

.ui.mapEditorSidebarMenuItem:hover {
    background: #F4F7FC;
}

.ui.mapEditorSidebarMenuItem.active,
.ui.mapEditorSidebarMenuItem.active:hover {
    background: #F4F7FC;
    border-color: #F15944;
}

.ui.mapEditorSidebarMenuItem.disabled {
    opacity: .5;
}

.mapEditorScroll>div:nth-child(2) {
    display: none;
}

.mapEditorSidebar {
    width: 200px;
    height: calc(100vh - 109px);
    background: #fff;
    position: fixed;
    top: 109px;
    left: 51px;
    box-shadow: 2px 0 4px rgba(0, 0, 0, .1);
}

.mapEditorSidebar>.ui.header {
    width: 100%;
    float: left;
    height: 30px;
    padding: 0 0 0 10px;
    margin: 0;
    background: #F4F7FC;
    font: 400 13px 'Rubik', sans-serif;
    color: #000;
    display: flex;
    align-items: center;
}

.alignerMapEditor {
    width: 100%;
    float: left;
    padding: 0 10px;
    overflow: hidden;
}

.mapEditorSidebarGroup {
    width: 100%;
    float: left;
    border-bottom: solid 6px #F4F7FC;
    padding: 12px 0 10px;
}

.ui.mapEditorSidebarItem {
    width: 100%;
    float: left;
    padding: 5px 0;
    margin: 0 0 5px;
    background: none !important;
    border-radius: 0;
    display: flex;
    align-items: center;

    font: 400 13px 'Rubik', sans-serif;
    color: #000;
}

.ui.mapEditorSidebarItem span {
    min-width: 15px;
    width: 15px;
    margin: 0 10px 0 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.ui.mapEditorSidebarItem:not(.active) span img {
    filter: saturate(.1) brightness(0.9);
}

.ui.mapEditorSidebarItem.active {
    color: #727AFF !important;
}

.ui.button.buttonIcon.shortcutsPopupTrigger {
    width: 100%;
    border-top: solid 1px #D3D2DE;
    border-radius: 0;
    padding: 10px 0 10px 10px;
    position: absolute;
    left: 0;
    bottom: 0;
    color: #000 !important;
}

.ui.button.buttonIcon.shortcutsPopupTrigger img {
    margin-left: 0;
}

.ui.button.buttonIcon.shortcutsPopupTrigger.mapEditorLegendTrigger {
    bottom: 35px;
}

.ui.popup.shortcutsPopup {
    width: 180px;
    max-width: 180px;
    padding: 10px;
    border-radius: 4px;
    border: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, .2);
}

.shortcutsPopupItem {
    width: 100%;
    float: left;
    font: 400 13px 'Rubik', sans-serif;
    display: flex;
    align-items: center;
    margin: 0 0 5px;
}

.shortcutsPopupItem span {
    width: 48%;
    color: #667581;
    margin-left: auto;
}


.ui.popup.mapEditorLegendPopup {
    width: 180px;
    max-width: 180px;
    padding: 10px;
    border-radius: 4px;
    border: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, .2);
}

.ui.popup.mapEditorLegendPopup .ui.header {
    font: 500 15px 'Rubik', sans-serif;
}

.mapEditorLegendItem {
    width: 100%;
    float: left;
    font: 400 13px 'Rubik', sans-serif;
    display: flex;
    align-items: center;
    margin: 0 0 5px;
}

.mapEditorLegendItem span {
    min-width: 20px;
    width: 12px;
    height: 2px;
    border-radius: 100px;
    background: #333;
    margin: 0 15px 0 0;
}

.mapEditorLegendItem:nth-child(2) span {
    background: #F15944;
}

.mapEditorLegendItem:nth-child(3) span {
    background: #0020FF;
}

.mapEditorLegendItem:last-child span {
    background: #000000;
}


.mapEditorSidebarAccordion {
    width: 100%;
    float: left;
}

.mapEditorSidebarAccordion .title:not(.ui) {
    width: 100%;
    height: 40px;
    float: left;
    border-bottom: solid 6px #F4F7FC;
    font: 400 13px 'Rubik', sans-serif !important;
    color: #000;
    position: relative;
    display: flex;
    align-items: center;
}

.mapEditorSidebarAccordion .title:before {
    content: '';
    width: 11px;
    height: 6px;
    background: url(/img/icon-editor-chevron-down.svg) no-repeat right;
    position: absolute;
    right: 0;
    transition: all .3s ease;
}

.mapEditorSidebarAccordion .title.active {
    border-color: #fff;
}

.mapEditorSidebarAccordion .title.active:before {
    transform: rotate(180deg);
}

.mapEditorSidebarAccordion .title.active:after {
    content: '';
    width: 220px;
    height: 5px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, .1);
    position: absolute;
    bottom: 0;
    left: -10px;
}

.mapEditorContentbody {
    width: 100%;
    float: left;
    height: calc(100vh - 110px);
    max-height: calc(100vh - 110px);
    overflow-y: auto;
    padding: 0 0 0 251px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}



.mapEditorSidebarAccordion>.content {
    width: 100%;
    float: left;
}

.mapLayerItem {
    width: 100%;
    float: left;
    height: 30px;
    display: flex;
    align-items: center;
    border-bottom: solid 1px #D3D2DE;
    font: 400 13px 'Rubik', sans-serif !important;
    position: relative;
}

.mapLayerItem:hover {
    background: #F4F7FC;
}

.mapLayerItem:before,
.mapLayerItem:after,
.ui.buttonIcon.smartFilterItem:before,
.ui.buttonIcon.smartFilterItem:after {
    content: '';
    width: 10px;
    height: 100%;
    background: #fff;
    border-bottom: solid 1px #D3D2DE;
    position: absolute;
    top: 1px;
}

.mapLayerItem:before,
.ui.buttonIcon.smartFilterItem:before {
    left: -10px;
}

.mapLayerItem:after,
.ui.buttonIcon.smartFilterItem:after {
    right: -10px;
}

.mapLayerItem:hover:before,
.mapLayerItem:hover:after,
.ui.buttonIcon.smartFilterItem:hover:before,
.ui.buttonIcon.smartFilterItem:hover:after {
    background: #F4F7FC;
}

.mapLayerItem .ui.button.buttonIcon {
    min-width: 20px;
    width: 20px;
    height: 20px;
    margin: 0 5px 0 0;
    color: #000;
    border: solid 1px #D3D2DE;
    border-radius: 2px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.ui.popup.mapLayerPopup {
    border: none;
    font: 400 15px 'Rubik', sans-serif;
    padding: 10px 12px;
}

.ui.popup.mapLayerPopup:before {
    box-shadow: none !important;
}

.ui.button.buttonIcon.buttonEye.disabled img {
    filter: saturate(0);
}

.ui.button.selectedLayerPointer {
    position: absolute;
    right: 10px;
    width: 9px;
    height: 9px;
    min-height: 0;
    padding: 0;
    border-radius: 10px;
    border: solid 1px #D3D2DE;
    background: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.ui.button.selectedLayerPointer:before {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 5px;
    border: solid 1px #667581;
}

.smartFilterRadioGroup {
    width: 100%;
    float: left;
    margin: 15px 0 10px;
}

.smartFilterRadioGroup .ui.checkbox.radio.checkboxGroup {
    margin: 0 0 10px;
}

.smartFilterRadioGroup .ui.checkbox.checkboxGroup label {
    display: flex;
    align-items: center;
    padding: 0;
}

.smartFilterRadioGroup .ui.checkbox.checkboxGroup label:before {
    position: relative;
    min-width: 15px;
    margin: -3px 10px 2px 0;
}

.ui.button.smartFilterSearchButton {
    float: right;
    background: none !important;
    border: solid 1px #F15944;
    border-radius: 3px;
    color: #F15944 !important;
}

.smartFilterGroup {
    width: 100%;
    float: left;
    margin: 20px 0 0;
}

.ui.buttonIcon.smartFilterItem {
    width: 100%;
    float: left;
    height: auto;
    min-height: 30px;
    padding: 3px 0;
    border-top: solid 1px #D3D2DE;
    border-radius: 0;
    font: 400 13px 'Rubik', sans-serif;
    color: #000 !important;
    position: relative;
    text-decoration: none;
    text-align: left;
}

.ui.buttonIcon.smartFilterItem {
    border-bottom: none;
    border-top: solid 1px #D3D2DE;
    text-decoration: none;
}

.ui.buttonIcon.smartFilterItem:hover,
.ui.buttonIcon.smartFilterItem.active {
    background: #F4F7FC !important;
}

.ui.buttonIcon.smartFilterItem.active {
    color: #727aff !important;
    font-weight: 500;
    position: relative;
    padding-right: 18px;
}

.ui.buttonIcon.smartFilterItem.active:before {
    content: '';
    width: 10px;
    height: 100%;
    background: #F4F7FC !important;
    position: absolute;
    left: -10px;
    top: -1px;
}

.ui.buttonIcon.smartFilterItem>img {
    position: absolute;
    right: 0;
}

.ui.buttonIcon.smartFilterItem:before,
.ui.buttonIcon.smartFilterItem:after {
    border-bottom: none;
    border-top: solid 1px #D3D2DE;
    top: -1px;
}

.mapEditorActivitySidebar h2.ui.header {
    margin-bottom: 0;
    display: flex;
    align-items: center;
}

.mapEditorSidebarMenuItem {
    width: 100%;
    float: left;
    padding: 15px 0;
    display: flex;
    align-items: center;
    border-bottom: solid 1px #D3D2DE;
}

.mapEditorSidebarMenuItem .ui.image {
    min-width: 24px;
    width: 24px;
    margin: 0 10px 0 0;
}

.mapEditorSidebarMenuItem .ui.image img {
    width: 100%;
}

.mapEditorSidebarMenuItem p {
    flex: 1;
    font: 400 15px 'Rubik', sans-serif;
    color: #000;
}

.mapEditorSidebarMenuItem p span {
    display: block;
    color: #667581;
    font-size: 12px;
}

/***/


.mapEditorEditInfoSidebar {
    width: 400px;
    height: calc(100vh - 109px);
    background: #fff;
    position: fixed;
    top: 109px;
    right: 0;
    z-index: 10000;
    box-shadow: 2px 0 4px rgba(0, 0, 0, .1);
}

.headerSidebar {
    width: 100%;
    float: left;
    margin: 0 0 15px;
    padding: 15px 15px 0;
    display: flex;
    align-items: center;
}

.headerSidebar .ui.header {
    font: 500 20px 'Rubik', sans-serif;
    color: #000;
    margin: 0;
}

.headerSidebar .ui.button {
    margin-left: auto;
    min-width: 70px;
    height: 30px;
    font: 500 13px/30px 'Rubik', sans-serif;
}

.alignerMapRightbar {
    width: 100%;
    float: left;
    padding: 0 15px;
}

.mapEditorEditInfoSidebar .textGroup>p {
    color: #667581;
}



.ui.modal.modalTransitionEdit {
    width: 500px;
}

.ui.modal.modalTransitionEdit>.actions {
    padding: 0 40px;
    margin: 20px 0 0;
}

.ui.modal.modalTransitionEdit>.actions .ui.button {
    margin: 0;
}


.transitions-box {
    background: #fff;
    width: 400px;
    padding: 20px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 110px;
    left: 50%;
    z-index: 15;
    transform: translateX(-50%);
}

.transitionCheckboxGroup {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
}

.transitions-box .ui.checkbox {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    margin: 0 0 15px;
    font: 400 14px/1.45 'Rubik', sans-serif;
}

.transitions-box .ui.dropdown {
    height: 25px;
    margin: 0 0 15px;
    width: 200px;
    border-bottom: solid 1px #D3D2DE;
    font: 400 14px 'Rubik', sans-serif;
}

.transitions-box .ui.dropdown>.dropdown.icon {
    margin: 0;
    position: absolute;
    right: 0;
    top: 3px;
}

.transition-box-actions {
    width: 100%;
    float: left;
    margin: 10px 0 0;
}

.ui.button.primary.directionButton {
    min-width: 30px;
    height: 30px;
    padding: 5px;
    position: absolute;
    right: 15px;
    bottom: 80px;
}

.ui.button.primary.directionButton .icon {
    position: absolute;
    top: -3px;
    right: 8px;
}

.createTransitionPopupbox{
    width:330px;
    padding:15px;
    background:#fff;
    border-radius:0 0 10px 10px;
    position: fixed;
    left:50%;
    top: 109px;
    transform: translateX(-165px);
    z-index: 10;
    box-shadow:0 5px 12px rgba(0, 0, 0, .1);
}
.createTransitionPopupbox .ui.dropdown .menu{
    max-height:300px;
    overflow-y:auto;
}

.transitionCheckboxRow .textGroupContent{
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap;
    margin-top:6px;
}

.routeOrderGroup{
    width: 100%; float: left;
    position: relative;
}
.routeOrderItem{
    width: 100%; float: left;
    font:400 14px 'Rubik',sans-serif;
    color:#667090;
    margin:0 0 10px;
    position: relative;
    display: flex;
    align-items: center;
}
.routeOrderItem:before{
    content:'';
    min-width:8px;
    width:8px;
    height:8px;
    border-radius:100px;
    border:solid 1px #667090;
    margin:0 8px 0 0;
}
.routeOrderItem:after{
    content:'';
    background:url(../img/dots.svg) no-repeat center;
    width:2px;
    height:10px;
    position: absolute;
    left: 3px;
    bottom:-10px;
}
.routeOrderItem:nth-last-child(2):after{
    display: none;
}
.routeOrderGroup .ui.button.buttonIcon{
    position: absolute;
    right: 0;
    bottom:10px;   
}
.routeOrderGroup .ui.button.buttonIcon img{
    height:16px;
    width:16px;
}