.listview-n-advanced-filteration {
}

.ui.segment.pushable.listview-n-advanced-filteration-sidebar-bx {
  position: fixed;
  z-index: 999;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  top: 0;
}

.listview-n-advanced-filteration-sidebar-bx.ui.segement {
}

.listview-n-advanced-filteration-sidebar-bx .dimmed {
  /* background-color: #3737372e !important; */
}

.listview-n-advanced-filteration-sidebar.ui.sidebar.thin {
  width: 60vh !important;
  background-color: white !important;
  padding: 24px;
  padding-bottom: 0;
}

.listview-n-advanced-filteration-sidebar.ui.sidebar.thin .header-bx {
  position: relative;
}

.listview-n-advanced-filteration-sidebar.ui.sidebar.thin .ui.button.close {
  right: 16px !important;
  top: 0px;
}

.listview-n-advanced-filteration-sidebar.ui.sidebar.thin .header-main {
  margin-bottom: 32px !important;
}

.listview-n-advanced-filteration-sidebar.ui.sidebar.thin .main {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.listview-n-advanced-filteration-sidebar.ui.sidebar.thin .header-txt {
  margin-bottom: 0px !important;
}

.listview-n-advanced-filteration-sidebar.ui.sidebar.thin
  .header-txt
  p:first-child {
  font-size: 14px;
  font-weight: 400;

  color: #000;
}
.listview-n-advanced-filteration-sidebar.ui.sidebar.thin .input-bx.ui.input {
  border: 0 !important;
}
.listview-n-advanced-filteration-sidebar.ui.sidebar.thin
  .input-bx.ui.input
  input {
  padding: 12px 14px !important;
  height: max-content !important;
  border: 0.1px solid #b7b7b7 !important;
  font: 400 14px "Rubik", sans-serif;
  color: #667581;
  border-radius: 6px;
}
.listview-n-advanced-filteration-sidebar.ui.sidebar.thin
  .input-bx.ui.input
  input::placeholder {
  color: #667581 !important;
}

.listview-n-advanced-filteration-sidebar.ui.sidebar.thin .textGroupContent {
  margin-top: 6px !important;
}

.listview-n-advanced-filteration-sidebar.ui.sidebar.thin
  .ui.dropdown.dropdownForm {
  padding: 10px 14px !important;
  padding-right: 35px !important;
  align-self: stretch !important;
  border: 0.1px solid #b7b7b7 !important;
  border-radius: 6px;
  position: relative;
}

.listview-n-advanced-filteration-sidebar.ui.sidebar.thin
  .ui.dropdown.dropdownForm
  div:first-child {
  font-size: 14px !important;
}

.listview-n-advanced-filteration-sidebar.ui.sidebar.thin
  .ui.dropdown.dropdownForm
  img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 14px;
}

.listview-n-advanced-filteration-sidebar.ui.sidebar.thin .dropdown-group-bx {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 14px;
}

.listview-n-advanced-filteration-sidebar.ui.sidebar.thin .action-btn-bx {
  display: flex;
  gap: 12px;
  padding: 32px 0;
  justify-content: right;
  border-top: 0.2px solid #cdd1ff;
  margin-top: 32px;
}

.listview-n-advanced-filteration-sidebar.ui.sidebar.thin
  .action-btn-bx
  .button-apply {
  background: #f15944 !important;
  font: 500 13px "Rubik", sans-serif;
  padding: 12px;
  color: white !important;
}

.listview-n-advanced-filteration-sidebar.ui.sidebar.thin
  .action-btn-bx
  .button-clear {
  background: white !important;
  font: 500 13px "Rubik", sans-serif;
  padding: 12px;
  color: #727df7 !important;
  border: 0.2px solid #727df7 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listview-n-advanced-filteration-sidebar.ui.sidebar.thin
  .keywordsDropdown
  .divider.default.text {
  padding: 4px 14px !important;
}

.listview-n-advanced-filteration-sidebar.ui.sidebar.thin .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #667581;
}
