.addBlock {
  display: inline-block;
  position: relative;
  right: 0px;
  z-index: 999999;
}

.uploadInput {
  display: none;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  cursor: pointer;
  background-color: white;
  width: 100%;
  z-index: 888;
  box-shadow: 0 0px 4px #a3a3a3;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.dropdown_item {
  padding: 5px 3.5px;
  font-size: 12px;
  cursor: pointer;
}

.label{
  cursor: pointer;
}

.dropdown_item:not(:last-child) {
  border-bottom: solid 1px #d3d2de;
}

.uploadTrigger {
  background-color: white !important;
  display: flex !important;
  align-items: center;
  border-radius: 4px !important;
  border: 1px solid #F15944 !important;
  padding: 5.2px !important;
  color: #F15944 !important;
}

.uploadTrigger span {
  padding-right: 0.8rem;
  margin-right: 8px;
  border-right: 1px solid #F15944 ;
}

.uploadTrigger img{
  filter: invert(40%) sepia(85%) saturate(2073%) hue-rotate(338deg) brightness(104%) contrast(90%) !important;
}

