.settingsPageContainer{
    width:100%; float:left;
    background:#fff;
    padding:0 20px 0 0;
}
.settingsPageTab{
    width:100%; float:left;
    min-height:calc(100vh - 62px);
    display:flex;
}
.settingsPageTab > .ui.secondary.pointing.menu{
    width:304px;
    padding:0 20px;
    border-bottom:none;
    flex-direction:column;
}
.settingsPageTab > .ui.secondary.pointing.menu .item{
    width:100%; float:left;
    height:62px;
    padding:0;
    margin:0;
    border-bottom:solid 11px #F4F7FC!important;
    box-shadow:none;
    font:400 13px 'Rubik',sans-serif!important;
    text-transform:uppercase;

    display:flex;
    align-items:center;
}
.settingsPageTab > .ui.secondary.pointing.menu .item .icon {
    margin: -4px 0 0 5px;
}
.settingsPageTab > .ui.secondary.pointing.menu .item:last-child{
    border:none!important;
}
.settingsPageTab > .ui.secondary.pointing.menu .active.item{
    font-weight:500!important;
    color:#727AFF!important;
}

.settingsPageTab > .ui.attached.segment{
    border-radius:0;
    border:none;
    border-left:solid 1px #C0CBDA;
    padding:0;
}
.settingsPageTab .settingsPageTabPane{
    width:100%;
    float:left;
    max-width:735px;
    padding:20px 20px 20px 75px;
}

.settingsPageTabCover{
    width:100%; float:left;
    padding:0 0 20px;
    margin:0 0 20px;
    border-bottom:solid 1px #D3D2DE;
}
.settingsPageTabCover:last-child{
    border-bottom:none;
}

.settingsPageHeading{
    width:100%; float:left;
    margin:0 0 20px;
    position:relative;
}
.settingsPageHeading h2.ui.header,
.settingsPageHeading .ui.header{
    font:600 18px 'Rubik',sans-serif;
    color:#000;
    margin:0 0 15px;
}
.settingsPageHeading h2.ui.header > img{
    width:20px;
    position:absolute;
    left:-40px;
}
.settingsPageHeading .ui.header{
    margin:10px 0 15px;
    font-size:15px;
}

.paragraph{
    width:100%; float:left;
    margin:0 0 20px!important;
    font:400 15px/20px 'Rubik',sans-serif;
    color:#969DB8;
}
.settingsPageTabCover > .paragraph:last-child{
    margin-bottom:0!important;
}

.settingsPageHeading .paragraph{margin:0!important}

.settingsPageTabCover .ui.buttonIcon{
    font-size:15px;
}

.publicUrlWrap{
    width:100%; float:left;
    display:flex;
    align-items:flex-start;
}
.publicUrlWrap .textGroup .ui.input>input{
    height:34px;
}
.publicUrlWrap .publicUrlNumber{
    width:auto;
    max-width:100%;
    height:34px;
    padding:5px 10px;
    margin-left:30px;
    background:#F4F7FC;
    border:solid 1px #D3D2DE;
    color:#667581;
    display:inline-flex;
}
.publicUrlWrap .publicUrlNumber > p,
.publicUrlWrap .publicUrlNumber > .textGroupContent{
    color:#667581;
    font-size:13px;
}
.publicUrlNumber span{
    border-radius:2px;
    background:#D3D2DE;
    color:#000;
    padding:5px 8px;
}
.publicUrlWrap > .textGroup:first-child{
    width:250px;
    margin-bottom:10px;

}

.settingsPageHalfCover{
    width:100%;
    max-width:320px;
}
.settingsPageHalfCover .ui.checkbox.checkboxGroup{
    width:auto;
    min-width:120px;
    margin:0 0 10px!important;
}
.placeholderSearchMap{
    width:100%; float:left;
    padding:15px 20px 0;
    margin:20px 0;
    display:flex;
    justify-content:space-between;
    align-items:flex-start;
    background:#F4F7FC;
    border:solid 1px #D3D2DE;
}
.placeholderSearchMap .textGroup{
    width:337px;
    padding:0 0 15px;
    margin:0;
}
.placeholderSearchMap .textGroup > p{
    font:400 15px 'Rubik',sans-serif;
    color:#99A9C4;
}
.settingsPageHalfCover .textGroup .ui.input>input::placeholder{
    color:#000;
}
.settingsPageHalfCover .textGroup>p{
    min-width:125px;
}

.settingsPageTabCover .ui.checkboxGroup{
    width:auto;}
.settingsPageTabCover .ui.checkboxGroup label{
    font-size:15px;
}
.settingsPageTabCover .ui.checkboxGroup.fluid label{
    width:auto;
    float:left;
}

.settingsPageHalfCover .textGroupFlexible .ui.input>input::placeholder{
    color:#666!important;
}

.indoorMapssettingsPage .ui.checkboxGroup{
    min-width:90px;
}

.zoomScreenViewCover{
    width:max-content;
    position:absolute;
    top:40px;
    display: flex;
    flex-direction: column;
    transform: translateX(-50%);
    left:50%;
    z-index:10000;
}
.zoomScreenButtonGroup{
    width:100%; float:left;
    display:flex;
    align-items:center;
    justify-content:center;
}
.zoomScreenButtonGroup .ui.buttonIcon{
    width:83px;
    height:55px;
    background:#fff!important;
    border-radius:4px;
    border:solid 1px #d3d2de;
    margin:0 10px 20px;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    color:#000!important;
    text-decoration:none!important;
}
.zoomScreenButtonGroup .ui.buttonIcon img{
    width:auto;
    height:15px;
    margin:0 0 5px;
}
.zoomScreenButtonGroup .ui.buttonIcon:not(.active) img{
    filter:saturate(.8) brightness(1.3);
}
.zoomScreenButtonGroup .ui.buttonIcon.active{
    border-color:#727aff;
    color:#727aff!important;
    box-shadow:0 3px 9px rgba(0,0,0,.25);

}
.zoomPercentage{
    font-size: 14px;
    line-height: 2;
}
.zoomPercentageBox{
    width: 29px !important;
}

.anchorProcessing{
    width:100%; float:left;
    display:flex;
    align-items: center;
    gap:8px
}

.processingBox{
    height:30px;
    border-radius:3px;
    background:#fff!important;
    border:solid 1px #D3D2DE;
    display:flex;
    align-items:center;
}

.processingBox{
    margin:0;
    padding:0 10px;
}
.processingBox span{
    height:30px;
    padding:0 6px;
    margin-left:16px;
    font:400 13px 'Rubik',sans-serif;
    color:#667581;
    border-left:solid 1px #D3D2DE;
    display:flex;
    align-items:center;
    justify-content:center;
}
.anchorProcessing .ui.button.primary{
    width: max-content !important;
    height:30px;
    border-radius:3px;
    background:#E75330;
    font:500 13px/30px 'Rubik',sans-serif;
}
.anchorProcessing .ui.button.grey{
    color:#000;
    background:#d3d2de;
}

#settingsMenuTitle {
    font-weight: 700!important;
    font-size: 1.2rem!important;
    text-transform: inherit;
    border-bottom:solid 30px #F4F7FC!important;
    padding-top: 30px;
    padding-bottom: 20px;
}

/***/

.settingsPageTabDefault{
    width:100%; float:left;
    margin:20px 0 0;
}
.settingsPageContentRow{
    width:100%; float:left;
    margin:0 0 35px;
    display:flex;
    align-items:flex-start;
}
.settingsPageContentBody{
    flex:1;
    padding-right:80px;
}

.settingsPageContentBody .app-logo-img{
    width: 50px !important;
    height: 50px !important;
    object-fit: cover;
}

.settingsPreviewBox{
    min-width:320px;
    width:320px;
    padding:15px 20px;
    background:#F4F7FC;
    border:solid 1px #D3D2DE;
    border-radius:3px;

    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
}
.ui.button.uploadLogoSettings{
    margin:15px 0 0;
}
.ui.button.addItemSettings{
    margin:0 0 30px;
}
.ui.button.addItemSettings img{
    margin-left:0;
}

.settingsPreviewBox > .ui.header{
    font:500 14px 'Rubik',sans-serif;
    margin:0 0 10px;
}
.settingsPreviewBox > .ui.header:first-child{
    margin-top:15px;
}
.settingsPreviewBox p{
    font:400 12px 'Rubik',sans-serif;
    text-align:center;
}
.previewLogoImg{
    width:52px;
    height:52px;
    min-width:52px;
    background:#fff;
    border:solid 1px #D3D2DE;
    font:400 12px 'Rubik',sans-serif;
    color:#7C88A2;
    border-radius:3px;
    display:flex;
    align-items:center;
    justify-content:center;
    margin:10px 0 16px;
}
.previewPlaceholderBox{
    width:100%;
    height:24px;
    margin:0 0 15px;
    background:#fff;
    box-shadow:inset 0 0 0 1px #D3D2DE;
    border-radius:3px 0 0 3px;
    display:flex;
    align-items:center;
}
.previewPlaceholderBox:last-child{
    margin:0;
}
.previewButtonGrey{
    min-width:25px;
    width:25px;
    height:14px;
    background:#D3D2DE;
    border-radius:2px;
    margin:0 10px 0 6px;
}
.placeholderExample{
    width:100%;
    height:8px;
    background:#D3D2DE;
    border-radius:2px;
    margin:0 0 8px;
}
.placeholderExample:last-child{
    margin:0;
}
.placeholderExample.small{
    width:65px;
}
.placeholderExample.dot{
    width:8px;
    background:#7C88A2;
}
.placeholderExample.dark{
    background:#7C88A2;
}

.previewPlaceholderBox .placeholderExample{
    margin-bottom:0;
}

.previewButtonOrange{
    width:70px;
    height:24px;
    border-radius:3px;
    background:#F15944;
    margin:0 0 10px;
}

.previewPlaceholderBox .previewButtonOrange{
    width:42px;
    min-width:42px;
    border-radius:0 3px 3px 0;
    margin:0 0 0 10px;
}

.previewBoxActions{
    width:100%;
    margin:0 0 10px;
    display:flex;
    align-items:center;
    justify-content:center;
}
.previewBoxActionButton{
    width:80px;
    height:24px;
    margin:0 7px;
    background:#000000;
    border-radius:3px;
    display:flex;
    align-items:center;
    justify-content:center;
}
.previewBoxActionButton span{
    width:40px;
    height:10px;
    border-radius:1px;
    background:#454545;
}
.previewBoxActionButton img{
    margin:0 8px 0 0;
}

.previewCustomizeBox{
    width:188px;
    border-radius:100px;
    padding:10px;
}
.previewCustomizeBox img{
    margin:0 10px 0 0;
}
.crossPreviewIcon{
    position:relative;
    min-width:10px;
    height:10px;
    margin:0 0 0 5px;
}
.crossPreviewIcon:before,
.crossPreviewIcon:after{
    content:'';
    width:1px;
    height:9px;
    border-right:solid 1px #000;
    position:absolute;
    top:1px;
    left:5px;
}
.crossPreviewIcon:before{
    transform:rotate(45deg);
}
.crossPreviewIcon:after{
    transform:rotate(-45deg);
}

.previewUl{
    width:100%; float:left;
    margin:0 0 15px;
    padding:0 30px;
    list-style:none;
}
.previewUl li{
    width:100%; float:left;
    margin:0 0 10px;
    padding:0 0 0 18px;
    font:400 12px 'Rubik',sans-serif;
    background:url(/img/icon-check-orange.svg) no-repeat 0 6px;
    background-size:8px auto;
}

.settingsCustomizePreviewBox{
    flex-direction:row;
}
.previewPlaceholderBoxInner{
    flex:1;
}
.settingsCustomizePreviewBox .previewButtonOrange{
    margin:0 0 0 20px;
}
.previewPlaceholderBoxInner .previewButtonOrange{
    margin:22px 0 8px;
}

.previewCustomizeRightbar{
    padding:0 0 0 20px;
    text-align:center;
}
.previewCustomizeRightbar .previewLogoImg{
    margin:10px 0 0;
}
.previewCustomizeRightbar .placeholderExample{
    float:none;
    display:inline-block;
    margin:3px 0 0;
}
.previewGap{
    width:100%;
    height:10px;
}
.previewGapBox{
    width:100%;
    margin:0 0 16px;
    padding:8px;
    border-radius:1px;
    border:solid 1px #D3D2DE;
    display:flex;
    align-items:center;
}
.previewGapBox .placeholderExample{
    margin:0;
}
.previewGapBox .placeholderExample.dot{
    margin:0 0 0 10px;
}

/*** mapsted map module updated ***/

.spaceCover{
    width:100%; float:left;
}
.buildingSettingCover{
  
    width: 100%;
    float: left;
    padding: 15px 20px 0;
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border: solid 1px #D3D2DE;
    flex-direction: column;
   
}

.settingsPageTab .spaceCover .settingsPageTabPane{padding-top:0;}

.settingsPageTabPaneModule{
    max-width:835px!important;
}
.settingsPageTabPaneModule .paragraph{
    color:#667581;
    margin-bottom:0!important;
}

.ui.button.mapstedModuleSectionItem{
    width:100%; float:left;
    min-height:60px;
    background:#fff;
    border:solid 1px #D3D2DE;
    border-radius:2px;
    padding:0 20px;
    margin:0 0 5px;
    position:relative;

    font:500 15px 'Rubik',sans-serif;
    color:#000;

    display:flex;
    flex-direction:column;
    justify-content:center;

    align-items: flex-start;
}
.ui.button.mapstedModuleSectionItem span{
    font-size:11px;
    font-weight:400;
    color:#667581;
}
.ui.button.mapstedModuleSectionItem i.icon{
    position:absolute;
    right:20px;
}

.zoomLevelItem{
    width:100%; float:left;
    margin:0 0 20px;
    display:flex;
    align-items:center;
}
.zoomLevelItem > .ui.image{
    min-width:24px;
    text-align:center;
    display:inline-flex;
    align-items:center;
    justify-content:center;
    margin:0 6px 0 0;
}
.zoomLevelItem p{
    min-width:200px;
    margin:0;
}
.zoomSlideLevelBox{
    width:184px;
    height:20px;
    border:solid 1px #D3D2DE;
    border-radius:2px;
    padding:0 5px;
    margin:0 15px 0 0;
}
.zoomSlideLevel{
    width:100%;
    height:8px;
    margin:0 0 1px;
    border-radius:20px;
    box-shadow:inset 0 0 0 1px #D3D2DE;
    display:inline-block;
    position:relative;
}
.zoomSlidLevelBar{
    width:50%;
    height:100%;
    float:left;
    background:#727AFF;
    position:relative;
    border-radius:12px 0 0 12px;
}
.zoomSlidLevelBar:after{
    content:"";
    width:12px;
    height:12px;
    background:#727AFF;
    border-radius:12px;
    box-shadow:0 0 5px rgba(0,0,0,.1);
    position:absolute;
    top:-2px;
    right:-6px;
}
.tgZoomLevel > p{
    min-width:230px;
}
.tgZoomLevel .ui.dropdown.dropdownForm{
    width:183px;
}
.tgZoomLevel .ui.dropdown.dropdownForm i.icon.caret{
    position:absolute;
    right:0;
    color:#939CA3;
}
.settingsPageContentBody .textGroup .ui.input input::placeholder{
    color:#B2BAC0;
}

/*** orientation modal ***/

.ui.modal.orientationModal{
    width:100%;
    max-width:1180px!important;
    padding-top: 25px;
}
.orientationModalbody{
    width:100%; float:left;
    margin:0 0 15px;
    border-bottom:solid 1px #D3D2DE;
    position:relative;
}

.ui.modal.orientationModal .map-container{
    height:calc(100vh - 255px);
}
.disableRotation .ol-rotate{
    top: 0.5em;
    right: 0.5em;
    transition: opacity .25s linear, visibility 0s linear;
    display: none;
}



.orientationModalbody img{
    float:left;
    max-width:100%;
}
.orientationMessage{
    height:36px;
    padding:0 15px;
    background:#fff;
    border:solid 1px #D3D2DE;
    border-radius:3px;
    box-shadow:0 0 4px rgba(0,0,0,.1);
    font:400 15px 'Rubik',sans-serif;
    display:inline-flex;
    align-items:center;

    position:absolute;
    top:20px;
    left:10px;
}

.settingsPageSubheading{
    display:flex;
    align-items:center;
    font-size:18px;
}
.settingsPageSubheading > img{
    transform:rotate(-90deg);
    margin:0 10px;
}
.settingsPageSubheading .ui.button{
    font:400 18px 'Rubik',sans-serif;
    color:#727AFF;
    padding:0;
    background:none;
}

.settingsPropertyRow .settingsPageContentBody{
    padding-right:40px;
}
.settingsPropertyRow .settingsPageContentBody .textGroup .ui.input{
    max-width:230px;
}
.settingsPropertyRow .settingsPageContentBody .textGroup > p{
    min-width:120px;
}

.settingsPropertyPreviewBox{
    min-width:272px;
    width:272px;
    padding:10px 0;
}
.placeholderBanner{
    width:100%; float:left;
    height:85px;
    margin:0 0 5px;
    background:#fff;
    border:solid 1px #D3D2DE;
    border-left:none;
    border-right:none;
    position:relative;
}
.placeholderBanner .previewLogoImg{
    position:absolute;
    left:20px;
    bottom:-10px;
    margin:0;
}
.settingsPropertyPreviewBox .previewPlaceholderBoxInner{
    width:100%;
    padding:15px 20px;
}
.settingsPropertyPreviewBox .placeholderExample{
    float:left;
}
.settingsPropertyPreviewBox .placeholderExample.small{
    width:85px;
}
.customizedPOICover{
    width:100%; float:left;
    max-width:350px;
}
.ui.input.coloredForm >input{
    background:#F4F7FC;
    border:none;
    border-radius:2px;
    padding:0 10px;
    color:#000;
}
.ui.input.coloredForm >input::placeholder{
    color:#000;
}

.tagPOIGroup{
    width:100%; float:left;
    margin:15px 0 0;
}
.tagPOI{
    height:30px;
    background:#F4F7FC;
    border-radius:2px;
    font:400 15px 'Rubik',sans-serif;
    display:inline-flex;
    align-items:center;
    padding:0 8px;
    margin:0 5px 5px 0;
}
.tagPOI .ui.buttonIcon{
    margin:0 0 0 10px;
}
.tagPOI .ui.buttonIcon img{
    width:10px;
}

.configureTabs{
    width:100%; float:left;
}
.configureTabs .ui.secondary.pointing.menu{
    border:none;
    border-bottom:solid 1px #D3D2DE;
}
.configureTabs .ui.secondary.pointing.menu .item{
    margin-right:30px;
}
.configureTabs > .ui.attached.segment{
    width:100%; float:left;
    padding:0;
    border-radius:0;
    border:none;
}

.configureMapOptions{
    width:100%; float:left;
    padding:20px 0;
    display:flex;
    flex-wrap:wrap;
}
.configureMapOptions .ui.checkboxGroup{
    width:33.3%;
    min-width:100px;
}
.configureMapBox{
    width:100%; float:left;
    padding:20px;
    margin:0 0 30px;
    background:#F4F7FC;
    border:solid 1px #D3D2DE;
    border-radius:3px;

    display:flex;
    align-items:flex-start;
}
.configureMapLeft,
.configureMapRight{
    flex:1;
    display:inline-flex;
    flex-direction:column;
}
.configureMapRight{
    align-items:flex-end;
}

.configureMapBox span{
    margin:0 0 10px;
}
.configureMapBox span img{
    float:left;
}
.configureMapBox .qrMap{
    margin-top:55px;
}

.configureMapBox span.disabled{
    opacity:.5;
}

.configureMapBoxMobile{
    width:248px;
    padding:20px 13.5px 50px;
    flex-direction:column;
    align-items:flex-end;

    position:relative;
}

.footerMapMobile{
    width:100%;
    margin:0!important;
    position:absolute;
    left:-1px;
    bottom:-2px;
}

.logoMapMobile{
    position:absolute;
    left:10px;
    top:20px;
}
.floorMobile{
    position:absolute;
    left:10px;
    bottom:50%;
}

/*** mapsted kiosk module ***/

.ui.fullscreen.modal{
    left:auto!important;
}

.ui.modal.addKioskModal >.header{
    margin-bottom:0;
}
.ui.modal.addKioskModal .modalMapWrap{
    border-top:none;
}

.contentGroup{
    width:100%; float:left;
    margin:0 0 20px;
}
.paraContent{
    color:#667581;
}

.contentGroupRow{
    width:100%; float:left;
    display:flex;
    align-items:flex-start;
}
.contentGroupBody{
    flex:1;
    padding:0 20px 0 0;
}
.ui.buttonIcon.buttonAddKiosk{
    font-size:13px;
}

.kioskNameTooltip{
    background:#fff;
    border-radius:2px;
    box-shadow:0 0 6px rgba(0,0,0,.1);
    padding:5px 10px;
    margin-bottom:20px;
    position:absolute;
    bottom:100%;
    left:-50%;
    transform:translate(-50%);
}
.kioskNameTooltip:after{
    content:'';
    background:#fff;
    width:10px;
    height:10px;
    transform:rotate(45deg);
    position:absolute;
    bottom:-5px;
    left:50%;
    margin-left:-5px;
    z-index:1;
}
.kioskNameTooltip p{
    float:left;
    max-width:100px;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    font:400 12px 'Rubik',sans-serif;
    color:#333;
    position:relative;
    z-index:2;
}

.kioskModuleTable{
    width:100%; float:left;
    margin:0 0 10px;
}
.kioskModuleTableRow{
    width:100%; float:left;
    display:flex;
    min-height:40px;
    border:solid 1px #D3D2DE;
    border-radius:3px;
    margin:0 0 5px;
}
.kioskModuleTableColumn{
    min-width:20%;
    width:30%;
    font:400 13px 'Rubik',sans-serif;
    color:#000;
    display:inline-flex;
    align-items:center;
    padding:0 0 0 10px;

}
.kioskModuleTableColumn p{
    font-size:14px;
    line-height:18px;
    max-width: 205px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.kioskModuleTableColumn > img:first-child{
    min-width:25px;
    margin:0 10px 0 0;
}
.kioskModuleTableColumn > .ui.buttonIcon:last-child{
    margin:0 15px 0 10px;
}
.kioskModuleTableColumn:last-child > .ui.buttonIcon:nth-last-child(2){
    margin-left:auto;
}
.kioskModuleTableColumn:nth-last-child(2) > .ui.buttonIcon{
    margin:0;
}
.kioskModuleTableColumn:last-child{
    width:100px;
}

.kioskModuleTableColumn:nth-child(2){
    flex:1;
}

.kioskModuleTable .kioskModuleTableRow:first-child{
    min-height:15px;
    border:none;
}
.kioskModuleTable .kioskModuleTableRow:first-child .kioskModuleTableColumn{
    color:#667581;
    font-size:12px;
}
.kioskModuleTable .kioskModuleTableRow:first-child .kioskModuleTableColumn:first-child{
    padding:0 0 0 45px;
}

.videoBoxThumb{
    min-width:300px;
    width:300px;
    display:inline-flex;
    flex-direction:column;
    margin-top:20px;
}

.videoThumb{
    width:100%; float:left;
    height:200px;
    background:#fff;
    border-radius:3px;
    border:solid 1px #D3D2DE;
    display:flex;
    align-items:center;
    justify-content:center;
}
.videoBoxThumb p{
    font:400 13px/17px 'Rubik',sans-serif;
    color:#667581;
    margin:8px 0 0;
}


.previewEmbedBox{
    min-width:272px;
    width:272px;
    background:#F4F7FC;
    border:solid 1px #D3D2DE;
    border-radius:3px;
    position:relative;
    padding:20px;
}

.sessionRefreshEmbed{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    position:relative;
}

.staticPlaceholder{
    display:inline-block;
    width:100%;
    height:8px;
    margin:0 0 10px;
    border-radius:2px;
    background:#D3D2DE;
}
.staticPlaceholder:last-child{
    margin:0;
}
.staticPlaceholder.dark{
    background:#7C88A2;
}
.sessionRefreshCounter{
    min-width:23px;
    height:23px;
    padding:0 4px;
    background:#fff;
    border:solid 1px #D3D2DE;
    border-radius:2px;
    font:400 13px/23px 'Rubik',sans-serif;
    color:#F15944;
    position:absolute;
    top:10px;
    right:10px;
}

.sessionRefreshTime{
    width:100%; float:left;
    margin:5px 0 0;
    font:400 15px 'Rubik', sans-serif;
    color:#000;
    display:flex;
    align-items:center;
}
.sessionRefreshTime .ui.input{
    padding:0 10px;
}
.sessionRefreshTime .ui.input>input{
    width:50px;
    height:28px;
    padding:0;
    font:400 15px 'Rubik', sans-serif;
    color:#000;
    text-align:center;
    border-radius:0;
    border:none;
    border-bottom:solid 1px #D3D2DE;
}
.sessionRefreshTime .ui.input>input:focus{
    border-color:#727AFF;
}

/*** add kiosk modal ***/


.ui.modal.addKioskModal{
    width:98%;
    max-width:1380!important;

}
.ui.modal.addKioskModal.scrolling{
    left:auto!important;
}

.modalSelectBox{
    width:100%; float:left;
    padding-right:15px;
}

.modalKioskWrap .modalMapSidebar{
    padding:15px 2px 50px 20px;
    border-right:none;
    border-left:solid 1px #D3D2DE;
    position:relative;
}
.modalKioskWrap .tgModalMap {
    padding-right:15px;
}
.modalKioskWrap .tgModalMap .textGroupContent .ui.button.buttonIcon{
    right:15px;
}
.modalKioskWrap .ui.input.searchIcon{
    width:100%;
}
.modalKioskWrap .colorSwatchesGroup{
    padding-right:0;
}
.modalKioskWrap .colorSwatchesGroup .colorCube{
    margin-right:7.5px;
    width:24px;
    height:24px;
    cursor:pointer;
}

.kioskListItem{
    width:100%; float:left;
    margin:0 0 10px;
    display:flex;
    align-items:center;
    font:400 15px 'Rubik',sans-serif;
    color:#000;
}
.kioskListItem p{
    margin:0 0 0 10px;
}
.kioskListActions{
    display:inline-flex;
    margin:0 0 0 auto;
}
.kioskListActions .ui.buttonIcon{
    margin:0 0 0 10px;
}

.ui.button.buttonAddKioskFloat{
    position:absolute;
    right:0;
    bottom:15px;
    min-width:90px;
}

/**/
.addEditKioskPopup{
    width:254px;
    padding:20px 15px;
    background:#fff;
    border-radius:3px;
    box-shadow:0 2px 8px rgba(0,0,0,.15);
    position:absolute;
    left:-127px;
    bottom:100%;
    margin-bottom:30px;
    z-index:1000;
}
.addEditKioskPopup:after{
    content:'';
    width:20px;
    height:20px;
    background:#fff;
    position:absolute;
    bottom:-10px;
    left:50%;
    transform:translate(-50%) rotate(45deg) skew(10deg, 10deg);
    box-shadow:4px 4px 6px rgba(0,0,0,.15);
}

.addEditKioskPopup > .ui.header{
    font:600 15px 'Rubik',sans-serif;
    color:#000;
    padding:0 0 10px;
    border-bottom:solid 1px #D3D2DE;
}
.addEditKioskPopup .para{
    font:400 13px 'Rubik',sans-serif;
    color:#99A9C4;
    margin:0 0 10px;
}

.tgKiosk{
    display:flex;
    align-items:center;
    border-bottom:solid 1px #D3D2DE;
    padding:0 0 5px;
}
.tgKiosk p{
    margin:0;
}
.tgKiosk .textGroupContent{
    flex:1;
    display:flex;
    align-items:center;
}
.kioskIconSymbol{
    min-width:20px;
    width:20px;
    height:20px;
    border-radius:3px;
    background:#999;
    display:inline-flex;
    align-items:center;
    justify-content:center;
    margin:0 0 0 auto;
}
.kioskIconSymbol img{
    width:9px;
}
.kioskIconGroup{
    width:100%;float:left;
    margin:0 0 15px;
    padding:8px 0 0 8px;
    border:solid 1px #D3D2DE;
    border-radius:2px;
}
.kioskIconGroup img{
    width:36px;
    margin:0 7px 5px 0;
}

.tgOpacity{
    display:flex;
    align-items:center;
}
.tgOpacity > p{
    width:auto;
    margin:0 10px 0 0;
}
.tgOpacity .textGroupContent{
    flex:1;
    display:flex;
    align-items:center;
}
.tgOpacity .ui.input{
    width:auto;
    padding:0 10px 0 0;
}
.tgOpacity .ui.input>input{
    min-width:0px;
    width:50px;
    height:28px;
    padding:0;
    font:400 15px 'Rubik', sans-serif;
    color:#000;
    text-align:center;
    border-radius:0;
    border:none;
    border-bottom:solid 1px #D3D2DE;
}
.tgOpacity .ui.input>input:focus{
    border-color:#727AFF;
}
.opacitySlide{
    min-width:104px;
    width:104px;
    display:inline-block;
    height:2px;
    border-bottom:solid 2px #727AFF;
    position:relative;
}
.opacitySlide span{
    width:12px;
    height:12px;
    border-radius:12px;
    background:#727AFF;
    position:absolute;
    left:0;
    top:-5px;
}

.ui.modal.confirmActionModal.deleteKioskModal{
    width:340px;
}
.ui.modal.confirmActionModal.deleteKioskModal .actions{
    margin-top:20px;
}

.tgSelectTheme .checkboxGroup{
    width:auto;
    float:left;
    margin:5px 20px 0 0;
}
.tgSelectTheme .checkboxGroup .ui.checkbox label{
    display:flex;
    align-items:center;
    line-height:22px;
}
.tgSelectTheme .checkboxGroup .ui.radio.checkbox input:checked~label:after{
    width: 20px;
    height: 20px;
    border-radius: 4px;
    top: 0px;
}

.tgMapOrientation .textGroupContent{
    display:flex;
    align-items:center;
}

.ui.button.rotateResetButton{
    padding:5px 10px;
    font:500 12px 'Rubik',sans-serif;
    margin-left:auto;
}
.instructionTooltip{
    background:rgba(255,255,255,.8);
    padding:5px 7px;
    border:solid 1px #D3D2DE;
    border-radius:2px;
    font:400 11px 'Rubik',sans-serif;
    color:#000;
    max-width: 218px;
    position:absolute;
    top:5px;
    left:5px;
    z-index:10;
}

.settingsPageTab .settingsPageTabPaneKioskModule{
    max-width:1010px;
}
.kioskUrl{
    max-width:300px;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    margin:0;
    padding:0 5px 0 0;
}

.emptyBox{
    width:100%;
    background: #F4F7FC;
    border: solid 1px #D3D2DE;
    border-radius: 3px;
    position: relative;
    padding: 10px;
    font:500 14px 'Rubik',sans-serif;
    margin:0 0 20px;
}

.ui.popup.webCopyButtonTooltip{
    padding:5px 10px;
    border-radius:2px;
    font:400 13px 'Rubik',sans-serif;
}

.alignerKioskSidebar{
    width:100%; float:left;
    padding-right:10px;
}
.alignerKioskSidebar .error-box.isMultiple{
    margin:0 0 10px;
}

.ui.dropdown.dropdownSelectTheme{
    max-width:220px;
}

.rotationContent{
    margin-right: 18px !important;
}

.settingsInfo {
    display: inline-block;
}

.geolocationWebSettingHeading {
    margin-bottom: 0;
}