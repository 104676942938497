.list-view-multi-input-editor-modal.ui.modal {
  width: 700px;
  padding: 20px;
}

.list-view-multi-input-editor-modal.ui.modal .header {
  padding: 0;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-view-multi-input-editor-modal.ui.modal .content {
  margin-top: 18px !important;
}

.list-view-multi-input-editor-modal.ui.modal .content .dropdownCategory {
  padding: 10px 14px !important;
}

.list-view-multi-input-editor-modal.ui.modal .content input.search {
  padding: 3px !important;
  margin: 0 !important;
}

.list-view-multi-input-editor-modal.ui.modal .actions {
  margin: 0 !important;
}


.dropdown-multi--disabled{
  margin-bottom: 14px !important;
}

.dropdown-multi--disabled input{
  display: none;
}

.list-view-multi-input-editor-modal .err-mssg{
  color: red;
}