.ui.uploadModal>.header,
.ui.uploadModal>.content,
.ui.uploadModal>.actions{
    width:100%; float:left;
    padding:0!important;
}

.ui.uploadModal>.actions{
    padding:0 0 30px!important;
}

.ui.uploadModal>.actions .ui.button:first-child{
    margin-left:0;
}
.ui.uploadModal>.actions .ui.button{
    margin-bottom:0;
}

/*** remove above after reSetup ***/

.aligner{
    width:100%; float:left;
}

.ui.uploadModal{
    width:616px!important;
    height:auto;
    top:100px!important;
    border-radius:2px!important;
    border:solid 1px #d3d2de!important;
    box-shadow:0 0 20px rgba(0, 0, 0, 0.3)!important;
    padding:0 40px!important;
    font-family:'Rubik',sans-serif!important;
    z-index: 1000!important;
}

.ui.modal.crop-image-modal{
    width:774px;
    border:none!important;
    padding:0!important;
}

.ui.crop-image-modal .aligner{
    padding:0 40px;
}

.ui.modal.crop-image-modal .actions{
    padding:0 40px 29px!important;
    display:flex;
    align-items:center;
}
.ui.modal.crop-image-modal .actions .ui.button{
    margin-bottom:0;
}

.ui.modal.crop-image-modal .actions .dividerLine{
    margin:0 10px;
    height:23px;
}

.ui.modal.crop-image-modal .ui.modal>.close{
    top:8px;
}

.ui.modal.crop-image-modal .ui.secondary.button{
    font:500 15px 'Rubik',sans-serif;
    color:#727aff!important;
    margin:0;
}
.ui.modal.crop-image-modal .actions .ui.secondary.button{
    display:flex;
    align-items:center;
}

.ui.modal.crop-image-modal:not(.deleteInventoryModal) .actions .ui.button:last-child{
    margin-left:auto;
}
.ui.modal.deleteInventoryModal .actions{
    padding-bottom:0!important;
}

.ui.uploadModal .aligner > .header {
    margin:36px 0 15px;
    padding:0 0 5px;
    font:400 18px 'Rubik', sans-serif!important;
    color:#222;
    border-bottom:solid 1px #d3d2de;
    display:flex;
    align-items:center;
}

.uploadTab{
    width:100%; float:left;
    padding:0;
    margin:12px 0 0;
}

.uploadTab .ui.secondary.pointing.menu{
    border-bottom:solid 1px #d3d2de!important;
    margin:0!important;
}
.uploadTab .ui.secondary.pointing.menu .item {
    font: 400 15px 'Rubik', sans-serif!important;
    color: #000!important;
    padding: 7px 0;
    border: none;
    margin: 0 40px -1px 0;
}

.uploadTab .ui.secondary.pointing.menu .active.item {
    box-shadow: inset 0 -3px 0 0 #727aff;
}

.uploadTab >.ui.segment {
    width: 100%;
    min-height:248px;
    float: left;
    padding: 14px 0 0!important;
    margin: 0;
    border: none;
    border-radius: 0;
}

.ui.placeholder.brandUpload {
    border-radius: 0;
    border: dashed 1px #b8b5ce;
    background: #f8f7fa;
    box-shadow: none;
    height: 234px;
    min-height: 0;
}

.ui.placeholder.brandUpload .header {
    font: 500 18px 'Rubik', sans-serif;
    color: #a1a1ae;
}

.ui.placeholder.brandUpload .header .sub.header {
    font: 400 15px 'Rubik', sans-serif;
    margin: 20px 0 10px;
}

.uploadTab >.ui.basic.large.button {
    max-width: 100%;
    color: #727aff;
    border:solid 1px #727aff;
}

.ui.input.uploadUrl {
    width: 100%;
}

.ui.input.uploadUrl>input {
    background: #f8f7fa;
    border: solid 1px #b8b5ce!important;
    border-radius: 2px;
    height: 47px;
    padding: 0 18px;
    font: 300 15px 'Rubik', sans-serif;
    color: #636364;
}

.ui.input.uploadUrl>input:focus {
    border: solid 1px #727aff!important;
}

.ui.uploadModal > .actions {
    width: 100%;
    float: left;
    border: none;
    background: none;
    margin: 30px 0 0;
}

.ui.uploadModal > .actions .ui.button{
    float:right;
    margin-right:0;
}

.ui.uploadModal > .actions .ui.button:last-child,
.ui.crop-image-modal > .actions .ui.button:last-child{
    background:#F15944;
    color:#fff;
    border-radius:3px;
    width:auto;
    min-width:100px;
    min-height:36px;
    border-radius:4px;
    font:600 13px 'Rubik',sans-serif!important;
    padding:0 15px!important;
    margin:0;
    display:flex;
    align-items:center;
    justify-content:center;
}

.label-crop{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 32px;
    padding: 0;
}

.label-crop > input {
    background: #ebebeb;
    padding: 0 12px;
    height: 34px;
    font: 400 13px/34px "Rubik",sans-serif;
    color: #000;
    flex: 1 1;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    outline: none;
    border: none;
}

.label-crop > span {
    background: #ebebeb;
    padding: 0 12px;
    height: 34px;
    font: 400 13px/34px "Rubik",sans-serif;
    color: #000;
    flex: 1 1;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.label-crop .ui.secondary.button{
    margin:0 3px 0 30px!important;
    padding:0;
    display:flex;
    align-items:center;
    font:400 15px 'Rubik',sans-serif!important;
}
.label-crop .ui.button img{
    max-width:17px;
}

.crop-bg {
    width: 100%;
    float: left;
    min-height: 256px;
    background: #ccc;
    margin: 0 0 39px;
    position: relative;
}

.cropper-bg{
    width:774px!important;
}

.crop-bg>div:first-child{
    width: 100%!important;
}

.ui.modal.deleteImg{
    width:400px;
    padding-left:40px;
    padding-right:40px;
}
.ui.modal.deleteImg .aligner{
    padding:0;
}
.ui.modal.deleteImg{
    width:400px;
}
.ui.modal.deleteImg > .ui.button{
    padding:0;
    background:none;
}
.ui.modal.deleteImg .actions{
    display:flex;
    justify-content:space-between;
    margin:0;
}
.ui.modal.deleteImg .actions .ui.secondary.button{
    border:none!important;
    background:#e0e1e2!important;
    box-shadow:none!important;
}
.ui.modal.deleteImg .actions .ui.button:last-child{
    background:#F15944!important;
    color:#fff!important;
}

.ui.modal.deleteImg .pModal{
    margin-bottom:25px!important;
    letter-spacing:-0.4px;
}

.ui.modal.deleteImg .actions{
    justify-content:space-between!important;
}
.ui.modal.deleteImg .actions .ui.button{
    margin:0!important;
}

@media (max-width:768px){
    .ui.modal.crop-image-modal > .content{
        padding:0!important;
    }
}
