
.drawingComponent{
    display:flex;
    border-radius:4px;
    box-shadow:0 0 12px rgba(0, 0, 0, .3);
    overflow:hidden;
    position:absolute;
    bottom:90px;
    left:42.5%;
    transform: translateX(-42.5%);
    z-index:10;
}
.drawingComponent .ui.buttonIcon{
    width:34px;
    height:36px;
    background:#fff!important;
    border-radius:0;
    border-right:solid 1px #D3D2DE;
    display:flex;
    align-items:center;
    justify-content:center;
}
.drawingComponent .ui.buttonIcon:last-child{
    border-right:none;
}
.drawingComponent .ui.buttonIcon img{
    width:auto;
    height:15px;
}
.drawingComponent .ui.buttonIcon:not(.active) img{
    filter:saturate(0);
}
.drawingComponent:not(.designatedDrawingBar) .ui.buttonIcon:nth-last-child(2) img{
    filter:saturate(1);
}
.drawingComponent:not(.designatedDrawingBar) .ui.button:last-child{
    border-radius:0 4px 4px 0!important;
    background:#D3D2DE!important;

}

.drawingComponent:not(.designatedDrawingBar) .drawingComponentSelect.ui.button:last-child
{
    background-color: #e75330!important;
    color: white;
}


.drawingComponent{
    display:flex;
    border-radius:4px;
    box-shadow:0 0 12px rgba(0, 0, 0, .3);
    overflow:hidden;
    position:absolute;
    bottom:90px;
    left:43%;
    z-index:10;
}
.drawingComponent .ui.buttonIcon{
    width:34px;
    height:36px;
    background:#fff!important;
    border-radius:0;
    border-right:solid 1px #D3D2DE;
    display:flex;
    align-items:center;
    justify-content:center;
}
.drawingComponent .ui.buttonIcon:last-child{
    border-right:none;
}
.drawingComponent .ui.buttonIcon img{
    width:auto;
    height:15px;
}
.drawingComponent .ui.buttonIcon:not(.active) img{
    filter:saturate(0);
}
.drawingComponent:not(.designatedDrawingBar) .ui.buttonIcon:nth-last-child(2) img{
    filter:saturate(1);
}
.drawingComponent:not(.designatedDrawingBar) .ui.button:last-child{
    border-radius:0 4px 4px 0!important;
    background:#D3D2DE!important;
}
.drawingComponent .ui.buttonIcon.disabled{
    opacity:1!important;
    background:#eee!important;
    color:#888!important;
}
.drawingComponent .ui.buttonIcon.disabled > *{opacity:.5!important;}
.drawingComponent .ui.buttonIcon.buttonExpanded{
    width:auto;
    padding:0 10px!important;
    font:400 14px 'Rubik',sans-serif;
}
.drawingComponent .ui.buttonIcon.buttonDrawingCancel:last-child{
    color:#f15944!important;
    font-weight:500;
    width:auto;
    padding:0 10px;
}