.changeAddressPopup{
    width:max-content;
    height:42px;
    padding:8px 5px 8px 15px;
    border-radius:2px;
    background:#fff;
    border:solid 1px #d3d2de;
    box-shadow:0 5px 12px rgba(0,0,0,0.2);

    display:flex;
    align-items:center;

    position:absolute;
    top:80px;
    left:50%;
}

.changeAddressPopup .ui.header{
    font:500 13px 'Rubik',sans-serif;
    color:#222;
    margin: 0;
    margin-right: 18px !important;
    display:flex;
    align-items:center;
    margin-right:auto;
}
.changeAddressPopup .ui.header img{
    width:18px;
    margin:0 5px 0 0;
}

.changeAddressPopup .ui.button{
    width:max-content;
    padding: 0 8px;
    height:24px;
    margin:0 10px 0 0;
    text-align:center;
    font:400 11px/24px 'Rubik',sans-serif;
    color:#fff;
    border-radius:4px;
    
}