
.drawingComponent{
    display:flex;
    border-radius:4px;
    box-shadow:0 0 12px rgba(0, 0, 0, .3);
    overflow:hidden;
    position:absolute;
    bottom:90px;
    left:48%;
    z-index:10;
}
.drawingComponent .ui.buttonIcon{
    width:34px;
    height:36px;
    background:#fff!important;
    border-radius:0;
    border-right:solid 1px #D3D2DE;
    display:flex;
    align-items:center;
    justify-content:center;
}
.drawingComponent .ui.buttonIcon:last-child{
    border-right:none;
}
.drawingComponent .ui.buttonIcon img{
    width:auto;
    height:15px;
}
.drawingComponent .ui.buttonIcon:not(.active) img{
    filter:saturate(0);
}
.drawingComponent:not(.designatedDrawingBar) .ui.buttonIcon:nth-last-child(2) img{
    filter:saturate(1);
}
.drawingComponent:not(.designatedDrawingBar) .ui.button:last-child{
    border-radius:0 4px 4px 0!important;
    background:#D3D2DE!important;

}

.drawingComponent:not(.designatedDrawingBar) .drawingComponentSelect.ui.button:last-child
{
    background-color: #e75330!important;
    color: white;
}

.indexIndicator{
    min-width:142px;
    height:34px;
    padding:5px 7px;
    background:#fff;
    box-shadow:0 0 8px rgba(0,0,0,.2);
    border-radius:4px;
    font:400 15px 'Rubik',sans-serif;
    color:#000;
    z-index:10;
    margin:15px 0 0 15px;
    display:inline-flex;
    align-items:center;
}

.countIndicator {
    min-width:130px;
    height:34px;
    padding:5px 7px;
    background:#fff;
    box-shadow:0 0 8px rgba(0,0,0,.2);
    border-radius:4px;
    font:400 15px 'Rubik',sans-serif;
    color:#000;
    z-index:10;
    margin:15px 0 0 15px;
    display:inline-flex;
    align-items:center;
}

.indexCountContainer{
    display: grid;
    grid-template-columns: repeat(2, max-content);
    gap: 8px;
    position:absolute;
    bottom:140px;
    left:50%;
}