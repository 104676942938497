.image-cropper.ui.container {
  padding: 0 !important;
  display: flex !important;
  flex-direction: column !important;
}

.image-cropper .ui.container {
  padding: 0 !important;
  width: auto !important;
  margin: 0 !important;
}

.image-cropper .cropper-bg {
  width: 100% !important;
  /* height: 100% !important; */
}

.image-cropper-crop-bx.ui.container {
  border-radius: 0.28571429rem !important;
 flex: 1;
  overflow: hidden;
  margin-top: 18px !important;
}

.image-cropper-crop-bx > div{
    /* height: 100%; */
}

.image-cropper-aspect-ratio-selectors-bx.ui.container {
  display: flex;
  gap: 14px;
  align-items: center;
}

.image-cropper-aspect-ratio-title.ui.container {
  font-size: 13px;
  margin-bottom: 4px !important;
  font-weight: 600;
}

.image-cropper-radio label {
  font-family: inherit !important;
  font-weight: 100 !important;
}

.image-cropper-heading.ui.container {
  display: flex;
  font: 400 18px "Rubik", sans-serif !important;
  border-bottom: 1px solid #d3d2de;
  justify-content: space-between;
  align-items: center;
  color: #000;
  padding-bottom: 8px !important;
}

.image-cropper-content.ui.container {
  margin: 18px 0 !important;
  flex: 1 !important;
  display: flex;
  flex-direction: column;
}

.image-cropper-actions-bx.ui.container {
  display: flex;
  justify-content: space-between;
}

.image-cropper-crop-actions-bx.ui.container {
  display: flex;
  gap: 14px;
}

.image-cropper-crop-btn.ui.button {
  width: max-content !important;
  background-color: transparent !important;
  min-width: auto;
  display: flex;
  gap: 8px;
  align-items: center;
  align-items: center;
  font-weight: bold;
  padding: 0 !important;
  font: 500 14px "Rubik", sans-serif;
  color: #727aff;
  cursor: pointer;
}

.image-cropper-input.ui.input {
  height: auto !important;
  width: 100%;
  outline: none !important;
}

.image-cropper-input.ui.input input {
  background: #ebebeb;
  padding: 10px 12px;
  outline: none;
  font: 400 14px "Rubik", sans-serif;
  color: #000;
}

.image-cropper-btn-redo{
    transform: rotate(180deg);
}

.image-cropper-inputs-bx.ui.container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}

.btn-img {
  width: 20px;
  height: 20px;
}

.image-cropper-btn-text {
  font-weight: 500;
}

.image-cropper-close-btn.ui.button.close{
  position: relative !important;
  right: 0 !important;
  top: 0 !important;
}
