.ui.categorySidebar{

}

.infoIconCategory.icon{
    font-size:15px!important;
    cursor:pointer;
    float:right;
}

h3.ui.header.categoryEditHeading{
    width:100%;
    float:left;
    margin:20px 0;
    font:500 18px 'Rubik',sans-serif!important;
    color:#000;
    display:flex;
    align-items:center;
}


.ui.categoryEditHeading .ui.button{
    margin-left:auto;
}

.inScroll > div:nth-last-child(2){
    display:none;
}

.categorySidebarTab{
    width:100%;
    float:left;
}

.categorySidebarTab h3.ui.header.categoryEditHeading{
    font-size:16px!important;
}

.categorySidebarTab .ui.secondary.pointing.menu{
    width:100%;
    float:left;
    margin:0;
    padding-left:20px;
    border:none!important;
}

.categorySidebarTab .ui.secondary.pointing.menu .item{
    margin:0 1% 0;
    font:400 11.9px 'Rubik',sans-serif!important;
    color:#b2b2b2!important;
    display:inline-flex;
    flex-direction:column;
    max-width:18%;
}

.categorySidebarTab .ui.secondary.pointing.menu .item > .groupImg{
    width:100%;
    float:left;
    text-align:center;
    margin:0 0 8px;
}

.categorySidebarTab .ui.secondary.pointing.menu .item > .groupImg img{
    width:46px;
    filter:saturate(0);
    border-radius:100%;
    box-shadow:0 3px 3px rgba(0,0,0,0.2);
    opacity:0.5;
}

.categorySidebarTab .ui.secondary.pointing.menu .item > span:last-child{
    max-width:100%;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
}

.categorySidebarTab .ui.secondary.pointing.menu .active.item{
    color:#727aff!important;
    box-shadow:inset 0 -2px 0 0 #727aff;
}

.categorySidebarTab .ui.secondary.pointing.menu .active.item span img{
    filter:saturate(1);
    opacity:1;
}

.categorySidebarTab > .ui.segment{
    width:100%!important;
    float:left!important;
    padding:0!important;
    margin:0!important;
    border:none!important;
}

.headingActions{
    display: inline-flex;
    align-items: center;
}
.headingActions .ui.buttonIcon{
    margin:0 0 0 6px!important;
}

.categoryCover{
    width:100%;
    float:left;
}

.categoryList,
.category-list-item{
    width:100%; float:left;
    list-style:none;
    margin:0;
    padding:0;
}
.category-list-item{
    position:relative;
    border-top:solid 1px #d3d2de;
}


.category-list-item .ui.button.itemCategory .ui.button.categoryItemIcon img,
.sortableHelper .ui.button.categoryItemIcon img{
    width:8px!important;
   margin: 0 8px 0 0 !important;
}

.category-list-item .ui.button.itemCategory,
.ui.button.itemCategory{
    width:100%;
    height:39px;
    margin:0;
    padding:0 32px 0 0;
    justify-content: space-between;
    font:400 14px/39px 'Rubik',sans-serif;
    color:#000!important;
    border-radius:0;
    max-width:100%;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    cursor:default;
}
.category-list-item .ui.button.itemCategory.clickAble,
.ui.button.itemCategory.clickAble{
    cursor:pointer;
}
.category-list-item .ui.button.itemCategory >span,
.ui.button.itemCategory >span{
    max-width:100%;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
}

.category-list-item .ui.button.itemCategory:hover{
    text-decoration: none;
    padding-right:31.5px !important;
}

.category-list-item:hover{
    background:#f3f3f3;
}

.category-list-item:hover{
    width:392px;
    margin-left:-22px;
    padding:0 22px;
}

.category-list-item .ui.button.itemCategory img,
.ui.button.itemCategory svg{
    width:23px!important;
    height:39px;
    float:right;
    /* margin-top:8px; */
    margin-left:auto;
}

.category-list-item .ui.button.itemCategory .icon{
    margin:0 0 0 5px!important;
    color:#7981f0!important;
    transform: rotate(-90deg) translate(-1px, -14px);
}

.category-list-item .ui.button.itemCategory .iconOpen{
    transform:rotate(0deg) translate(-1px, -12px);
}

.category-list-item  .ui.button.editCate{
    position:absolute;
    top:13px;
    right:22px !important;
}

.category-list-item .ui.button.editCate{
    display:none;
}

.category-list-item:hover > .ui.button.editCate{
    display:block !important;
}

.categorySubList{
    width:100%;
    float:left;
    background:#fff;
}

.categorySubList li .ui.button.editCate{
    right:0 !important;
}

.categorySubList li:hover .ui.button.editCate{
    right:54px !important;
}
.categorySubList li:hover .ui.button.editCate{
    display: block !important;
}

.categorySubList{
    border-top:solid 1px #d3d2de;
    padding-left:30px;

}

.category-list-item .categorySubList li{
    border-top:none;
}

.category-list-item .categorySubList li:last-child .ui.button.itemCategory{
    border-bottom:none;
}
.categorySubList li .ui.button.itemCategory{
    color:#667581!important;
    border-bottom:solid 1px #d3d2de;
}
.categorySubList li:hover .ui.button.itemCategory{
    width:349px;
    margin-left:-30px;
    padding-left:30px;
    /* padding-right:2px !important; */
    background:#f3f3f3!important;
    box-shadow:0 -1px 0 #d3d2de;
}

.categoryNameHighlight {
    color: red;
}
