.linked-entities-container {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  gap: 8px;
  max-width: 280px;
  max-height: 72px;
  overflow-y: auto;
  overflow-x: hidden;
  align-items: center;
  font: 400 15px "Rubik", sans-serif !important;
  margin-top: -3px;
}

.linked-entity-chip {
  color: #727aff;
  background-color: #7279ff31;
  display: flex;
  align-items: center;
  border-radius: 40px;
  padding: 4px 8px;
  min-width: 0;
  cursor: pointer;
}

.linked-entity-chip-content {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}


.linked-entities-placeholder {
  width: 100%;
  height: 12px;
  border-radius: 40px;
  overflow: hidden;
  height: 20px;
}

.linked-entities-placeholder .line {
  height: 100%;
  margin-top: 4px;
  opacity: 0;
}

.linked-entity-icon-bx{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
}

.linked-entity-icon-bx svg{
  width: 24px;
  height: 24px;
}

.linked-entities-additional-info-section{
  display: flex;
  justify-content: space-between;
}


.linked-entities-additional-info{
  width: 160px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.linked-entities-additional-info-section div:first-child{
  font-weight: bold;
}

.linked-entities-additional-info-section div:last-child{
  color: #667090;
}

.linked-entities-popup.ui.popup{
  border-radius: 6px;
}

.linked-entities-show-btn.ui.button{
  border: none;
  padding: 0;
  text-decoration: underline;
  color: black;
  background: transparent;
}