.images-management-footer{
    margin-top: 14px;
    display: flex;
    justify-content: flex-end;
}

.images-management-footer .cancel-btn{
    margin-left: 12px;
    background: transparent;
    border: 1px solid #E75330;
    color: #E75330;
}

.images-management-footer .cancel-btn:hover{
    background-color: transparent !important;
}