.zoneNameMarkerPopup {
  margin: 0 auto;
  position: relative;
  text-align: center;
  z-index: 100;
  color: white;
}

.zoneNameMarkerPopup:after {
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 1px 14px rgba(0, 0, 0, 0.2);
}

.zoneNameMarkerPopup:before {
  content: "";
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  box-shadow: 0 1px 14px rgba(0, 0, 0, 0.2);
  background: #4c74c5;
  position: absolute;
  z-index: 99;
  left: calc(50% - 10px);
  bottom: -8px;
}

.zoneNameMarkerPopup-wrapper {
  width: 100%;
  padding: 1px 6px 1px 6px;
  border-radius: 4px;
  background: #4c74c5;
  position: relative;
  z-index: 99;
}

.noZoneMessage {
  min-width:142px;
    height:34px;
    padding:5px 7px;
    background:#fff;
    box-shadow:0 0 8px rgba(0,0,0,.2);
    border-radius:4px;
    font:400 15px 'Rubik',sans-serif;
    color:#000;
    position:absolute;
    top:9px;
    left:45%;
    margin:15px 0 0 15px;
    z-index:100;

    display:inline-flex;
    align-items:center;
}