.images-management-main-inifinite-scroller {
  height: 416px;
}

.images-management-main-inifinite-scroller-mod{
  height: 362px;
}


.image-skeleton.ui.placeholder {
  width: 100%;
  height: 100%;
  margin-top: 0 !important;
  border-radius: 4px;
  overflow: hidden;
}

