.image-upload-progress-modal.ui.modal{
    width: 650px !important;
    padding: 0 !important;
        top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.image-upload-progress-modal.ui.modal.visible .header{
            padding: 16px;

}

.image-upload-progress-modal.ui.modal.visible .content{
    padding: 16px !important;
}

.image-upload-progress-modal.ui.modal.visible .actions{
    margin-bottom: 0 !important;
    padding: 16px;
}

.image-upload-progress-modal.ui.modal .ui.progress{
    width: 100%;
    margin-bottom: 12px;
}

.image-upload-progress-modal.ui.modal .ui.progress .bar{
        background: #f15944 !important;
    height: 15px;
}

.image-upload-progress-modal.ui.modal .ui.progress .bar>.progress{
       white-space: nowrap;
    position: absolute;
    width: auto;
    font-size: .92857143em;
    top: 50%;
    right: .5em;
    left: auto;
    bottom: auto;
    color: hsla(0, 0%, 100%, .7);
    text-shadow: none;
    margin-top: -.5em;
    font-weight: 700;
    text-align: left;
}

.files-list{
    display: grid;
    grid-template-columns: max-content 1fr;
    color: #000;
    gap: 4px;
}

.files-list-title{
    font-weight: 600;
}

.success-files-list .files-list-title{
   color: #149f00;
}

.failed-files-list .files-list-title{
   color: #ff0000;
}

.failed-files-list{
    margin-top: 8px;
}


.files-list-files-bx{
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}

.files-list-title{
    flex: 1;
}