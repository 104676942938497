.zoomScreen{
    width:100%; float:left;
    background:#fff;
}

.screenViewPhoneCover{
    width:100%;
    float:left;
    height:calc(100vh - 60px);
    overflow-y:auto;
    background:#fff;
    text-align:center;
    margin:0;
    padding:170px 0 20px;
    position:relative;
}
.screenViewPhoneCover:before{
    content:'';
    width:100%;
    height:205px;
    margin-left:-10px;
    background:#fff;
    position:fixed;
    top:0;
    left:0;
    z-index:100;
}
.screenViewPhone img{
    width:100%;
    max-width:323px;
}
.phoneOverlayViewBox{
    text-align: center;
    width: 100%;
}

.screenViewPhoneBox{
    display:inline-block;
    width:324px;
    height:671px;
    border:solid 13px #cbced9;
    border-radius:45px;
    overflow:hidden;
    position:relative;
    z-index:99;
}
.screenViewPhoneBox:before{
    content:'';
    width:162px;
    height:24px;
    border-radius:0 0 16px 16px;
    background:#cbced9;
    position:absolute;
    top:0;
    left:50%;
    margin-left:-80.5px;
    z-index:1;
}
.screenViewPhoneBox:after{
    content:'';
    width:100%;
    height:24px;
    background:url(/img/mobile-view-corners.svg) no-repeat center;
    position:absolute;
    top:-10px;
    left:0;

}

.screenViewActions{
    width:100%; float:left;
}

.screenViewActions > img{
    position:absolute;
    z-index:100;
}
.screenViewActions >img:first-child{
    top:25px;
    left:-7px;
}
.screenViewActions >img:nth-child(2){
    left:-7px;
    bottom:35px;
}
.screenViewActions >img:nth-child(3){
    right:-6px;
    bottom:36px;
}
.actionView{
    width:100%;
    height:35px;
    background:url(/img/mobile-view-action2.svg) no-repeat center #F2F2F2;
    border-top:solid 1px #DDDDDD;
    border-bottom-right-radius:32px;
    border-bottom-left-radius:32px;
    position:absolute;
    bottom:-1px;
    left:0;
}
.actionView:before,
.actionView:after{
    content:'';
    width:35px;
    height:35px;
    position:absolute;
    top:0;
}
.actionView:before{
    background:url(/img/mobile-view-action1.svg) no-repeat center;
    left:46px;
}
.actionView:after{
    background:url(/img/mobile-view-action3.svg) no-repeat center;
    right:48px;
}