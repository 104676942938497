.zoomLevelMessage {
    min-width: 160px;
    height: 34px;
    padding: 5px 15px;
    background: #fff;
    box-shadow: 0 0 8px rgba(0, 0, 0, .2);
    border-radius: 4px;
    font: 400 15px 'Rubik', sans-serif;
    color: #000;
    position: absolute;
    top: 50px;
    left: 100%;
    margin: 15px 0 0 15px;
    z-index: 100;

    display: inline-flex;
    align-items: center;
}

.zoomLevelMessage .ui.buttonIcon {
    margin-left: auto;
}

.mapOverlaysSidebar .alignerSidebar>h2.header {
    display: flex;
    align-items: center;
}

.ui.popup.multiplePopover{
    padding:10px;
    border-radius:4px;
    border:none;
    box-shadow:0 0 8px rgba(0, 0, 0, .2);
    font:400 14px/20px 'Rubik',sans-serif;
}

.mapOverlaysSidebar .alignerSidebar>h2.header .ui.buttonIcon {
    margin-left: auto;
    margin-right:-10px;
}

.mapOverlaysSidebar .alignerSidebar>h2.header .ui.buttonIcon img {
    width: 14px;
}

.tgTitle{
    display:flex;
    align-items:center;
    justify-content: space-between;
    flex-wrap:wrap;
}
.tgTitle .ui.buttonIcon{
    white-space: nowrap;
    font-size: 12.4px !important;
}

.tgTitle .ui.buttonIcon img{
    margin:0 5px 0 0;
}

.tgOverlayConfig>b {
    display: flex;
    align-items: center;
}

.tgOverlayConfig>b .ui.buttonIcon {
    margin-left: auto;
}

.tgOverlayConfig>b .ui.buttonIcon img {
    width: 16px;
}



.overlayTgRow {
    width: 100%;
    float: left;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.overlayTgRow>* {
    width: 100%;
    max-width: 90%;
}

.kioskIconTransparent {
    min-width: 23px;
    width: 23px;
    margin: 0 0 0 auto;
}

.overlayTgColumn {
    display: flex;
    align-items: center;
    margin: 0 0 20px;
}

.overlayTgColumn .opacitySlide {
    margin-top: -2px;
}

.overlayTgColumn .textGroup {
    margin: 0;
}

.ui.basic.orange.previewButton {
    float: left;
    margin: 10px 0 0 auto;
    margin-left: 25px;
}

.overlayConfigurationItem {
    width: 100%;
    float: left;
    margin: 0 0 10px;
    display: flex;
    align-items: center;
    font: 400 15px 'Rubik', sans-serif;
    color: #000;
}


.mapOverlaysContainer-modal {
    border: 1px solid #d3d2de;
    float: left;
    width: 100%;
}

.modal-overlayList{
height: 39px;
    width: 100%;
    display: flex;
    align-items: center;
    float: left;
        padding-left: 10px;
    padding-right: 15px;
    margin: 0;
    border-bottom: 1px solid #d3d2de;
     
}
.modal-overlayList:hover{
    background-color: rgb(241, 241, 241);
}

.modal-overlayList p{
    margin-left: 10px;
}
.modal-overlayList:first-child{
    margin-top: 0;
}
.modal-overlayList:last-child{
    border-bottom:none
}

.overlayConfigurationItem:first-child {
    margin-top: 10px;
}


.overlayItemIcon img{
    width: 100% !important;
}

.draggable-item{
    z-index: 99999;
       box-shadow: 0 0 0.28rem 1.67px #c2c2c2;
       background-color: white;
       opacity: .76;
      
}

.overlayConfigurationItem p {
    margin: 0 0 0 10px;
}

.overlayConfigActions {
    display: inline-flex;
    margin: 0 0 0 auto;
}

.overlayConfigActions .ui.buttonIcon {
    margin: 0 0 0 10px;
}

.zoomLevelDropdown.ui.dropdown {
    width: 50%;
}

.colorPicker {
    width: 35%;
}

.colorPicker.ui.input>input {
    border: none !important;
    height: 28px !important;
}

.colorPickerRow {
    justify-content: flex-start;
}

.colorPickerColumn {
    max-width: 100%;
}

.tabHeading {
    margin-bottom: 10px;
}

.dynamicSelectBox.ui.checkboxGroup {
    margin-bottom: 10px;
}

.closeZoomSettings.ui.button.buttonIcon {
    float: right;
    margin-right: 9px;
}

.cancelButton.ui.button {
    margin-top: 1px
}

.fromZoom {
    margin-top: 6px;
}

.dynamicSelectBox.ui.checkboxGroup label {
    font-weight: bold;
}

.opacityRangeContainer {
    min-width: 75%;
}

.zoomLevelSettingsColumn {
    min-width: 25%;
}

.zoomLevelSettingsColumn.overlayTgColumn {
    max-width: 100%;
}

.zoomLevelSettings-input {
    max-width: 40px;
}

/* Chrome, Safari, Edge, Opera */
.zoomLevelSettings-input input::-webkit-outer-spin-button,
.zoomLevelSettings-input input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.zoomLevelSettings-input input[type=number] {
    -moz-appearance: textfield;
}

.moveToZoom.ui.button.buttonIcon {
    margin-left: 19px
}

.overlayConfigurationItem .checkboxGroup {
    margin: 0 10px 0 0;
}

.configureStatus {
    height: 20px;
    padding: 0 5px;
    background: #EFF1F2;
    border: solid 1px #667581;
    border-radius: 3px;
    color: #667581;
    font: 400 11px/19px 'Rubik', sans-serif;
}

.configureStatus.publishedStatus {
    color: #F15944;
    background: #FEEEEC;
    border-color: #F15944;
}

.activityPublish {
    float: right;
    margin-right: 25px;
}

.zoomLevelLock.ui.radio.checkboxGroup {
    margin-left: 19px;
}

.confirmationDone.ui.button {
    float: right;
    margin-right: 14px;
}

.templateHeading{
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 40px;
}

.templateHeadingIndent{
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 21px;
}

.overlayTemplateSidebarHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px;
}

.overlayTemplateSidebarHeading{
    font-size: 18px;
    margin:0px
}

.overlayTemplateSidebarActions{
    display: flex;
    justify-content: space-between;
    width: 295;
    flex-direction: column;
    gap: 15px;
    margin: 0 30px 18px 30px;
}

.templateActionButtons{
    margin-right: 21px;
}

.templateGroupHeader{
    display: flex;
    align-items: center;
    height: 58px;
    padding: 0px 21px 0px 21px;
    margin-bottom: 10px;
    background-color: rgba(239, 244, 249, 0.4);
    justify-content: left;
}

.templateGroupHeader h3{
    margin: 0px 0px 0px 10px !important;
}

.activitySidebar-overlayName {
    cursor: pointer;
    word-break: break-word;
    margin-bottom: 0px;
}

.templateGroupsParent{
    background-color: blue !important;
    margin: 0px 0px 20px 0px !important;
}

.fillOpacityDisplay {
    min-width: 35%;
}

.fillOpacityDisplay .textGroupContent {
    max-width: 56px;
    /* margin-left: 51px */
}

.textOpacityDisplay{
    min-width: 35%;
}

.textOpacityDisplay .textGroupContent {
    max-width: 56px;
    /* margin-left: 40px; */
}

.borderFillOpacityDisplay {
    min-width: 35%;
}

.borderFillOpacityDisplay .textGroupContent {
    max-width: 56px;
}

.zoomSettings-label p {
    padding: 0 4px 0 0;
}

.opacityInput {
    margin: 0px;
}

.percentSymbol.opacityInput {
    margin-top: 1px;
}

.defaultInput {
    width: 25%;
    box-sizing: border-box;
    margin: 1px 0 0 0;
}

.defaultInput .ui.input {
    width: 83%;
}

.defaultInput .ui.input:not(.formButton)>input {
    font: 400 16px 'Rubik', sans-serif;
}

.defaultOpacitySettings {
    width: 45%;
    box-sizing: border-box;
    margin: 5px 6px 0 0;
}

.colorOptions {
    width: 10%;
    box-sizing: border-box;
}

.defaultSettingsSymbol {
    margin-top: 12px;
}

.defaultInputRow {
    justify-content: center;
}

.defaultInputRow .percentSymbol {
    max-width: 22%;
}

.defaultTextOpacitySettings .textGroupContent {
    margin-left: 9px;
}

.defaultTextOpacitySettings {
    margin-right: 87px;
}

.activitySectionPreview:hover {
    cursor: pointer
}

.defaultInputLabel {
    border-bottom: none;
}

.mapOverlaysSidebar .alignerSidebar>h2.header.mapOverlaySidebarHeader {
    border-bottom: none;
    margin: 11px 0 0 0;
    padding-bottom: 0;
    min-height: 48px;
    font-weight: 500;
}

.ui.button.multipleOverlaysTrigger{
    white-space:nowrap;
    margin-left:auto;
}
.ui.button.multipleOverlaysTrigger > img{
    margin:0 5px 0 0;
}

p.mapOverlaySidebarTabInfo {
    font-size: 14px;
    margin-bottom: 10px;
}

p.mapOverlaySidebarZoomSettingInfo {
    font-size: 14px;
    margin-bottom: 0;
}

.mapOverlayColorPickerLaber p {
    width: 100px;
}

.overlayTgColumn .textGroup.borderFillColorPicker {
    margin-left: 20px;
}

.defaultFillColorPicker.colorPicker.ui.input {
    width: 58%;
    margin-left: 11px;
}

.defaultBorderColorPicker.colorPicker.ui.input {
    width: 47%;
    margin-left: 23px;
}

.ui.button.saveButton{
    min-width:100px;
    height:30px;
    background:#F15944;
    font:500 13px 'Rubik',sans-serif;
    color:#fff;
    padding:0 10px;
    white-space:nowrap;
}

.saveContext {
    width: auto;
    margin:0 0 0 auto;
    float:right;
}

.ui.popup.errorPopup {
    width: 260px;
    max-width: 260px;
    padding: 0 0 0 15px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.ui.popup.errorPopup:before {
    display: none !important;
}

.ui.header.errorPopupHead {
    display: flex;
    align-items: center;
    font: 400 15px 'Rubik', sans-serif;
    border-bottom: solid 1px #D3D2DE;
    padding: 16px 0 14px;
}

.ui.header.errorPopupHead>.ui.buttonIcon:first-child,
.ui.header.errorPopupHead>img:first-child {
    margin: 0 10px 0 0;
}

.ui.header.errorPopupHead>.ui.buttonIcon img {
    margin: 0;
}

.ui.header.errorPopupHead>img:first-child {
    width: 18px;
}

.overrideGlobalTextLabel {
    float: left;
    margin-bottom: 10px;
}

.ovrrideGlobalTextLabel.ui.checkbox label {
    font-weight: 400;
}

.addLevelBtn {
    float: left;
}

.languageSelector {
    margin-bottom: 0px;
}

.mapOverlays_sidebar_actions .ui.basic.orange.previewButton {
    margin-left: 15px
}

.ui.button:not(.icon)>.icon:not(.button):not(.dropdown) {
    margin-left: 4px;
}

.mapOverlayIdDisplay {
    font-weight: bold;
    display: inline-block;
    margin-right: 10px;
}

.ui.button.buttonIcon.entityIdCopyBtn {
    display: inline-block;
}

.ui.modal.confirmActionModal.multipleDeleteConfirmation {
    width: 363px;
}

.mapOverlaysSidebar .overlay-template-dropdown .menu {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    border-top: 0px !important;
}

.mapOverlaysSidebar .overlay-template-dropdown i{
    position: absolute !important;
}

.mapOverlaysSidebar .overlay-template-dropdown .icon:not(.clear):before{
    content: url("/img/icon-chevron-down.svg") !important;
}

.mapOverlaysSidebar .overlay-template-dropdown .menu div{
   width: 300px;
}

.activitySidebar-overlayName{
    display: flex;
    align-items: center;
    gap: 4px;
}

.map-overlay-template-popup .content{
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.activity-sidebar{
    z-index: 0;

}
