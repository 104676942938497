.image-upload-progress .ui.progress .bar {
  background: #f15944 !important;
  height: 15px;
}

.image-upload-progress .ui.progress {
  margin: 0 !important;
  width: 100% !important;
}
.image-upload-progress.ui.container,
.image-upload-progress .ui.container {
  padding: 0 !important;
  margin: 0 !important;
  width: auto !important;
}

.image-upload-progress.ui.container {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
}

.image-upload-info-cx.ui.container{
    display: flex;
    flex-direction: column;
    gap: 14px;
    margin-top: 18px !important;
}

.image-upload-progress .ui.progress .bar > .progress {
  white-space: nowrap;
  position: absolute;
  width: auto;
  font-size: 0.92857143em;
  top: 50%;
  right: 0.5em;
  left: auto;
  bottom: auto;
  color: rgba(255, 255, 255, 0.7);
  text-shadow: none;
  margin-top: -0.5em;
  font-weight: 700;
  text-align: left;
}

.image-upload-info-bx.ui.container {
  display: flex;
  gap: 4px;
}

.image-upload-info-title.ui.container {
  font-weight: 600;
  flex-shrink: 0;
}

.image-upload-progress-heading-bx.ui.container{
    padding: 0 0 5px !important;
    font: 400 18px "Rubik",sans-serif!important;
    border-bottom: 1px solid #d3d2de !important;
    color: #000;
    margin-bottom: 14px !important;
}

.image-upload-info-bx--errors.ui.container
  .image-upload-info-title.ui.container {
  color: rgb(255, 86, 86);
}

.image-upload-info-bx--success.ui.container
  .image-upload-info-title.ui.container {
  color: rgb(20, 159 ,11);
}

.image-upload-info-list.ui.container {
  display: flex;
  flex-wrap: wrap;
  min-width: 0;
  gap: 4px;
}

.ui.container.image-upload-info-name{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}