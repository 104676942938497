.rearrangeCategoryContent {
    padding: 10px 20px 10px 10px;
    border-right: 1px solid #C9C7D6;
    margin-right: 4px;
}

.rearrangeCategory-header {
    display: flex;
    justify-content: space-between;
    padding: 15px 10px 20px 10px;
    align-items: center;
}

.rearrangeCategory-leftHeader {
    display: flex;
    flex-direction: column;
}

.rearrangeCategory-leftHeaderTop {
    display: flex;
}

.rearrangeCategory-header .title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 16.8px;
    line-height: 20px;
    color: #000000;
}

.rearrangeCategory-header .help {
    margin-left: 10px;
}

.rearrangeCategory-back {
    color: #667581;
    height: 16px;
    width: 16px;
    cursor: pointer;
}

.rearrangeCategory-leftHeaderBottom {
    width: 725.16px;
    height: 20px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #667581;
    margin-left: 21px;
    margin-top: 10px;
}

.rearrangeCategory-rightHeader {
    display: flex;
    gap: 15px;
}

.rearrangeCategoryContent {
    margin-left: 31px;
    margin-right: 10px;
    background-color: #ffffff;
    height: 700px;
    padding: 15px;
    border-radius: 4px;
    display: flex;

}

.rearrangeCategory-main {
    display: flex;
    flex-direction: column;
    padding: 10px 10px 10px 20px;
    width: 100%;
    min-width: 600px;
    /* border-left: 1px solid #C9C7D6; */
}


.rearrangeCategoryGroups {
    width: 467px;
    padding: 10px 20px 10px 10px;
    /* border-right: 1px solid #C9C7D6;
    margin-right: 4px; */
}

.rearrangeCategoryGroups .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: flex-start;
}

.rearrangeCategoryGroups .topHeading {
    height: 18px;
    font-family: 'Rubik';
    font-style: normal;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
}

.rearrangeCategoryGroups .topHeading strong {
    font-weight: 600;
}

.rearrangeCategoryGroups .sub-text,
.rearrangeCategoryContent .sub-text {
    min-width: 319.33px;
    height: 17px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #667581;
}

.rearrangeCategoryContent .sub-text {
    margin-top: 5px;
}

.rearrangeCategory-main .sub-text {
    margin: 10px 0px;
}

.rearrangeCategoryGroupItem-root {
    border: 1px solid #CBCBCB;
    width: 100%;
    height: 80px;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
}

.rearrangeCategoryGroupItem-root.active {
    border-color: #F15944;
    background-color: #FFEEEC;
}

.rearrangeCategoryGroupItem-root .icon img {
    width: 52.5px;
    height: 52.5px;
    margin-left: 8px;
}

.rearrangeCategoryGroupItem-root .middle {
    display: flex;
    flex-direction: column;
}

.rearrangeCategoryGroupItem-root .name {
    min-width: 319.33px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
}

.rearrangeSubList {
    margin-left: 50px;
}

.rearrangeCategoryItem-root {
    border: 1px solid #CBCBCB;
    width: calc(100%-15px);
    height: 40px;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
}

.group-dragHandle,
.category-dragHandle {
    background-color: #6670901A;
    margin: -10px 0px -10px -10px;
    padding: 5px 4px;
}

.category-dragHandle img {
    height: 18px;
    margin-top: 5px !important;
}

.group-dragHandle {
    margin: -20px -10px;
    padding: 20px 5px;
}

.rearrangeSubList .categoryItemRoot:first-child {
    margin-top: 10px;
}

.rearrangeCategory-main .heading-rightSide {
    display: flex;
    gap: 10px;

}

.rearrangeCategory-main .heading-rightSide .sortDropdown {
    box-sizing: border-box;
    width: 143px;
    min-width: auto;
    min-height: auto;
    background: #FFFFFF;
    border: 1px solid #D3D2DE;
    box-shadow: inset 0px 0px 0px 1px rgba(34, 36, 38, 0.15);
    border-radius: 4px;

    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
}

.rearrangeCategory-main .heading-rightSide .sortDropdown .text {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
}

.rearrangeCategory-main .heading-rightSide .sortDropdown .active .text {
    font-weight: 600;
}

.rearrangeCategory-main .heading-rightSide .sortDropdown,
.rearrangeCategory-main .heading-rightSide .sortDropdown .icon {
    display: flex;
    align-items: center;
    height: 30px;
}

.rearrange-saveBtn {
    width: 90px;
    height: 30px;
    background: #F15944 !important;
    border-radius: 4px;
    color: #ffffff !important;
}

.ui.button.manageCategorySort {
    min-width: 90px;
    height: 30px;
    background: #fff !important;
    font: 500 13px 'Rubik', sans-serif;
    color: #666666;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    border: 1px solid #D3D2DE;
    white-space: nowrap;
}

.ui.button.manageCategorySort:hover {
    color: #666666;
}

.ui.button.manageCategorySort img {
    margin: 0 0 0 12px;
    padding: 5px 0 5px 8px;
    border-left: solid 1px #D3D2DE;
    line-height: 20px;
}

.rearrange-category-section-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    padding: 10px;
    height: 550px;
    width: 100%;
}


.rearrange-category-section-no-data .ui.image img,
.rearrange-category-section-no-data .ui.image svg {
    display: block;
    max-width: 50px;
    height: 50px;
    margin-bottom: 16px;
}

.rearrange-category-section-no-data {
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;
    margin: auto;
}

.categoryItemRoot .rearrangecategoryItem-content {
    display: flex;
    gap: 10px;
    border: 1px solid #CBCBCB;
    border-radius: 4px;
    width: calc(100% - 15px);
    height: 40px;
    position: relative;
}

.categoryRearrage-overlayList {
    width: 100%;
    margin: 0;
    border-bottom: 1px solid #d3d2de;
}