.ui.popup.popupTagInfoLegend {
    top: auto !important;
    left: auto !important;
    right: 0 !important;
    bottom: 0 !important;
    width: 126px;
    overflow-x: scroll;
    border: none;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    padding: 10px 0 10px 10px;
}

.ui.popup.popupTagInfoLegend .ui.header {
    font: 600 15px "Rubik", sans-serif;
    color: #333;
    border-bottom: solid 1px #d3d2de;
    padding: 0 0 10px;
}

.ui.popup.popupTagInfoLegend p {
    width: 100%;
    float: left;
    font: 400 13px "Rubik", sans-serif;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.ui.popup.popupTagInfoLegend p span {
    width: 14px;
    min-width: 14px;
    height: 14px;
    border-radius: 100px;
    background: #4f5051;
    margin: 0 10px 0 0;
}

.legends-wrapper {
    display: block;
    position: relative;
    top: -90%;
    left: 68%;
}

.legendsPopbox {
    right: 20px;
    bottom: 20px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.legendsPopbox::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.legendsPopbox::-webkit-scrollbar-track {
    background: #fff;
}

.legendsPopbox::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #BABBCB;
}

.legendsPopbox .ui.header {
    width: 100%;
    float: left;
    margin: 0 0 10px;
    font: 400 14px 'Rubik', sans-serif;
}

.legendsPopbox p {
    width: 100%;
    float: left;
    margin: 0 0 5px;
    display: flex;
    align-items: center;
    font: 400 13px 'Rubik', sans-serif;
    white-space: nowrap;
}

.legendsPopbox p>span {
    min-width: 18px;
    width: 18px;
    height: 18px;
    border-radius:4px;
    margin: 0 8px 0 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.legendsPopbox p>span img{
    height:12px;
}