.loading-container{
    width:100%;
    height:100%;
    position:fixed;
    top:0;
    left:0;
    background:rgba(0,0,0,0.2);
    /* background:url(/img/m-colored.gif) no-repeat center; */
    z-index:4500000;
}

.loading-container img{
    width:100px;
    height:100px;
    position:fixed;
    top:50%;
    left:50%;
    margin-top:-50px;
    margin-left:-50px;
}

.loading-screen,
.loading-comp,
.loading-footer{
    display:flex;
    align-items:center;
    justify-content:center;
}

.loading-screen{
    width:100%;
    height:100%;
    background:#faf6f7;
    position:fixed;
    top:0;
    left:0;
    flex-direction:column;
    z-index:9000;;
}

.loading-comp{
    flex:1;
    flex-direction:column;
}

.loading-comp span{
    display:block;
    margin:20px 0 0;
    font:400 italic 15px 'Rubik', sans-serif;
    letter-spacing:1.5px;
}

.loading-footer{
    margin:20px 0 30px;
    font:400 italic 12px 'Rubik', sans-serif;
    letter-spacing:1px;
}

.loading-footer img{
    margin:-5px 0 0 10px;
}
