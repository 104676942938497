.images-list-bx {
  padding: 0 !important;
  max-height: 600px !important;
  overflow-y: auto;
}

.images-list-bx::-webkit-scrollbar {
  display: block;
  width: 4px;
}

.images-list-image-bx-selected{
    border: 2.4px solid #f15944 !important;
}

.images-list-bx::-webkit-scrollbar-track {
  background: transparent;
}

.images-list-bx::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.207);
  border-right: none;
  border-left: none;
  height: 4px;
}

.images-list-bx::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 10px;
}

.images-list-bx::-webkit-scrollbar-track-piece:start {
  /* background: red; */
  margin-top: 10px;
}

.images-list-image-bx {
  width: 100px !important;
  height: 100px !important;
  padding: 0 !important;
  cursor: pointer;
  border-radius: 0.28571429rem !important;
  overflow: hidden;
  border: 1px solid #d3d2de;
  transition: border 0.17s ease-in;
  position: relative;
}

.images-list-image-bx.ui.container:not(:last-of-type) {
  margin-bottom: 12px !important;
}

.images-list-image-bx img {
  width: 100%;
  height: 100%;
  object-fit: cover;

}


.images-list-checkbox-icon-bx.ui.container{
    position: absolute;
    right: 6px;
    bottom: 5px;
    width: auto !important;
    padding: 0;
}
.images-list-checkbox-icon-bx img{
 width: 100%;
 height: 100%;
}