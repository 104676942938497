.lookupcover{
    width:100%;
    float:left;
    position:relative;
}

.malllookupcover{
    background:#fff;
    position:relative;
    
    width:100%;
    float:left;
    display:flex;
    justify-content:space-between;
}

.location-search-input
{
    height: 60px;
    background: none;
    border: none;
    font: 400 18px 'Rubik', sans-serif;
    color: #000;
    width: 100%;
    background: url(/img/icon-finder.png) no-repeat left 18px;
    padding: 0 30px;
    outline: 0;
}

.location-search-input:focus{}



.autocomplete-dropdown-container,
.mall-autocomplete-dropdown-container
{
    width:100%;
    border-radius: 4px;
    background:#fff;
    border: solid 1px #d3d2de;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    position:absolute;
    left:0;
    top:40px;
    text-align:left;
    z-index:100;
}

.suggestion-item
{
    width:100%;
    float:left;
    border-bottom:solid 1px #f2f2f2;
    padding:8px 5px;
    font:400 14px 'Rubik', sans-serif;
    cursor: pointer;
}
.suggestion-item:last-child{ border-bottom:none;}

.suggestion-item.active
{
    background-color: cornflowerblue;
    color:#fff;
}

.suggestion-item.inactive
{
    color: gray;
    /* background-color: azure; */    
}

/* style. = suggestion.active ? '#f0f0f0' : '#ffffff'; */








