.logo, .image{
    overflow:hidden;
}

.logo{
    width:70px;
    height:70px;
    box-shadow:0 2px 7px rgba(0,0,0,0.2);
    float:left;
    margin:-84px 0 20px 22px;
}

.logo.placeHolder,
.coverImage{
    background:url(/img/icon-upload.svg) no-repeat center #fff!important;
    background-size:18px 18px;
    background-position:center 18px!important;
    position:relative;
}

.coverPlaceHolder{
    width:100%;
    position:relative;
    margin-bottom:24px;
}
.coverPlaceHolder img{
    width:100%!important;
}

.coverImage{
    width:70px;
    height:70px;
    padding:35px 10px 0!important;
    border-radius:0!important;
    text-align:center;
    position:absolute;
    left:50%;
    top:50%;
    margin:-35px 0 0 -35px!important;
    z-index:1;
    font:500 10px 'Rubik',sans-serif!important;
    color:#828282;
}

.logo.placeHolder{
    cursor:pointer;
}

.logo.placeHolder:after{
    width:100%;
    font:500 10px 'Rubik',sans-serif;
    color:#828282;
    text-align:center;
    position:absolute;
    bottom:5px;
    padding:0 8px;
}

.logo.placeHolder:after{
    content:'LOGO HERE';
}
.image.placeHolder:after{
    content:'IMAGES HERE';
    bottom:50px;
}

.logo.placeHolder:hover:after{
    color:#000;
}

.image{
    width:250px;
    min-height:157px;
}

.keywords{
    float:left;
    border:solid 1px #d3d2de !important;
    border-radius:100px !important;
    background:#fff!important;
    font:400 14px 'Rubik',sans-serif !important;
    color:#000!important;
    padding:2px 10px!important;
    margin:0 10px 10px 0!important;
}

.buttonCross{
    padding:0!important;
    background:none!important;
    border-radius:0!important;
    border:none!important;
    margin:0 0 0 20px!important;
    box-shadow:none!important;
}

.buttonCross img{
    width:10px;
}

p.para{
    font:400 14px/22px 'Rubik',sans-serif!important;
    color:#000;
    padding-right:20px;
}

.textArea{
    height:68px;
    resize:none!important;
    font: 400 14px/22px 'Rubik', sans-serif!important;
    background: #fff;
    border: none!important;
    border-radius: 0!important;
    border-bottom: solid 1px #d3d2de!important;
    margin: 0;
    padding: 0 10px 1px 0!important;
    color:#000!important;
}
.textArea:focus{
    border-color:#757dff!important;
}

.socialLinks{
    width:100%; float:left;
    margin:16px 0 0;
}

.socialLinks a{
    margin:0 20px 0 0;
}