.ui.checkboxGroup{
    width:100%; float:left;
    margin:0 0 10px;
}

.ui.checkboxGroup label{
    font:400 13px 'Rubik',sans-serif;
    color:#000;
    line-height:16px;
}

.ui.checkboxGroup label:before{
    top:0;
}

.ui.checkboxGroup label:before{
    width:16px;
    height:16px;
    border-radius:2px;
    border-color:#D3D2DE!important;
    opacity:1!important
}

.ui.checkboxGroup label:hover:before{
    border-color:#B1B0BF!important;
}

.ui.checkboxGroup label:after{
    display:none;
}
.ui.checkboxGroup input:checked~label:before{
    background:url(/img/icon-check-orange.svg) no-repeat center;
    border:solid 1px #F15944!important;
}

.ui.checkboxGroup input:indeterminate~.box:before, 
.ui.checkboxGroup input:indeterminate~label:before{
    border-color:#F15944!important;
}

.ui.checkboxGroup input:indeterminate~.box:after, 
.ui.checkboxGroup input:indeterminate~label:after{
    display:block;
    font-size: 11px;
    color: #F15944!important;
    top: .5px;
    left: -2px;
}

.ui.checkboxGroup.radio label{
    font-size:15px;
    padding-left:23px;
}
.ui.checkboxGroup.radio label:before{
    width:15px;
    height:15px;
    border-radius:20px;
    border-color:#D3D2DE!important;
    opacity:1!important
}

.ui.checkboxGroup.radio label:hover:before{
    border-color:#B1B0BF!important;
}
.ui.checkboxGroup.radio input:checked~label:before{
    background:#727AFF;
    box-shadow:inset 0 0 0 2px #fff;
    border-color:#D3D2DE!important;
}

.ui.checkboxGroup.fluid{
    width:100%!important;
    margin:0 0 15px!important;
}

.ui.checkbox.checkboxPrimary input~label:before{
    border:solid 1px #C0C7E0;
    border-radius:4px;
    width:16px;
    height:16px;
}
.ui.checkbox.checkboxPrimary input:checked~label:before{
    background:url(/img/icon-check-white.svg) no-repeat center #727DF7;
    border:solid 1px #727DF7;
}
.ui.checkbox.checkboxPrimary input:checked~label:after{
    display: none;
}

.ui.checkbox.checkboxPrimary input:indeterminate~.box:before, 
.ui.checkbox.checkboxPrimary input:indeterminate~label:before{
    border-color: #727DF7;
}

.ui.checkbox.checkboxPrimary input:indeterminate~.box:after, 
.ui.checkbox.checkboxPrimary input:indeterminate~label:after{
    display:block;
    font-size: 11px;
    color: #727DF7!important;
    top: .5px;
    left: -2px;
}

.ui.checkbox.checkboxPrimary label{
    color:#667090;
    line-height:16px;
    padding-left:22px;
}