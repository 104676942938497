.image-component-bx {
  width: 100%;
  height: 100%;
  position: relative;
}

.image-component-img-bx-loading {
  opacity: 0;
}

.image-component-bx .image-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image-component-img-bx {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: 1px solid #d3d2de;
  border-radius: 4px;
  overflow: hidden;
  transition: border .1s ease-in-out;
}

.image-component-bx.selected .image-component-img-bx {
  border: 2.4px solid #f15944;
}

.image-component-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}


