.globalGroupContainer{
    width:100%; float:left;
    padding:10px 20px 0;
}
.groupHeadbar{
    width:100%; float:left;
    padding:10px 0;
    margin:0 0 20px;
    border-bottom:solid 1px #D3D2DE;
    display:flex;
    align-items:center;
}
.ui.pageHeaderGroup{
    width:auto;
    background:none;
    padding:0;
    margin:0;
    border:none;
    padding:0 20px 0 0;
    font:500 18px 'Rubik',sans-serif;
}
.ui.pageHeaderGroup > img{
    position:absolute;
    right:0;
    top:8px;
}
.groupHeadbar > .ui.button.primary{
    margin:0 0 0 auto;
    min-width:74px;
    height:30px;
    line-height:30px;
}
.globalGroupSidebar{
    width:20%;
    min-width:20%;
    border-right:solid 1px #D3D2DE;
    padding:0 5px 0 0;
}
.globalGroupCover{
    width:100%;
    float:left;
    display:flex;
}
.groupCategoriesCover{
    width:100%; float:left;
    padding:0;
    border:solid 1px #D3D2DE;
    background:#fff;
    border-radius:2px;
}
.globalGroupSidebar > b{
    width:100%;
    float:left;
    margin:0 0 12px;
    font:400 12px 'Rubik', sans-serif;
    color:#667581;
}
.ui.button.buttonGroupCategory{
    width: 100%;
    height:38px;
    float: left;
    display: flex;
    align-items: center;
    text-align:left;
    border-radius: 0;
    background: none;
    border-bottom: solid 1px #D3D2DE;
    padding:0 40px 0 12px;
    position:relative;
    font:400 15px 'Rubik',sans-serif;
    color:#000;
}
.ui.button.buttonGroupCategory .titleContent{
    flex:1;
    max-width:100%;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    padding-right:10px;
}
.ui.button.buttonGroupCategory:last-child{
    border:none;
}
.ui.button.buttonGroupCategory:hover{
    border-color:#fff;
    box-shadow:0 0 0 1px #F15944;
}
.ui.button.buttonGroupCategory.hasSub:after{
    content:'';
    width:11px;
    height:6px;
    background:url(/img/icon-chevron-down.svg) no-repeat center;
    background-size:100% auto;
    position:absolute;
    right:15px;
    filter:brightness(0.5);
}
.ui.button.buttonGroupCategory.isOpen:after{
    transform:rotate(180deg);
}
.groupCategorySection{
    width:100%; float:left;
}
.groupCategorySection .ui.button.buttonGroupCategory{
    height:39px;
    padding-left:20px;
    padding-bottom:1px;
    border:none;
}
.groupCategorySection .ui.button.buttonGroupCategory:before{
    content:'';
    width:95%;
    height:1px;
    border-bottom:solid 1px #D3D2DE;
    position:absolute;
    right:0;
    bottom:0;
}
.groupCategorySection .ui.button.buttonGroupCategory:hover:before{
    display:none;
}

.groupCategorySection .groupCategorySection .ui.button.buttonGroupCategory{
    padding-left:30px;
}
.groupCategoriesCover > .ui.button.buttonGroupCategory:first-child{
    border-radius:2px 2px 0 0;
}
.groupCategoriesCover > .ui.button.buttonGroupCategory:last-child{
    border-radius:0 0 2px 2px;
}
.syncStatus{
    width:16px;
    height:16px;
    margin:0 10px 0 0;
    border-radius:2px;
    background:#17C0AC;
    position:relative;
}
.syncStatus:after{
    content: '';
    width: 6px;
    height: 1.5px;
    background: #fff;
    border-radius: 4px;
    position: absolute;
    top: 7.5px;
    left: 5.5px;
}
.syncStatus.synced:after{
    content:'';
    width:100%;
    height:100%;
    background:url(/img/icon-check-orange.svg) no-repeat center;
    background-size:8px auto;
    filter:saturate(0) brightness(4);
    top:0;
    left:0;
}
.ui.popup.syncTooltipPopup{
    border-radius:3px;
    border:none;
    box-shadow:0 3px 12px rgba(0,0,0,.2);
    padding:5px 10px;
    font:400 13px 'Rubik',sans-serif;
}
.ui.popup.syncTooltipPopup:before{
    box-shadow:none!important;
}

.globalGroupBody{
    flex:1;
    max-width:80%;
}
.mallGroupCover{
    width:100%; float:left;
    height:calc(100vh - 205px);
    display:flex;
    margin:0 0 10px;
}
.mallGroupSection{
    min-width:262px;
    width:262px;
    border-right:solid 1px #D3D2DE;
    padding:0 5px;
}
.groupMallLogo{
    width:100%; float:left;
    display:flex;
    align-items:center;
    color:#000;
    font:500 15px 'Rubik',sans-serif;
}
.groupMallLogo .ui.image{
    width:45px;
    height:45px;
    margin:0 10px 0 0;
    border-radius:2px;
    overflow:hidden;
    background:#fff;
    border:solid 1px #D3D2DE;
    display:flex;
    align-items:center;
    justify-content:center;
}
.groupMallLogo .ui.image img{
    max-width:100%;
    max-height:100%;
}
.mallItemsGroup{
    width:100%; float:left;
    margin:10px 0 0;
}
.selectedCategoryItem{
    width:100%; float:left;
    margin:0 0 5px;
    background:#fff;
    border-radius:2px;
    padding:15px 11px;
    display:flex;
    align-items:center;
}
.selectedCategoryItem .ui.checkboxGroup{
    margin:0;
}
.scrollMallGroup>div:nth-last-child(2){
    display: none;
}