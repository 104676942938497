
.ui.modal.settingsModal {
    width: 586px;
    border-radius: 2px;
    border: solid 1px #d3d2de;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    padding: 0 40px;
}

.ui.modal.settingsModal .aligner {
    padding: 0;
}

.ui.modal.settingsModal .aligner>.header {
    margin: 35px 0 15px;
    font: 500 18px 'Rubik', sans-serif!important;
    color: #222;
}

.groupItemCover{
    width:100%; float:left;
    margin:0 0 14px;
    display:flex;
    align-items:center;
}

.groupItemCover .ui.radioItem{
    margin-right:22px!important;
}

.categoryItemBox{
    width:100%;
    float:left;
    background:#f3f2f6;
    border:solid 1px #d3d2de;
    padding:0;
    display:flex;
    align-items:center;
    position:relative;
    margin-bottom:30px;
}

.groupItemCover .categoryItemBox{
    margin:0;
    flex:1;
}

.groupItemCover.active .categoryItemBox{
    box-shadow:0 3px 10px rgba(0, 0, 0, 0.25);
}

.categoryItemBox .ui.image{
    background:#fff;
    width:70px;
    height:70px;
    display:flex;
    align-items:center;
    justify-content:center;
    margin-right:15px;
}
.categoryItemBox .ui.image img{
    width:46px;
    box-shadow:0 2px 3px rgba(0, 0, 0, 0.3);
    border-radius:70px;
}

.groupName{
    font:500 13px 'Rubik',sans-serif;
    color:#000;
    margin:0;
}

.groupActions{
    position:absolute;
    right:0;
    display:flex;
    align-items:center;
}

.groupActions .ui.buttonIcon{
    margin:0 10px 0 0!important;
    display:flex;
    align-items:center;
}

.ui.modal.settingsModal .ui.button-more{
    font-weight:500;
    margin:10px 0 0;
}

.ui.modal.settingsModal .ui.button-more img{
    width:16px;
    height:16px;
}

.ui.modal.addEditGroupModal{
    width:538px;
}

.colorResult{
    float:left;
    width:18px;
    height:18px;
    border-radius:4px;
    background:#000;
}

.modalInput .colorResult{
    position:absolute;
    left:0;
    bottom:2px;
    z-index:1;
}

.modalInput .ui.button.editBtn{
    position:absolute;
    right:0;
    top:0;
    background:none;
    border:none;
    padding:0;
    margin:0;
}
.modalInput .ui.button.editBtn img{
    width:12px;
}

.modalInput .ui.input>input{
    padding:0 20px 0 25px!important;
}

.alignWrap{
    width:405px;
}

.ui.modal.changeColorModal{
    width:382px;
}

.colorChangeBox{
    width:100%; float:left;
    padding:20px;
    margin:10px 0 20px;
    background:#f3f6fb;
    border:solid 1px #d3d2de;
}

.colorChangeBox > p{
    width:100%; float:left;
    font:400 14px 'Rubik',sans-serif;
    margin:0 0 10px;
}

.flex-picker .ui.input>input{
    border:solid 1px #d3d2de!important;
    border-radius:2px!important;
    color:#667581!important;
    height:28px!important;
    padding:0 10px!important;
    font-size:14px!important;
}

.flex-picker .ui.input>input:focus{
    border-color:#737aff!important;
}

.changeColorModal .colors-cover{
    width:80%;
    margin-top:20px;
}


.ui.categorySearch{
    padding:0 0 4px!important;
}

.ui.search.dropdown.categorySearch>.text{
    margin:0;
    padding:0;
    font:400 15px 'Rubik',sans-serif;
    color:#000;
    cursor:pointer;
}

.ui.search.dropdown.categorySearch>.text i.icon{
    font-size:17px;
    color:#4c4d64!important;
    margin:1px 0 0px 2px;
    float:right;
}

.ui.search.dropdown.categorySearch>.text span{
    font-size:13px;
    color:#667581;
}

.ui.dropdown.categorySearch>i.icon{
    margin:5px 0 0px 0!important;
    font-size:13px;
    color:#727aff!important;
}


.ui.input.categorySearch{
    width:100%; float:left;
    padding:0 0 4px!important;
    margin:0 0 15px;
}

.ui.input.categorySearch>input{
    font: 400 15px "Rubik",sans-serif;
    background: #fff;
    color: #000;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #d3d2de!important;
    margin: 0;
    padding: 0 0 1px;
    opacity:1!important;
}

.ui.input.categorySearch>input::placeholder{
    opacity:1;
    color: #000;
}
.ui.input.categorySearch>input:focus{
    border-color:#737aff!important;
}

.ui.input.categorySearch> i.icon{
    margin:5px 0 0px 0!important;
    font-size:13px;
    color:#727aff!important;
    opacity:1!important;
    padding:0!important;
    width:15px;
    height:9px;
}

.checkSelectBox{
    width:100%;
    float:left;
    margin:0 0 20px;
    padding:20px 10px 10px 20px;
    border:solid 1px #d3d2de;
}

.checkSelectItem{
    width:100%; float:left;
    margin:0 0 25px;
}

.checkSelectItem .ui.input label:before,
.checkSelectItem .ui.input label:after{
    line-height:20px;
}

.checkSelectItem .ui.checkbox label{
    color:#000;
    font:400 14px/20px 'Rubik',sans-serif;
    padding-left:30px;
}

.checkSelectItem span{
    font:400 13px 'Rubik',sans-serif;
    color:#667581;
    margin-left:5px;
}

.checkSelectBox > div:last-child > div:last-child{
    opacity:0;
}

.checkSelectBox:hover > div:last-child > div:last-child{
    opacity:1;
}

.checkSelectBox > div:last-child > div:nth-last-child(2){
    display:none;
}

.newGroupDisplay{
    width:100%; float:left;
    margin:10px 0 35px;
    display:flex;
    align-items:center;
}

.newGroupDisplay .ui.button.button-more{
    margin:0 0 0 12px!important;
}

.groupDisplay{
    width:46px;
    height:46px;
    background:#f3f3f3;
    border-radius:100px;
    box-shadow:0 2px 3px rgba(0, 0, 0, 0.3);
    overflow:hidden;
    display:flex;
    align-items:center;
    justify-content:center;
}

.groupDisplay img{
    width:17px;
    max-width:100%;
    filter:saturate(50%) brightness(126%);
}

.ui.modal.settingsInfoModal{
    width:616px;
}

.ui.headingModal{
    width:100%; float:left;
    font:500 16px 'Rubik',sans-serif!important;
    color:#000;
}
.ui.hmLarge{
    font-size:18px!important;
}

.ui.modal.settingsInfoModal .ui.image{
    margin:0 0 20px;
}

.ui.modal.settingsModal .p-modal{
    font-size:15px;
    color:#000;
}
.ui.modal.settingsModal .p-modal a:hover{
    text-decoration:underline;
}

.ui.modal.deleteGroupModal{
    width:386px;
    text-align:center;
}

.ui.modal.deleteGroupModal .p-modal{
    width:100%; float:left;
    font:400 15px/22px 'Rubik',sans-serif;
    margin:10px 0;
}

.ui.modal.deleteGroupModal .actions{
    display:flex;
    justify-content:center;
}

.ui.modal.deleteGroupModal .actions .ui.button{
    margin:0;

}

.ui.modal.deleteCategoryModal{
    text-align: left;
}

.ui.modal.deleteCategoryModal .header {
    text-align: center;
}

.ui.modal.deleteCategoryModal .relatedStores h4{  
    text-align: left;
    display: inline-block;      
    font : 500 16px/26px 'Rubik', sans-serif;
    margin: 0;
}
.ui.modal.deleteCategoryModal .relatedStores h4 span{
    border-bottom: solid 1px #d3d2de;
    padding-bottom: 10px;

}

.ui.modal.deleteCategoryModal .relatedStores ol{   
    padding-inline-start: 16px;
    height: 141px;
    overflow-y: scroll;
    margin: 15px 0 0 0;
}
.ui.modal.deleteCategoryModal .relatedStores ol li {
    
    width:100%; float:left;
    font:400 15px/22px 'Rubik',sans-serif;
}
