.manageCategory-header {
    display: flex;
    justify-content: space-between;
    padding: 15px 10px 20px 10px;
    align-items: center;
}

.manageCategory-leftHeader {
    display: flex;
    flex-direction: column;
}

.manageCategory-leftHeaderTop {
    display: flex;
}

.manageCategory-header .title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 16.8px;
    line-height: 20px;
    color: #000000;
}

.manageCategory-header .help {
    margin-left: 10px;
}

.manageCategory-back {
    color: #667581;
    height: 16px;
    width: 16px;
}

.manageCategory-leftHeaderBottom {
    width: 725.16px;
    height: 20px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #667581;
    margin-left: 21px;
    margin-top: 10px;
}

.manageCategory-rightHeader {
    display: flex;
    gap: 15px;
}

.manageCategory-rightHeader > .ui.button.buttonIcon {
    font-size: 14px !important;
}

.manageCategoryContent {
    margin-left: 31px;
    margin-right: 10px;
    background-color: #ffffff;
    height: 700px;
    padding: 15px;
    border-radius: 4px;
    display: flex;

}

.manageCategoryFooter {
    font-family: Rubik;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.59px;
    text-align: center;
    columns: #000000;
    margin-top: 25px !important;
}

.manageCategory-main {
    display: flex;
    flex-direction: column;
    padding: 10px 10px 10px 20px;
    width: 100%;
    min-width: 600px;
}

.manageCategory-Divider {
    display: flex;
    gap: 4px;
    margin-top: -15px;
    margin-bottom: -15px;
    border: 1px solid #C9C7D6;
    border-top: 0;
    border-bottom: 0;
}

.manageCategory-Divider > .vertical-line {
    width: 0px;
    height: 100%;
    background-color: #C9C7D6;
}