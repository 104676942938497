.overlay-template-item-bx{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.overlay-template-info-bx{
    display: flex;
    justify-content: space-between;
}

.templateActionButtons{
    display: flex;
    align-items: center;
}


.overlay-template-info-bx .expand-icon{
    transform: rotate(270deg);
    margin-left: 4px;
    cursor: pointer;
}

.overlay-template-info-bx .expand-icon.expanded{
    transform: rotate(360deg);
}

.group-name-input.ui.input>input {
    border-radius: 9px;
    height: 48px;
    width: 460px;
    font-weight: bold;
    color: black;
    margin-bottom: 15px;
}

.activitySidebar-overlayName{
    margin-left: 8px;
}

.close-button{
    background-image: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #e75330, #e75330) !important;
    border: 1px solid !important;
    border-radius: 8px !important;
    width: 200px !important;
    height: 46px !important;
    color: #e75330 !important;
}

.save-button{
    width: 200px;
    height: 46px;
    border-radius: 8px;
}

.template-group-name{
    color: #e75330;
}

.ui.modal.template-group-modal{  
    border-radius: 12px;
}

.ui.modal.template-group-delete-modal{  
    width: 405px;
    height: 320px;
}

.ui.modalBox.template-group-delete-modal>.actions{
    display: flex;
    justify-content: center;
    margin-top: 15px ;
}

.ui.modal.template-group-delete-modal .actions .ui.orange.button{
    width: 160px;
    height: 45px;
    font-size: 16px;
    font-weight: 600;
}

.delete-icon-container{
    border: 2px solid rgba(225, 65, 65, 1);
    border-radius: 50px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.delete-icon-container img{
    height: 25px;
}

.modal-header-text{
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    margin-top: 10px;
    font-family: "Rubik";
}
.p-modal-text{
    font-size: 22px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    color: rgba(0, 0, 0, 1);
    letter-spacing: 0.5%;
}

.deleteModal-text {
    font-size: 18px;
    text-align: center;
    color: rgba(102, 117, 129, 1);
}

.template-sideBar{
    z-index: 0;
}