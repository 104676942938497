.dropdownSelector {
    padding: 0 40px;
    max-width: 300px;
}

.mapOverlaySelector b {
    margin-bottom: 11px;
}

.dropdownSelector.languageSelector {
    padding-left: 0px;
}

.bulkCreationEntires, .bulkCreationEntry {
    width: 100%;
    float: left;
}

.bulkCreationEntires {
    padding: 0 40px;
    max-height: 360px;
    overflow: auto;
}

.ui.input.mapOverlayName {
    width: 40%;
}

.ui.input.mapOverlayLabel {
    width: 40%;
    margin-left: 20px;
}

.bulkCreationEntryTextGroup > p {
    min-width:32px;
    padding:0;
}

.modalActions {
    padding: 0 40px;
}

.ui.modal.bulkMapOverlayModal {
    padding-bottom: 4px;
    width: 600px
}

.ui.button.bulkFinish {
    margin-left: 30px;
}

.ui.button.bulkCreationActions {
    float: left;
    margin-left: 20px;
    margin-top: 11px;
}

.errors {
    padding: 0 20px;
}