.category-tree-select-all-checkbox .ui.checkboxGroup {
    width: auto;
    padding-left: 12px;
}

.category-tree-select-all-checkbox .ui.checkboxGroup label {
    padding-left: 42px;
    font-weight: 500
}

.groupCategoryCover {
    width: 100%;
    float: left;
    margin: 0 0 20px;
    /* overflow-y: auto; */
    /* border: solid 1px #D3D2DE; */
    border-radius: 2px;
}

.groupCategoryItem {
    width: 100%;
    float: left;
    /* border-bottom: solid 1px #D3D2DE; */
}

.groupCategoryItem:last-child {
    border: none;
}

.groupCategoryItemButton {
    width: 100%;
    float: left;
    height: 38px;
    padding: 5px 12px;
    display: flex;
    align-items: center;
    /* border-bottom: solid 1px #D3D2DE; */
}

.groupCategoryItemButton>p {
    font: 400 14px 'Rubik', sans-serif;
    padding: 0 10px 0 0;
    margin: 0;

    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.groupCategoryItemButton:last-child {
    border: none;
}

.groupCategoryItemButton .ui.checkboxGroup {
    width: auto;
    margin: 0 10px 0 0;
}

.groupCategoryItemButton .ui.image {
    width: 23px;
    min-width: 23px;
    margin-right: 5px;
    margin-left: 10px;
}

.groupCategoryItemIcon {
    margin-left: auto !important;
}
.subGroupCategoryCover {
    width: 100%;
    float: left;
    padding-left: 10px;
}

.subGroupCategoryCover .groupCategoryItemButton {
    padding-left: 30px;
}

.subGroupCategoryCover .subGroupCategoryCover .groupCategoryItemButton {
    padding-left: 47px;
}
