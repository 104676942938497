.ui.menu.header-bar{
    height:62px;
    z-index:3;
}

.ui.menu.header-bar .item:first-child{
    padding:0;
    margin:-14px 35px 0 0;
}

.ui.menu .right.menu{
    align-items:center;
}

.ui.menu.header-bar .item,
.ui.menu.header-bar .ui.button{
    background:none!important;
}

.ui.menu.header-bar .item a{
    font:500 14px/9px 'Rubik', sans-serif;
    text-transform:uppercase;
    color:#000;
    float:left;
}

.ui.menu.header-bar .item.disabled a{
    pointer-events: none;
    opacity:0.3;
}

.ui.menu.header-bar .active.item a,
.ui.menu.header-bar .item a:hover{
    color:#f15944;
}

.ui.menu.header-bar .right.menu .item,
.ui.menu.header-bar .right.menu .ui.button{
    padding:5px;
    margin:0 0 0 15px;
    display:flex;
    align-items:center;
    position:relative;
    cursor:pointer;
    outline:0;
}

.ui.menu.header-bar .right.menu .item span,
.ui.menu.header-bar .right.menu .ui.button span{
    font:500 12px/16px 'Rubik', sans-serif;
    color:#fff;
    background:#f15944;
    height:16px;
    min-width:16px;
    border-radius:20px;
    padding:0 2px;
    text-align:center;
    position:absolute;
    right:2px;
    top:1px;
}

.ui.menu.header-bar .right.menu .item img,
.ui.menu.header-bar .right.menu .ui.button img{
    width:21px;
}
.ui.menu.header-bar .right.menu .item:hover,
.ui.menu.header-bar .right.menu .ui.button:hover{
    background:#f4f7fc!important;
}

.ui.toolButton.disabled{
    color:#727bfe!important;
    font-weight:500;
    opacity:1!important;
}

.ui.toolButton.disabled img{
    filter:saturate(13) hue-rotate(46deg);
    opacity:1!important;
}


/*** footer ***/

.footer{
    width:100%; float:left;
    text-align:center;
    font:400 13px 'Rubik', sans-serif;
    color:#000;
    margin:10px 0;
}