/** remove after fix **/


/** remove ends **/

.dashboardListingWrapper{
    width:100%; float:left;
    min-height:calc(100vh - 110px);
    padding:20px;
}

.dashboardSubheader{
    width:100%; float:left;
    display:flex;
    align-items:center;
}

.ui.input.dashboardSearch{
    width:222px;
    align-items:center;
    margin:0 10px 0 50px;
}
.ui.input.dashboardSearch>input{
    width:100%;
    height:30px;
    border-radius:4px;
    border:solid 1px #D3D2DE;
    padding:0 10px;
    font:400 13px 'Rubik',sans-serif;
    color:#000;
}
.ui.input.dashboardSearch>input::placeholder{
    color:#7E7E7E;
}
.ui.input.dashboardSearch>input:focus{
    border-color:#727AFF;
}
.ui.input.dashboardSearch > img{
    position:absolute;
    right:10px;
}

.dashboardSubheader .inputGroup{
    display:flex;
    margin:0 10px;
}
.dashboardSubheader .inputGroup .ui.dropdown,
.dashboardSubheader .inputGroup .ui.buttonTenants{
    min-width:130px;
    font:400 13px 'Rubik',sans-serif!important;
    color:#000!important;
}
.ui.button.buttonTenants{
    background:url(/img/icon-chevron-down.svg) no-repeat 94% center #fff!important;
}
.ui.button.buttonIcon img{
    margin:0 5px 0 10px;
}

.dashboardSubheader .ui.dropdown.dropdownSearchCity{
    min-width:245px;
    max-width:245px;
    padding:0 20px 0 27px!important;
    background:url(/img/icon-pin-location.svg) no-repeat 10px center #fff; 
    color:#7E7E7E!important;
}
.dashboardSubheader .inputGroup .ui.dropdown>.text{
    max-width:100%;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
}
.dashboardSubheader .inputGroup .ui.dropdown > img{
    top:11px;
    right:9px;
}

/**/

.dashboardListing{
    margin:0 0 10px;
}
.dashboardListing .dataListingTableColumn{
    padding:14px 10px 10px 15px;
}
.dashboardListing .dataListingTableColumn .ui.image{
    min-width:30px;
    width:30px;
    height:30px;
    overflow:hidden;
    border-radius:2px;
    margin:0 12px 0 0;
}
.dashboardListing .dataListingTableRow:last-child{
    margin-bottom:5px;
}

.tableScrollDashboard > div:nth-last-child(2){
    border:none;
}
.dashboardListing .dataListingTableColumn.wide{
    flex:2;
    max-width:350px;
}

.ui.button.statusButtonTrigger{
    font:400 15px 'Rubik',sans-serif;
    display:inline-flex;
    align-items:center;
    color:#667581!important;
    padding:0;
    background:none;
    border:none;
    border-radius:0;
}
.ui.button.statusButtonTrigger img:first-child{
    margin:0 10px 0 0;
}
.ui.button.statusButtonTrigger img:last-child{
    margin:0 0 0 10px;
}

.emptyDashboardList{
    padding:12px;
    display:flex;
    align-items:center;
}
.emptyDashboardList p{
    flex:1;
    font:400 13px 'Rubik',sans-serif;
    color:#99A9C4;
    padding-left:20px;
}
.emptyDashboardList p strong{
    display:block;
    font-weight:500;
    font-size:15px;
    color:#727AFF;
    margin:0 0 5px;
}

/** popup **/

.ui.popup.popupBox{
    max-width:303px;
    width:303px;
    padding:15px 12px;
    border:none;
    border-radius:3px;
    box-shadow:0 3px 12px rgba(0,0,0,.15);
}
.ui.popup.popupBox:before{
    display:none;
}

.popupBox > .ui.header{
    font:600 15px 'Rubik',sans-serif;
    color:#000;
}
.ui.popup.popupBox .para{
    font:400 13px 'Rubik',sans-serif;
    color:#99A9C4;
    margin:0 0 10px;
}

.ui.button.add-property-button {
    margin-left: auto;
}


