.icons-view-infinite-scroller {
  flex: 1;
  grid-template-columns: repeat(auto-fill, minmax(0, 44px));
  grid-auto-rows: 44px;
  gap: 6px;
  grid-row-gap: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
  height: 445px;
}



.icons-view-section-no-icons {
  padding: 10px;
}

