.setterRow {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.setterInnerRow{
    display: flex;
    align-items: center;
}

.ui.input.colorPicker {
    width: 25px;
}


.ui.input.colorPicker input {
    width: 100%;
    height: 25px;
    border-radius: 10px;
    border: none;
    padding: 0;
}

.opacitySetter .transparentIcon {
    margin-left: 12px;
}

.opacitySetter {
    display: flex;
}

.setterColumn.opacitySetter {
    margin-left: 8px;
}