.groupList {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.manageCategoryGroups {
    width: 467px;
    padding: 10px 20px 10px 10px;
}

.manageCategoryGroups .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: flex-start;
}

.manageCategoryGroups .topHeading {
    height: 18px;
    font-family: 'Rubik';
    font-style: normal;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
}

.manageCategoryGroups .topHeading strong {
    font-weight: 600;
}

.manageCategoryGroups .sub-text,
.manageCategoryContent .sub-text {
    min-width: 300px;
    height: 17px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #667581;
}

.manageCategoryGroups .sub-text {
    margin-top: 5px;
}

.manageCategoryContent .sub-text {
    margin-top: 10px;
}

.manageCategoryGroups .addGrpBtn {
    width: 130px;
    height: 30px;
    background: #E75330;
    border-radius: 4px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    padding: 7px;
    color: #FFFFFF;
}

.manageCategoryGroups .ui.button.buttonIcon.group-icons img {
    margin: 0 !important;
    width: 19.5px !important;
    height: 17.5px !important;
}

.ui.modal.categoryModalBox {
    width: 537px;
    min-height: 533px;
    background: #FFFFFF;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
    padding: 20px 25px;
}

.NoGroupMsg {
    text-align: center;
    color: #000000;
    font-family: Rubik;
    font-size: 15px;
    font-weight: 400;
    line-height: 17.78px;
    text-align: center;
}