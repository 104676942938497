



.image-edit-details{
    max-height: 492px;
    overflow-y: auto;
    overflow-x: hidden;
    border-left: 1.5px solid #E8E8E8;
}

.image-edit-details-file-info-section-info-section{
    border-bottom: none;
}