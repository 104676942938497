.searchBar{
 position: relative;
 margin-top: .7rem;
 margin-bottom: 1.4rem;
}
.icon{
    width: 14.4px;
    height: 14.4px;
    opacity: .7;
    position: absolute;
    top: 50%;
    transform: translateY(-70%);
    right: 1px;
}

.input {
  border: none;
  width: 100%;
  border-bottom: 2px solid #dcdcdc;
  padding: 0.4rem 0;
  box-shadow: 0 0 0rem 0px #c9c9c9;
  transition: border 0.09s ease-in;
  font-size: 1rem;
}

.input::placeholder {
  font-family: inherit;
}

.input:focus-visible {
  outline: none;
  border-bottom: 2px solid #c6c6c6;
}