.imageSelectionModal {
  width: 750px !important;
}

.imageSelectionInfoModal {
  height: 585.8px !important;
}

.ui.modal.imageSelectionModal .aligner>.header {
  padding: 0 !important;
  border:none!important;
}


.imageSelectionModal  .content {
  padding: 0 40px !important;
}

.imageSelectionInfoModal .content{
  height: calc(100% - 96.6px) !important;
}

.imageInfoHeading{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ui.modal.imageSelectionInfoModal .aligner>.header{
  display: flex;
  padding-bottom: 8px !important;
  margin-bottom: 20px !important;
  align-items: center;
  border-bottom: solid 1px #d3d2de !important;
}

.iconHeaderCustom > div:first-child{
    border-bottom:solid 1px #D3D2DE;
    flex:1;
}

.iconHeaderCustom > div:nth-child(2){
  padding:0;
  margin:0 0 0 20px;
  border-bottom:solid 1px #D3D2DE;
}
.iconHeaderCustom > div:nth-child(2) > div{
  margin-bottom:-1px;
  font:400 15px 'Rubik',sans-serif;
}

.ui.disabled.checkboxGroup.radio label:hover:before{
    border-color: #D3D2DE !important
}


