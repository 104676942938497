.templateModal, .templateContent{
    border-radius: 20px !important;
}

.templateAction{
    background-color: #EFF4F9 !important;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    margin-bottom: 0 !important;
    padding: 18px 25px 12px 0px !important;
}

.templateActions{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.templateLabels p {
    font-weight: 500;
}

.templateLabels p span{
    font-weight: 400;
}

.templateActions > button:first-child {
    margin-right: 10px;
  }

.templateDivider{
    display: flex;
    flex-direction: row;
}

.templateModal{
    padding-bottom: 0px !important;
    margin: 0;
}

.templateLeftSide {
    width: 65%;
    display: flex;
    flex-direction: column;
}

.templateMap {
    width: 100%;
    height: calc(100vh - 250px)
    
}

.templateSidebar{
    width:35%;
    background:#fff;
    padding-top: 40px;
    border-radius: 0px 20px 0px 0px;
    border-left: 1px solid #d8d6d6;
}

.ui.modal.templateModal {
    width: 1189px;
    border-radius: 0px !important;
}

.ui.modal .actions {
    margin-top: 0px;
    border-radius: 0px !important;
}

.previewOptions .drawingComponent {
    bottom: unset;
    top: auto;
    left: 22%;
}

.actionButtonsContainer{
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin: 20px 20px 0px 0px;
}

.previewOptions {
    margin: 10px 0px 0px 10px;
    display: flex;
}

.previewOptions> p{
    text-align: start;
    width: 130px;
    display: flex;
    align-items: center;
}

.previewOptions .previewInput, .previewOptions .previewInput > p{
    display: flex;
    text-align: center;
    gap: 10px;
    margin-bottom: 0px;
    justify-content: space-between;
    width: 550px;
}

  /* For screens larger than 800px */
  @media screen and (min-width: 800px) {
    .previewOptions .previewInput, .previewOptions .previewInput > p{
        width: 555px;
    }
  }
.previewOptions .previewInput > p{
    width: 125px;
    padding-top: 3px;
}

.previewOptions .textGroupContent{
    width: 120px;
}

.templateHeader {
    padding: 10px 0px;
    font-family: "Rubik";
}

.templateHeader {
    margin-left: 12px;
}

.overlayTemplateZoomLevelIndicator {
    min-width: 142px;
    height: 34px;
    padding: 5px 7px;
    background: #fff;
    box-shadow: 0 0 8px rgba(0,0,0,.2);
    border-radius: 4px;
    font: 400 15px 'Rubik', sans-serif;
    color: #000;
    position: absolute;
    top: 50px;
    margin: 15px 0 0 15px;
    z-index: 100;
    display: inline-flex;
    align-items: center;
}

.ui.search.dropdown.overlayTemplate-groupName>.text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 320px;
}

.overlayTemplate-groupName .default.text {
    color: grey !important;
    opacity: 1 !important;
}

.overlayTemplate-groupName input {
    color: black !important;
}

.confirmActionModal .header{
    border-bottom: 1px solid rgb(186 189 192) !important;
    padding-bottom: 10px !important;
}