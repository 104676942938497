.tableScroll > div:nth-last-child(2){
    background:#fff;
    border-top:solid 1px #F3F6FB;
    border-radius:none;
    padding:1px 0;
    height:10px !important;
    z-index:3;
}
.tableScroll > div:nth-last-child(2) div{
    background:rgba(174, 184, 214,1) !important;
    height:8px!important;
    border-radius:8px!important;
}

.tableScroll > div:last-child{
    width:8px !important;
}
.tableScroll > div:last-child div{
    background:rgba(174, 184, 214,0.5) !important;
    width:8px!important;
    border-radius:8px!important;
}

.dataListingTableBox{
    width:100%; float:left;
    margin:0 0 20px;
    padding:0;
}

.dataListingTableRow{
    min-width:100%;
    float:left;
    display: flex;
    padding:0;
    border-radius:4px;
    margin:0 0 5px;
    background:#fff;
}

.dataListingTableRow-head{
    background:#f4f7fc;
    position:sticky;
    top:0;
    z-index:1;
}
.dataListingTableRow-head .dataListingTableColumn{
    padding:10px 10px 10px 15px;
    min-height:0;
}
.dataListingTableRow-head .dataListingTableColumn:last-child{
    background:none!important;
    /* border-left:none; */
}
.dataListingTableRow-head .dataListingTableColumn > p{
    font:400 12px 'Rubik',sans-serif;
    color:#667581;
}

.dataListingTableColumn{
    flex:1;
    min-width:130px;
    max-width:130px;
    min-height:40px;
    display:flex;
    align-items: center;
    padding:0 10px 0 15px;
    position:relative;
}

.dataListingTableColumn,
.dataListingTableColumn > p{
    font:400 15px 'Rubik', sans-serif;
    color:#000;
    max-width:100%;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}

.dataListingTableActions{
    height:40px;
    display:flex;
    align-items:center;
    margin-left:auto;
    padding-right:30px;
}
.dataListingTableActions .ui.buttonIcon{
    margin:0 0 0 10px!important;
}


