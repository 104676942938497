.ui.error.message {
    padding: 0;
}

.ui.error.message:last-child {
    margin: 0 !important;
}

.ui.error.message .content {
    padding: 10px;
    margin: 0 !important;
}

.ui.error.message .header {
    font: 500 13px 'Rubik', sans-serif;
}

.ui.error.message p {
    font: 400 13px 'Rubik', sans-serif;
}

.ui.styled.accordion .title {
    display: flex;
    align-items: center;
    padding: 8px 10px;
}

.ui.styled.accordion .title .dropdown.icon {
    order: 2;
    margin-left: auto;
}

.ui.styled.accordion .title.active .dropdown.icon {
    color: #000 !important;
}

.ui.styled.accordion .title .ui.label {
    background: none;
    padding: 0;
    font: 400 14px 'Rubik', sans-serif;
    color: #000;
}

.ui.styled.accordion .title:hover {
    background: #fff6f6;
}

.ui.styled.accordion .title.active {
    background: #912d2b;
}

.ui.styled.accordion .title.active .ui.label {
    color: #fff !important;
}

.ui.buttonIcon.validationInfoTrigger {
    margin: 0 0 0 10px;
}

.ui.buttonIcon.validationInfoTrigger img {
    width: 20px;
}

.floating-label {
    position: absolute;
    top: 0;
    right: 24px;
    background-color: red;
    padding: 0.2em 0.5em;
    border-radius: 6.3em;
    border: 1px solid #ccc;
    font-size: 0.6em;
    color: #ffff;
}

.ui.styled.accordion .title.active .ui.label {
    color: #000 !important;
}

.mapEditorSidebarAccordion>.ui.styled.accordion {
    max-height: 200px;
    position: absolute;
    top: 276px;
}

.validationErrorGroup {
    width: 100%;
    float: left;
    /* border:solid 1px #912d2b!important; */
}

.validationErrorGroup .ui.styled.accordion {
    width: 100%;
    float: left;
    margin: 0;
    border: none;
    border-bottom: solid 1px #ccc;
    border-radius: 0;
    box-shadow: none;
}

.validationErrorGroup>.ui.styled.accordion>.content {
    border: solid 1px #912d2b !important;
}


.validationErrorGroup .ui.styled.accordion:last-child {
    border-bottom: 0;
}

.validationErrorGroup .title {
    width: 100%;
    height: 40px;
    float: left;
    padding: 5px 0 5px 5px !important;
    border-radius: 0 !important;
    border-bottom: solid 6px #F4F7FC;
    font: 400 13px 'Rubik', sans-serif !important;
    position: relative;
    display: flex;
    align-items: center;
    transition: all .3s ease;
}


.validationErrorGroup .ui.styled.accordion .ui.styled.accordion .title:before {
    content: '';
    width: 1px;
    height: 80%;
    border-right: dotted 3px #ccc;
    border-spacing: 2px;
    position: absolute;
    left: 5px;
    top: 10%;
}

.validationErrorGroup .ui.styled.accordion .ui.styled.accordion .title:after {
    content: '';
    width: 3px;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 10px;
    margin-top: -1px;
    border-bottom: dotted 3px #ccc;
}

.validationErrorGroup .ui.styled.accordion .ui.styled.accordion .active.title:before,
.validationErrorGroup .ui.styled.accordion .ui.styled.accordion .active.title:after {
    border-color: #fff;
}


.validationErrorGroup .title .ui.label {
    font: 400 13px 'Rubik', sans-serif !important;
    color: #912d2b !important;
}

.validationErrorGroup>.ui.styled.accordion .title:last-child {
    border-bottom: solid 1px #912d2b !important;
}

.validationErrorGroup .ui.styled.accordion .active.title {
    background: #912d2b !important;
    position: relative;
}

.validationErrorGroup .ui.styled.accordion>.active.title .ui.label {
    color: #fff !important;
}

.validationErrorGroup .ui.styled.accordion>.active.title>i.icon {
    color: #fff !important;
}

.validationErrorGroup .ui.styled.accordion>.content {
    padding: 0 !important;
    width: 100%;
    float: left;
}

.validationErrorGroup .ui.styled.accordion .content.active {
    border: none !important;
}

.validationErrorGroup .ui.styled.accordion:last-child .title {
    border: none;
}

.validationErrorGroup .ui.styled.accordion .ui.styled.accordion .title {
    padding: 5px 0 5px 20px !important;
}

.validationErrorGroup .ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .title {
    padding: 5px 0 5px 25px !important;
}

.validationErrorGroup .ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .title:before {
    /* left:10px; */
}

.validationErrorGroup .ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .title:after {
    width: 12px;
}

.validationErrorGroup .ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .title {
    padding: 5px 0 5px 30px !important;
}

.validationErrorGroup .ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .title:before {
    /* left:15px; */
}

.validationErrorGroup .ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .title:after {
    width: 17px;
}

.validationErrorGroup .ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .title {
    padding: 5px 0 5px 35px !important;
}

.validationErrorGroup .ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .title:before {
    /* left:20px; */
}

.validationErrorGroup .ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .title:after {
    width: 22px;
}





.validationErrorGroup .ui.styled.accordion .ui.styled.accordion .active.title {
    background: #a04543 !important;
    border-bottom: solid 1px #912d2b;
}

.validationErrorGroup .ui.styled.accordion .ui.message.error {
    width: 100%;
    float: left;
    padding: 5px;
    font: 400 12px 'Rubik', sans-serif;
    margin: 0 0 5px !important;
}

.validationErrorGroup .ui.styled.accordion>div>.ui.message.error:last-child {
    margin: 0;
}

.validationErrorGroup .ui.styled.accordion .ui.message.error .content {
    padding: 0;
    width: 100%;
    float: left;
}

.validationErrorGroup .ui.styled.accordion .ui.message.error strong {
    font-weight: 500;
    font-size: 12px;
    display: block;
    margin: 3px 0 0;
}

.errorMessageCover {
    width: 100%;
    float: left;
    padding: 0 5px;
}

.errorMessageCover:first-child {
    padding-top: 5px;
}



.floating-label-group {
    background-color: red;
    padding: 0.2em 0.5em;
    border-radius: 6.3em;
    border: 1px solid #ccc;
    font-size: 0.6em;
    color: #ffff;
}

/*** popup ***/

.ui.popup.validation-error-info-popup {
    min-width: 0;
    width: 300px;
    max-width: 300px;
    padding: 5px !important;
    max-height: 350px;
    overflow-y: auto;
}

.ui.popup.validation-error-info-popup:before {
    display: none;
}

.ui.popup.validation-error-info-popup .ui.styled.accordion {
    border: none;
    box-shadow: none;
    margin: 0 0 5px;
}

.ui.popup.validation-error-info-popup .ui.styled.accordion .title {
    border-radius: 6px;
    padding: 10px 5px;
    background: #fff6f6 !important;

}

.ui.popup.validation-error-info-popup .ui.styled.accordion .title img {
    width: 15px !important;
    margin-right: 5px;
    height: auto !important;
}

.ui.popup.validation-error-info-popup .ui.styled.accordion .title.active {
    background: #f6dbdb !important;
    border-radius: 6px 6px 0 0;
}

.ui.popup.validation-error-info-popup .ui.styled.accordion .title .ui.label {
    font: 400 13px 'Rubik', sans-serif !important;
    display: flex;
    align-items: center;

}

.ui.popup.validation-error-info-popup .ui.styled.accordion .content {
    padding: 0;
    width: 100%;
    float: left;
    border-radius: 0 0 6px 6px;
}

.ui.popup.validation-error-info-popup>.ui.styled.accordion .active.content {
    background: #fff6f6;
    padding: 5px;
}

.ui.popup.validation-error-info-popup>.ui.styled.accordion .ui.styled.accordion .title,
.ui.popup.validation-error-info-popup>.ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .title {
    background: #fff !important;
}

.ui.popup.validation-error-info-popup>.ui.styled.accordion .ui.styled.accordion .active.content,
.ui.popup.validation-error-info-popup>.ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .active.content {
    background: #fff;
}

.ui.popup.validation-error-info-popup>.ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .title {
    background: #ebc6c6 !important;
}

.ui.popup.validation-error-info-popup>.ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .active.content {
    background: #ebc6c6;
}

.ui.popup.validation-error-info-popup>.ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .title {
    background: #f6dbdb !important;
}

.ui.popup.validation-error-info-popup>.ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .ui.styled.accordion .active.content {
    background: #f6dbdb;
}

.ui.popup.validation-error-info-popup .ui.message {
    width: 100%;
    float: left;
    border-radius: 4px;
    padding: 5px;
    margin: 0 0 5px;
    cursor: pointer;
}

.ui.popup.validation-error-info-popup .ui.message .content {
    width: 100%;
    float: left;
    padding: 0;
    font: 400 12px 'Rubik', sans-serif;
}

.ui.popup.validation-error-info-popup .ui.message .content strong {
    display: block;
    font-weight: 500;
}

.ui.popup.validation-error-info-popup .errorMessageCover {
    padding: 0;
}

.d-flex {
    display: flex;
}





/***/

.errorbox {
    width: 100%;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
    padding-left: 51px;
}

.menu-expanded {
    padding-left: 251px;
}


.errorbox-heading {
    width: 100%;
    float: left;
    height: 30px;
    padding: 5px 10px;
    background: #EAF0F8;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.errorbox-heading h4 {
    float: left;
    font: 500 13px 'Rubik', sans-serif;
    display: inline-flex;
    align-items: center;
    margin: 0;
}

.errorbox-heading h4>img {
    height: 18px;
    margin: 0 6px 0 0;
}

.errorbox-heading .ui.closeBtn {
    position: relative;
    top: auto;
    right: auto;
}

.errorbox-content {
    width: 100%;
    float: left;
    display: flex;
}

.errorbox-level-col1 {
    min-width: 300px;
    width: 400px;
    max-height: 100px;
    overflow-y: auto;
    float: left;
    border-right: solid 1px #CFD6EA;
}

.ui.button.buttonErrorboxItem {
    width: 100%;
    float: left;
    height: 30px;
    padding: 0 30px 0 10px;
    border-bottom: 1px solid #eef0f7;
    font: 400 11px 'Rubik', sans-serif !important;
    color: #000;
    display: flex;
    align-items: center;
    border-radius: 0;
    background: none;
    text-align: left;

    position: relative;
}

.ui.button.buttonErrorboxItem:after {
    content: '';
    width: 7px;
    height: 12px;
    background: url(/img/icon-chevron-right.svg);
    background-size: 100% 100%;
    position: absolute;
    right: 5px;
}

.ui.button.buttonErrorboxItem:hover,
.ui.button.buttonErrorboxItem.active {
    background: #F6F9FF;
}

.ui.button.buttonErrorboxItem p {
    flex: 1;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.statusIcon {
    width: 14px;
    height: 14px;
    border-radius: 4px;
    margin: 0 6px 0 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    background: #F15944;
}

.statusIcon img {
    height: 10px;
}


.errorbox-content-level2 {
    flex: 1;
    max-width: 36%;
    max-height: 100px;
    overflow-y: auto;
    border-right: solid 1px #CFD6EA;
}

.errorbox-content-section {
    max-height: 100px;
    overflow-y: auto;
    flex: 1;
    border-right: solid 1px #CFD6EA;
}

.errorbox-content-section:last-child {
    border: none;
}

.errorbox-message {
    width: 100%;
    float: left;
    padding: 15px;
    color: #B95243;
    font: 400 14px 'Rubik', sans-serif;
}

.errorbox-message strong {
    font-weight: 500;
    display: inline-block;
}
.ui.button.launcher-btn-wrp {
    position: absolute;
    top: 10px;
    left:280px;
    background: red;
    padding:4px 10px 2px;
}
.ui.button.launcher-btn-wrp img{
    width:22px;
    filter: saturate(0) brightness(10);
}
