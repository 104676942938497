.accordionBranding {
    width: 100%;
    float: left;
    margin: 20px 0 0;
}

.ui.button.marginBtn {
    margin-top: 20px;
}

.ui.accordion.accordionBranding .title {
    width: 100%;
    float: left;
    padding: 25px 0;
    font: 400 18px 'Rubik', sans-serif;
    color: #000;
    display: flex;
    align-items: center;
    border-top: solid 11px #f4f7fc;
}

.ui.accordion.accordionBranding .ui.customCheck{
    margin-bottom:10px;
}

.accordionHeaderError {
    /* Might need color change */
    color: #9f3a38 !important;
}

.ui.accordion.accordionBranding .title:first-child {
    border-top: none;
}

.ui.accordion.accordionBranding .title .ui.header.nameHead {
    font: 400 18px 'Rubik', sans-serif;
    color: #000;
}

.ui.accordion.accordionBranding .title img {
    margin-left: auto;
    width: 11px;
    filter: brightness(2.5);
}

.ui.accordion.accordionBranding .active.title img {
    transform: rotate(180deg);
}

.ui.accordion.accordionBranding>.content {
    width: 100%;
    float: left;
    margin: 0 0 20px;
}

.ui.accordion.accordionBranding>.content.active>div {
    padding-right: 0;
}

.editSidebarBox .bdi-hours .detail .ui.basic.form-group {
    margin: 0;
}

.editSidebarBox .bdi-hours .detail .ui.basic.form-group .field {
    width: 88px;
}

.editSidebarBox .bdi-hours .detail .ui.basic.form-group .field .ui.input input {
    padding-right: 15px!important;
}

.editSidebarBox .bdi-hours>b {
    min-width: 90px;
}

.editSidebarBox .hoursEditWrap .hoursBottom {
    padding-left: 90px;
}

.editSidebarBox .hoursEditWrap .error-box-small {
    width: 260px;
}

.editSidebarBox .hoursEditWrap:last-child {
    margin-bottom: 0;
}

.defaultCheckCover{
    width:100%; float:left;
    display:flex;
    align-items:center;
    font:400 15px 'Rubik',sans-serif;
}
.defaultCheckCover .ui.checkbox{
    margin:0 15px 0 0;
    line-height:20px;
}

.optionMapDropdown{
    width:100%; float:left;
    margin:10px 0 20px;
}

.optionMapDropdown .ui.dropdown{
    float:left;
    width: max-content;
    border-radius:0;
    border-bottom:solid 1px #d6d5e0;
    font:400 15px 'Rubik',sans-serif;
    color:#667581;
}

.optionMapDropdown .ui.dropdown .menu>.header{
    font:500 13px 'Rubik',sans-serif;
    margin:0 0 7px;
    padding:10px 0 0 10px;
}

.optionMapDropdown .ui.dropdown .menu{
    max-width:100%;
    width:180px;
    padding:5px 0;
}
.optionMapDropdown .ui.dropdown .menu .item{
    margin:0;
    padding:0!important;
}

.optionMapDropdown .ui.dropdown .menu .ui.checkbox{
    font:400 15px/20px 'Rubik',sans-serif;
    position:relative;
    padding:8px 10px;
}

.ui.buttonIcon.inventoryButton img{
    width:25px;
}

.ui.button.buttonIcon.listViewUploadButton .onlineKeywordUploadBtn {
    filter: none;
    margin-left: 15px;
}

.ui.accordion.accordionBranding .active.title .onlineKeywordUploadBtn {
    transform: none;
}