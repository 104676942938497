.error-box{
    width:100%; float:left;
    padding:8px 8px 8px 40px;
    border:solid 1px #e62600;
    border-left-width:8px;
    background:url(/img/icon-error.svg) no-repeat 12px center #fcf4f2;
    background-size:16px 16px;
    border-radius:2px;
    font:400 15px 'Rubik',sans-serif;
    color:#a31b00;
    display:flex;
    align-items:center;
}

.error-box .header {
    display: block;
    font-weight: 500;
    margin-right: 10px;
}

.error-box.gap{
    margin-top:15px;
}

.error-box-small{
    height:33px;
    margin-bottom:30px;
}

.error-box-large {
    height: 85px;
    padding-top:10px;
    padding-bottom:10px;
    display: block;
    background-position: 12px 11px;
}

.error-box p {
    font-size: 0.9em;
    margin-right: 10px;
}

.floor-i .error-box{
    width:285px;
}

.error-box.isMultiple{
    padding-top:10px;
    padding-bottom:10px;
    min-height:54px;
    height:auto;
    flex-direction:column;
    align-items:flex-start;
}
