.ui.modal.mapOverlayUploadModal{
    width:470px;
}
.mapOverlayFileUploader.dragDropBox {
    margin-bottom: 0;
}

.uploadFileInfo .ui.grid {
    margin-top: -1rem;
    margin-bottom: -1rem;
    margin-left: -1rem;
    margin-right: -1rem;
}

.uploadFileInfo {
    float: left;
    margin: 25px 0 25px;
}

.downloadLink {
    float: left;
    text-align:left;
    font-size:14!important;
}

.downloadLink button {
    font-size: 14px;
}

.mapOverlayUploadModal.ui.mini.modal .aligner>.header {
    margin: 31px 0 16px;
}

.error-message {
    text-align: start;
}

.mapOverlayUploadModal .error-box{
    margin: 12px 0;
}