.importGroupModal .textGroup {
    width: 50%;
    max-width: 100%;
}

.importGroupModal .textGroup>p {
    color: #667581;
}

.select-all-checkbox .ui.checkboxGroup label {
    font-weight: 500 !important;
}

.ui.message.modalWarning {
    width: 100%;
    float: left;
    padding: 10px;
    box-shadow: none;
    background: #FFF8D6;
    border-radius: 2px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: #666666;

}

.ui.message.modalWarning strong {
    width: 58px;
    height: 17px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #544D2A;
}

.dropdown-auto {
    overflow-y: auto;
}

.exportGroupModal {
    width: 634px !important;
    height: 673px !important;
    padding: 15px !important;
    border-radius: 4px !important;
}

.exportGroupModal .actions {
    margin-top: 25px !important;
}

.exportGroupModal .content {
    display: flex !important;
    flex-direction: column;
}

.copyModal-content {
    display: flex;
    justify-content: space-between;
}
.copyModal-content > div {
    width: 282px;
}
.copyModal-categorySection {
    border: 1px solid black;
    padding: 10px;
    margin-top: 10px;
    height: 360px;
}

.copyModal-propertySection {
    width: 282px;

    border: 1px solid black;
}

.copyGroupModal-header .title {
    height: 18px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
}

.copyGroupModal-header .subTitle {
    width: 574px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-top: 10px;
}

.copyModal-categorySide {
    width: 282px;
    height: 34px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.copyModal-categoryHeading {
    width: 257px;
    height: 17px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 20px;
    color: #000000;
}

.exportGroupModal .ui.checkboxGroup label {
/* height: 17px; */
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
color: #000000;
}

.exportGroupModal .ui.checkbox label:before {
    width: 20px !important;
    height: 20px !important;
    border-radius: 2px;
}

.exportGroupModal .ui.checkboxGroup input:checked~label:before {
    background: url(/img/icon-check-category.svg) no-repeat center;
    border-color: #D5D4DF !important;
}
.exportGroupModal .ui.checkboxGroup input:indeterminate~label:before {
    border-color: #D5D4DF !important;

}
.exportGroupModal .ui.checkboxGroup input:indeterminate~label:after {
    color: #727AFF !important;
    top: 2.5px;
    left: 0px;
}