.mapPopup{
	background: white;
    text-align: center;
	max-width:317px;
	min-width:70px;
    min-height: 30px;
    max-height: 100px;
	border-radius:2px;
    font-weight: 500;
    font-size: 15px;
	border:none;
	padding:10px;
	box-shadow:0 4px 18px rgba(0,0,0,.2);
    overflow: auto;
}