.image-preview{
    display: flex;
    align-items: center;
    gap: 16px;
}

.image-preview img{
    width: 52px;
    height: 52px;
    object-fit: contain;
    border-radius: 4px;
    border: 1px solid #E0E0E0;
}

.image-preview-info-bx{
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.image-preview-info-name{
    font: 400 14px "Rubik", sans-serif;
    color: #000000;
    width: 270px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.image-preview-additional-info{
    font: 400 13px "Rubik", sans-serif;
    color: #5b5b5b;
}

