.gridContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(5, 102px);
  grid-auto-rows: 102px;
  grid-gap: 8px;
  border: 1px solid #d3d2de;
  border-radius: 4px;
  padding: 9.4px;
  height: 376px;
  margin-bottom: 10px;
  overflow-y: hidden;
}

.container{
  height: 376px;
}


.gridContainer {
  overflow-y: auto;
}

.gridContainer::-webkit-scrollbar {
  display: block;
  width: 4px;
}

.gridContainer::-webkit-scrollbar-track {
  background: transparent;
}

.gridContainer::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.207);
  border-right: none;
  border-left: none;
  height: 4px;
}

.gridContainer::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 10px;
}

.gridContainer::-webkit-scrollbar-track-piece:start {
  /* background: red; */
  margin-top: 10px;
}
