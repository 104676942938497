.dashboardListingWrapper.templatesBody {
    height: calc(100vh - 230px) !important;
    min-height: calc(100vh - 230px) !important;
}

.templateActionButtons{
    width: 19px;
    height: 18px;
    border-radius: 3.51px;
    border: 1px solid white;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.actionButton{
    width: 100%;
    size: 15px;
}

.actionButton .buttonIcon img{
    height: 15px;
}

.actionItemMenu{
    display: flex !important;
    align-items: center;
    gap: 10px;
}

.menuItem.ui.button.buttonIcon img{
    width: 19px !important;
}
.editItemMenu.ui.button.buttonIcon img{
    width: 15px !important;
}

.editItemMenu, .menuItem{
    width: 25px !important;
}

.actionModal{
    padding: 0px !important;
    border: 0px !important;
}

.templatesBody .displayText{
    position:absolute;
    left:0 !important;
}

.templatesBody .paginationBar {
    justify-content: end !important;
}

.templatesLeftHeader, .templatesRightHeader{
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.templatesRightHeader > :first-child {
  display: flex;
  gap:7px;
} 

.templatesRightHeader > :first-child > :first-child {
  display: flex;
  font-family: "Rubik";
  font-weight: 500;
  align-items: center;
  margin: 0px;
} 

.templateHeaderContainer{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between ;
    padding: 23px 30px 0px 30px;
    margin-top: 50px;
}

.headIcon img{
    width: 20px !important;
    height: 25px !important;
}

.heading{
    font-family: Rubik;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.33px;
    text-align: left;
    color: black;
}

.headerContainer{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    font-family: Rubik;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: rgba(102, 117, 129, 1);
}

.ui.dropdown .menu>.item {
    border-bottom: 0px !important;
}

.ui.dropdown:not(.button)>.default.text{
    color: black;
}

.textGroupContent .ui.selection.dropdown {
  width: 378px !important;
}

.templatesRightHeader .ui.selection.dropdown {
    border: 0 !important;
    min-height: 0px;
    width: 155px !important;
    height: 39px;
    border-radius: 4px;
    border: 1px solid rgba(211, 210, 222, 1) !important;
    color: black;
}

.copyTemplates {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    width: 180px !important;
    height: 39px !important;
    border-radius: 5px !important;
    background: transparent !important;
    color: rgba(231, 83, 48, 1) !important;
    border: 1px solid rgba(231, 83, 48, 1) !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 18.96px !important;
}

.createNewTemplate{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 200px !important;
    height: 39px !important;
    gap: 0px;
    border-radius: 5px !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 18.96px;
    background-color: rgba(231, 83, 48, 1) !important;
    color: white !important;
}

.headingText{
    font-size: 18px;
    color: black;
    font-weight: 600;
    padding: 0px 0px 7px 0px;
    line-height: 31.33px;
}


.templatePropertyModal .ui.small.modal {
    width: 700px !important;
  }
  
.templatePropertyModal.ui.small.modal>.header:not(.ui) {
    font-size: 17px !important;
    border-bottom: 0px !important;
    font-weight: 800;
    font-family: "Rubik";
    padding-bottom: 0 !important;
}
  
.templatePropertyModal .ui.modal > .content {
    padding-top: 0;
}

.templatePropertyModal .ui.grid>.row {
    justify-content: space-between;
}
  
  .templatePropertyModal .subheader {
    font-size: 15px;
    font-weight: 400;
    margin: 5px 20px 20px 20px;
    padding-bottom: 8px;
    border-bottom: 1px solid #ddd7d7;
  }
  
  .templatePropertyModal .ui.grid {
    margin: 0px 20px 20px 20px;
    
  }

  .templatePropertyModal .colHeader {
    font-weight: 600;
    margin-bottom: 10px;
  }

  .ui.divided.grid:not([class*="vertically divided"])>.row>.column {
    box-shadow: none !important;
  }
  
  .templatePropertyModal .ui.segment {
    box-shadow: none;
    border: 1px solid #ddd;
  }

  .templatePropertyModal .listHeader{
    font-weight: 600;
  }
  
  .templatePropertyModal .segmentHeader {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 10px;
    color:black;
    height: 40px;
    width: 278px;
  }

  .templatePropertyModal .segmentHeaderContainer{
    margin: 0px 20px;
    display: grid;
    grid-auto-flow: column;
    gap: 40px;
  }
  .ui[class*="two column"].grid>.row>.column {
    width: 47% !important;
  }
  
  .templatePropertyModal .ui.input {
    margin-bottom: 10px;
  }
  
  .templatePropertyModal .checkboxList {
    height: 210px !important;
    overflow-y: auto;
    padding-right: 10px;
  }
  
  .templatePropertyModal .checkboxItem {
    margin-bottom: 12px;
  }
  
  .templatePropertyModal .ui.checkbox label {
    font-size: 14px;
  }
  
  .templatePropertyModal .ui.button.assign {
    background-color: rgba(231, 83, 48, 1) !important;
    padding: 10px 33px;
    font-size: 16px;
    color: white;
  }
  
  .templatePropertyModal .ui.button.cancel {
    background-color: white;
    color: rgba(231, 83, 48, 1) !important;
    padding: 10px 33px;
    font-size: 16px;
    border: 1px solid rgba(231, 83, 48, 1) !important;
  }
/*   
  .templatePropertyModal .disabled-item {
    color: #999;
    font-style: italic;
  } */

  .templatePropertyModal .ui.input>input {
    border: 1px solid white;
    padding: 0px 0px 4px 0px;
    border-bottom: 1px solid rgba(34,36,38,.15);
    border-radius: 0px;
  }

  .templatePropertyModal .actions {
    display: flex !important;
    justify-content: center !important;
    margin-bottom: 0px !important;
  }

  .templatePropertyModal .closeIcon.ui.button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background: transparent;
    padding: 0.5rem;
    margin: 0;
    width: auto;
    height: auto;
}

.templatePropertyModal .closeIcon.ui.button:before {
    content: "\f00d";  /* Unicode for the 'close' icon */
    font-family: Icons;  /* Semantic UI icon font */
    font-size: 15px;
    color: #686464;
}

.templatePropertyModal .closeIcon.ui.button:hover {
    background: rgba(0, 0, 0, 0.03);
}

.templatePropertyModal .closeIcon.ui.button:focus {
    outline: none;
    box-shadow: none;
}

/* Hide the default icon */
.templatePropertyModal .closeIcon.ui.button > i.icon {
    display: none;
}

.emptyDashboardList {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: calc(80vh - 203px);
  font-family: "Rubik";
  font-size: 16px;
  flex-direction: column;
}

.tableHeading, .tableHeading > p {
  white-space: normal !important; 
  overflow: visible !important;
  text-overflow: clip !important;
}

.dataListingTableColumn.medium, .toolTip {
  min-width: 220px !important;
  max-width: 220px !important;
}

/* For screens larger than 800px */
@media screen and (min-width: 800px) {
  .dataListingTableColumn.medium, .toolTip {
    min-width: auto !important;
    max-width: 500px !important;
  }
}


/* For screens larger than 800px */
@media screen and (min-width: 800px) {
    .dataListingTableColumn.narrow {
    min-width: auto !important;
    max-width: 150px !important;
  }
}


.toolTip > p {
  font:400 15px 'Rubik', sans-serif;
  color:#000;
  max-width:100%;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}

.toolTip{
  min-width:130px;
  max-width:130px;
  overflow: hidden;
}

.templatesLeftHeader .ui.input.dashboardSearch {
  width: 260px !important;
}