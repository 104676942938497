/* .groupCategoryCover > .categoryItemRoot:not(:first-child) {
    padding: 10px;
} */
.categoryItemRoot:not(:first-child) {
    padding-top: 10px;
    /* padding-bottom: 10px; */
}

.categoryItemRoot-icon {
    border-radius: 30px;
    width: 25px;
    height: 23px;
}

.categoryItem-name {
    display: flex;
    align-items: center;
    gap: 10px;
}

.categoryItemRoot .categoryItem-content {
    display: flex;
    gap: 10px;
    border: 1px solid #CBCBCB;
    border-radius: 4px;
    width: calc(100% - 15px);
    height: 40px;
    padding: 10px;
}

.subCategoryCover {
    padding-left: 66px;
    margin-top: 10px;
}

.categoryItemRoot .rightSideComponent {
    margin-left: auto;
}

.categoryItemRoot .rightSideComponent .visibleOnlyOnHover {
    visibility: hidden;
}

.categoryItemRoot:hover > .categoryItem-content > .rightSideComponent .visibleOnlyOnHover {
    visibility: visible;
}
