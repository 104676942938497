.content-section-header{
     font:500 15px "Rubik", sans-serif !important;
     color: #000;

}

.content-section-main{
    color: #667090;
    margin-top: 8px;
    font: 400 12px "Rubik", sans-serif !important;

}


.content-section{
    border-bottom: 1.5px solid #E8E8E8;
    padding: 16px 20px;
}