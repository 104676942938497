

.active-map-square-foot{
    font: 400 13px 'Rubik', sans-serif;
    color: #000;
    position: relative;
    padding: 0 10px 0 0;
    margin: 0 10px 0 0;
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
    background: none;
    border-right: solid 1px #c0cbda;
    border-radius: 0;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
