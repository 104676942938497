.categorySection-search {
    max-width: 423px;
    margin-top: 20px;
}

.categorySectionItem {
    display: flex;
    gap: 10px;
}

.ui.button.buttonIcon.category-icons img {
    margin: 0 !important;
    width: 15.59px !important;
    height: 14px !important;
}

.ui.button.buttonIcon.categorySectionSearchIcon {
    position: relative;
    right: 15px;
}

.NoCategorySearchResult {
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: larger;
    flex-direction: column;
}