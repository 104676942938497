.ui.modal.editCategoryModal{
    width:536px;
    padding:0;
}

.ui.modal.editCategoryModal .aligner>.header {
    margin: 35px 0 15px;
    font: 500 18px 'Rubik', sans-serif!important;
    color: #222;
}

.ui.modal.editCategoryModal .content{
    padding:0 40px;
}

.ui.modal.editCategoryModal .actions{
    padding:0 40px;
}

.ui.header.headingModalCategory{
    width:100%; float:left;
    display:flex;
    align-items:center;
    font:400 16px 'Rubik',sans-serif;
    color:#000;
}
.sortableHelper {
    z-index: 1000 !important;
    background:#fff;
    list-style-type: none;
    display:inline-flex;
    align-items:center;
    padding:0 15px 0 10px;
    font:400 14px/39px 'Rubik',sans-serif;
    
}
.sortableHelper .ui.button.categoryItemIcon{
    margin:0 10px 0 0;
    position:relative;
    top:-6px;
}
.sortableHelper .ui.button.itemCategory{
    justify-content:flex-start;
    padding-right:0;
}
.sortableHelper .ui.button.itemCategory .icon{
    transform:rotate(-90deg)!important;
    position:relative;
    margin:2px 0 0 -9.5px!important;
}
.sortableHelper .ui.button.itemCategory > img{
    float:right;
    margin-left:auto;
}
.insiderSortableHelper{
    padding-left:30px;
}


.ui.header.headingModalCategory .ui.button-more{
    font:400 13px 'Rubik',sans-serif;
    color:#000!important;
    padding:4px 0;
    margin-left:10px;
}
.ui.header.headingModalCategory .ui.button-more img{
    width:16px;
    margin:-1px 5px 0 0;
}

.categoryGroupModal{
    width:100%; float:left;
    border:solid 1px #D3D2DE;
}

.categoryGroupModal .categoryList li{
    width:100%; float:left;
}

.categoryGroupModal .categoryList li .categorySubList li .categorySubList li{
    background:#fff!important;
}

.categoryGroupModal .categoryList li:first-child{
    border-top:none;
}

.categoryGroupModal .categoryList li:hover{
    width: 100%!important;
    margin-left:0;
    padding:0!important;
}

.sortableHelper{
    box-shadow:0 0 12px rgba(0,0,0,0.3);
    opacity:0.8;
    align-items:flex-start;
    justify-content:flex-start;
    text-align:left;
}

.sortableHelper ul, .sortableHelper li{
    list-style: none;
}

.sortableHelper > .ui.button.itemCategory > img{
    width:23px!important;
}

.categoryGroupModal .categoryList li .ui.button.itemCategory .ui.button.categoryItemIcon img,
.sortableHelper .ui.button.categoryItemIcon img{
    width:8px!important;
    margin:0 15px 0 7px;
}

.sortableHelper .ui.button.categoryItemIcon{
    margin-top:13px;
}

.categoryGroupModal .categorySubList{
    padding:0;
    background:#fff;
    border-bottom:none;
}

.categoryGroupModal .categorySubList li .ui.button.itemCategory{
    padding-left:25px;
}

.categoryGroupModal .categorySubList .categorySubList{
    border-top:none;
    background:#fff;
}

.categoryGroupModal .categorySubList .categorySubList li .ui.button.itemCategory{
    border-bottom:none!important;
    background:#fff;
}

.categoryGroupModal .categorySubList li .ui.button.itemCategory {
    width: 100%;
    margin-left: 0px!important;
    padding-left: 25px!important;
    padding-right: 32px!important;
}

.ui.modal.editCategoryModal .categorySubList li .ui.button.itemCategory {
    padding-left: 30px;
    padding-right: 15px!important;
}

.subSubCategoryList{
    padding-left: 45px!important;
}

.ui.modal.editCategoryModal .categoryList li .ui.button.itemCategory{
    padding-left:10px;
    padding-right:15px !important;
}
.ui.modal.editCategoryModal .categoryList li .ui.button.itemCategory .ui.button.categoryItemIcon,
.ui.modal.editCategoryModal .categorySubList li .ui.button.itemCategory .ui.button.categoryItemIcon{
    margin:0 10px 0 0;
}

.categoryGroupModal .categorySubList li .categorySubList li{
    border-top:none;
    border-bottom:solid 1px #D3D2DE;
}
.categoryGroupModal .categorySubList li .categorySubList li .ui.button.itemCategory{
    padding-left:50px!important;
    background:#fff!important;
}
.categoryGroupModal .categorySubList li .categorySubList li .ui.button.itemCategory:hover{
    background:#f3f3f3!important;
}

.categoryGroupModal .categoryList li .ui.button.itemCategory .icon{
    color:#000!important;
}

.categoryItemRow:last-child{
    border-bottom:none;
}

.categoryItem{
    width:100%; float:left;
    height:39px;
    display:flex;
    align-items:center;
    position:relative;
    font:400 14px 'Rubik',sans-serif;
    color:#000;
}

.categoryItem:hover{
    box-shadow:0 4px 12px rgba(0,0,0,0.1);
}

.categoryItem > .ui.button.buttonCategoryItem{
    width:100%; float:left;
    height:39px;
    font:400 14px 'Rubik',sans-serif;
    color:#000;
    background:none!important;
    border-radius:0;
    padding:0 90px 0 0;
    margin:0;
    position:relative;
    display:flex;
    align-items:center;
}

.ui.button.buttonCategoryItem:before{
    content:'';
    width:8px;
    height:11px;
    background:url(/img/icon-settings-item.svg) no-repeat;
    margin:0 15px 0 7px;
}

.categoryItem > .ui.button.buttonCategoryItem > span{
    width:11px;
    height:6px;
    background:url(/img/caret.svg) no-repeat center;
    margin:0 0 0 10px;
}

.categoryItem > .ui.button.buttonCategoryItem > img{
    width:23px;
    margin-left:auto;
}

.iconsGroupItem{
    position:absolute;
    right:0;
    height:22px;
    display:flex;
    align-items:center;
    display:none;
}

.iconsGroupItem .ui.buttonIcon{
    float:none!important;
    margin:0!important;
    padding:5px 15px 0!important;
    height:22px;
}

.iconsGroupItem .ui.buttonIcon img{
    margin:0!important;
}

.iconsGroupItem .divideLine{
    margin:0;
}

.categoryItem:hover .iconsGroupItem{
    display:flex;
}

.categoryItemRow > .categoryItemRow{
    border-top:solid 1px #D3D2DE;
    border-bottom:none;
}
.categoryItemRow > .categoryItemRow .ui.button.buttonCategoryItem:before{
    margin-left:30px;
}

.ui.button.editCategoryTrigger{
    float:right;
    padding:0;
    margin:0 6px 0 auto;
    background:none;
    border-radius:0;
    background:none!important;
}
.ui.button.editCategoryTrigger img{
    width:15px;
}
