.image-progress-modal.ui.modal {
  width: 600px !important;
  padding: 0 !important;
}
.image-editor-modal.ui.modal {
  padding: 24px !important;
  width: 950px !important;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex !important;
  flex-direction: column;
  gap: 14px;
}

.image-editor-modal.image-editor-modal--single.ui.modal {
  width: 774px !important;
}
.err-message-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  word-break: break-word
}
