.copyStireInfoGroup{
    width:100%; float:left;
    margin:0 0 10px;
}
.copyStoreInfoItem{
    width:100%; float:left;
    min-height:40px;
    padding:10px 15px 10px 12px;
    border:solid 1px #D3D2DE;
    border-bottom:none;
    font:400 15px/22px 'Rubik',sans-serif;
    color:#000;

    display:flex;
    align-items:flex-start;
}

.copyStoreInfoItem:first-child{
    border-top-left-radius:2px;
    border-top-right-radius:2px;
}
.copyStoreInfoItem:last-child{
    border-bottom-left-radius:2px;
    border-bottom-right-radius:2px;
    border-bottom:solid 1px #D3D2DE;
}

.copyStoreInfoItem .ui.checkboxGroup{
    width:auto;
    margin:3px 10px 0 0;
}

.copyStoreInfoItem span{
    margin-left:auto;
    color:#667581;
    text-align:right;
}
.copyStoreInfoItem p{
    margin:0;
    flex:1;
}