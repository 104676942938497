.paraHeading{
    border-bottom:solid 1px #D3D2DE;
    padding:0 0 5px;
    margin:0;
}
.venueItem{
    width: 100%;
    float: left;
    padding: 20px 0 10px;
    border-bottom: 1px solid #D3D2DE;
}
.venueItem:last-child{
    margin:0 0 20px;
}
.venueItem .ui.checkboxGroup{
    margin: 0;
    width: auto;
}
.venueItemHead{
    width: 100%;
    float: left;
    margin: 0 0 20px;
    display: flex;
    align-items: center;
}
.venueItemHead .ui.image{
    min-width: 36px;
    height: 36px;
    border:solid 1px #D3D2DE;
    margin: 0 10px;
    overflow:hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 36px;
}
.venueItemHead .ui.image img{
    max-width:100%;
}
.venueItemHeadContent{
    flex: 1;
    font: 400 14px 'Rubik',sans-serif;
    color:#667581;
}
.venueItemHeadContent strong{
    display: block;
    font-weight:500;
    color:#000;
    margin:0 0 3px;
}
.venueItemBody{
    width: 100%;
    float: left;
    max-width: 80%;
    padding: 0 0 0 27px;
}
.venueItemBody .ui.checkboxGroup{
    width: 50%;
    margin: 0 0 10px;
}

.venue-items {
    overflow: auto;
    max-height: 410px;
}

.copy-venue-error {
    margin-top: 10px;
}
