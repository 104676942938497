.categoryGroupItem-root {
    border: 1px solid #CBCBCB;
    width: 100%;
    height: 80px;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    cursor: pointer;
}

.categoryGroupItem-root.active {
    border-color: #F15944;
    background-color: #FFEEEC;
}

.categoryGroupItem-root .icon img {
    width: 52.5px;
    height: 52.5px;
}

.categoryGroupItem-root .middle {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.categoryGroupItem-root .name {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
}

.categoryGroupItem-root .sub-text {
    width: 182px;
    height: 14px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #667581;
}

.categoryGroupItem-root .rightSide {
    visibility: hidden;
}

.categoryGroupItem-root:hover .rightSide {
    visibility: visible;
}