.map-overlays-list{
    display: flex;
    gap: 10px;
    flex-direction: column;
    margin-left:52px;
    margin-top: 18px;
    margin-bottom: 18px;
}

.map-overlays-list .map-overlay{
    display: flex;
    justify-content: space-between;
    padding-right: 21px;
}

.map-overlay-info{
    display: flex;
    gap:8px
}


.map-overlay-info img{
    width: 17px;
    height: 17px;
}
.map-overlay-action-bx{
    display: flex;
    gap: 8px;
    align-items: center;
}

.map-overlay-name{
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.map-overlay-highlight-icon{
    color: #727AFF;
    margin-top: -7px !important;
    cursor: pointer !important;
}