.listview-n-main {
  padding: 12px;
  min-height: calc(100vh - 150px);
}

.listview-n-main .footer{
  margin-bottom: 0;
  margin-top: 24px;
  float: none;
  text-align: center;
}

.list-view-modeButtonGroupListing {
  box-shadow: none !important;
  height: 100%;
  top: 0;
  right: 0;
  border-radius: 6px !important;
}

.list-view-action-btn.ui.button {
  border-radius: 4px;
  padding: 6px 12px !important;
  display: flex;
  gap: 6px !important;
  font-size: 14px;
  background-color: white !important;
  cursor: pointer;
}

.list-view-action-btn--save.list-view-action-btn.ui.button {
  background: #727df7 !important;
  color: white !important;
}
