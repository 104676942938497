.image-delete-modal.ui.modal.visible{
    width: 770px;
    padding: 30px 34px;
    color: #000000;
        top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
}

.image-delete-modal.ui.modal.visible .header{
    padding: 0px !important;
    padding-bottom: 12px !important;
    font: 500 18px "Rubik", sans-serif;
}

.image-delete-modal.ui.modal.visible .content{
    margin: 20px 0;
}

.image-delete-modal.ui.modal.visible .actions{
    margin-bottom: 0 !important;
}

.image-delete-modal.choose-new-image-and-delete-old-one {
    visibility: hidden !important;
}


