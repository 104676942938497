.defaultDetail {
    width: 100%;
    float: left;
    position: relative;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 11px 31px 11px 0;
    border-bottom: solid 1px #d6d5e0;
    position: relative;
}

.defaultDetail > div .ui.multiple.search.dropdown>input.search{
    max-width:100%;
}

.defaultDetail > div{
    width:100%;
    max-width:100%;
    display:inline-block;
}
.defaultDetail > div .ui.label,
.defaultDetail > div div{
    float:left;
    max-width:100%;
    word-wrap: break-word;
    white-space:initial;
    text-align:left;
}

.defaultDetail p {
    margin: 0;
}

.title {
    width: 100px;
    min-width: 100px;
    font: 400 15px 'Rubik', sans-serif;
    color: #667581;
}

.title>span {
    font-size: 13px;
}

.content {
    flex: 1;
    font: 400 15px 'Rubik', sans-serif;
    color: #000;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.content>.input {
    width: 100px;
    min-width: 100%;
    margin: 0!important;
    opacity: 1!important;
}

.content>.input input, .content>textarea, .dropdown {
    min-height: 0!important;
    outline: 0!important;
    font: 400 15px 'Rubik', sans-serif!important;
    background: #fff!important;
    color: #000!important;
    border: none!important;
    border-radius: 0!important;
    border-bottom: solid 1px #d3d2de!important;
    margin: 0!important;
    padding: 0 0 1px!important;
}

.content>textarea {
    width: 100%;
    height: 58px;
    resize: none;
}

.dropdown {
    width: 100%;
    float: left;
}

.dropdown .menu {
    width: 100%;
}

.content>.input input::placeholder, .content>textarea::placeholder {
    color: #7f7f7f!important;
    opacity: 1!important;
}

.content>.input input:focus, .content>textarea:focus {
    border-color: #757dff!important;
}

.content.iconInput {
    position: relative;
}

.content.iconInput img {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}

.content.iconInput>.input input {
    padding-left: 27px!important;
}

.success {
    width: 13px;
    position: absolute;
    right: 0;
}

.defaultEdit {
    margin: 0 0 27px!important;
    border: none;
    flex-direction: column;
    padding: 0;
}

.defaultEdit .title, .defaultEdit>.content {
    width: 100%;
    float: left;
}

.defaultEdit .title {
    margin-bottom: 12px;
}

.saveBtn {
    float: right;
}

.distributedText{
    display: flex;
    justify-content: space-between;
}