.imageBlock {
  width: 100%;
  height: 100%;
  position: relative;
}

.container {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 4px;
}

.container:hover div:last-child {
  opacity: 1;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.clickDisable {
  pointer-events: none;
}

.imageBlock::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  border-radius: 4px;
  border: 1px solid #D3D2DE;
  transition: border 0.17s ease-in;
}

.clicked::after {
   border: 2.4px solid #F15944;
}
