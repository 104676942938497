.ui.button.vacantButton {
    padding: 8px 18px;
    height: auto;
    line-height: 16px;
    margin-top: 20px;
    bottom:0;
    left:25px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 180px !important;
    height: 39px !important;
    gap: 0px;
    border-radius: 5px !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 18.96px;
    background-color: rgba(231, 83, 48, 1) !important;
    color: white !important;
}

.ui.modal.vacantModal {
    padding: 0 30px;
}

.ui.modal.vacantModal .aligner {
    padding: 0;
}

.ui.modal.vacantModal .pModal {
    margin: 50px 0 20px;
}

.ui.modal.vacantModal .aligner>.header {
    text-align: left;
}

.ui.modal.vacantModal .actions {
    justify-content: space-between!important;
}

.ui.modal.vacantModal .actions .ui.primary.button {
    padding: 0 30px;
}

.ui.close:before, .ui.close:after, span.close a:before, span.close a:after {
    background-color: black;
}
.ui.modal.transferModal .actions {
    margin-top: 0;
    border-radius: 0 !important;
    position: fixed;
    right: 0;
    bottom: 0;
    justify-content: space-around;
    padding: 10px 10px;
    background: #fff;
    box-shadow: 10px -4px 12px 0 rgba(0, 0, 0, .2);
}

.transferButton {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    width: 160px !important;
    height: 39px !important;
    border-radius: 5px !important;
    background: transparent !important;
    color: rgba(231, 83, 48, 1) !important;
    border: 1px solid rgba(231, 83, 48, 1) !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 18.96px !important;
}

.eraseButton{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 160px !important;
    height: 39px !important;
    gap: 0px;
    border-radius: 5px !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 18.96px;
    background-color: rgba(231, 83, 48, 1) !important;
    color: white !important;
    
}

.heading{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center !important;
    gap: 10px;
    padding-bottom: 10px;
}




/*** transfer modal ***/

.ui.modal.transferModal{
    width:100%;
    max-width:1180px!important;
    padding-top: 25px;
}
.transferModalbody{
    width:100%; float:left;
    margin:0 0 15px;
    border-bottom:solid 1px #D3D2DE;
    position:relative;
}

.ui.modal.transferModal .map-container{
    height:calc(100vh - 255px);
}

.buttonContainer{
    display: flex;
    justify-content: flex-end;
    gap: 15px;
}
.transfer{
    width: 100px !important ;
}

.instructionTooltip{
    background:rgba(255,255,255,.8);
    padding:5px 7px;
    border:solid 1px #D3D2DE;
    border-radius:2px;
    font:400 11px 'Rubik',sans-serif;
    color:#000;
    max-width: 232px;
    position:absolute;
    top:5px;
    left:5px;
    z-index:10;
}

.warinigMessage{
    z-index: 2;
    background: red;
    bottom: 60px;
    right: 0;
}
.ui.message.warinigMessage {
    position: absolute;
    right: 0;
    bottom: 47px;
    min-height: 1em;
    margin: 1em 0;
    background: #f8f8f9;
    padding: 1em 1.5em;
    line-height: 1.4285em;
    color: rgba(0, 0, 0, .87);
    transition: opacity .1s ease, color .1s ease, background .1s ease, box-shadow .1s ease;
    border-radius: .28571429rem;
    box-shadow: 0 0 0 1px rgba(34, 36, 38, .22) inset, 0 0 0 0 transparent;
}

.ui.red.message.warinigMessage {
    background-color: #ffe8e6;
    color: #db2828;
    box-shadow: 0 0 0 1px #db2828 inset, 0 0 0 0 transparent;
}
.red-border-icon {
    border: 2px solid red; /* Adjust the width if necessary */
  }

