.entityListItem {
    width: 100%;
    float: left;
    margin: 0 0 10px;
    display: flex;
    align-items: center;
    font: 400 15px 'Rubik', sans-serif;
    color: #000;
}

.entityListItem:first-child {
    margin-top: 10px;
}

.entityListItem p {
    margin: 0 0 0 10px;
}

.entityListItem .checkboxGroup {
    margin: 0 10px 0 0;
}

.entityListItem-name {
    word-break: break-word;
}

.entityListItem-actions {
    display: inline-flex;
    margin: 0 0 0 auto;
}

.entityListItem-actions .ui.buttonIcon {
    margin: 0 0 0 10px;
}