.textGroup{
    width:100%; float:left;
    margin:0 0 20px;
}

.textGroup > p,
.textGroup > b,
.textGroup > .textGroupContent{
    width:100%; float:left;
    font:400 15px 'Rubik',sans-serif;
    color:#000;
}
.textGroup > .textGroupContent small{
    width:100%; float:left;
    font:400 13px/18px 'Rubik',sans-serif;
    color:#667581;
}

.textGroup > p{
    margin:0 0 5px;
}

.textGroup > b{
    font:500 15px 'Rubik',sans-serif;
    margin:0 0 5px;
}

.textGroupFlexible{
    display:flex;
    align-items:center;
}
.textGroupFlexible > .textGroupContent{
    flex:1;
}
.textGroupFlexible > p,
.textGroupFlexible > b{
    width:auto;
    padding:0 10px 0 0;
    margin:0;
}
.textGroup .ui.input{
    float:left;
    width: 100%;
}
.textGroup .ui.input:not(.formButton)>input{
    width:100%;
    height:25px;
    float:left;
    border-radius:0;
    border:none;
    border-bottom:solid 1px #D3D2DE;
    background:none;
    padding:0;
    font:400 15px 'Rubik',sans-serif;
    color:#000;
}
.textGroup .ui.input:not(.formButton)>input::placeholder{
    color:#666666;
}
.textGroup .ui.input:not(.formButton)>input:focus{
    border-color:#1289E5;
}
.zoomTextGroup{
    margin: 1px;
}