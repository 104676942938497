.divide{
    display:inline-block;
}

.divide.horizontal{
    width:100%;
    border-top:solid 1px #d3d2de;
    padding:10px 0;
}
.divide.vertical{
    min-height:19px;
    width:1px;
    margin:0 10px 0 5px;
    border-right:solid 1px #d3d2de;
}