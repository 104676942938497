.ui.uploadModal{
    width:616px!important;
    border-radius:2px!important;
    border:solid 1px #d3d2de!important;
    box-shadow:0 0 20px rgba(0, 0, 0, 0.3)!important;
    padding:0 40px!important;
    font-family:'Rubik',sans-serif!important;
    z-index: 1000!important;
}
.ui.uploadModal > .ui.button,
.ui.crop-image-modal > .ui.button{
    padding:0;
    background:none;
}

.ui.uploadModal > .header {
    margin:36px 0 15px;
    padding:0 0 5px;
    font-size:18px!important;
    color:#222;
    border-bottom:solid 1px #d3d2de;
}
.ui.uploadModal .aligner{
    padding:0;
}
.ui.uploadModal .para{
    font:400 15px/22px 'Rubik', sans-serif;
    color:#222;
    margin:0 0 15px!important;
}

.uploadTab{
    width:100%; float:left;
    padding:0;
    margin:12px 0 0;
}

.uploadTab .ui.secondary.pointing.menu{
    border-bottom:solid 1px #d3d2de!important;
    margin:0!important;
}
.uploadTab .ui.secondary.pointing.menu .item {
    font: 400 15px 'Rubik', sans-serif!important;
    color: #000!important;
    padding: 7px 0;
    border: none;
    margin: 0 40px -1px 0;
}

.uploadTab .ui.secondary.pointing.menu .active.item {
    box-shadow: inset 0 -3px 0 0 #727aff;
}

.uploadTab >.ui.segment {
    width: 100%;
    float: left;
    padding: 14px 0 0!important;
    margin: 0;
    border: none;
    border-radius: 0;
}

.ui.placeholder.brandUpload {
    border-radius: 0;
    border: dashed 1px #b8b5ce;
    background: #f8f7fa;
    box-shadow: none;
    height: 234px;
    min-height: 0;
}

.ui.placeholder.brandUpload .header {
    font: 500 18px 'Rubik', sans-serif;
    color: #a1a1ae;
}

.ui.placeholder.brandUpload .header .sub.header {
    font: 400 15px 'Rubik', sans-serif;
    margin: 20px 0 0;
}

.uploadTab >.ui.basic.large.primary.button {
    font: 500 15px 'Rubik', sans-serif;
    padding: 17px 30px;
    max-width: 100%;
    border-radius: 0;
    border-color: #f8f7fa;
    color: #727aff;
}

.ui.input.uploadUrl {
    width: 100%;
}

.ui.input.uploadUrl>input {
    background: #f8f7fa;
    border: solid 1px #b8b5ce!important;
    border-radius: 2px;
    height: 47px;
    padding: 0 18px;
    font: 300 15px 'Rubik', sans-serif;
    color: #636364;
}

.ui.input.uploadUrl>input:focus {
    border: solid 1px #727aff!important;
}

.ui.uploadModal > .actions {
    width: 100%;
    float: left;
    border: none;
    background: none;
    margin: 30px 0;
    padding: 0;
}

.ui.uploadModal .ui.button.grey,
.ui.uploadModal .ui.button.orange{
    min-width:100px;
    height:36px;
    padding:0 20px;
    border-radius:4px;
    font-weight:500;
    font-size:15px;
    line-height:36px;
    text-align:center;
}

/** Gallery **/

.emptyGallery{
   border: 1px solid #d3d2de;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   border-radius: 3px;
   height: calc(582px - 167.4px);
}
.emptyGallery,
.emptyGallery b{
    width:100%; float:left;
    text-align:center;
}

.emptyGallery b{
    color:#a1a1ae;
    font-size:18px;
    font-weight:400;
    margin-bottom:9px;
}
.emptyGallery p {
    font-size:16px;
    color:#a1a1ae;
}

.emptyGallery p a{
    color:#727aff;
}
.emptyGallery p a:hover{
    text-decoration:underline;
}

.galleryGroup{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-auto-rows: 120px;
    margin: 10px 0;
    grid-gap: 8.7px;
    max-height: 430px;
}

.galleryThumb{
    width: 100%;
    height: 100%;
    border-radius: 3px;
    overflow: hidden;
}

.galleryThumb > .thumb{
    width:100%;
    height: 75%; 
}

.galleryThumb > .thumb div:first-child{
    width:100%;
    height: 100%; 
}

.galleryThumb > .thumb img{
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.actionsThumb{
   display: flex;
   align-items: center;
   background:#f3f2f6;
   height: 25%;
}

.actionsThumb button span{
    font-size: 13.4px;
}

.ui.actionButton{
    width:auto;
    background:none!important;
    border:none;
    border-radius:0;
    padding:0;
    margin:0;
    color:#737bff;
    font-size:13px;
    font-weight:400;
    display:flex;
    align-items:center;
}

.ui.actionButton > img{
    height:13px;
    margin:0 3px 0 0;
}

.actionsThumb > div{
    min-height:0!important;
    height:11px!important;
    border-color:#d8d8d8!important;
    margin:0 10px!important;
}

.galleryThumbDrag{
    box-shadow:0 0 20px rgba(0,0,0,0.1);
}

.galleryThumbTemplate{
   background-color: #d3d2dea8;
   border-radius: 3px;
}

.galleryThumbDrag .actionsThumb{
    opacity:0;
}

.ui.modal.crop-image-modal .ui.secondary.button{
    background:none!important;
}

.image-upload-btn{
  background-color: white !important;
  display: flex !important;
  align-items: center;
  border-radius: 4px !important;
  border: 1px solid #F15944 !important;
  padding: 6px 18px !important;
  margin: 17px 0 !important;
  cursor: pointer;
  color: #F15944 !important;
}