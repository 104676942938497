
.mapsted-splash
{
    top:0;
    left:0;

    width: 100vw;
    height: 100vh;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.842);

    background-image: url(https://studio.mapsted.com/Content/images/loadbtn.gif);
    background-repeat: no-repeat ;
    background-size: 100% 3px;
    background-position: top;
}

.mapsted-splash > img
{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.mapsted-splash-small
{
    animation-name: fadeOut;
    animation-duration: 5s;
    animation-fill-mode: both;

    text-align: center;
    background: url(https://studio.mapsted.com/Content/images/loadbtn.gif) no-repeat ;
    background-size: 100% 3px;
    background-position: bottom;

}

 @keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0.2;}
 }

