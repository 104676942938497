
.publishContext{
    width:auto;
    display:inline-block;

    margin:0 0 0 10px;
}

.ui.popup.publishPopup{
    width:260px;
    max-width:260px;
    padding:0 0 0 15px;
    border:none;
    border-radius:3px;
    box-shadow:0 2px 8px rgba(0,0,0,0.1);
}
.ui.popup.publishPopup:before{
    display:none!important;
}

.ui.button.publishButton{
    width:100%; float:left;
    padding:12px 12px 13px 0;
    background:none;
    position:relative;
    font:400 15px 'Rubik',sans-serif;
    color:#000;
    border-radius:0;
    border-bottom:solid 1px #D3D2DE;

    display:inline-flex;
    align-items:center;
    justify-content:space-between;
}
.ui.button.publishButton:last-child{
    border:none;
}
.ui.button.publishButton > img:last-child{
    transform:rotate(-90deg);
}

.ui.header.publishHead{
    display:flex;
    align-items:center;
    font:400 15px 'Rubik',sans-serif;
    border-bottom:solid 1px #D3D2DE;
    padding:16px 0 14px;
}
.ui.header.publishHead > .ui.buttonIcon:first-child,
.ui.header.publishHead > img:first-child{
    margin:0 10px 0 0;
}
.ui.header.publishHead > .ui.buttonIcon img{
    margin:0;
    filter:saturate(0) brightness(.8);
}
.ui.header.publishHead > img:first-child{
    width:18px;
}

.applicationItem{
    width:100%; float:left;
    padding:0 15px 0 0;
    margin:0 0 12px;
    display:flex;
    align-items:center;
    font:400 15px 'Rubik',sans-serif;
    color:#000;
}
.applicationItem .ui.image{
    width:20px;
    display:flex;
    justify-content:center;
    margin:0 10px 0 0;
}
.applicationItem .ui.buttonIcon{
    margin-left:auto;
}
.applicationItem small{
    font:400 12px/20px 'Rubik',sans-serif;
    min-height:20px;
    border-radius:3px;
    padding:0 5px;
    background:#F4F7FC;
    color:#5C6266;
    margin:0 0 0 auto;
}

.publishActionGroup{
    width:100%; float:left;
    padding:15px 15px 15px 0;
    border-top:solid 1px #D3D2DE;
}
.publishActionGroup .ui.button{
    margin:0 0 10px;
}
.publishActionGroup .ui.button:last-child{
    margin: 0;
    min-height: 36px;
    max-height: max-content !important;
    height: auto;
}

.ui.publishPopup .textGroup{
    padding-right:15px;
}

.publishProgress{
    width:100%;
    position:relative;
    padding:20px 15px 20px 0;
    height: 7rem;
    font:400 13px 'Rubik',sans-serif;
}
.publishProgress .ui.text.loader{
    font:400 13px 'Rubik',sans-serif;
}

.publishProgress .ui.button{
    position: absolute;
    right: 8px;
    top: 12px;
}
.publishProgress .ui.button img{
    width:18px;
}
.publishProgress p{
    flex-basis:100%;
    font:400 13px 'Rubik',sans-serif;
    color:#667581;
    margin:0;
}
.publishProgress .ui.progress{
    flex:1;
    float:left;
    margin:20px 0;
    position:relative;
}
.publishProgress .ui.progress .bar>.progress{
    position:absolute;
    top:0!important;
    right:0!important;
    color:#667581;
    margin-top:-20px!important;
}

.disabledGroup{
    cursor: initial;
}
.disabledGroup .ui.button{
    color:#b1b1b1;
}
.disabledGroup .ui.button img{
    opacity:.5;
}
.ui.popup.publishTooltip{
    width:160px;
    padding:6px 10px;
    border-radius:4px;
    border:none;
    font:400 13px/18px 'Rubik',sans-serif;
    color:#000;
}
.publishValidationActions{
    width:100%; float:left;
    padding:0 10px 10px 0;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
}
.publishValidationActions b{
    font:600 15px 'Rubik',sans-serif;
    margin:0 0 5px;
}
.publishValidationActions b:not(:first-child){
    margin-top:10px;
}
.publishValidationActions .ui.button{
    width:100%;
    float:left;
    min-height:32px;
    background:#feeeec!important;
    border:solid 1px #f47e6e!important;
    border-radius:3px;
    text-align:left;
    display:inline-flex;
    align-items:center;
    justify-content:flex-start;
    font:400 15px 'Rubik',sans-serif;
    padding:0 8px 0 5px;
    color:#000;
    margin:0 0 5px;
}
.publishValidationActions .ui.button small{
    font-size:14px;
    margin-left:auto;
}
.publishValidationActions .ui.button span{
    font-size:14px;
    color:#f2634f;
    margin-left:auto;
}

.publishValidationActions .ui.button.buttonSuccess{
    background:#e5f7e8!important;
    border-color:#4ac561!important;
}
.publishValidationActions .ui.button i.icon{
    margin:-2px 3px 0 0!important;
}
.publishValidationActions .ui.button .ui.loader{margin-right:7px;}

.iconCloseCustom{
    width:21px;
    height:17px;
    position:relative;
    margin-top:1px;
}

.iconCloseCustom:before,
.iconCloseCustom:after{
    position: absolute;
    left: 7px;
    top: 0;
    content: '';
    height: 17px;
    width: 2.5px;
    background:#f2634f;
}

.iconCloseCustom:before{
    transform: rotate(45deg);
}

.iconCloseCustom:after{
    transform: rotate(-45deg);
}