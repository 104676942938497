.ui.header.nameHead{
    width:100%; float:left;
    margin:0 0 2px;
    font:500 20px 'Rubik',sans-serif;
    color:#000;
    display:flex;
    align-items:center;
    justify-content:space-between;
}

.store-detail-box .short-heading{
    color:#666;
    margin:0 0 15px;
}

.p-description{
    width:100%; float:left;
    margin:0 0 17px;
    font:400 15px/24px 'Rubik',sans-serif;
    color:#000;
}

.ui.button.saveBtn{
    float: right;
    padding-left:40px;
    padding-right:40px;
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    width: 180px !important;
    height: 39px !important;
    border-radius: 5px !important;
    background: transparent !important;
    color: rgba(231, 83, 48, 1) !important;
    border: 1px solid rgba(231, 83, 48, 1) !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 18.96px !important;
}

.ui.dropdownCategory{
    box-shadow:none!important;
    padding:0!important;
}
.ui.dropdown.dropdownCategory>i.icon{
    top:6px!important;
}
.ui.dropdown.dropdownCategory>input.search{
    padding:0!important;
}
.ui.search.dropdown.dropdownCategory >.text{
    min-height:25px;
    max-width: 450px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ui.selection.dropdownCategory .menu .item.active.selected{
    background:#f7f7f7!important;
    font-weight:500!important;
}

.ui.dropdownCategory .menu .item, .ui.selection.dropdownCategory .menu .item{
    width:100%; float:left;
    box-shadow:inset 0 -1px 0 0 #ccc;
}
.ui.dropdownCategory .menu .item:last-child, 
.ui.selection.dropdownCategory .menu .item:last-child{
    box-shadow:none;
}

.ui.dropdown.dropdownCategory.dropdownCategoryAlt >input.search{
    position:relative;
    padding:0px 0px 12px 0px!important;
}
.ui.dropdown.dropdownCategory.dropdownCategoryAlt >.text{
    position:absolute;
    top:0;
    left:0;
}

.ui.dropdownCategory-mod{
    height: 32.76px;
}

.ui.dropdownCategory input{
    padding:0!important;
}

.ui.keywordsDropdown input{
    padding:0!important;
}

.ui.multiple.dropdownCategory >.label{
    border:solid 1px #d3d2de !important;
    box-shadow:none!important;
    border-radius:100px !important;
    background:#fff!important;
    font:400 14px 'Rubik',sans-serif !important;
    color:#000!important;
    padding:2px 10px!important;
    margin:0 10px 5px 0!important;

    word-wrap: break-word;
    max-width:100%;
}

.ui.multiple.dropdownCategory >.label i.icon{
    padding:0!important;
    margin:0 0 0 10px!important;
    font-size:12px;
}

.ui.dropdownFlexed .ui.search.dropdown .menu,
.ui.selection.dropdownCategory .menu{
    border-radius:2px!important;
    border:solid 1px #000!important;
}

.ui.dropdownCategory .menu .item,
.ui.selection.dropdownCategory .menu .item,
.ui.dropdownFlexed .ui.search.dropdown .menu .item{
    background:none!important;
    font:400 14px 'Rubik',sans-serif!important;
    color:#000!important;
    padding:10px!important;
    border:none!important;
}

.ui.dropdownCategory .menu,
.ui.selection.active.dropdown.dropdownCategory .menu,
.ui.dropdownFlexed .ui.search.dropdown .menu{
    border:solid 1px #d3d2de!important;
    box-shadow:0 0 20px rgba(0, 0, 0, 0.3);
}

.ui.dropdownCategory .menu .item:hover,
.ui.selection.dropdownCategory .menu .item:hover,
.ui.dropdownFlexed .ui.search.dropdown .menu .item:hover{
    background:#f7f7f7!important;
}

.keywordsArea > div{
    flex-wrap:wrap;
}


.keywordsDropdown .default.text{
    padding:0!important;
    margin-left:0!important;
}
.keywordsDropdown input{
    padding:0!important;
    margin-left:0!important;
}
.ui.selection.dropdownCategory.keywordsDropdown .menu .item{
    padding:13px 35px 12px 10px!important;
}
.ui.keywordsDropdown .menu .item:after,
.ui.keywordsDropdown .menu .message:after{
    content:'';
    background:url(/img/icon-arrow-cms.svg) no-repeat center;
    background-size:16px auto;
    transform:rotate(180deg);
    filter:saturate(0);
    width:40px;
    height:100%;
    position: absolute;
    right:0;
    top:0;
    z-index:1000
}

.ui.trigger-calendar.edit-sidebar-calendar {
    margin-top: 1rem;
    margin-left: 0px;
}

.entityIdDisplay {
    font-weight: bold;
    display: inline-block;
    margin-right: 10px;
}

.buildingIdDisplay {
    font-weight: bold;
    display: inline-block;
    margin: 0px 10px 0 26px;
}

.ui.button.buttonIcon.entityIdCopyBtn {
    display: inline-block;
}
.buttonWrapper{
    z-index: 1;
    position: fixed;
    right: 0px;
    bottom: 0px;
    justify-content: space-around;
    display: flex;
    width: 420px;
    padding: 10px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 10px -4px 12px 0px rgba(0, 0, 0, 0.2);
}