.image-controls {
    display: flex;
    justify-content: space-between;
}

.absolute {
    position: absolute;
}

.img-wrapper {
    z-index: 1;
    border: 2px solid black;
    background: transparent;
    bottom: 5px;
    left: 5px;
    cursor: move;
}

.img-wrapper>.ol-viewport {
    cursor: default;
}

.img-wrapper>[role="presentation"] {
    padding: 15px
}


div>.react-draggable {
    z-index: 100 !important
}

.image-preview-container {
    width: 400px;
    height: 500px;
    overflow: hidden;
}

.image-preview-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.floorPlan-body {
    width: auto;
    padding: 0;
}

.floorPlanGeoReferencingTabs {
    width: 100%;
    float: left;
}

.ui.button.floorPlanGeoReferencingTab {
    width: 100%;
    float: left;
    padding: 5px 0;
    border-bottom: solid 1px #eee;
    border-radius: 0;
    font: 400 13px 'Rubik', sans-serif;
}

.ui.button.floorPlanGeoReferencingTab:last-child {
    border: none;
}

.floorPlanPreview {
    max-width: 100%;
    display: inline-block;
}

.floorPlanPreview .image-preview-container {
    border: solid 1px #ddd;
    width: 100%;
    max-width: 600px;
}

.floorPlanPreview .image-preview-container img {
    max-height: 100%;
}

.floorplanPreviewActions {

    position: fixed;
    float: left;
    padding: 5px 0;
    display: flex;
    align-items: center;
    bottom: -3px;
}

.floorplanPreviewActions .ui.button.primary {
    height: 26px;
    padding: 0 10px;
    line-height: 27px;
    margin: 0 0 0 25px;
}

.floorplanPreviewActions .ui.button.buttonIcon {
    padding: 5px;
    border: solid 1px #0020FF;
    margin: 0 10px 0 0;
}

.ui.modal.PDFSelectionModal {
    width: 100%;
    max-width: 700px !important;
    padding: 0 45px 30px;

}

.ui.modal.PDFSelectionModal>.header {
    width: 100%;
    float: left;
    border-bottom: solid 1px #D3D2DE;
    margin: 46px 0 15px;
    padding: 0 0 5px;
    font: 400 18px 'Rubik', sans-serif !important;
}

.ui.modal.PDFSelectionModal>.content {
    padding: 0 !important;
}

.pdf-modal-body {
    width: 100%;
    float: left;
}

.uploadPdfPlaceholder {
    width: 100%;
    float: left;
    padding: 10px;
    margin: 20px 0;
    background: #f4f7fc;
    border: dashed 1px #d3d2de;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.uploadPdfPlaceholder .ui.button.primary {
    width: auto;
    background: none;
    border: solid 1px #F15944;
    color: #F15944;
}

.no-file-added {
    width: 100%;
    float: left;
    margin: 10px 0 0 !important;
    font: 400 13px 'Rubik', sans-serif;
}

.pdfPreviewGroup {
    width: 100%;
    float: left;
    padding: 20px 0 0;
    display: flex;
    flex-wrap: wrap;
}

.pdfPreviewColumn {
    width: 19%;
    height: 120px;
    margin: 0 1% 10px 0;
    border: solid 1px #D3D2DE;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.pdfPreviewColumn .ui.image {
    max-height: 100%;
}

.pdfPreviewColumn.image-active {
    border-color: #F15944;
}

.flex-center {
    display: flex;
    align-items: center;
}

.pd-right-15 {
    padding-right: 15px;
}

.width-2p {
    width: 20%;
}
