.bulk-image-upload-modal.ui.modal{
    width: 990px !important;
    padding: 0 !important;
        top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.bulk-image-upload-modal.ui.modal .content{
    display: flex;
    padding: 16px;
}

.bulk-image-upload-modal.ui.modal .content .image-upload-main-wrapper{
    flex: 1;
}

.bulk-image-upload-modal.ui.modal.visible .header{
            padding: 16px;


}

.bulk-image-upload-modal.ui.modal .content .image-upload-main-wrapper .image-cropper-box{
    padding-top: 0;
    padding-bottom: 0;
}


.bulk-image-upload-modal.ui.modal.visible .actions{
    margin-bottom: 0 !important;
    padding: 16px;
}