.images-management-header {

}

.images-management-header-title {
    
    margin: 16px 0;
        font: 400 13px "Rubik", sans-serif;
    color: #000;
}

.images-management-header-actions {
    margin-bottom: 18px;
    display: grid;
    grid-template-columns: 1fr repeat(4, minmax(0, max-content));
    align-items: center;
    gap: 10px;
}

.images-management-header-duplicate-search img{
    width: max-content !important;
}


