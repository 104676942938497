.image-editor-bx.ui.container {
  display: grid !important;
  gap: 24px;
  height: 100%;
  grid-template-columns: 110px minmax(0, 1fr);
  padding: 0 !important;
  position: relative;
}

.upload-btn {
  position: absolute;
  bottom: -4px !important;
  right: 0px;
}

.close-btn {
  top: -9px !important;
  z-index: 999999;
  left: 98%;
}
