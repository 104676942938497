.bulk-images-list 
{
    max-height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 2px;
}

.bulk-images-list::-webkit-scrollbar {
  width: 4px;
}

.bulk-image-item 
{
    width: 100px;
    height: 100px;
    position: relative;
    margin-bottom: 14px;
}

.bulk-image-item img 
{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.bulk-image-item-checkbox-icon-bx{
    width: 18px;
    height: 18px;
    position: absolute;
    bottom: 8px;
    right: 8px;
}
