.image-view-modal.ui.modal.visible{
 width: 770px;
    padding: 30px 34px 0px  34px;
        top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
}

.image-view-modal.ui.modal.visible .header{
    padding: 0px !important;
    padding-bottom: 12px !important;
    display: flex;
    align-items: baseline;
}

.image-view-modal.ui.modal.visible .content{
    margin: 14px 0;
}

.image-view-modal.ui.modal.visible .actions{
    margin-bottom: 0 !important;
}

.image-view-modal.ui.modal.visible .image-container{
    height: 221px;
    margin-bottom: 14px;
    border: 1px solid #D3D2DE;
    border-radius: 4px;
    overflow: hidden;
}

.image-view-modal.ui.modal.visible .image-container img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.image-view-modal.ui.modal.visible .image-info-container{
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, .7fr);
    gap: 18px;
}

.image-info-layout{
    display: flex;
    align-items: flex-start;
    gap: 6px;
         font: 400 15px "Rubik", sans-serif !important;
         margin-bottom: 9px;
         color: #000;

}

.image-info-label{
     font-weight: 500;
     min-width: max-content;
}

.image-info-value{
    flex: 1;
    flex-wrap: wrap;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.image-view-modal.ui.modal.visible .actions{
    display: flex;
    gap: 4px;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 0px;
    border-top: 1px solid rgba(34,36,38,.15);
}

.image-view-modal.ui.modal.visible .delete-btn{
    background-color: transparent;
    color: #E75330;
    display: flex;
    align-items: center;
    gap: 6px;
    margin: 0;
   
}

.delete-img-wrapper{
  width: 24px;
  height: 24px;
  background-color:  #F159441A;
  border-radius: 50%;
  display: flex  ;
  justify-content: center;
  align-items: center;
}

.delete-img-wrapper img{
    width: 16px;
    height: 16px;
}


.image-view-modal-image-header-name{
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
      white-space: nowrap;
}
