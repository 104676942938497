.categoryDropdownItem {
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 7px;
    gap: 8px;

    /* width: 151.44px; */
    height: 22px;

    border: 1px solid #969696;
    border-radius: 30px;


    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;

    color: #666666;



}