.dynamicMapLayerSidebar h2.ui.header {
    display: inline-flex;
    align-items: center;
    padding:0;
    border:none;
    margin:0;
}
.dynamicMapLayerSidebar h2.ui.header .ui.buttonIcon{
    margin-left:auto;
}
.paraDynamicHead{
    border-bottom:solid 11px #F4F7FC;
    padding:0 0 20px;
    margin-top:0;
}
.dynamicTgRow {
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
}

.colorLabelContainer {
    width: 265px;
}

.headingButton img{
    margin:0 5px 0 0 !important;
}

.dynamicTgRow .textGroup {
    width: 45%;
}

.dynamicLayerCodebox {
    width: 100%;
    float: left;
    height: 30px;
    padding: 7px 30px 0 10px;
    background: #F4F7FC;
    border: solid 1px #D3D2DE;
    border-radius: 3px;

    position: relative;
}

.dynamicLayerCodebox p {
    float: left;
    color: #7A7B7E;
    font: 400 13px 'Rubik', sans-serif;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dynamicLayerCodebox>.ui.buttonIcon {
    position: absolute;
    right: 10px;
}

.dynamicTg>b {
    display: flex;
    align-items: center;
}

.dynamicTg>b img {
    margin: 0 0 0 5px;
}

.dynamicTg .checkboxGroup {
    width: 100% !important;
    margin: 10px 0 0 !important;
}

.ui.popup.dataTypeInfoPopup {
    width: 320px;
    max-width: 316px;
    font: 400 13px/18px 'Rubik', sans-serif;
    color: #000;
    padding: 20px;
    border-radius: 3px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .2);
    border: none;
}

.dynamicLayerIconbox {
    width: 100%;
    float: left;
    margin: 0 0 20px;
    padding: 8px 0 0 8px;
    background: #F4F7FC;
    border: solid 1px #D3D2DE;
    border-radius: 2px;
}

.paraDynamic {
    font: 400 13px/18px 'Rubik', sans-serif;
    color: #667581;
}

.paraDynamic a{
    color:#757DFB;
    text-decoration:underline;
}

.ruleConditionBox {
    width: 100%;
    float: left;
    margin: 15px 0 0;
    display: flex;
    align-items: center;
}

.ruleConditionButtons {
    display: inline-flex;
    margin: 0 20px 0 0;
}

.ruleConditionButtons .ui.buttonIcon {
    min-width: 30px;
    width: 30px;
    height: 30px;
    border-radius: 0;
    background: #fff !important;
    border: solid 1px #D3D2DE !important;
    justify-content: center;
}

.ruleConditionButtons .ui.buttonIcon:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-right: none !important;
}

.ruleConditionButtons .ui.buttonIcon:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-left: none !important;
}

.ruleConditionButtons .ui.buttonIcon.active {
    background: #737BFF !important;
    border-color: #737BFF !important;
}

.ruleConditionButtons .ui.buttonIcon.active img {
    filter: saturate(0) brightness(100) !important;
}

.ruleConditionBox>.ui.input {
    width: auto;
}

.ruleConditionBox>.ui.input>input {
    width: 140px;
}

.mapOverlayPropertybox {
    width: 100%;
    float: left;
    padding: 10px 0 0 10px;
    margin: 0 0 20px;
    background: #F4F7FC;
    border: solid 1px #D3D2DE;
    border-radius: 3px;
}

.mapOverlayPropertybox .textGroup {
    width: 100%;
    float: left;
    margin: 0 0 10px;
    font: 400 15px 'Rubik', sans-serif;
    display: flex;
    align-items: center;
}

.mapOverlayPropertybox .textGroup p {
    width: auto;
    color: #667581;
    white-space: nowrap;
    margin: 0 10px 0 0;
}

.mapOverlayPropertybox .textGroup>.textGroupContent {
    width: auto;
    flex: auto;
    word-break: break-word;
    max-width: 220px;
}

.mapOverlayPropertyRow {
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
}

.colorFill {
    display: block;
    margin: 0 20px 0 0;
    min-width: 20px;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background: #00D99F;
}

.mapOverlayPropertyRow .textGroup {
    width: auto;
}

.dynamicMapLayercancelButton.ui.button {
    margin-left: 133px;
}
.dymanicMapLayerBox .textGroup .textGroupContent{
    display:flex;
    align-items:center;
}

.conditionalArrow{
    display:inline-block;
    margin:0 10px;
}
.conditionalArrow.greater{
    transform:rotate(180deg);
}
.ruleIcon{
    width:26px;
    height:26px;
    border-radius:3px;
    border:solid 1px #D3D2DE;
    background:#fff;
    margin:0 10px;
    display:inline-flex;
    align-items:center;
    justify-content:center;
    align-items:center;
}
.ruleIcon img{
    width:15px;
}
.tgTextPopup{
    flex-direction:column;
    align-items:flex-start!important;
    text-align:left;
}
.tgTextPopup > p{
    margin:0 0 10px!important;
}
.tgRuleHeading{
    display:flex;
    align-items:center;
    margin:0 0 5px;
}
.tgRuleHeading .ui.buttonIcon{
    margin-left:auto;
}

.uploadedFiles{
    width:100%; float:left;
    display:flex;
    align-items:center;
}
.uploadedFiles p{
    margin:0;
}
.uploadedActions{
    margin-left:auto;
    display:inline-flex;
    align-items:center;
    font:400 13px 'Rubik',sans-serif;
    color:#667581;
}
.uploadedActions .ui.buttonIcon{
    margin:0 0 0 10px;
}

.dragDropBox{
    width:100%; float:left;
    padding:25px 20px 30px;
    margin:0 0 20px;
    border-radius:2px;
    border:dashed 1px #D3D2DE;
    background:#F4F7FC;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    font:400 15px 'Rubik',sans-serif;
    color:#667581;
}
.dragDropBox span{
    margin:10px 0;
}
.dragDropBox .ui.button{
    font:500 13px 'Rubik',sans-serif;
    background:none;
    color:#F15944;
    border:solid 1px #F15944;
    border-radius:2px;
}

.dynamicMapLayerOpacitySetting .transparentIcon {
    width: 29px;
}

.dynamicMapLayerOpacitySetting.textGroup {
    margin-bottom: 0;
}

.dynamicMapLayerOpacitySetting b {
    margin-bottom: 15px;
}

.emptyIconPlaceholder.ui.input {
    width: 50%;
}

.editIconButton.ui.button {
    float: right;
    margin-top: 10px;
}

.automationRuleIcon {
    float: left;
}

p.automationRuleIconText {
    display: inline-block;
    margin: 7px 0 0 10px !important;
}

.mapOverlayPropertybox.active {
    background-color: #d6feff;
}

.dynamicMapLayerConfigurationItem {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    font: 400 15px 'Rubik', sans-serif;
    color: #000;
    padding: 0px 16px;
    margin-bottom: 10px;
    overflow-x:hidden;
}

.dynamicMapLayerConfigurationItem:first-child {
    margin-top: 10px;
}

.mapOverlayClearButton.ui.button {
    float: right;
    margin-top: 3px;
}

.mapOverlayPicker.ui.dropdown {
    width: 89%;
}

.mapOverlayPicker.ui.dropdown .text {
    word-break: break-word;
    max-width: 250px;
}

.expandIcon.ui.button.buttonIcon {
    margin: 0px 9px 0 0;
}

.expandIcon.ui.button.buttonIcon.iconOpen {
    transform: rotate(-90deg);
}

.dynamicMapLayerAutomationRuleItem {
    cursor: pointer;
    width: 92%;
    float: left;
    display: flex;
    align-items: center;
    font: 400 13px 'Rubik', sans-serif;
    color: #000;
    padding: 0px 16px;
    margin: 5px 0 5px 29px;
}
.automationRuleItem{
    word-break: break-word;
}

.dynamicMapLayerConfigurationItem.expanded {
    margin-bottom: 0;
}

.activitySidebar-mapLayerName {
    cursor: pointer;
    word-break: break-word;
    max-width:100%;
    padding:0 20px 0 0;
}

.dynamicMapLayerConfigurationItem .activitySidebar-mapLayerName {
    margin: 0;
}

.dynamicMapLayerActivityCloseButton.ui.button.buttonIcon {
    display: inline-block;
    margin-left: 48px;
    margin-top: 3px;
}

.sidebarPortal.activitySidebarDynamicLayer h2.ui.header{
    display:flex;
    align-items:center;
}
.sidebarPortal.activitySidebarDynamicLayer h2.ui.header strong{
    font-weight:500;
    margin:0;
}
.sidebarPortal.activitySidebarDynamicLayer h2.ui.header .dynamicMapLayerActivityCloseButton{
    margin-left:auto;
    float:right;
}

.multiDynamicMapLayerItem {
    margin-left: 10px;
}

.multiDynamicMapLayerItem-creationSidebar {
    padding: 0;
}

.multiCreationSidebar .dynamicMapLayerConfigurationItem  {
    padding: 0;
}

.multiCreationSidebar .dynamicMapLayerAutomationRuleItem {
    padding: 0;
    width: 95%;
    margin: 5px 0 5px 24px;
}

.automationRuleActions .ui.button.buttonIcon {
    float: right;
    margin-left: 10px;
}

.dynamicMapLayerConfigurationItem.active .activitySidebar-mapLayerName {
    color: #757dff;
}

.dynamicMapLayerAutomationRuleItem .automationRuleLabel.active {
    color: #757dff;
}

.dynamicMapLayerAutomationRuleItem .automationRuleLabel {
    margin-right: 10px;
    white-space: nowrap;
}

.multiMapLayerTab .dynamicMapLayerAutomationRuleItem {
    padding: 0;
    width: 80%;
    margin: 5px 0 5px 55px;
}

.headingTextGroup {
    margin-bottom: 6px;
}

.creationTabHeading {
    font-weight: 500;
}

/* Chrome, Safari, Edge, Opera */
.automationRuleValue-input input::-webkit-outer-spin-button,
.automationRuleValue-input input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.automationRuleValue-input input[type=number] {
    -moz-appearance: textfield;
}

.dividerLineUp{
    padding-top:15px;
    border-top:solid 11px #F4F7FC;
}

.mapOverlayPicker.ui.scrolling.dropdown .menu {
    max-height: 180px;
}

.automationRuleTextLabel .textGroupContent {
    max-width: 220px;
}