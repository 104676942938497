.category-uses-trigger {
    width: fit-content;
    height: 14px;

    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #757DFF;

    gap: 5px;
}

.expandedCategoryUseRoot {
    width: 455px;
    height: 82px;
    background: rgba(114, 122, 255, 0.15);
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 30px;
}

.expandedCategoryUseRoot p {
    margin-left: 20px;
    font-family: Rubik;
    font-size: 11px;
    font-weight: 400;
    line-height: 13.04px;
    text-align: left;
    color: #666666;
}