.mergeActions {
    width: auto;
    display: flex;
}

.mergeActions .ui.button.closeBtn {
    width: 36px;
    height: 36px;
    margin-left: 10px;
    padding: 0;
    background: #fff!important;
    border-radius: 3px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    position: relative;
    top: auto;
    right: auto;
    opacity: 1!important;
}

.mergeActions .ui.button.closeBtn:before, .mergeActions .ui.button.closeBtn:after {
    background-color: #555;
    top: 9px;
    left: 16px;
}

.mergeActions .ui.button.closeBtn:hover:before, .mergeActions .ui.button.closeBtn:hover:after {
    background-color: #000;
}