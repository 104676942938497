.ui.modal.kioskPreviewModal{
    width:430px!important;
}

.kioskPreviewTable{
    width: 100%;
    float: left;
}
.kioskPreviewRow{
    width: 100%;
    float: left;
    display: flex;
    border:solid 1px #d3d2de;
    border-radius:2px;
    margin:0 0 10px;
}
.kioskpreviewtableItem{
    flex:1;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    font-size:14px;
    color:#000;
    padding:5px 10px;
}
.ui.popup.webCopyPopup{z-index:2000;}

.kioskImg{
    width:25px;
    margin:0 10px 0 0;
}

.kioskPreviewRow:first-child .kioskpreviewtableItem{
    color:#666;
    font-size:13px;
    padding:2px 10px;
}
.kioskPreviewRow:first-child{
    border:none;
    margin:0;
}
.kioskpreviewtableItem:last-child .ui.buttonIcon{margin-left:auto;}

.kioskpreviewtableItem:first-child{
    flex:2;
}
.kioskpreviewtableItem p{
    width: 100%;
    float: left;
    max-width:125px;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
}