.ui.button.toggleBtn{
    width:40px;
    height:40px;
    background:none;
    margin:0;
    display:none;
    margin-right:auto;
}

.subheaderToggle{
    width:100%;
    padding:5px 10px;
    background:#fff;
    box-shadow:0 0 8px rgba(0,0,0,0.2);
    position:absolute;
    top:47px;
    left:0;
    z-index:2;
}

.subheaderToggle .ui.btn-view-popup{
    float: right;
    margin: 4px 0 0 0;
}

@media (max-width:1435px){
    .subheader-right>.ui.button{
        margin-left:5px;
    }
}

@media (max-width:1250px){
    .ui.main-grid>.column:last-child{
        margin-left:20px;
    }
    .ui.container{
        padding:0 15px;
    }
}

@media (max-width:1400px){
    .ui.label.dropdown-submenu{
        width:160px;
        max-width:230px;
        display:inline-block;
        padding-left:22px;
    }
    .ui.label.dropdown-submenu strong{
        width:100%;
        max-width:100%;
        margin-left:0;
    }
    .ui.label.dropdown-submenu>img{
        position:absolute;
        left:0;
        top:7px;
        margin:0;
    }
}

@media (max-width:1110px){
    .subheader-right .ui.button{
        margin-left:10px;
    }

    .ui.button.property-button a{
        padding:8px 10px;
    }
}

@media (max-width:1024px){

    .ui.main-grid{
        display:inline-block;
    }

    .ui.main-grid > [class*="equal width"].row>.column, 
    .ui.main-grid[class*="equal width"].grid>.column:not(.row), 
    .ui.main-grid[class*="equal width"].grid>.row>.column,
    .ui.cover-image{
        width:100%!important;
        float:left;
        margin:0;
    }
    .ui.cover-image{
        text-align:center;
    }
    .ui.cover-image img{
        display:inline-block;
        max-width:100%;
    }

}

@media (max-width:767px){
    .ui.menu.header-bar .right.menu .item{
        padding:3px;
        margin-left:5px;
    }
    .ui.menu.header-bar .item, .ui.menu.header-bar .ui.button{
        padding-left:0;
        padding-right:0;
    }
    .ui.button.toggleBtn{
        display:block;
    }
    .ui.basic.segment.subheader .subheader-left{
        display:none;
    }
    .ui.basic.segment.subheader{
        padding:4px 0 0;
    }

    .header .ui.fluid.container,
    .subheader .ui.fluid.container{
        padding:0 15px;
        margin:0!important;
    }

    .ui.popup.popup-submenu:before{
        left:28%!important;
    }

    .ui.popup.view-all-popup:before{
        left:auto!important;
        right:30px!important;
    }

    .ui.view-building-group .item{
        flex-direction:row!important;
        margin:0 0 12px;
    }
}