.image-upload-details-box{
    border-left: 1.5px solid #E8E8E8;
}

.image-name-edit-input{
        background: #ebebeb;
    padding: 10px 12px;
    outline: none;
    font: 400 14px "Rubik", sans-serif;
    color: #000;
    border: none;
    border-radius: 4px;
    width: 100%;
}

.image-upload-details-crop-image-section .content-section-main{
    display: flex;
    align-items: center;
    gap: 12px;
}

.image-upload-details-aspect-ratio-section .content-section-main{
    display: grid;
    grid-template-columns: repeat(2, 60px);
    align-items: center;
    gap: 12px;
}
