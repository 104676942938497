.ui.tagLabelShare{
    width:auto;
    height:29px;
    display:inline-flex;
    align-items:center;
    position:relative;

    border-radius:2px;
    background:#F3F6FB;
    padding:0 30px 0 7px;
    margin:0 5px 5px 0;
    font:400 14px 'Rubik',sans-serif;
    color:#000;

    max-width:150px;
    white-space:nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
}
.ui.tagLabelShare .ui.buttonIcon{
    position:absolute;
    right:5px;
    top:10px;
}
.ui.tagLabelShare .ui.buttonIcon img{
    width:10px;
}

.ui.button.publishTrigger{
    min-width:100px;
    height:30px;
    background:#F15944!important;
    font:500 13px 'Rubik',sans-serif;
    color:#fff;
    display:flex;
    align-items:center;
    justify-content:center;
    padding:0 10px;
    white-space:nowrap;
}
.ui.button.publishTrigger img{
    margin:0 0 0 12px;
    padding:5px 0 5px 8px;
    border-left:solid 1px #fff;
    line-height:20px;
}

.ui.input.formButton,
.ui.icon.input.formButton{
    width:100%; float:left;
    display:flex;
    align-items:center;
    position:relative;
}
.ui.input.formButton>input,
.ui.icon.input.formButton>input{
    height:30px;
    border-radius:3px;
    border:solid 1px #D3D2DE;
    color:#000;
    font:400 13px 'Rubik',sans-serif;
}
.ui.icon.input.formButton>input::placeholder{
    color:#667581;
}
.ui.icon.input.formButton>img{
    position:absolute;
    right:10px;
}
.ui.input.formButton>input:focus,
.ui.icon.input.formButton>input:focus{
     border-color:#727AFF;
}

/** Modalbox **/

.ui.modalBox{
    border:solid 1px #D3D2DE!important;
    width:576px;
    padding:35px 40px 25px;
    font-family:'Rubik',sans-serif;
}
.ui.modalBox .ui.close:before, 
.ui.modalBox .ui.close:after{
    background:#222;
}
.ui.modalBox>.header,
.ui.modalBox>.aligner>.header{
    font:400 18px 'Rubik'!important;
    padding:0 0 5px;
    margin:0 0 20px;
    border-bottom:solid 1px #D3D2DE;
    display:flex;
    align-items:center;
}
.ui.modalBox>.actions{
    padding:10px 0 0;
    margin:0;
}
.ui.modalBox>.header .ui.buttonIcon{
    margin-left:auto;
    font-size:18px!important;
    text-decoration:none!important;
}

.ui.modalBox.small{
    width:535px;
}

/*** popup ***/

.headingPopup{
    width:100%; float:left;
    font:500 15px 'Rubik',sans-serif;
    color:#000;
    margin:0 0 15px;
}

.paraPopup{
    font:400 13px/18px 'Rubik',sans-serif;
    color:#667581;
    margin:0 0 15px;
}
.paraPopup span{
    color:#000;
}