#reactFlatPicker {
  padding: 5px;
  border: 1px solid #828e97;
  border-radius: 4px;
  font-size: 16px;
}

#reactFlatPicker:focus {
  outline: none;
  box-shadow: 0 0 0 2px #828e97;
}

.flatpickr-calendar {
  width: 210px;
  border-radius: 5px;
  box-shadow: 0 0 0 2px #828e97;
  color:#727aff
}

.flatpickr-time input:hover{
  background-color: white;
}
.flatpickr-time input:focus{
  background-color: white;
}
.flatpickr-time input.flatpickr-hour {
  font-weight: 600;
  color:#727aff
}
.flatpickr-time input.flatpickr-minute {
  font-weight: 600;
  color:#727aff
}
.flatpickr-time .flatpickr-am-pm:hover{
  background-color: white;
}

.flatpickr-time .flatpickr-am-pm:focus{
  background-color: white;
}

.flatpickr-time .flatpickr-am-pm {
  font-weight: 600;
  color:#727aff
}