.image-cropper-box{
    padding: 20px;
}

.image-cropper-box .cropper-container.cropper-bg{
    width: 100% !important;
}

.image-cropper-box .cropper-point{
    background-color: white;
    border: 1px solid #4C93FF;
}

.image-cropper-box  .cropper-line{
    background-color: #4C93FF;
}