.copyCategoryModal {
    width: 535px;
    height: 406px;
    background: #FFFFFF;

    padding: 15px !important;
    border-radius: 4px !important;
}


.copyCategoryModal .actions {
    margin-top: 25px !important;
}

.copyCategoryModal .content {
    display: flex !important;
    flex-direction: column;
}

.copy-cateogorytree {}

.copyCategoryConfirmModal {
    width: 535px;
    height: 200px;
    background: #FFFFFF;

    padding: 15px !important;
    border-radius: 4px !important;
}

.copyCateogryConfirmText {
    font-family: Rubik;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.59px;
    text-align: left;
}

.copyCategoryActionBtns {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}

.copyCategoryActionBtns .ui.button.creatCopy {
    box-sizing: border-box;
    padding: 7px 10px !important;
    width: 132px;
    height: 36px !important;
    background-color: #FFFFFF !important;
    border: 1px solid #E75330 !important;
    border-radius: 5px !important;
    color: #E75330 !important;
}

.copyCategoryItemRoot:not(:first-child) {
    padding-top: 10px;
  }
  
  .copyCategoryItem-content.active {
    background: #666ab44a;
    border-radius: 5px;
  }

  .copyCategoryItem-content.disabled {
    background: #efefef;
    border-radius: 5px;
  }

  .copyCategoryItemRoot-icon {
    border-radius: 30px;
    width: 25px;
    height: 23px;
  }
  
  .copyCategoryItem-name {
    display: flex;
    align-items: stretch;
    gap: 10px;
    width: 100%;
  }
  
  .copyCategoryItemRoot .copyCategoryItem-content {
    display: flex;
    gap: 10px;
    width: calc(100% - 15px);
    height: 40px;
    padding: 10px;
    cursor: pointer;
  }
  
  .copy-subCategoryCover {
    padding-left: 20px;
    margin-top: 5px;
  }