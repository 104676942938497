.image-box-actions-bx {
  display: flex;
  justify-content: center;
  grid-gap: 14px;
  gap: 14px;
  background-color: #000000;
  opacity: 0;
  position: absolute;
  width: 100%;
  padding: 4px 8px;
  bottom: 0;
}

.image-box{
    position: relative;
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 4px;
}

.image-box.disable {
    pointer-events: none;
    opacity: 0.5;
}

.image-box:hover .image-box-actions-bx {
  opacity: 0.9;
}


.image-box-copy-icon img{
  width: 10px !important;
}