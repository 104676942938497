.icons-management-main{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.icons-management-main-top-bx{
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 16px;
    align-items: center;
}

.icons-management-main-icons-bx{
    flex: 1;
}
