.toolbar{
    display:flex;
    align-items:center;
}

.ui.toolButton,
.ui.toolButton:active,
.ui.toolButton:focus{
    font:400 12.5px 'Rubik',sans-serif;
    color:#000;
    background:none!important;
    margin:0 5px 0 0;
    display:flex;
    align-items:center;
    padding:5px 5px;
}

.ui.toolButton img{
    height:16px;
    margin:0 5px 0 0;
}

.ui.toolButton.active{
    color:#727bfe!important;
    font-weight:500;
    opacity:1!important;
}

.ui.toolButton.active img{
    filter:saturate(40) hue-rotate(42deg) brightness(0.7);
    opacity:1!important;
}

@media (max-width:1436px){
    .ui.toolButton{
        margin:0!important;
    }
}

@media (max-width:1087px){
    .toolbar{
        width:100%;
        position: absolute;
        right:0;
        top:55px;
        z-index:1;
        flex-wrap:wrap;
    }
    .toolbar > div{
        display:none;
    }
    .ui.toolButton{
        background:#fff!important;
        border-radius:4px;
        margin:0 5px!important;
    }
}