.image-delete-strategy-selector-content-options{
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.image-delete-strategy-selector-content-option{
    display: flex;
    gap: 10px;
}

.image-delete-strategy-selector-content-option-description{
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.image-delete-strategy-selector-content-option-description-title{
    font: 500 14px "Rubik", sans-serif;
    cursor: pointer;
}

.image-delete-strategy-selector-content-option-description-text{
    font: 400 12px "Rubik", sans-serif;
    cursor: pointer;
}


.image-delete-strategy-selector-content-header{
    font: 400 14px "Rubik", sans-serif;
    margin-bottom: 24px;
}

.image-delete-strategy-selector-header{
    font: 500 15px "Rubik", sans-serif;
    margin-bottom: 14px;
}

.image-delete-strategy-selector{
    margin-top: 16px;
}
