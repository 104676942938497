.gallerySlider{
    width:100%; float:left;
    position:relative;
    margin-bottom:0;
}

.gallerySlider .ui.card,
.gallerySlider .ui.card>.content{
    background:none;
    border:none;
    border-radius:0!important;
    box-shadow:none!important;
}

.gallerySlider .ui.card>.content{
    padding:0;
    overflow:hidden;
    width: 100%;
}

.gallerySlider .carousel-indicators{
    width:100%; float:left;
    height:24px;
    display:flex;
    align-items:center;
    justify-content:center;
}

.gallerySlider .carousel-indicators > a{
    margin:0 2.5px;
    padding:0;
}

.gallerySlider .carousel-indicators .ui.empty.circular.label{
    width:8px;
    height:8px;
    border-radius:8px;
    padding:0!important;
}

.gallerySlider .carousel-indicators .ui.black.label{
    background:#b6b6b6!important;
}
.gallerySlider .carousel-indicators .ui.grey.label{
    background:#dedede!important;
}

.gallerySlider .ui.card>.content > .ui.button{
    position:absolute;
    top:117px;
    width:23px;
    height:23px;
    border-radius:0;
    z-index:2;
    background:url(/img/arrow-slider.svg) no-repeat center #fff;
    background-size:6px 11px;
    background-position:8px center;
    box-shadow:0 0 5px rgba(0,0,0,0.2);
}
.gallerySlider .ui.card>.content > .ui.button i{
    display:none;
}

.gallerySlider .ui.card>.content > .ui.button.prev{
    left:10px;
}
.gallerySlider .ui.card>.content > .ui.button.next{
    right:10px;
    transform:rotate(180deg);
}

.cover-banner .gallerySlider .ui.card>.content > .ui.button{
    top:77px;
}

.cover-banner .gallerySlider{
    min-height:203px;
}

.gallerySlider .content.carousel img
{
    width: 392px;
    height:131px;
    object-fit: cover;
}

.gallerySliderOverlay{
    width:100%;
    height:133px;
    cursor:pointer;
    position:absolute;
    top:0;
    left:0;
    z-index:1;
}

.cover-banner .ui.fluid.card{
    margin:0;
}

.cover-banner .gallerySlider .content.carousel img
{
    min-height:180px;
    object-fit: cover;
    width: 100%;
}
.cover-banner .gallerySliderOverlay{
    height:180px;
}

.editSidebarBox .gallerySlider{
    min-height:168px;
}
.editSidebarBox .gallerySlider .ui.card>.content > .ui.button{
    top:50px;
}