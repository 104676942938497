.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font: 400 15px "Rubik", sans-serif;
}

.info_box {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 6px;
  justify-content: space-between;
}

.title {
  font-weight: bold;
  margin-right: 6px;
}

.info_sub_box {
  display: flex;
  align-items: center;
}

.info_sub_box_inner{
  display: flex;
  margin-right: 6px;
}
.info_sub_box:last-child{
  grid-column: span 2;
}

.name {
  word-break: break-all;
}

.image {
  width: 100%;
  height: calc(100% - 42.8px - 20px);
  object-fit: contain;
  border-radius: 3px !important;
  border: 1px solid #dfe5ec;
  margin-top: 15px;
  border-radius: 3px;
}
