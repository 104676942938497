.info-section-label{
    font: 500 13px "Rubik", sans-serif !important;
    color: #000;
}

.info-section-value{
    font: 400 12px "Rubik", sans-serif !important;
    color: #667090;
       white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-section{
    display: flex;
    flex-direction: column;
    gap: 4px
}
