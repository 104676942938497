.subheaderMaintenance,
.subheaderMaintenance .subheader-right,
.maintenanceMenuGroup,
.maintenanceMenuGroup .ui.button.buttonIcon{
    display:flex;
    align-items:center;
}


.subheaderMaintenance{
    width:100%;
    height: 47px;
    float:left;
    background: #fff;
    box-shadow: 0 2px 5px #ced3e2;
    padding: 9px 35px;
    position: sticky;
    top: 0;
    z-index: 11;
}
.subheaderMaintenance .subheader-right{
    margin-left:auto;
}

.maintenanceMenuGroup .ui.button.buttonIcon{
    font-size:13px;
    color:#667581!important;
    margin:0 10px;
}

.maintenanceMenuGroup .ui.button.buttonIcon.active{
    color:#757dff!important;
}
.maintenanceMenuGroup .ui.button.buttonIcon:not(.active) img{
    filter:contrast(.2) brightness(0.8) hue-rotate(-30deg);
}

.maintenanceBody{
    flex:1;
    max-height:calc(100vh - 110px);
    height:calc(100vh - 110px);
}

.maintenanceRightbar{
    min-width:311px;
    width:311px;
    height:calc(100vh - 65px);
    background:#fff;
    border-right:solid 1px #C0CBDA;
    position:relative;
}

.maintenanceRightbar .header-txt{
    text-transform: uppercase;
}
.maintenanceRightbar h2.ui.header{
    height:47px;
    margin:0;
    font:400 14px 'Rubik',sans-serif;
    background:#F4F7FC;
    display:flex;
    align-items:center;
    justify-content:center;
}

.minimizedSidebar{
    min-width:80px;
    width:80px;
}
.ui.button.triggerMinimize{
    background:#F4F7FC!important;
    position: absolute;
    right:0;
    top: 47px;
    width: 47px;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ui.button.triggerMinimize.active{
    position:relative;
    width:100%;
    top: 0px;
}
.ui.button.triggerMinimize:not(.active) img{
    transform:rotate(180deg);
}

.minimizedSidebar .maintenanceTypeBox .ui.image{
    height:46px;
    box-shadow:inset 0 0 0 1.5px #DFE5EC;
    border-radius:4px;
}
.minimizedSidebar .maintenanceTypeBox .ui.image img{
    width:20px;
}
.minimizedSidebar .maintenanceTypeBody{
    display:none;
}
.minimizedSidebar .maintenanceTypeBox{
    border:none;
    border-radius:4px;
}


.alignerSidebar{
    width:100%; float:left;
    padding:15px 15px 0;
}

.maintenanceTypeBox{
    width:100%; float:left;
    margin:0 0 15px;
    border-radius:2px;
    border:solid 1px #D3D2DE;
    overflow:hidden;
}
.maintenanceTypeBox.active{
    border:solid 2px #F15944;
}
.maintenanceTypeBox:not(.active){
    cursor:pointer;
}
.maintenanceTypeBox .ui.image{
    width:100%; float:left;
    height:100px;
    background:#DFE5EC;
    display:flex;
    align-items:center;
    justify-content:center;
}
.maintenanceTypeBox .ui.image img{
    width:auto;
}
.maintenanceTypeBody{
    width:100%; float:left;
    padding:10px 10px 15px;
    display:flex;
    flex-direction:column;
    color:#000;
}
.maintenanceTypeBody b{
    font:500 18px 'Rubik',sans-serif;
    letter-spacing:-.5px;
    margin:0 0 10px;
}
.maintenanceTypeBody p{
    font:400 15px/20px 'Rubik',sans-serif;
}

.maintenanceTypeBox:nth-child(2) b{
    /* text-align:center; */
}

.erpFeatureName b {
    text-align:center;
}

.mapToolsGroup{
    width:34px;
    position:fixed;
    right:10px;
    top:120px;
    display:flex;
    flex-direction:column;
}

.ui.input.toggleSearchBar{
    position:relative;
    height:31px;
    margin:0 0 20px;
}
.ui.input.toggleSearchBar>input{
    width:34px;
    height:31px;
    margin:0;
    padding:0 0 0 10px!important;
    background:url(/img/icon-search.svg) no-repeat center #fff;
    border:none;
    box-shadow:0 0 10px rgba(0,0,0,.3)!important;
    border-radius:3px;
    font:400 13px 'Rubik',sans-serif;
    position:absolute;
    right:0;
    top:0;
    z-index:1;
}
.ui.input.toggleSearchBar>input::placeholder{
    opacity:0;
}
.ui.input.toggleSearchBar>input:focus{
    min-width:230px!important;
    background-position:94.6% center;
    padding:0 40px 0 10px;
}
.ui.input.toggleSearchBar>input:focus::placeholder{
    opacity:1;
}

.ui.buttonsGroup .ui.button,
.ui.button.layersGroupTrigger{
    display:flex;
    align-items:center;
    justify-content:center;
}

.ui.buttonsGroup{
    margin:0 0 20px;
    box-shadow:0 0 8px rgba(0,0,0,.3)!important;
    border-radius:3px;
}
.ui.buttonsGroup .ui.button{
    width:34px;
    height:31px;
    background:#fff;
    border-bottom:solid 1px #D3D2DE;
    padding:0;
    font:400 13px 'Rubik',sans-serif!important;
    color:#000;
}
.ui.buttonsGroup .ui.button:last-child{
    border:none;
}

.ui.buttonsGroup .ui.button.active{
    background:#757DFF;
    color:#fff;
}

.mapActionsGroup .ui.button{
    position:relative;
}
.mapActionsGroup .ui.button:before,
.mapActionsGroup .ui.button:after{
    content:'';
    width:12px;
    height:2.5px;
    background:#000;
}
.mapActionsGroup .ui.button:after{
    width:2px;
    height:12px;
    position:absolute;
    top:9px;
    left:16px;
}
.mapActionsGroup .ui.button:last-child:after{
    display:none;
}

.ui.button.layersGroupTrigger{
    width:34px;
    height:31px;
    background:#fff!important;
    box-shadow:0 0 10px rgba(0,0,0,.3)!important;
    margin:0 0 20px;
}
.ui.button.layersGroupTrigger.active{
    background:#757DFF!important;
}
.ui.button.layersGroupTrigger.active img{
    filter:invert(1);
}

.ui.popup.popupPointingBox{
    max-width:300px;
    width:277px;
    padding:20px 0 15px!important;
    border:none;
    border-radius:3px;
    box-shadow:0 2px 8px rgba(0,0,0,.2);
}
.ui.popup.popupPointingBox:before{
    box-shadow:none!important;
}

.popupAligner{
    width:100%; float:left;
    padding:0 15px;
}
.popupScroller > div:nth-last-child(2){
    display:none;
}
.popupScroller > div:last-child{
    z-index:2;
}

.ui.popup.popupPointingBox .ui.header{
    font:400 15px 'Rubik',sans-serif;
    border-bottom:solid 1px #C6CBD0;
    background:#fff;
    color:#000;
    padding:0 0 10px;
    margin:0 0 15px;
}
.ui.popup.popupPointingBox .ui.buttonIcon{
    display:inline-flex;
    align-items:center;
}

.ui.buttonIcon.viewMoreButton{
    font-size:13px!important;
    margin:10px 0 0 15px;
}
.ui.buttonIcon.viewMoreButton > img{
    order:2;
    margin:1px 0 0 4px;
}
.ui.buttonIcon.clearAllButton{
    float:right;
    margin:10px 10px 0 auto;
}
.ui.buttonIcon.clearAllButton .icon{
    margin:0 0 0 4px!important;
}
.ui.buttonIcon.clearAllButton:hover i{
    text-decoration:none!important;
}

.layerBoxItem,
.layerBoxSubItem{
    width:100%; float:left;
    margin:0 0 13px;
    display:flex;
    align-items:center;
}
.layerBoxItem .ui.checkboxGroup,
.layerBoxSubItem .ui.checkboxGroup{
    width:auto;
    margin:0 10px 0 0;
}

.layerBoxItem .ui.buttonIcon,
.layerBoxSubItem .ui.buttonIcon{
    font-size:13px;
    color:#000!important;
    position:relative;
}

.layerBoxItem .ui.buttonIcon{
    padding-left:15px;
    flex:1;
}

.layerBoxItem .ui.buttonIcon:before{
    content:"";
	border: solid transparent;
	height: 0;
	width: 0;
	position: absolute;
    left:0;
	top:2px;
	pointer-events: none;
	border-color: rgba(0, 0, 0, 0);
	border-left-color: #000000;
	border-width: 5px;
}
.layerBoxItem.active .ui.buttonIcon:before{
    top:8px;
    left:-2px;
    transform:rotate(90deg);
}
.layerBoxItem .ui.buttonIcon span.statusPoint{
    width:8px;
    height:8px;
    border-radius:8px;
    background:#757DFF;
    margin:1px 0 0 3px;
}

.layerBoxItem .colorBox{
    width:20px;
    height:20px;
    margin-left:auto;
    background:#fff;
    border:solid 1px #AAAAAA;
    border-radius:5px;
}

.layerBoxSubItem .ui.buttonIcon{
    color:#757DFF!important;
}
.layerBoxSubItem p{
    margin:0 6px 0 0;
    padding:0 6px 0 0;
    font:400 13px 'Rubik',sans-serif!important;
}

.layerBoxSubCover{
    width:100%; float:left;
    padding:0 0 0 25px;
}

.ui.input.searchSelected{
    width:100%; float:left;
    margin:0 0 15px;
}
.ui.input.searchSelected>input{
    height:31px;
    border-radius:3px;
    border:solid 1px #C0CBDA;
    font:400 13px 'Rubik',sans-serif;
    padding:0 10px;
}
.ui.input.searchSelected>input:focus{
    border-color:#727aff;
}
.ui.input.searchSelected >img{
    height:13px;
    position:absolute;
    right:8px;
    top:9px;
}

.ui.globalCheckbox{
    margin:0 0 20px;
}
.selectedCover{
    width:100%; float:left;
    margin:0 0 10px;
    border-bottom:solid 11px #F4F7FC;
}

.templateModal, .templateContent{
    border-radius: 20px !important;
}

.templateAction{
    background-color: #EFF4F9 !important;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    margin-bottom: 0 !important;
    padding: 18px 25px 12px 0px !important;
}

.templateActions{
    display: flex;
    align-items: center;
    justify-content: end;

}

.templateActions > button:first-child {
    margin-right: 10px;
}

.templateModal{
    padding-bottom: 0px !important;
    margin: 0;
}

.templateMap{
    width: 65%;
}

.templateSidebar{
    width:35%;
    background:#fff;

}

.sidebarPortal{
    width:420px;
    height:calc(100vh - 110px);
    background:#fff;
    box-shadow:-3px 10px 12px rgba(0, 0, 0, 0.2);
    position:fixed;
    right:0;
    top:109px;
    z-index: 1;
}
.sidebarPortal .ui.dropdownForm .menu, .templateSidebar .ui.dropdownForm .menu{
    border-radius:0;
    border:none;
    border-top:solid 1px #667581;
    box-shadow:0 0 8px rgba(0,0,0,.2);
}
.sidebarPortal .ui.dropdownForm .menu > .item, .templateSidebar .ui.dropdownForm .menu > .item{
    border:none;
    color:#000;
    font:400 15px 'Rubik',sans-serif;
    word-wrap: break-word;
    white-space:initial;
}
.sidebarPortal .ui.dropdownForm .menu > .item.active , .templateSidebar .ui.dropdownForm .menu > .item.active{
    font-weight:500;
}

.sidebarPortal .ui.button.buttonIcon, .templateSidebar .ui.button.buttonIcon{
    font-size:12.5px;
    display:flex;
    align-items:center;
}
.ui.button.closeBarButton{
    position:absolute;
    right:0;
    top:6px;
}

.createAlertSidebar > .ui.button.closeBarButton{
    top: 25px;
    right: 20px;
}

.sidebarPortal h2.ui.header, .templateSidebar h2.ui.header{
    width:100%;
    min-height:69px;
    font:400 18px 'Rubik',sans-serif;
    margin:20px 0 15px;
    padding:0 13px 15px 0;
    border-bottom:solid 11px #F4F7FC;
    display:inline-block;
    position:relative;
}
.sidebarPortal h2.ui.header strong, .templateSidebar h2.ui.header strong{
    font-weight:500;
    margin:0 0 0 5px;
    word-break:break-word;
}
h2.ui.header.successHeading{
    position:relative;
    padding:0 0 0 40px;
    border:none;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    align-items: flex-start!important;
}
h2.ui.header.successHeading > img{
    width:auto;
    height:25px;
    position: sticky;
    top: 20px;
    margin: 0px 10px 0 -40px
}

.sidebarPortal .alignerSidebar, .templateSidebar .alignerSidebar{
    padding:0 20px;
}
.sidebarPortal h6{
    width:100%; float:left;
    margin:0 0 18px;
    font:400 13px 'Rubik',sans-serif;
}
.sidebarPortal .checkboxGroup, .templateSidebar .checkboxGroup{
    width:auto;
}

.centeralBox{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 275px);;
}

.centeralBox img {
    max-width: 35%;
    height: auto; 
    margin-bottom: 10px;
}

.noTemplateHeading{
    font-weight: 800;
    word-spacing: 2px;
}

.noTemplateSubHeading{
    margin-top: 3px;
}

.sidebarSection{
    width:100%; float:left;
    padding:0;
    margin:0 0 20px;
    border-bottom:solid 11px #F4F7FC;
}
.sidebarSection:last-child{
    border-bottom:none;
    margin:0;
}
.sidebarSection .buttonGroupEmergency{
    margin:0;
}

.activityHighlight {
    background-color: #e9e9ff;
}

.descriptionText{
    width:100%;
    height:35px;
    border:none;
    border-bottom:solid 1px #D3D2DE;
    outline:0;
    resize:none;
}
.descriptionText:focus{
    border-color:#1289E5;
}

.ui.button.saveButton.grey{
    background:#667581;
    width:90px;
    float:left;
    margin:10px 0 0 20px;
}
.ui.button.saveButton{
    width:90px;
    float:right;
    margin:10px 20px 10px 0;
}


.ui.closeBtn {
    position: absolute;
    right: 15px;
    top: 15px;
    min-width:0!important;
    width: 13px;
    height: 13px!important;
    padding:0!important;
    background: none!important;
    opacity:1;
    cursor: pointer;
    z-index:10;
}
.ui.closeBtn:active{
    box-shadow:none!important;
}

.ui.closeBtn:before,
.ui.closeBtn:after{
    position: absolute;
    left: 7px;
    top: 0;
    content: '';
    height: 13px;
    width: 1.8px;
    background:#000;
}

.ui.closeBtn:before{
    transform: rotate(45deg);
}

.ui.closeBtn:after{
    transform: rotate(-45deg);
}

.ui.closeBtn:hover{
    opacity: 0.8;
}

.colorCube{
    min-width:20px;
    width:20px;
    height:20px;
    margin:0 13px 8px 0;
    display:inline-block;
    border-radius:6px;
    background:#000;
    border:solid 1px #fff;
    cursor:pointer;
}
.colorCube:hover{
    box-shadow:0 0 5px rgba(0, 0, 0, .4)
}
.colorCube.active{
    box-shadow:0 0 5px rgba(0, 0, 0, .8)
}
.colorPickerLabel{
    width:100%; float:left;
    margin:0 0 15px;
}

.colorSwatchesGroup{
    width:100%;
    padding-right:90px;
    float:left;
}

.ui.popup.activityDetailPopup{
    width:335px;
    max-width:335px;
}
.ui.popup.activityDetailPopup .textGroup{
    margin:0 0 8px;
}
.ui.popup.activityDetailPopup .textGroup:last-child{
    margin-top:15px;
}
.ui.popup.activityDetailPopup .textGroup > p{
    min-width:80px;
}
.ui.popup.activityDetailPopup .activityHeadingBar{
    margin:0 0 18px;
}
.ui.popup.activityDetailPopup .activityHeadActions{
    float:right;
}

.ui.modal.confirmActionModal{
    width:335px;
    padding:35px 30px 25px;
}
.ui.modal.confirmActionModal .actions .ui.orange.button{
    height:36px;
}

.confirmActionModal .p-modal{
    word-break: break-word;
}
.ui.modal.confirmActionModal >.content{
    max-height:calc(80vh);
    overflow-y:auto;
}

.ui.modal.externalLinkModal .actions .ui.button{
    width:100px;
    height:36px;
}
.linkLable{
    background:url(/img/icon-link.svg) no-repeat 10px center #F3F6FB;
    border:dashed 1.5px #D3D2DE;
    border-radius:4px;
    width:100%; float:left;
    height:40px;
    padding:0 90px 0 36px;
    margin:0 0 15px;
    display:flex;
    align-items:center;
    position:relative;
    font:400 15px 'Rubik',sans-serif;
    color:#6E6E6E;
}
.linkLable .ui.buttonIcon{
    position:absolute;
    right:15px;
    font-size:15px;
    color:#F15944!important;
}
.linkLable p{
    max-width:100%;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    margin:0;
}

.externalShareBox{
    align-items:flex-start;
}
.externalShareBox > p{
    min-width:100px;
}
.externalShareBox .textGroupContent{
    border-bottom:solid 1px #D3D2DE;
    padding:0 0 5px;
}
.externalShareBox .ui.tagLabel{
    background:#F0F1F2;
}

/*** Tabs ***/


.boxTabs{
    width:100%; float:left;
}

.boxTabs .ui.secondary.menu{
    width:100%; float:left;
    border-bottom:solid 1px #D3D2DE;
    min-height:0;
    margin:0;
}

.boxTabs .ui.secondary.menu .item{
    font:400 15px/16px 'Rubik',sans-serif;
    color:#000;
    padding:8px 0;
    margin:0 30px -1px 0;
    border-radius:0!important;
    background:none!important;
}

.boxTabs .ui.secondary.menu .active.item{
    box-shadow:inset 0 -3px 0 0 #727AFF!important;
}

.boxTabs > .ui.segment{
    width:100%; float:left;
    margin:0!important;
    padding:0!important;
    border:none!important;
    background:none;
}

.spaceBottom button{
    padding-bottom: 15px !important;
}

.subheaderMaintenance .subheader-left {
    display: flex;
    align-items: center;
}

.alertTabHeader h6 {
    width: 57%;
}

.alertTabHeader .ui.button.ui.buttonIcon {
    margin-right: 16px;
    float: right;
}

/*** Emergency ***/

.buttonGroupEmergency{
    /* position:fixed;
    right:400px;
    top:140px; */
    border-radius:100px!important;
}
.buttonGroupEmergency .ui.button{
    border:none;
}
.buttonGroupEmergency div{
    width:100%;
}
.buttonGroupEmergency div:first-child .ui.button{
    border-top-left-radius:100px;
    border-top-right-radius:100px;
}
.buttonGroupEmergency div:last-child .ui.button{
    border-bottom-left-radius:100px;
    border-bottom-right-radius:100px;
}

.ui.popup.emergencyMapButtonPopup{
    border-radius:2px;
    border:none;
    font:400 15px 'Rubik',sans-serif;
    padding:8px 10px;
}
.ui.popup.emergencyMapButtonPopup:before{
    box-shadow:none!important;
}

.emergencyFloorButtons{
    border-radius:100px!important;
    flex-direction:row;
    width:auto;
    margin:0 0 0 20px!important;
}
.emergencyFloorButtons .ui.button{
    width:100%;
}
.emergencyFloorButtons > .ui.button:first-child,
.emergencyFloorButtons > div:first-child .ui.button{
    border-top-left-radius:100px!important;
    border-top-right-radius:100px!important;
}
.emergencyFloorButtons > .ui.button:last-child,
.emergencyFloorButtons > div:last-child .ui.button{
    border-bottom-left-radius:100px!important;
    border-bottom-right-radius:100px!important;
}

.tgIndividual .textGroupContent{
    display:flex;
    align-items:flex-start;
}
.tgIndividual .textGroupContent .ui.checkboxGroup{
    margin:2px 10px 0 0;
}
.tgIndividual .textGroupContent .ui.buttonIcon{
    display:inline-flex;
}

/*** designated area modal ***/

.ui.modal.designatedAreaModal{
    width:100%;
    max-width:1110px!important;
}

.modalMapWrap{
    width:100%; float:left;
    height:calc(100vh - 255px);
    margin:0 0 15px;
    display:flex;
    border-top:solid 1px #D3D2DE;
    border-bottom:solid 1px #D3D2DE;
}
.modalMapSidebar{
    min-width:250px;
    width:250px;
    border-right:solid 1px #D3D2DE;
    padding:20px 10px 0 0;
    position:relative;
}
.modalMapSidebar2{
    min-width:240px;
    width:240px;
}

.tgModalMap{
    position:relative;
}
.tgModalMap .textGroupContent .ui.button.buttonIcon{
    position:absolute;
    right:0;
    top:5px;
}

.modalSelectBox{
    width:100%; float:left;
    margin:10px 0 0;
}
.modalSelectBox > .ui.checkboxGroup{
    margin:0 0 15px;
}
.modalSelectBox .ui.checkboxGroup label{
    padding-left:28px;
}
.modalSelectBoxItem{
    width:100%; float:left;
    margin:0 0 15px;
    display:flex;
    align-items:center;
}
.modalSelectBoxItem .ui.checkboxGroup{
    margin:0;
}
.selectBoxAction{
    display:inline-flex;
    align-items:center;
    margin-left:auto;
}
.selectBoxAction .ui.buttonIcon{
    margin:0 0 0 10px;
}
.selectBoxAction .ui.buttonIcon img{
    margin:0;
}
.modalMapBody{
    flex:1;
    position:relative;
    /* height:calc(100vh - 260px); */
}


.designatedMapButtons{
    position:absolute;
    right:10px;
    top:10px;
    border-radius:100px;
}
.designatedMapButtons .ui.button:first-child{
    border-top-left-radius:100px;
    border-top-right-radius:100px;
}
.designatedMapButtons .ui.button:last-child{
    border-bottom-left-radius:100px;
    border-bottom-right-radius:100px;
}
.paraDesignatedModal{
    display:flex;
    align-items:center;
}
.paraDesignatedModal .ui.buttonIcon{
    font-size:15px;
    white-space:nowrap;
}
.paraDesignatedModal .iconSelectorColorPicker{
    width:auto;
    float:none;
    margin:0 0 0 auto;
}

.selectBoxCover{
    width:100%; float:left;
    max-height:calc(100vh - 400px);
    overflow-y:auto;
    padding-right:5px;
}

/*** designated style popup ***/

.ui.popup.popupCustom{
    max-width:500px;
    padding:20px;
    border:none;
    border-radius:3px;
    box-shadow:0 0 12px rgba(0,0,0,.2);
}
.ui.popup.popupCustom:before{
    box-shadow:none!important;
}

.ui.popup.designatedModalStylePopup{
    left: auto!important;
    bottom: auto !important;
    top: -60px!important;
    right: 100%!important;
    width:290px;

    margin-right:10px;
}
.ui.popup.designatedModalStylePopup:before{
    top:16%!important;
}
.ui.header.popupHeader{
    width:100%; float:left;
    padding:0 0 10px;
    border-bottom:solid 1px #E2E2E2;
    font:500 15px 'Rubik',sans-serif;
}
.designatedMapStyleItem{
    width:100%; float:left;
    padding:5px 0;
    margin:0 0 10px;
    border-bottom:solid 1px #D3D2DE;
    font:400 15px 'Rubik',sans-serif;
    color:#000;
    display:flex;
    align-items:center;
}
.polygonColorBox{
    display:inline-block;
    margin:0 0 0 auto;
    width:20px;
    height:20px;
    border-radius:3px;
    background:#57310B;
}
.ui.image.iconSymbolBox{
    width:20px;
    height:20px;
    border-radius:3px;
    border:solid 1px #E2E2E2;
    background:#fff;
    display:inline-flex;
    align-items:center;
    justify-content:center;
    margin-left:auto;
}
.ui.designatedStyleDropdown{
    font:400 15px 'Rubik',sans-serif;
    padding-top:2px;
    padding-bottom:6px;
    margin:0 0 20px;
}

.iconSymbolGroup{
    width:100%; float:left;
    padding:5px;
    margin:0 0 15px;
    border-radius:2px;
    border:solid 1px #D3D2DE;
}
.iconSymbolGroup .ui.image{
    width:24px;
    height:24px;
    border-radius:50px;
    border:solid 1px #D3D2DE;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
}
.iconSymbolGroup .ui.image.active{
    background:#F0E5D9;
    border-color:#F0E5D9;
    box-shadow:0 0 6px rgba(0,0,0,.2);
}
.ui.button.orange.wide{
    width:160px;
}

.designatedDrawingBar{
    left:50%;
    bottom:20px;
    transform:translate(-50%, 0 );
    box-shadow:0 3px 8px rgba(0,0,0,.3);
}
.designatedDrawingBar .ui.buttonIcon:not(.active, :last-child) img{
    filter:brightness(0);
}

.alertMessage{
    width:89%;
    padding:10px 15px;
    border-radius:3px;
    background:#fff;
    box-shadow:0 0 10px rgba(0,0,0,.2);
    font:400 13px 'Rubik',sans-serif;
    color:#000;

    display:flex;
    align-items:center;

    position:absolute;
    top:10px;
    left:20px;
}
.alertMessage.error{
    box-shadow:inset 0 0 0 1px #F15944;
}
.alertMessage > img{
    width:16px;
    margin:0 10px 0 0;
}

.ui.addDesignatedAreaPopup{
    width:310px;
}

.ui.transitionToggle{
    position:absolute;
    right:-4px;
    top:90px;
}
.ui.toggle.checkbox.transitionToggle label{
    width:34px!important;
}
.ui.toggle.checkbox.transitionToggle label:before,
.ui.toggle.checkbox.transitionToggle input:checked~label:before{
    width: 34px;
    height: 67px;
    background-color:rgba(244,247,252,1)!important;
    box-shadow:0 0 8px rgba(0,0,0,.3);
}
.ui.toggle.checkbox.transitionToggle input:checked~label:before{
    background-color:rgba(255,255,255,1)!important;
}
.ui.toggle.checkbox.transitionToggle input~label:after{
    width: 30px;
    height: 30px;
    box-shadow: none;
    border: none;
    top: 35px;
    left: 2px;
    transition:all .3s ease;
    background:url(/img/icon-toggle-slider.svg) no-repeat center #99A9C4;
}
.ui.toggle.checkbox.transitionToggle input:checked~label:after{
    top:2px;
    background-color:#757DFF;
}

.designatedAreaOptions{
    width:100%; float:left;
    display:flex;
    align-items:center;
    flex-wrap:wrap;
}

.ui.radio.checkbox.checkboxGroup{
    width:auto;
    margin:0 10px 0 0;
}

.paraCustom{
    width:100%; float:left;
    margin:20px 0 15px;
}

.popupActions{
    width:100%; float:left;
    margin:15px 0 0;
}



/*** transition points tab ***/

.transitionTab{
    width: 100%;
    float: left;
}

.transitionTab .ui.secondary.pointing.menu {
    border-bottom: solid 1px #D3D2DE;
    margin: 0 13px;
}

.transitionTab .ui.secondary.pointing.menu .item {
    font: 400 14px 'Rubik', sans-serif!important;
    color: #000!important;
    padding: 3px 0 3px;
    margin: 0 25px -1px 0;
    border-bottom: solid 1px #d3d2de;
    box-shadow:none!important;
}

.transitionTab .ui.secondary.pointing.menu{
    margin:0 0 20px;
    min-height:0;
}

.transitionTab .ui.secondary.pointing.menu .item {
    font: 400 13px 'Rubik', sans-serif!important;
    margin: 0 20px -1px 0;
}

.transitionTab .ui.secondary.pointing.menu .active.item,
.transitionTab .ui.secondary.pointing.menu .active.item:hover {
    border-bottom:solid 2px #757DFF;
    padding-bottom:2px;
}

.transitionTab>.ui.segment.tab {
    padding: 0 1px 0 0;
    margin: 0;
    border: none;
    background: none;
}

.transitionTab .modalSelectBox {
    margin:0;
    padding:0 15px 0 0;
}

.ui.checkboxTransitionFix{
    width:100%;
    position:absolute;
    bottom:0;
    left:0;
    background:#fff;
    border-top:solid 1px #D3D2DE;
    margin:0;
    padding:10px 0;
    z-index:10;
}
.modalMapBody .ui.checkboxTransitionFix{
    padding-left:10px;
}

.alertDetailBox{
    width:100%; float:left;
    margin:15px 0 0;
    padding:10px;
    background:#F4F7FC;
    border:solid 1px #D3D2DE;
    border-radius:3px;
}
.alertDetailItem{
    width:100%; float:left;
    display:flex;
    align-items:flex-start;
    margin:0 0 10px;
}
.alertDetailItem > p{
    font:400 13px 'Rubik',sans-serif;
    margin:0 3px 0 0 !important;
}
.alertDetailContent{
    flex:1;
    font:400 13px 'Rubik',sans-serif;
}
.alertDetailItem:last-child{
    margin:0;
}


.emergencyActivityTabs .aligner{
    padding:0 20px;
}

.historyLogs{
    width:100%; float:left;
    margin:0;
    padding:0;
    list-style:none;
}
.historyLogs li{
    width:100%; float:left;
    padding:13px 0;
    border-bottom:solid 1px #D3D2DE;
    font:400 15px 'Rubik',sans-serif;
    color:#000;
    display:flex;
    align-items:flex-start;
}
.historyLogs li p{
    margin:0 0 5px;
    line-height:18px;
}
.historyLogs li span{
    font-size:12px;
    color:#667581;
}
.historyLogs li img{
    min-width:24px;
    width:24px;
    margin:0 6px 0 0;
}
.logContent{
    flex:1;
}

.transitionTab .selectBoxCover{
    max-height:5000px!important;
    overflow-y:visible;
}

/*** map overlays ***/

.zoomLevelMessage{
    min-width:160px;
    height:34px;
    padding:5px 15px;
    background:#fff;
    box-shadow:0 0 8px rgba(0,0,0,.2);
    border-radius:4px;
    font:400 15px 'Rubik',sans-serif;
    color:#000;
    position:absolute;
    top:50px;
    left:100%;
    margin:15px 0 0 15px;
    z-index:100;

    display:inline-flex;
    align-items:center;
}
.zoomLevelMessage .ui.buttonIcon{
    margin-left:auto;
}

.mapOverlaysSidebar .alignerSidebar > h2.header{
    display:flex;
    align-items:center;
}
.mapOverlaysSidebar .alignerSidebar > h2.header .ui.buttonIcon{
    margin-left:auto;
}
.mapOverlaysSidebar .alignerSidebar > h2.header .ui.buttonIcon img{
    width:16px;
}
.tgOverlayConfig > b{
    display:flex;
    align-items:center;
}
.tgOverlayConfig > b .ui.buttonIcon{
    margin-left:auto;
}
.tgOverlayConfig > b .ui.buttonIcon img{
    width:16px;
}

.overlayTgRow{
    width:100%; float:left;
    display:flex;
    align-items:flex-start;
    justify-content:space-between;
}
.overlayTgRow > *{
    width:100%;
    max-width:45%;
}

.kioskIconTransparent{
    min-width:23px;
    width:23px;
    margin:0 0 0 auto;
}
.overlayTgColumn{
    display:flex;
    align-items:center;
}
.overlayTgColumn .opacitySlide{
    margin-top:-2px;
}

.ui.basic.orange.simulateButton{
    float:right;
    margin:10px 0 0 auto;
}

.overlayConfigurationItem{
    width:100%; float:left;
    margin:0 0 10px;
    display:flex;
    align-items:center;
    font:400 15px 'Rubik',sans-serif;
    color:#000;
}
.overlayConfigurationItem:first-child{
    margin-top:10px;
}
.overlayConfigurationItem p{
    margin:0 0 0 10px;
}
.overlayConfigActions{
    display:inline-flex;
    margin:0 0 0 auto;
}
.overlayConfigActions .ui.buttonIcon{
    margin:0 0 0 10px;
}

.ui.modal.designatedAreaModal .modalMapBody .buttons-wrap{
    top:auto;
    bottom:30px;
}

.activityTabs{

}
.activityTabs .ui.secondary.menu{
    position:relative;
    padding:0 20px;
    margin:0 0 20px!important;
}
.activityTabs .ui.secondary.menu .item{
    font-size:14px!important;
}
.activityTabs .ui.secondary.menu:before,
.activityTabs .ui.secondary.menu:after{
    content:'';
    width:20px;
    height:3px;
    background:#fff;
    position:absolute;
    bottom:-2px;
    left:0;
}
.activityTabs .ui.secondary.menu:after{
    left:auto;
    right:0;
    visibility: initial;
}

.activityTabs .textGroup{
    margin:0 0 10px;
    align-items:flex-start;
}
.activityTabs .textGroup > p:first-child{
    min-width:80px;
}
.activityTabs .textGroup .textGroupContent p{
    width:100%; float:left;
    margin:10px 0 8px;
}
.activityTabs .textGroup .textGroupContent p:first-child{
    margin-top:0;
}
.activityTabs .textGroup .textGroupContent p.flexible{
    display:flex;
    align-items:center;
    margin:0 0 5px;
}
.activityTabs .textGroup .textGroupContent p img{
    margin:0 8px 0 0;
}
.activityTabs .textGroup .textGroup > p{
    width:auto;
}

.activityHeadingBar > p,
.activityHeadingBar > b{
    width:100%;
    flex: auto;
}
.activityHeadingBar > .textGroupContent{
    margin-left:auto;
}
.activityHeadActions{
    display:flex;
    align-items:center;
}
.activityHeadActions .ui.buttonIcon{
    margin-left:8px;
}
.acitivityDetailItem{
    width:100%; float:left;
    margin:0 0 8px;
    display:flex;
    align-items:center;
}
.acitivityDetailItem > span.ui.image{
    width:18px;
    margin:0 5px 0 0;
    display:flex;
    justify-content:center;
}

.activityEmail{
    float:left;
    height:25px;
    padding:0 7px;
    margin:0 0 5px;
    font:400 13px/25px 'Rubik',sans-serif;
    background:#F0F1F2;
    max-width:100%;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
}

.gapFix{
    margin:-5px 0 15px!important;
}