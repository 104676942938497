.icon-component-bx {
    width: 100%;
    height: 100%;
    position: relative;
}




.icon-component-popup-trigger-bx{
    width: 100%;
    height: 100%;
}

.icon-component-img-popup-content p{
    font-size: 12px;
    color: gray;
}

.icon-popup.ui.popup{
  padding:  4px 6px;
  border-radius: 4px;
}