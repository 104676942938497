.zoomLevelIndicator {
    height: 34px;
    padding: 5px 7px;
    background: #fff;
    box-shadow: 0 0 8px rgba(0, 0, 0, .2);
    border-radius: 4px;
    font: 400 15px 'Rubik', sans-serif;
    color: #000;
    position: absolute;
    top: 50px;
    left: 100%;
    margin: 15px 0 0 15px;
    z-index: 100;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

.zoomLevelIndicator .ui.buttonIcon{
    margin-left:auto;
}