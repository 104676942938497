.multiDropdown .sizer {
    display: none;
}

.multiDropdown .ui.multiple.search>.text {
    padding: 0;
    margin: 0;
}

.multiDropdown .ui.multiple.search.dropdown>input.search {
    margin: 5px 0 0;
}

.multiDropdown .ui.dropdown .menu>.message:not(.ui) {
    color: #000;
}

.multiDropdown .ui.multiple.dropdown>.label {
    background: #fff;
    border-radius: 100px;
    font: 400 13px 'Rubik', sans-serif;
    color: #000;
    cursor: default;
}

.ui.modal.categoryModal .ui.button-more img {
    margin-left: 0;
}

.flexibleWrap {
    width: 100%;

    margin: 0 0 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    /* gap: 15px; */
    position: relative;
}

.flexibleWrap .inputGroupWrap {
    max-width: 48%;
    margin: 0;
}

.categoryModal .inputGroupWrap p {
    height: 18px;

    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #000000;

}

.flexibleWrap .inputGroupWrap .ui.dropdownForm {
    margin: 0 0 10px;
}

.ui.buttonAddLang {

    margin: -10px 0 0 !important;
    height: 14px;
    font-family: 'Rubik' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: #727AFF !important;
}

.ui.buttonAddLang img {
    margin: 0 !important;
    width: 16px !important;
    height: 16px !important;
    margin-right: 5px !important;
}

.categoryNameHighlight input {
    color: red !important;
}

.ui.button.editCategoryModalNameDelete {
    position: absolute;
    right: -35px;
    top: 21px;
}

.ui.button.editCategoryModalNameDelete img {
    width: 17px !important;
    height: 17px !important;
}

.uploadImgContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    padding: 0;
    margin-bottom: 30px;
}

.uploadImgContainer p {
    text-align: center !important;
    width: 70px;
    font-family: Rubik;
    font-size: 15px;
    font-weight: 400;
    line-height: 17.78px;
    text-align: left;
    color: #727AFF;
}

.uploadImgContainer .uploadIconContainer {
    box-sizing: border-box;

    width: 70px;
    height: 70px;

    background: #FFFFFF;
    border: 1px solid #D3D2DE;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.uploadIconContainer .ui.image {
    background: #fff;
    width: 36px;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    margin: 0;
}

/* .uploadIconContainer img {
    width: 20px !important;
    height: 17px !important;
} */

.ui.modal.addEditGroupModal .header {
    margin: 0;
    border: 0;
    font-family: Rubik;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.33px;
    text-align: left;

}

.multiDropdown .ui.multiple.ui.dropdown {
    display: flex;
    flex-wrap: wrap;
    gap: 5px 10px;
}

.categoryModal .actionContent {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}


.categoryModal .actionContent .ui.button.buttonIcon.deleteAction {
    color: #F15944 !important;
    font-family: Rubik;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.22px;
    text-align: left;
}

.ui.button.buttonIcon.uploadImgCloseIcon {
    position: absolute;
    left: 54px;
    top: -5px;
}

.ui.modal.categoryModal >.content {
    display: flex;
    flex-direction: column
}