.list-view-confirmation-modal.ui.modal{
width: 450px;
padding: 20px;
}

.list-view-confirmation-modal.ui.modal .header{
    padding: 0;
    padding-bottom: 12px;
}

.list-view-confirmation-modal.ui.modal .content{
    padding-top: 12px;
}