.gms.ui.modal {
  width: 100%;
  height: 650px;
  width: 750px;
  padding: 0px;
      top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
}

.gms .gms-header {
  padding: 0 !important;
}

.gms .gms-header .gms-header-title {
  display: flex;
  align-items: center;
  position: absolute;
  padding: 0;
  top: 40px;
  left: 38px;
  color: #000;
  z-index: 9999;
  font: 400 18px "Rubik", sans-serif !important;
}

.gms .gms-tabs {
  position: absolute;
  width: calc(100% - 68px);
  top: 30px;
  right: 30px;
}

.gms .gms-tabs .menu {
  gap: 16px;
}

.gms .gms-tabs .menu .item {
  padding: 0;
  border: none;
  font-weight: 400 !important;
  font: 400 15px "Rubik", sans-serif !important;
}

.gms .gms-tabs .menu .item.active {
  border-bottom: 2.5px solid #727aff;
}

.gms .gms-tabs .ui.tab {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: none !important;
  padding: 0 !important;
}

.gms-dropdown {
  border: 1px solid #d3d2de;
  border-radius: 4px;
  padding: 6px 8px;
  display: flex !important;
  justify-content: space-between;
      padding-right: 23px;
}

.gms-dropdown .gms-dropdown-menu.menu.visible {
  border: 1px solid #d3d2de;
  border-radius: 4px;
  margin-top: 4px;
}

.gms-dropdown .gms-dropdown-menu.menu.visible .gms-dropdown-item.item {
  padding: 8px 12px !important;
  font: 400 14px "Rubik", sans-serif !important;
  color: #000000;
}

.gms-search-bar.ui.input {
  border-radius: 0px;
}

.gms-search-bar.ui.input input {
  border: none !important;
  border-bottom: 1px solid #d3d2de !important;
  padding: 8px 8px;
  border-radius: 0px;
}

.gms-search-bar.ui.input input::placeholder {
  color: #000;
}

.recycle-bin-trigger{
  position: absolute;
  cursor: pointer;
  right: 38px;
  top: 40px;
  z-index: 99999;
}

.recycle-bin-trigger.ui.button.buttonIcon img {
    width: 20px !important;
}


.gms-radio.ui.radio.checkbox label::before{
    width: 16px !important;
    height: 16px !important;
    border-radius: 50% !important;
    border: 1px solid #d3d2de !important;
    box-shadow: none !important;
}

.gms-radio.ui.radio.checkbox input:checked ~ label:after{
    width: 8px !important;
    height: 8px !important;
    top: 50% !important;
    left: 4px !important;
    transform: translateY(-50%) !important;
    background-color: #727aff !important;
    color: #727aff !important;
}


.gms-radio.ui.radio.checkbox input:checked~label:after{
    background-color: #727aff !important;
}

.gms-radio.ui.radio.checkbox label{
    color: #000 !important;
    width: 18px;
    height: 18px;
}

.gms-outline-btn{
  background-color: transparent !important;
    border: 1px solid #e75330 !important;
    color: #e75330 !important;
}

.gms-close-btn{
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.gms-close-btn.ui.button.close{
  position: relative !important;
  right: 0 !important;
  top: 0 !important;
}

.dimmer-hidden.dimmer.ui.page.modals{
    visibility: hidden !important;
}

.dimmer-hidden .ui.modal.visible{
    visibility: hidden !important;
}

.gms-tabs-image-management-active .ui.tabular.menu{
 width: 96%;
}


.image-details-file-info-section .info-section-bx{
    display: flex;
     display: grid;
    grid-template-columns: repeat(2, minmax(0, 50%));
      gap: 20px;
    border-bottom: 1.5px solid #E8E8E8;
        margin-bottom: 10px;
    padding-bottom: 8px;
}

.image-details-file-info-section .info-section-bx:last-of-type{
    border: none;
    margin-bottom: 0;
}
