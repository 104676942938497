.paginationBar{
    width:100%; float:left;
    display:flex;
    justify-content:center;
    position:relative;
}

.displayText{
    position:absolute;
    right:0;
    top:0;
    font:400 15px 'Rubik',sans-serif;
    color:#000;
}
.displayText strong{
    font-weight:600;
}

.ui.pagination.menu.paginationDashboard{
    background:none;
    box-shadow:none;
    border:none;
    min-height:0;
}

.ui.pagination.menu.paginationDashboard .item{
    font:400 13px 'Rubik',sans-serif;
    color:#667581;
    outline:0;
    background:#fff;
    border:solid 1px #D3D2DE;
    border-right:none;
    min-width:0!important;
    width:33px;
    height:33px;
    padding:0;
    display:flex;
    align-items:center;
    justify-content:center;
}
.ui.pagination.menu.paginationDashboard .icon.item>i.icon{
    margin:1px auto 0;
}
.ui.menu.menu.paginationDashboard .item:before{
    display:none;
}
.ui.pagination.menu.paginationDashboard .item:first-child{
    border-radius:4px 0 0 4px;
    font-size:14px;
}
.ui.pagination.menu.paginationDashboard .item:last-child{
    border-radius:0 4px 4px 0;
    border-right:solid 1px #D3D2DE;
    font-size:14px;
}

.ui.menu.menu.paginationDashboard .active.item{
    background:#727AFF;
    border-color:#727AFF;
    color:#fff;
}