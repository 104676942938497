.images-upload-selector.gms-dropdown {
   border: 1px solid #f15944 !important;
   color: #F15944 !important;
   width: 90px;
   font-weight: 500;
}


.images-upload-selector-item-input {
    display: none;
}

.images-upload-selector-item-label {
   cursor: pointer;
   display: block;
   width: 100%;
   padding: 8px 12px !important;
}

.gms-dropdown .gms-dropdown-menu.menu.visible .gms-dropdown-item.item:has(.images-upload-selector-item-label) {
   padding: 0px !important;
 }