.single-image-upload-modal.ui.modal{
    width: 960px !important;
    padding: 0 !important;
        top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.single-image-upload-modal.ui.modal.visible .header{
            padding: 16px;

}


.single-image-upload-modal.ui.modal.visible .actions{
    margin-bottom: 0 !important;
    padding: 16px;
}

