.ui.fluid.container {
    padding: 0 35px;
}

.ui.container {
    width: 1200px;
    max-width: 100%;
}

.ui.basic.segment,
.ui.grid,
.ui.grid>.row,
.ui.grid>.row>.column,
.ui.grid>.column,
.ui.grid>.column:not(.row) {
    padding: 0;
    margin: 0;
}

.ui.main-grid {
    margin-top: 45px;
}

.ui.main-grid>.column:last-child {
    width: 540px !important;
    margin-left: 60px;
}

h1.ui.header,
h1.ui.header:first-child {
    font: 500 24px 'Rubik', sans-serif;
    color: #000;
    margin: 50px 0 20px;
}

h2.ui.header,
h2.ui.header:first-child {
    width: 100%;
    float: left;
    font: 700 24px 'Rubik', sans-serif;
    color: #000;
    margin: 25px 0 45px;
    display: flex;
    align-items: center;
}

.ui.menu.header-bar {
    z-index: 13 !important;
}

.ui.dimmer {
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1901;
}

.ui.inverted.dimmer {
    top: 63px !important
}

.ui.menu.header-bar,
.ui.basic.segment.subheader {
    background: #fff;
    box-shadow: 0 2px 5px #ced3e2;
    margin: 0;
    position: relative;
}

.ui.basic.segment.subheader {
    height: 47px;
    padding: 9px 0;
    position: sticky;
    top: 0;
    z-index: 11;
}

.ui.basic.segment.subheader .ui.fluid.container,
.ui.basic.segment.subheader .subheader-left,
.ui.basic.segment.subheader .subheader-right {
    display: flex;
    align-items: center;
}

.ui.basic.segment.subheader .ui.fluid.container {
    justify-content: space-between;
}

.ui.label.dropdown-submenu {
    font: 400 13px 'Rubik', sans-serif;
    color: #000;
    position: relative;
    padding: 0 10px 0 0;
    margin: 0 10px 0 0;
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
    background: none;
    border-right: solid 1px #c0cbda;
    border-radius: 0;
    max-width: 320px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ui.label.segment.dropdown-submenu:before {
    content: '';
    width: 1px;
    height: 100%;
    border-right: solid 1px #c0cbda;
    position: absolute;
    right: 0;
    top: 0;
}

.ui.label.dropdown-submenu strong {
    font-weight: 500;
    padding: 0;
    margin: 0 0 0 5px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    float: left;
}

.ui.label.dropdown-submenu.active strong {
    font-weight: 400;
}

.ui.label.dropdown-submenu>img {
    height: 17px !important;
    margin: 0 5px 0 0;
}

.ui.popup.popup-submenu {
    width: 320px;
    max-width: 320px;
    border: solid 1px #d3d2de;
    border-radius: 2px;
    margin-top: 10px !important;
    padding: 0;
    margin-left: -10px !important;
}

.ui.popup.popup-submenu>div:first-child>div:last-child {
    z-index: 4;
}

.scrollDash>div:nth-last-child(2),
.popup-submenu>div:last-child>div:nth-last-child(2),
.view-all-popup>div:last-child>div:nth-last-child(2),
.view-all-detail-popup>div:last-child>div:nth-last-child(2),
.property-box>div:last-child>div:nth-last-child(2),
.modal-calendar .content>div:last-child>div:nth-last-child(2),
.notifications-box>div:last-child>div:nth-last-child(2) {
    display: none;
}

.popup-submenu>div:last-child>div:last-child {
    display: none;
}

.popup-submenu:hover>div:last-child>div:last-child {
    display: block;
}

.ui.segment.dropdown-submenu.disable .ui.basic.label>img,
.ui.label.dropdown-submenu.disable .ui.basic.label>img {
    opacity: 0.3;
}

.ui.popup.popup-submenu .ui.items>.item {
    width: 100%;
    float: left;
    font: 400 14px 'Rubik', sans-serif;
    padding: 11px 20px 11px 37px !important;
    cursor: pointer;
    margin: 0;
    position: relative;
    z-index: 3;
    max-width: 360px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.ui.popup.popup-submenu .ui.items>.item span {
    max-width: 85%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.ui.popup.ui.popup.popup-submenu .ui.items>.item:before {
    content: '';
    width: 90.4%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: solid 1px #d4d2df;
}

.ui.popup.ui.popup.popup-submenu .ui.items>.item:last-child:before {
    display: none;
}

.ui.popup.popup-submenu .ui.items>.item:hover {
    background-color: #e6fced;
}

.ui.popup.popup-submenu .ui.items>.active.item {
    background: url(/img/icon-check-filled.svg) no-repeat 10px 11px #e6fced;
    background-size: 16px 16px;
}

.ui.bottom.center.popup:before,
.ui.popup.info-heading:before,
.ui.pointing.dropdown>.menu:after {
    width: 1em;
    height: 1em;
}

.ui.popup.info-heading:before {
    left: -0.4em !important;
    margin-top: -5px !important;
}

.ui.bottom.center.popup:before,
.ui.pointing.dropdown>.menu:after {
    top: -0.4em !important;
}

.ui.popup.info-heading:before {
    display: none;
}

.ui.popup.view-all-popup,
.ui.popup.view-all-detail-popup {
    min-width: 660px;
    max-width: 100%;
    width: 570px;
    border-radius: 2px;
    border: solid 1px #d3d2de;
    padding: 50px 0 20px;
    margin: 7px 0 0;
}

.ui.popup.view-all-popup .aligner,
.ui.popup.view-all-detail-popup .aligner {
    padding: 0 20px;
}

.ui.popup.view-all-detail-popup:before {
    display: none;
}

i.blue.icon {
    color: #727aff !important;
}

i.green.icon {
    color: #05b93c !important;
}

.ui.orange.button,
.ui.orange.button:focus,
.ui.primary.button,
.ui.primary.button:focus {
    background: #e75330;
}

.ui.basic.orange.button,
.ui.basic.orange.button:focus {
    border-color: #e75330 !important;
    color: #e75330 !important;
}

.ui.orange.button:hover,
.ui.primary.button:hover {
    background: #d54422;
}

.ui.green.primary.button {
    background: #29AD73;
    border-radius: 6px;
}

.ui.green.primary.button:hover {
    background: #1a9b62;
}

.ui.green.primary.button:active {
    box-shadow: inset 0 2px 6px rgba(0, 0, 0, .3);
}

.ui.secondary.button,
.ui.secondary.button:focus {
    background: #babbcb;
}

.ui.secondary.button:hover {
    background: #a8a9c1;
}

.ui.mini.button {
    font: 500 12px 'Rubik', sans-serif;
    color: #fff;
    border-radius: 4px;
    padding: 6px 10px;
}

.ui.large.button {
    padding: 13px 18px;
    min-width: 120px;
    text-align: center;
    border-radius: 6px;
    font: 500 15px 'Rubik', sans-serif;
}

.ui.button {
    font: 500 13px 'Rubik', sans-serif;
    padding: 8px 18px;
    border-radius: 4px;
    margin: 0;
}

.ui.button.property-button {
    padding: 0;
    margin-left: 10px;
}

.ui.button.property-button a {
    float: left;
    padding: 8px 18px;
    color: #fff;
}

.ui.button.link {
    background: none;
    border: none;
    padding: 5px;
    font: 400 13px 'Rubik', sans-serif;
    color: #727aff;
}

.ui.button.link:hover {
    color: #000;
    text-decoration: underline;
}

.ui.button.link img {
    width: 9px;
    display: inline-block;
    margin: 0 0 0 3px;
}

.ui.button.link:hover img {
    filter: brightness(0%);
}

.ui.grey.button,
.ui.grey.button:focus {
    background: #abafbf;
}

.ui.grey.button:hover {
    background: #9499ac;
}

.ui.small.button {
    font-weight: 400;
    padding: 8px 25px;
}

.ui.primary.button {
    height: 30px;
    min-width: 100px;
}

.ui.basic.primary.button,
.ui.basic.primary.button:hover,
.ui.basic.primary.button:focus {
    color: #F15944 !important;
    border: none;
    box-shadow: inset 0 0 0 1px #F15944 !important;
}

.ui.basic.yellow.button,
.ui.basic.yellow.button:hover,
.ui.basic.yellow.button:focus {
    height: 36px;
    color: #FF9110 !important;
    border: none;
    box-shadow: inset 0 0 0 1px #FF9110 !important;
}

.ui.checkbox label:before {
    width: 20px;
    height: 20px;
    border: solid 1px #d5d4df;
}

.ui.checkbox label:after {
    width: 20px;
    height: 20px;
    font-size: 13px;
}

.ui.checkbox input:checked:focus~label:before,
.ui.checkbox input:checked~label:before {
    background: #727aff;
    border-color: #727aff;
}

.ui.checkbox input:checked:focus~label:after,
.ui.checkbox input:checked~label:after {
    color: #fff;
}

.ui.btn-view-popup {
    background: none !important;
    border: none;
    padding: 5px 10px;
    margin-left: -10px;
    font: 400 13px 'Rubik', sans-serif;
    color: #667581;
    display: flex;
    align-items: center;
}

.icon-bars,
.icon-bars:before,
.icon-bars:after {
    width: 15px;
    height: 2px;
    background: #727aff;
}

.icon-bars {
    position: relative;
    display: inline-block;
    margin: 0 10px 0 0;
}

.icon-bars:before,
.icon-bars:after {
    content: '';
    position: absolute;
    left: 0;
    top: -4px;
}

.icon-bars:after {
    top: auto;
    bottom: -4px;
}

span.close {
    position: absolute;
    right: 10px;
    top: 10px;
}

span.close a {
    width: 26px;
    height: 26px;
    float: left;
}

.ui.close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 26px;
    height: 26px;
    padding: 5px;
    background: none !important;
}

.ui.close:before,
.ui.close:after,
span.close a:before,
span.close a:after {
    position: absolute;
    left: 12px;
    top: 5px;
    content: '';
    height: 17px;
    width: 2px;
    background-color: #7a82ff;
}

.ui.close:before,
span.close a:before {
    transform: rotate(45deg);
}

.ui.close:after,
span.close a:after {
    transform: rotate(-45deg);
}

.ui.close:hover,
span.ui.close a:hover {
    opacity: 0.8;
}

.ui.popup h4.ui.header {
    width: 100%;
    float: left;
    padding: 0 0 6px;
    margin: 0 0 25px;
    font: 500 18px 'Rubik', sans-serif;
    border-bottom: solid 1px #d3d2de;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ui.popup h4.ui.header>span {
    font: 400 16px 'Rubik', sans-serif;
    margin-left: 4px;
}

.ui.input.search-header {
    width: 100%;
    float: left;
    margin: 0 0 25px;
    align-items: center;
    font: 500 18px 'Rubik', sans-serif;
    border-bottom: solid 1px #d3d2de;
}

.ui.input.search-header>.ui.label {
    margin: 0;
    padding: 0;
    background: none;
    font: 400 18px 'Rubik', sans-serif;
    color: #000;
    position: relative;
}

.ui.input.search-header>.ui.label>span {
    float: left;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ui.input.search-header>.ui.label strong {
    font-weight: 500;
}

.ui.input.search-header>.ui.label strong span {
    font: 400 16px 'Rubik', sans-serif;
    margin-left: 2px;
}

.ui.input.search-header i.icon {
    color: #737bff;
    width: 24px;
    font-size: 16px;
    float: left;
    margin: 3px 5px 0 0;
}

.ui.input.search-header>input {
    border: none;
    border-radius: 0;
    padding: 5px 10px;
    margin-bottom: -1px;
    border-bottom: solid 1px #d3d2de;
}

.ui.input.sh2>input {
    width: 221px;
}

.ui.input.search-header>input:focus {
    border-color: #5694fb;
}

.ui.popup .ui.header .ui.input {
    margin-left: auto;
}

.ui.popup .ui.header .ui.input input {
    text-align: right;
    border: none;
    box-shadow: none;
    height: 20px;
    padding-right: 20px !important;
}

.ui.popup .ui.header .ui.input i.icon {
    color: #737bff;
    width: 16px;
}

.ui.popup h4.ui.header>i.icon:first-child {
    font-size: 15px;
    margin: -3px 5px 0 0;
    cursor: pointer;
}

.aligner {
    width: 100%;
    float: left;
    padding: 0 40px;
}

.ui.popup.view-all-popup .aligner.mt-3 {
    margin-top: 3px;
}

.ui.view-all-popup .aligner.mt-3 h4.ui.header {
    max-width: 528px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ui.view-building-group {
    width: 100%;
    float: left;
}

.ui.view-building-group .item {
    width: 100%;
    float: left;
    min-height: 100px;
    border: solid 1px #d3d2de;
    border-radius: 2px;
    margin: 0 0 12px !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    position: relative;
}

.ui.view-building-group.buildingItem .item {
    cursor: default !important;
}

.ui.items.view-building-group>.item>.image {
    width: 100px !important;
    border-radius: 2px 0 0 2px;
    overflow: hidden;
}

.ui.items.view-building-group>.item>.image+.content {
    padding-left: 25px;
}

.ui.view-building-group .item .ui.orange.button {
    position: absolute;
    right: 10px;
    display: none;
}

.ui.view-building-group .item .ui.orange.button {
    top: 10px;
}

.ui.view-building-group .item .ui.orange.button.versions {
    right: 100px;
    padding: 8px 14px;
    text-align: center;
}

.ui.view-building-group>.item>.content>.header {
    font: 500 15px 'Rubik', sans-serif !important;
    margin-top: 5px;
    max-width: 210px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.ui.view-building-group>.item>.content>.description {
    width: 100%;
    float: left;
    margin: 0;
}

.ui.view-building-group>.item>.content>.description p {
    font: 400 13px 'Rubik', sans-serif;
    color: #333;
    float: left;
}

.ui.view-building-group>.item>.content>.description p:first-child {
    padding: 0 10px 0 0;
    margin: 0 10px 0 0;
    border-right: solid 1px #c7d1de;
}

.ui.view-building-group>.item>.content>.description p strong {
    font-weight: 500;
}

.ui.view-building-group>.item .extra {
    margin-bottom: 5px;
}

.ui.view-building-group>.item .extra span {
    float: left;
    font: 400 12px 'Rubik', sans-serif;
    color: #667581;
    padding: 0 14px 0 0;
    position: relative;
}

.ui.view-building-group>.item .extra span:after {
    content: '';
    width: 3px;
    height: 3px;
    border-radius: 3px;
    background: #667581;
    position: absolute;
    right: 3px;
    top: 6px;
}

.ui.view-building-group>.item .extra span:last-child:after {
    display: none;
}

.ui.view-building-group>.item .extra span.extra-numbers {
    font: 500 12px 'Rubik', sans-serif;
    color: #7179fe;
    padding-right: 0;
    margin-right: 0;
}

.ui.view-building-group .item:hover {
    border-color: #727aff;
}

.ui.view-building-group .item:hover .ui.link,
.ui.view-building-group .item:hover .ui.orange.button {
    display: block;
    z-index: 100;
}

.ui.grid-table {
    width: 100%;
    float: left;
}

.ui.grid-table>.row {
    border-bottom: solid 1px #d3d2de;
}

.ui.grid-table>.row:first-child {
    background: #ecedf1;
    border-bottom: none;
    border-radius: 2px;
}

.ui.grid-table>.row:last-child {
    border-bottom: none;
}

.ui.grid-table .ui.trigger-holiday {
    margin: 0;
    font: 400 15px 'Rubik', sans-serif;
    text-align: left;
}

.ui.grid-table>.row>.column {
    padding: 14px 20px 13px;
    font: 400 14px 'Rubik', sans-serif;
    color: #000;
    border-right: solid 1px #f2f1f5;
}

.ui.grid-table>.row>.column:last-child {
    border-right: none;
}

.ui.grid-table>.row:first-child>.column {
    padding: 16px 20px;
    border: none;
}

.ui.grid-table>.row>.column>strong {
    font-weight: 500;
}

.ui.grid-table>.row>.column:first-child>span {
    color: #828e97;
    margin-left: 3px;
}

.ui.grid-modal>.row>.column {
    font-size: 15px;
    display: flex !important;
    align-items: center;
}

.status {
    font: 400 13px 'Rubik', sans-serif;
    padding: 0 0 0 13px;
    position: relative;
    color: #333;
}

.status span {
    color: #333;
    font-weight: 500;
}

.status:before {
    content: '';
    width: 13px;
    height: 13px;
    border-radius: 13px;
    background: #333;
    border: solid 2px #666;
    position: absolute;
    left: -4px;
    top: 1px;
}

.status.draft span {
    color: #056989;
}

.status.draft:before {
    background: #056989;
    border-color: #acdfe2;
}

.status.incomplete span {
    color: #ed2527;
}

.status.incomplete:before {
    background: #ed2527;
    border-color: #ffbfd7;
}

.status.operational span {
    color: #14c0a8;
}

.status.operational:before {
    background: #14c0a8;
    border-color: #abfafe;
}

.status.processing span {
    color: #0d8cf5;
}

.status.processing:before {
    background: #0d8cf5;
    border-color: #b0f2fe;
}

.status.validating span {
    color: #ffad05;
}

.status.validating:before {
    background: #ffad05;
    border-color: #fef5aa;
}

.ui.basic.segment-box {
    width: 100%;
    float: left;
    background: #fff;
    box-shadow: 0 0 26px 0 #dee1e5;
    padding: 30px 35px 40px;
    margin: 0 0 50px;
    position: relative;
}

.ui.basic.segment-box h1.ui.header,
.ui.basic.segment-box>h2.ui.header {
    width: 100%;
    float: left;
    margin: 0 0 35px;
    font: 500 20px 'Rubik', sans-serif;
    color: #000;
    display: flex;
    align-items: center;
}

.ui.basic.segment-box>h2.ui.header .link {
    margin-left: auto;
}

.ui.basic.segment-box>h2.ui.header>span>.icon {
    font-size: 15px;
    top: -8px;
    margin-left: 3px;
    position: relative;
    cursor: pointer;
}

.ui.popup.info-heading {
    border: solid 1px #d3d2de;
    border-radius: 2px;
    padding: 15px 25px;
    max-width: 320px;
    width: 320px;
    color: #000;
}

.ui.popup.info-heading:before {
    box-shadow: -1px 1px 0 0 #d3d2de
}

.ui.popup.info-heading>.header {
    font: 500 15px 'Rubik', sans-serif;
}

.ui.popup.info-heading>p {
    font: 400 15px/22px 'Rubik', sans-serif;
}

.ui.popup.info-heading>p a {
    color: #727aff;
    margin: 0 0 0 5px;
}

.ui.popup.info-heading>p a:hover {
    text-decoration: underline;
    color: #333
}

p,
.p {
    font: 400 15px/22px 'Rubik', sans-serif;
}

p strong,
.p strong {
    font-weight: 500;
}

.p {
    margin: 0;
}

.ui.basic.push-notifications {
    background: url(/img/bg-push-noti.png) no-repeat left bottom #1d0a3d !important;
    padding-bottom: 25px;
}

.ui.push-notifications textarea {
    height: 100px;
    border-radius: 2px;
    border: solid 1px #d3d2de;
    padding: 12px 20px;
    margin: 0 0 15px;
    font: 400 15px/22px 'Rubik', sans-serif;
    resize: none;
}

.ui.push-notifications textarea:focus {
    border-radius: 2px;
}

.ui.push-notifications .ui.basic.segment {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.warning-text {
    font: 400 13px 'Rubik', sans-serif;
    color: #333;
}

.ui.push-notifications .warning-text {
    color: #fff;
}

.ui.basic.push-notifications>h2.ui.header {
    color: #fff;
    margin-bottom: 25px;
}

.ui.push-notifications .p,
.ui.push-notifications>h2.ui.header .link,
.ui.push-notifications>h2.ui.header>span>.icon {
    color: #938aa2 !important;
}

.ui.push-notifications .ui.button.link img {
    filter: saturate(29%) hue-rotate(20deg);
    opacity: 0.8;
}

.ui.push-notifications.locked .p,
.ui.push-notifications.locked .ui.form,
.ui.push-notifications.locked .ui.form textarea,
.ui.push-notifications.locked .ui.large.button {
    pointer-events: none;
}

.notify-check {
    margin: 0 10px 0 auto;
    width: 24px;
    cursor: pointer;
}

.ui.basic.segment-box>.ui.grid {
    width: 100%;
    float: left;
}

.ui.basic.segment-box .p {
    margin: 0 0 10px;
}

.ui.basic.segment-box .p-detail {
    overflow: hidden;
    padding: 0 0 15px;
}

.formatted {
    white-space: pre-wrap;
}

.value {
    font: 400 28px 'Rubik', sans-serif;
    margin: 0 0 10px;
}

.percentage {
    font: 500 14px 'Rubik', sans-serif;
    color: #ed2726;
}

.percentage.increase {
    color: #18bfad;
}

.analytics-chart {
    width: 100%;
    float: left;
    margin: 0 0 10px;
}

.gap {
    margin-top: 30px !important;
}

.ui.label.chart-label {
    float: left;
    min-height: 0;
    padding: 4px 10px 3px 8px !important;
    margin: 0 0 10px;
    clear: both;
    border-color: #d3d2de;
    font: 400 14px 'Rubik', sans-serif;
}

.ui.label.chart-label span {
    float: left;
    width: 13px;
    height: 13px;
    border-radius: 4px;
    margin: 1px 9px 0 0;
    background: #51d8de;
}

.ui.label.chart-label span.blue {
    background: #235ee8;
}

.ui.label.chart-label span.orange {
    background: #ffae06;
}

.ui.segment.building-box {
    padding: 0 0 42px;
}

.cover-banner {
    width: 100%;
    float: left;
    margin-bottom: 17px;
    position: relative;
}

.ui.cover-image {
    min-height: 131px;
}

.cover-banner .ui.cover-image {
    width: 100%;
    height: 180px;
    overflow: hidden;
    margin-bottom: 23px;
}

.ui.cover-image img {
    width: 100%;
}

.ui.placeholder.display-image-placeholder,
.ui.display-image {
    width: 70px;
    height: 70px;
    position: absolute;
    left: 22px;
    z-index: 3;
}

.ui.placeholder.display-image-placeholder {
    top: 125px;
}

.defaultScroll>div:nth-child(2) {
    display: none;
}

.editSidebarBox .ui.placeholder.display-image-placeholder {
    top: 90px;
}

.editSidebarBox .ui.display-image {
    top: 90px;
}

.cover-banner .ui.display-image {
    top: 125px;
}

.ui.display-image {
    background: #fff;
    overflow: hidden;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    border-radius: 5px;
    overflow: hidden;
    border: solid 1px #D3D2DE;
}

.ui.display-image img {
    max-width: 100%;
    object-fit: contain;
    width: 70px;
    height: 70px;
}

.ui.placeholder.display-image-placeholder .image:not(.header):not(.ui) {
    height: 70px;
}

.ui.placeholder {
    max-width: 50em;
}

.hidden {
    display: none;
}

.cover-banner.editing .ui.cover-image:before,
.cover-banner.editing .ui.cover-image:after,
.cover-banner.editing .ui.display-image:before,
.cover-banner.editing .ui.display-image:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.cover-banner.editing .ui.cover-image:before,
.cover-banner.editing .ui.display-image:before {
    transition: all 0.3s ease;
}

.cover-banner.editing .ui.cover-image:after,
.cover-banner.editing .ui.display-image:after {
    background: url(/img/icon-img-placeholder.svg) no-repeat center;
    background-size: 40px auto;
    filter: brightness(100);
    opacity: 0;
    transition: all 0.05s ease;
}

.cover-banner.editing .ui.cover-image:after {
    cursor: pointer;
}

.cover-banner.editing .ui.display-image:after {
    background-size: 32px auto;
}

.cover-banner.editing .ui.cover-image:hover:before,
.cover-banner.editing .ui.display-image:hover:before {
    background: rgba(0, 0, 0, 0.6);
}

.cover-banner.editing .ui.cover-image:hover:after,
.cover-banner.editing .ui.display-image:hover:after {
    opacity: 0.4;
}

.ui.basic.building-segment-cover {
    width: 100%;
    padding: 0 25px;
    float: left;
}

.ui.basic.building-segment-cover>.p {
    margin-bottom: 30px;
}

.ui.basic.segment-box h1.ui.header,
.ui.building-segment-cover h3.ui.header {
    margin: 30px 0 20px;
    display: flex;
    align-items: center;
}

.ui.basic.segment-box h1.ui.header {
    margin: 0;
    position: relative;
}

.short-heading {
    width: 100%;
    float: left;
    font: 500 17px 'Rubik', sans-serif;
    color: #666;
    margin: 5px 0 15px;
}

.ui.input.short-heading {
    width: 70%;
    margin: 0 0 10px;
}

/* .ui.basic.segment-box h1.ui.header > span{
    max-width:100%;
} */

.ui.basic.segment-box h1.ui.header>span {
    max-width: 90%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.ui.building-segment-cover h1.ui.header .ui.edit-icon {
    align-self: flex-start;
    position: absolute;
    top: 0;
    right: 0;
}

.ui.building-segment-cover h1.ui.header .ui.edit-icon,
.ui.building-segment-cover h3.ui.header .ui.edit-icon {
    margin-left: auto;
}

.ui.building-segment-cover h3.ui.header {
    width: 100%;
    float: left;
    font: 500 18px 'Rubik', sans-serif;
    border-top: solid 10px #f4f7fc;
    padding: 35px 0 0;
}

.ui.button.edit-icon {
    background: none;
    padding: 0;
    padding: 5px 0 5px 5px;
}

.ui.button.edit-icon img {
    width: 14px;
}

.ui.button.arrow-toggle {
    position: relative;
    padding: 4px 4px 2px 3px;
    box-shadow: inset 0 0 0 1px #d3d2de;
    margin: 0 0 0 5px;
    bottom: -5px;
}

.building-detail-item {
    width: 94%;
    float: left;
    font: 400 15px 'Rubik', sans-serif;
    color: #000;
    display: flex;
    align-items: center;
    border-bottom: solid 1px #d3d2de;
    padding: 10px 0;
}

.building-default .building-detail-item {
    width: 100%;
}

.building-default .building-detail-item.bdi-hours.error {
    border: solid 1px rgb(224, 88, 88);
    background: rgba(224, 88, 88, 0.2);
}

.building-default .building-detail-item.error>b {
    padding-left: 5px;
}

.building-detail-item.bdi-row.error {
    padding: 5px;
    border: solid 1px rgb(224, 88, 88);
    background: rgba(224, 88, 88, 0.2);
}

.building-detail-item:last-child {
    border: none;
    padding-bottom: 0;
}

.building-detail-item>b {
    min-width: 100px;
    font: 500 15px 'Rubik', sans-serif;
}

.bdi-content {
    flex: 1;
    display: flex;
    align-items: center;
}

.bdi-content>.icon.green {
    font-size: 13px;
    color: #00ce66 !important;
    position: absolute;
    right: -2px;
    bottom: 3px;
    padding: 3px 3px 0;
    background: #fff;
    height: 20px;
}

.bdi-content>p,
.bdi-content>.detail {
    color: #828e97;
    margin-left: auto;
}

/* .limit-content .bdi-content, */

/* .limit-content .p-detail > div > span > span > span{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
} */

.limit-content .bdi-content,
.limit-content .bdi-content>.detail {
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.hoursEditWrap .error-box-small {
    margin-bottom: 13px;
    max-width: 100%;
    float: right;
    width: 360px;
}

.hoursEditWrap .bdi-hours .bdi-content {
    display: inline-block;
    position: relative;
    padding-bottom: 15px;
}

.hoursEditWrap .bdi-hours .bdi-content>div {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.hoursEditWrap .bdi-hours .bdi-content>div:first-child {
    margin-top: 0;
}

.hoursEditWrap .bdi-hours .bdi-content>div.error .ui.input>input {
    color: #e62600;
}

.hoursEditWrap .bdi-hours>b>.commonHours {
    float: left;
}

.ui.button.closeHours,
.ui.button.btnMore {
    padding: 0;
    background: none;
    margin: 0 0 0 20px;
    min-width: 0;
    min-height: 0;
}

.ui.button.closeHours .icon {
    font-size: 14px;
    color: #ccc;
    cursor: pointer;
    transition: all 0.3s ease;
}

.ui.button.closeHours:hover .icon {
    color: #333;
}

.ui.button.btnMore {
    width: auto;
    margin: 0 33px 15px 0;
    color: #727aff;
    font: 500 13px 'Rubik' sans-serif;
    display: flex;
    align-items: center;
}

.ui.button.btnMore img {
    width: 15px;
    margin: 0 5px 0 0;
}

.ui.button.btnMore:hover {
    text-decoration: underline;
}

.hoursBottom .ui.checkbox {
    margin-bottom: 15px;
}

.bdi-hours>b,
.bdi-hours .bdi-content>p,
.bdi-hours>b,
.bdi-hours .bdi-content>.detail {
    font: 400 14px 'Rubik', sans-serif;
}

.seperate,
.bdi-hours .bdi-content .seperate {
    color: #828e97;
    margin: 0 5px;
}

.bdi-hours .bdi-content>p,
.bdi-hours .bdi-content>.detail {
    margin: 0;
}

.bdi-hours {
    width: 100%;
    min-height: 62px;
    border-bottom: solid 1px #d3d2de;
}

.bdi-hours:last-child {
    margin-bottom: 30px;
}

.bdi-hours>b {
    min-width: 129px;
    max-width: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bdi-hours>b span small {
    display: block;
    font-size: 12px;
}

.bdi-hours .bdi-content>p,
.bdi-hours .bdi-content>.detail {
    color: #000;
}

.statusHours {
    position: absolute;
    right: 0;
    top: 0;
    color: #828e97;
}

.statusHours.statusOpen {
    color: #04ad99;
    margin-top: 0.2rem;
    max-width: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ui.button.setHours,
.setHours {
    color: #727aff;
    padding: 0;
    margin: 0;
    background: none;
    font: 400 14px 'Rubik', sans-serif;
}

div.setHours {
    opacity: 0.9;
}

.seperateNew {
    width: 14px;
    height: 1px;
    background: #222;
    margin: 0 15px;
}

.bdi-hours .detail .ui.basic.form-group .field {
    width: 94px;
}

.bdi-hours .ui.icon.input>i.icon {
    width: 12px;
    font-size: 16px;
    color: #585974;
}

.hoursEditWrap {
    width: 100%;
    float: left;
    border-bottom: solid 1px #d3d2de;
}

.hoursEditWrap:last-child {
    margin-bottom: 30px;
}

.hoursEditWrap .bdi-hours {
    min-height: 20px;
    padding-top: 21px !important;
    margin: 0 !important;
    border: none;
    align-items: flex-start;
}

.allBuildingsCheck {
    padding-top: 22px;
}

.hoursBottom {
    width: 100%;
    float: left;
    padding-left: 130px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.allHours {
    padding: 0 0 18px 129px;
}

.hoursBottom .ui.checkbox,
.ui.customCheck {
    font: 400 14px/22px 'Rubik', sans-serif;
    margin-right: 25px;
}

.hoursBottom .ui.checkbox label,
.ui.customCheck label {
    color: #828e97;
}

.hoursBottom .error-box-small {
    margin-bottom: 16px;
}

.ui.trigger-calendar {
    padding: 5px;
    background: none !important;
    font: 500 14px 'Rubik', sans-serif;
    color: #828e97;
    margin: 0 0 0 20px;
}

.ui.trigger-calendar img {
    width: 17px;
    margin: 0 10px -3px 0;
}

.ui.modal.modal-calendar {
    width: 890px;
    min-height: 600px;
    border: solid 1px #d3d2de;
    border-radius: 2px;
}

.ui.modal.modal-calendar .aligner {
    padding: 0 40px;
    position: relative;
}

.emptyCalendar {
    width: 100%;
    float: left;
    padding: 15px;
    margin: 20px 0;
    font: 400 14px/22px 'Rubik', sans-serif;
    color: #000;
    background: #ecedf1;
}

.ui.modal .aligner>h4.ui.header-button {
    width: 100%;
    float: left;
    font-size: 16px;
    padding: 0;
    margin: 35px 0 30px;
}

.ui.modal .aligner>h4.ui.header-button span {
    cursor: pointer;
    color: #828e97;
    float: left;
    margin: 0 40px -1px 0;
}

.ui.modal .aligner>h4.ui.header-button span.active {
    color: #000;
    font-weight: 500;
    border-bottom: solid 3px #727aff;
}

.building-default .building-detail-item.bdi-row {
    /* border: none; */
    margin: 0 0 27px;
}

.floor-status,
.ui.button.floor-status {
    font: 400 13px 'Rubik', sans-serif;
    color: #17c0ac;
    display: flex;
    align-items: center;
    margin-left: auto;
}

.ui.button.floor-status {
    color: #727aff;
    padding: 0;
    border-radius: 0;
    background: none;
}

.floor-status img {
    margin-left: 6px;
}

.bdi-row p,
.bdi-row .detail {
    font: 400 14px 'Rubik', sans-serif;
    margin: 0;
    color: #828e97;
    flex: 1;
    display: flex;
    align-items: center;
}

.bdi-row p strong,
.bdi-row .detail strong {
    width: 110px;
    min-width: 110px;
    font-weight: 400;
    color: #000;
}

.bdi-row .detail>span {
    max-width: 90px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.bdi-row p:last-child strong,
.bdi-row .detail:last-child strong {
    width: 90px;
}

.bdi-row .detail.editing {
    display: inline-block;
    margin: 0 20px 0 0;
}

.bdi-row .detail.editing .ui.input {
    margin: 0;
    width: 100%;
}

.bdi-row .detail.editing strong {
    width: 100%;
    float: left;
    margin-bottom: 8px;
    color: #667581;
    font-size: 13px;
}

.bdi-row .detail.editing:first-child {
    width: 159px;
    max-width: 159px;
}

.bdi-row .detail.editing:nth-child(2) {
    width: 105px;
    max-width: 105px;
}

.ui.modal {
    width: 860px;
    padding: 0 0 36px;
    border-radius: 0;
    border-color: #d3d2de;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    max-width: 100% !important;
}

.ui.modal>.close {
    top: 4px;
    right: 3px;
}

.ui.modal>.close:before,
.ui.modal>.close:after {
    top: 9px;
}

.ui.modal>.content {
    padding: 0;
}

.ui.modal .aligner {
    padding: 0 40px;
}

.ui.modal .actions {
    margin: 30px 0 40px;
}

.ui.modal .aligner>.header {
    padding: 0 0 5px;
    margin: 46px 0 15px;
    font: 400 18px 'Rubik', sans-serif !important;
    border-bottom: solid 1px #d3d2de;
    color: #000;
}

.ui.button.link-popup {
    color: #727aff;
    background: none;
    padding: 0;
    font: 400 15px 'Rubik', sans-serif;
}

.ui.button.link-popup:hover {
    text-decoration: underline;
}

.trigger-hide {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
}

.ui.popup.holiday-popup {
    border: solid 1px #d3d2de;
    border-radius: 2px;
    min-width: 0px;
    width: 384px;
    max-width: 384px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    z-index: 1901;
    max-height: 338px;
    overflow-y: auto;
    overflow-x: hidden;
}

.modal-calendar.modal .ui.button.trigger-holiday .text {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hours-group-inner-bx {
    display: flex;
    align-items: center;
}


.ui.holiday-popup h4.ui.header {
    font: 500 16px 'Rubik', sans-serif;
    margin: 16px 0 16px;
    padding-bottom: 6px;
    margin-top: 0px;
}

.ui.holiday-popup .ui.basic.form-group>p {
    font: 400 15px 'Rubik', sans-serif;
    min-width: 63px;
    color: #000;
}

.ui.holiday-popup .ui.basic.form-group>.content {
    flex-direction: column;
}

.ui.checkbox.check-calendar {
    width: 100%;
    float: left;
    padding: 0;
    margin: 10px 0;
}

.ui.checkbox.check-calendar label:after {
    line-height: 20px;
}

.ui.holiday-popup .ui.checkbox.check-calendar label {
    color: #828e97;
}

.ui.checkbox.check-calendar label {
    font: 400 14px/21px 'Rubik', sans-serif;
}

.ui.checkbox.check-calendar label:after {
    line-height: 20px;
}

.ui.basic.form-group.hours-group {
    align-items: flex-start;
}

.ui.basic.form-group.hours-group>p {
    margin-top: 2px;
}

.ui.basic.hours-group>.content {
    flex-direction: column;
}

.ui.basic.hours-group .ui.basic.form-group {
    margin-bottom: 0 !important;
}

.ui.holiday-popup .ui.checkbox.check-calendar {
    margin: 0;
}

.ui.holiday-popup .actions-group {
    margin-top: 0;
}

.ui.holiday-popup .remove-group {
    flex-wrap: wrap;
}

.ui.holiday-popup .remove-group .ui.checkbox.check-calendar {
    width: 100%;
    margin: 0 0 15px;
}

.inner-group {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
}

.ui.holiday-popup .ui.checkbox.check-calendar {
    width: auto;
}

.ui.holiday-popup .inner-group .ui.checkbox.check-calendar {
    margin-bottom: 13px;
}

.inner-group .ui.checkbox.check-calendar:last-child {
    margin-left: auto;
}

.calender-group {
    display: flex;
    align-items: start;
    width: 100%;
    flex-direction: column !important;
}

.calender-group .form-group .content .ui.input {
    width: 105px !important;
    align-self: start !important;
}

.calender-check {
    margin-left: 0px !important;
}

.ui.basic.form-group {
    width: 100%;
    float: left;
    margin: 0 0 11px;
    padding: 0;
    display: flex;
    align-items: center;
}

.ui.popup.holiday-popup .ui.basic.form-group {
    border: solid 1px #fff;
    padding: 2px 0;
    margin-bottom: 14px;
}

.ui.popup.holiday-popup .ui.basic.form-group.error {
    border: solid 1px rgb(224, 88, 88) !important;
    background: rgba(224, 88, 88, 0.2);
    width: 104%;
    margin-left: -2%;
    padding: 2px 2%;
}

.ui.basic.form-group>.content {
    flex: 1;
    display: flex;
    align-items: center;
}

.ui.basic.form-group>.content>.ui.basic.form-group {
    margin: 0;
}

.ui.basic.form-group>p {
    width: 100px;
    margin: 0 10px 0 0;
    font: 400 14px 'Rubik', sans-serif;
}

.ui.basic.form-group .ui.input {
    margin: 0;
    width: 100%;
}

.ui.basic.form-group .ui.dropdown.label {
    max-width: 50px;
    background: none;
    position: relative;
}

.ui.basic.form-group .ui.dropdown.label:after {
    content: '';
    width: 1px;
    height: 17px;
    position: absolute;
    right: 0;
    bottom: 2px;
    border-right: solid 1px #ccc;
}

.form-property .ui.basic.form-group .ui.labeled.input input {
    padding-left: 10px;
}

.form-property .ui.form-group .ui.dropdown.label>i.icon {
    color: #4c4d64;
}

.ui.basic.form-group .ui.input>input,
.ui.form-group .ui.dropdown,
.building-default .ui.input>input,
.building-default .ui.form textarea {
    font: 400 15px 'Rubik', sans-serif;
    color: #000;
    border: none;
    border-radius: 0;
    border-bottom: solid 1px #d3d2de;
    margin: 0;
    padding: 0 0 1px;
}

.building-default .ui.input>input {
    padding-right: 2px;
}

.ui.basic.form-group .ui.input>input::placeholder,
.building-default .ui.input>input::placeholder,
.building-default .ui.input.error>input::placeholder,
.building-default .ui.form textarea::placeholder,
.building-default .ui.form .error.field textarea::placeholder {
    color: #7f7f7f;
}

.building-default .ui.input>input {
    color: #000;
}

.building-default .ui.form textarea {
    resize: none;
    height: 92px;
    background: #fff;
}

.ui.form-group.error .error-box-small {
    margin-top: 10px;
}

.ui.form-group .ui.dropdown {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 15px;
}

.ui.basic.form-group .ui.input>input:focus,
.ui.form-group .ui.active.dropdown {
    border-color: #85b7d9;
}

.ui.basic.form-group.form-group-input {
    display: inline-block;
    margin-bottom: 27px;
}

.ui.basic.form-group.form-group-input>p {
    width: 100%;
    color: #667581;
    margin-bottom: 12px;
    font-size: 15px;
}

.ui.dropdown .scrolling.menu {
    border-top: none;
}

.ui.form-group .ui.dropdown .menu {
    border-radius: 2px;
    margin-top: 0 !important;
    min-width: 100%;
    box-shadow: none;
    border-color: #85b7d9;
}

.ui.form-group .ui.dropdown .menu>.item {
    font: 400 15px 'Rubik', sans-serif;
}

.ui.form-group .ui.dropdown .menu:after {
    display: none;
}

.ui.form-group .ui.dropdown .menu .selected.item,
.ui.form-group .ui.dropdown.selected {
    font-weight: 500;
}

.ui.form-group .ui.dropdown>i.icon {
    color: #939ca3;
}

.ui.action-calendar {
    padding: 0 10px;
    background: none;
    border: solid 1px #d3d2de;
    border-left: none;
    border-radius: 0 2px 2px 0 !important;
}

.ui.action-calendar img {
    width: 17px;
}

.ui.action-calendar:hover {
    background: #f9f9f9;
}

.ui.form-group .seperate {
    font: 400 16px 'Rubik', sans-serif;
    color: #333;
}

.actions-group {
    width: 100%;
    float: left;
    margin: 20px 0 0;
    display: flex;
    align-items: center;
}

.actions-group .ui.orange.button {
    margin-left: auto;
}

.ui.upload-image-modal .actions .ui.button,
.ui.modal.crop-image-modal .actions .ui.button,
.actions-group .ui.button {
    font: 500 15px/36px 'Rubik', sans-serif;
    min-width: 100px;
    height: 36px;
    padding: 0 20px;
}

.ui.trigger-holiday {
    padding: 0;
    font: 500 13px 'Rubik', sans-serif;
    color: #727aff;
    float: right;
    margin: 0 0 10px;
    background: none !important;
    display: flex;
    align-items: center;
}

.ui.modal .aligner>.ui.trigger-holiday {
    position: absolute;
    right: 60px;
    top: 18px;
    z-index: 10;
}

.ui.trigger-holiday img {
    width: 16px;
    margin-right: 6px;
}


.ui.basic.segment-box.locked {
    background: rgba(255, 255, 255, 0.1);
}

.ui.basic.push-notifications.locked:before {
    content: '';
    background-color: rgba(255, 255, 255, 0.58);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    pointer-events: none;
}

.ui.basic.segment-box.mobile-analytics>.ui.grid:nth-child(2) .column {
    border-right: solid 1px #e9e8ed;
    padding-left: 15px;
}

.ui.basic.segment-box.mobile-analytics .ui.grid:nth-child(2)>.column:first-child {
    max-width: 180px;
    padding-left: 0
}

.ui.basic.segment-box.mobile-analytics .ui.grid:nth-child(2)>.column:last-child {
    border-right: none;
    padding-right: 0;
}

.ui.basic.mobile-analytics .ui.grid:nth-child(2)>.column:nth-child(2) {
    max-width: 180px;
}

.ui.basic.segment-box.locked>.ui.grid,
.ui.basic.segment-box.locked>h2.ui.header .link {
    opacity: 0.3;
    pointer-events: none;
}

.ui.basic.segment-box>h2.ui.header>span {
    background: #fff;
    box-shadow: 0 0 26px 0 #dee1e5;
    border-bottom-right-radius: 5px;
    height: 70px;
    padding: 0 30px 0 35px;
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
}

.ui.basic.push-notifications.locked>h2.ui.header>span {
    background: #1d0a3d;
    box-shadow: none;
    z-index: 2;
}

.bg-overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 47px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 8;
}

.ui.segment.notifications-cover {
    width: 310px;
    height: calc(100vh - 47px);
    border: none;
    border-radius: 0;
    border-left: solid 1px #c0cbda;
    padding: 100px 0 0;
    margin: 0;
    position: fixed;
    top: 47px;
    right: 0;
    z-index: 10;
}

.notifications-box {
    width: 380px;
    height: calc(100vh - 47px);
    border: none;
    border-radius: 0;
    background: #fff;
    border-left: solid 1px #c0cbda;
    padding: 67px 0 0;
    margin: 0;
    position: fixed;
    top: 47px;
    right: 0;
    z-index: 10;
}

.notifications-box>.ui.header {
    font: 500 14px 'Rubik', sans-serif;
    color: #000;
    padding: 10px;
    margin: 0;
    display: flex;
    align-items: center;
    border-bottom: solid 3px #f5f6f6;
}

.ui.button.notify-back {
    padding: 5px;
    background: none;
}

.ui.button.notify-back i.icon {
    font-size: 15px;
    color: #818d96;
    margin: 0 !important;
    padding: 0;
}

.ui.button.goBack {
    padding: 0;
    background: none;
    margin: 0 10px 0 0;
}

.ui.button.goBack img {
    width: 16px;
}

.notify-group h6.ui.header {
    width: 100%;
    float: left;
    font: 400 13px 'Rubik', sans-serif;
    color: #667581;
    text-align: center;
    margin: 10px 0 20px;
}

.notify-group h6.ui.header:first-child {
    margin-top: 20px;
}

.notifications-box .ui.basic.aligner {
    padding: 0 20px 0 20px;
}

.notify-group {
    margin: 0;
    padding: 0;
}

.ui.basic.notify-item {
    width: 100%;
    float: left;
    background: #fff;
    border: solid 1px #dfe5ec;
    margin: 0 0 10px;
    padding: 10px 20px 10px 10px;
    position: relative;
    cursor: pointer;
}

.notify-group div {
    width: 100%;
    float: left;
    font: 400 13.4px/18px 'Rubik', sans-serif;
    letter-spacing: -0.25px;
    color: #667581;
    word-break: break-word;
}

.notify-group div b,
.notify-group div strong {
    font-weight: 500;
    color: #222;
}

.unread-dot {
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 7px;
    background: #f15944;
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.time {
    width: 100%;
    float: left;
    margin: 5px 0 0;
    color: #85919a;
}

/*** Property ***/

.ui.property-cover {
    width: 100%;
    height: calc(100vh - 63px);
    background: #fff;
    position: fixed;
    top: 63px;
    z-index: 100;
}

.ui.property-cover2 {
    width: 100%;
    float: left;
    height: calc(100vh - 63px);
}

.ui.property-cover>.column:first-child,
.ui.property-cover2>.column:first-child {
    width: 435px !important;
    background: #fff;
}

.ui.property-cover2>.column:last-child .ui.image {
    width: 100%;
    height: calc(100vh - 67px);
    overflow: hidden;
}

.ui.basic.property-box {
    width: 100%;
    float: left;
    padding: 40px 0 0 15px;
}

.ui.basic.property-box .ui.close:before,
.ui.basic.property-box .ui.close:after {
    height: 22px;
    top: 3px;
}

.property-box h3.ui.header,
.property-box h3.ui.header:first-child,
.building-preview h3.ui.header {
    font: 500 18px 'Rubik', sans-serif;
    border-bottom: solid 1px #d3d2de;
    margin: 0 0 25px;
    padding: 0 0 5px;
    display: flex;
    align-items: center;
}

.property-box h3.ui.header i.question.icon {
    color: #727aff !important;
    font-size: 15px;
    margin: 0 0 0 5px;
    cursor: pointer;
}

.property-box h3.ui.header .ui.button.notify-back {
    padding: 0;
    margin: -2px 5px 0 0;
}

.property-box .aligner {
    padding: 0 30px 0 0;
}

.form-property {
    width: 100%;
    float: left;
    margin: 30px 0;
}

.form-property .ui.basic.form-group,
.form-property .ui.basic.form-group>.content {
    width: 100%;
    display: inline-block;
}

.form-property .ui.basic.form-group>p,
.p-property {
    text-transform: uppercase;
    margin: 0 0 5px;
}

.form-property .ui.basic.form-group>p,
.extra-label,
.p-property {
    width: 100%;
    float: left;
    color: #667581;
    font: 400 13px 'Rubik', sans-serif;
}

.extra-label {
    margin: 13px 0 16px;
    line-height: 18px;
}

.p-property {
    margin: 0 0 15px;
}

.form-property .ui.basic.form-group .ui.input>input,
.form-property .ui.basic.form-group .ui.dropdown {
    height: auto;
    color: #000;
    font: 400 15px 'Rubik', sans-serif;
    padding: 5px 0 1px;
    border-radius: 0;
    border: none;
    border-bottom: solid 1px #d6d5e0;
}

.form-property .ui.basic.form-group .ui.input>input:focus,
.form-property .ui.basic.form-group .ui.active.dropdown {
    border-color: #e75330;
}

.ui.search.selection.dropdown.add-property-drop>input.search {
    padding-left: 0;
}

.ui.form-group .ui.dropdown.add-property-drop .menu {
    border-radius: 4px;
    border: solid 1px #d3d2de;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.property-actions {
    width: 100%;
    float: left;
    margin: 15px 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.property-actions .ui.button {
    margin-left: 20px;
    min-width: 118px;
}

.property-actions .ui.button.property-button {
    min-width: 0;
}

.property-actions .ui.button.property-button a {
    min-width: 118px;
    text-align: center;
}

.ui.basic.property-box2 {
    width: 435px;
    height: calc(100vh - 63px);
    background: #fff;
    position: fixed;
    top: 98px;
    left: 0;
    z-index: 100;
    padding-top: 5px;
}

.property-box>div .aligner {
    min-height: calc(100vh - 170px);
}

.building-floor-upload {
    width: 100%;
    float: left;
    margin: 0 0 10px;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.ui.basic.segment.form-group p {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.building-floor-upload .ui.basic.segment.form-group input:placeholder-shown {
    text-overflow: ellipsis;
}

.building-floor-upload.error .ui.basic.organize-floor-row {
    border: solid 1px rgb(224, 88, 88) !important;
    background: rgba(224, 88, 88, 0.2);
}

.building-floor-upload:first-child {
    margin-top: 10px;
}

.ui.button.upload-floor {
    align-self: flex-end;
}

.building-floor-upload .ui.small.button {
    padding: 8px 18px;
}

.building-floor-upload .floor-status {
    margin-left: 20px !important;
}

.building-floor-upload .floor-status.reupload {
    margin-left: 17px !important;
}

.form-property .building-floor-upload>.ui.basic.form-group {
    margin: 0;
}

/* .form-property .building-floor-upload>.ui.basic.form-group:first-child {
    width: 160px;
}

.form-property .building-floor-upload>.ui.basic.form-group:nth-child(2) {
    width: 105px;
} */

.ui.basic.organize-floor-row,
.ui.basic.organize-floor-row>div,
.ui.basic.organize-floor-row>div>span {
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.ui.button.upload-remove {
    padding: 5px 5px 3px;
    min-width: 40px;
    border-radius: 0;
    background: none;
}

.ui.button.upload-remove:hover {
    background: #f9f9f9;
}

.ui.button.upload-remove:active {
    box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

.ui.button.upload-remove img {
    height: 15px;
}

.ui.basic.organize-floor-row {
    min-height: 52px;
    background: #fff;
    border: solid 1px #7e8fa6;
    border-radius: 2px;
    padding: 8px;
    display: flex;
    align-items: center;
    margin: 10px 0;
    cursor: move
}

.ui.basic.organize-floor-row:first-child {
    margin-top: 0;
}

.ui.basic.organize-floor-row:last-child {
    margin-bottom: 0;
}

.ui.basic.organize-floor-row>span {
    min-width: 32px;
    height: 32px;
    background: #7a82ff;
    margin: 0 15px 0 0;
    text-align: center;
    font: 500 13px/32px 'Rubik', sans-serif;
    color: #fff;
}

.ui.basic.organize-floor-row>div {
    font: 400 15px 'Rubik', sans-serif;
}

.ui.basic.organize-floor-row>div>span {
    font-size: 13px;
    color: #828e97;
    float: left;
    width: 100%;
}

.building-floor-upload .ui.basic.organize-floor-row {
    margin: 0;
}

.ui.basic.form-group>.content>.ui.basic.organize-floor-row:first-child {
    margin-top: 20px;
}

.ui.basic.form-group .ui.basic.organize-floor-row {
    flex: 1;
    border-color: #c0cbda;
}

.ui.basic.form-group .building-floor-upload .ui.basic.organize-floor-row {
    cursor: initial;
}

.ui.basic.building-preview {
    width: 100%;
    height: calc(100vh - 63px);
    background: #f8f8f8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.building-floor {
    width: 100%;
    padding: 10px 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.building-floor:first-child,
.building-floor:last-child {
    flex: 1;
}

.building-floor:nth-child(2) {
    background: #ececed;
    border-bottom: solid 4px #17c027;
}

.building-floor:last-child {
    background: #c9cad7;
}

.building-floor-section {
    width: 383px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.building-preview h3.ui.header {
    float: left;
    width: 100%;
    margin-top: 30px;
}

.add-floor-box {
    width: 100%;
    height: 52px;
    border: dashed 1px #7e8fa6;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font: 400 16px 'Rubik', sans-serif;
    color: #7e8fa6;
}

.add-floor-box.hovered {
    background-color: orange;
}

.add-floor-box img {
    width: 20px;
    margin: 0 8px 0 0;
}

.building-floor:nth-child(2) .add-floor-box {
    color: #292929;
    background: #fff;
}

.building-floor:last-child .add-floor-box {
    color: #fff;
    border-color: #fff;
}

.building-floor:last-child .add-floor-box>img {
    filter: brightness(200%);
}

.ui.basic.drag-instruction {
    margin: 0 0 20px;
    font: 400 16px/24px 'Rubik', sans-serif;
    color: #6f7d88;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ui.basic.drag-instruction i.icon {
    font-size: 15px;
    clear: both;
}

.building-floor:last-child .building-floor-section {
    justify-content: flex-start;
}

.title-floor {
    position: absolute;
    right: 10px;
    font: 400 12px 'Rubik', sans-serif;
    text-transform: uppercase;
    color: #000;
}

.building-floor:first-child .title-floor {
    bottom: 10px;
}

.building-floor:last-child .title-floor {
    top: 10px;
}

.building-floor:nth-child(2) .title-floor {
    bottom: 39%;
}

.title-floor>i.blue.icon {
    font-size: 15px;
    cursor: pointer;
    position: relative;
    top: 1px;
}

.p-modal {
    font: 400 16px/24px 'Rubik', sans-serif;
    letter-spacing: -0.3px;
}

.ui.ground-floor-graph {
    width: 100%;
    float: left;
    text-align: right;
    margin-top: 40px;
}

.ui.ground-floor-graph img {
    display: inline-block;
    max-width: 100%;
}

.ui.small.modal {
    width: 633px;
}

.ui.basic.mini.modal {
    width: 450px;
    text-align: center;
}

.ui.basic.modal>.actions,
.ui.basic.modal>.content,
.ui.basic.modal>.header {
    padding: 0;
    border: none;
    text-align: center;
}

.ui.basic.modal span.ui.image {
    margin: 0 0 51px;
}

.ui.basic.modal span.ui.image>img {
    width: 66px;
}

.ui.basic.modal>.header {
    font: 400 28px 'Rubik', sans-serif;
    color: rgba(0, 0, 0, 1);
    margin: 0 0 35px;
}

.ui.basic.modal p {
    font: 400 18px/25px 'Rubik', sans-serif;
    color: #000;
    margin: 0 0 25px;
}

.ui.basic.modal>.actions {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ui.basic.modal>.actions .ui.button {
    min-width: 196px;
    clear: both;
    margin: 0 0 18px;
    padding: 12px 18px;
}

/***/

.chart-bg {
    float: left;
    margin: 30px 0 10px;
    background-color: #235ee8;
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 100px;
    overflow: hidden;
}

.orange-bg {
    background-color: #ffae06;
}

.chart-bg .pie {
    position: absolute;
    width: 120px;
    height: 120px;
    clip: rect(0px, 60px, 120px, 0px);
}

.chart-bg .hold {
    position: absolute;
    width: 120px;
    height: 120px;
    clip: rect(0px, 120px, 120px, 60px);
}

.chart-bg .piechart .pie {
    background-color: #51d8de;
    /* transform:rotate(180deg); */
}

.chart-bg .piechart2 {
    transform: rotate(180deg);
}

.chart-bg .piechart2 .pie {
    background-color: #51d8de;
    transform: rotate(40deg);
}

.chart-bg .data {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    position: absolute;
    top: 10px;
    left: 10px;
    background: #fff;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.chart-bg .data span {
    font: 400 22px 'Rubik', sans-serif;
    width: 100%;
    padding: 5px 0;
    text-align: center;
    position: relative;
}

.chart-bg .data span:first-child:before {
    content: '';
    width: 19px;
    height: 3px;
    background: #d3d2de;
    position: absolute;
    bottom: -2px;
    left: 40px;
}

/**/

.building-default,
.default {
    width: 100%;
    float: left;
}

.building-default .ui.form {
    margin: 0 0 20px;
    padding: 0;
}

.building-default p .ui.basic.form-group,
.building-default .detail .ui.basic.form-group {
    margin: 0;
    width: auto;
}

.building-default .ui.basic.form-group .ui.dropdown,
.building-default p .ui.basic.form-group .field,
.building-default .detail .ui.basic.form-group .field {
    width: 110px;
}

.ui.popup.holiday-popup .field {
    width: 100%;
}

.ui.popup.holiday-popup .ui.basic.form-group .content .ui.basic.form-group .field {
    width: 105px;
    flex: 1;
}

.ui.popup.holiday-popup .ui.basic.form-group .content .calender-group .ui.basic.form-group .field {
    align-self: start;
}

.building-default .ui.input {
    width: 100%;
}

.building-default p .ui.basic.form-group .ui.dropdown {
    border: solid 1px #d3d2de;
    border-radius: 2px;
    height: 32px;
    color: #828e97;
    font: 400 15px 'Rubik', sans-serif;
}

.building-default .ui.form textarea:focus,
.ui.basic.segment-box h1.ui.header .ui.input>input:focus,
.ui.input.short-heading>input:focus,
.building-default .ui.input>input:focus {
    border-color: #5694fb !important;
}

.ui.basic.form-group .ui.input.disabled input {
    background: #e7e7e7;
    pointer-events: none;
}

.building-default .building-detail-item {
    border: none;
    padding: 0;
    margin: 0 0 10px;
}

.profile .building-default .building-detail-item {
    display: inline-block;
    margin-bottom: 15px;
}

.building-default .ui.form p,
.profile .building-default .building-detail-item>b {
    width: 100%;
    float: left;
    margin: 0 0 12px;
    font: 400 15px 'Rubik', sans-serif;
    color: #667581;
}

.profile .building-default .bdi-content {
    display: inline-block;
    width: 100%;
    position: relative;
}

.ui.basic.profile .building-default .bdi-content>p,
.ui.basic.profile .building-default .bdi-content>.detail {
    flex: 1;
}

.building-default .ui.orange.button {
    font: 500 15px 'Rubik', sans-serif;
    padding: 10px 32px;
    margin-top: 15px;
}

.building-default .bdi-hours-editing {
    margin-bottom: 10px;
}

.bdi-hours-editing .ui.basic.form-group {
    margin: 0;
    width: auto;
}

.bdi-hours-editing .ui.basic.form-group .ui.dropdown {
    height: 32px;
    padding: 10px 0px 10px 10px;
    width: 100px;
}

.bdi-hours .ui.checkbox {
    margin: 0 0 0 20px;
}

.bdi-hours .ui.checkbox label {
    font: 400 14px/21px 'Rubik', sans-serif;
    color: #939ca3;
    padding-left: 28px;
}

.bdi-hours .ui.checkbox label:after {
    line-height: 20px;
}

.ui.button.linkage {
    background: none;
    width: 14px;
    height: 16px;
    border-radius: 3px;
    border: solid 2px #727aff;
    position: relative;
    padding: 0;
    margin: 0 0 0 20px;
}

.ui.button.linkage:before,
.ui.button.linkage:after {
    content: '';
    width: 2px;
    height: 14px;
    background: #727aff;
    position: absolute;
    left: 4px;
}

.ui.button.linkage:before {
    top: -10px;
}

.ui.button.linkage:after {
    bottom: -10px;
}

.building-default>.ui.customCheck {
    margin-bottom: 20px;
}

.ui.checkbox.linking {
    width: 35px;
    height: 52px;
    background: url(/img/icon-linking-break.svg) no-repeat center;
    position: absolute;
    right: 40px;
    top: 50%;
    cursor: pointer;
    transition: height 0.3s ease;
}

.ui.checkbox.linking:before,
.ui.checkbox.linking:after {
    content: '';
    width: 100%;
    height: 25px;
    position: absolute;
    left: 0;
    background: url(/img/icon-linking.svg) no-repeat center;
    background-size: 19px auto;
}

.ui.checkbox.linking:before {
    top: 0;
}

.ui.checkbox.linking:after {
    bottom: 0;
    transform: rotate(180deg);
}

.ui.checkbox.linking.checked {
    height: 48px;
    background: none;
}

.ui.checkbox.linking label:before,
.ui.checkbox.linking label:after {
    content: '';
    border-radius: 0;
    border: none !important;
    background-color: none;
    position: static;
}

.ui.checkbox.linking label:before {
    content: 'Link hours for all ';
    background: none !important;
}

.ui.checkbox.linking.checked label:before {
    content: 'Break link between ';
}

.ui.checkbox.linking label:after {
    display: none;
}

.ui.checkbox.linking label {
    position: absolute;
    top: 100%;
    left: -75px;
    background: #fff;
    border-radius: 2px;
    border: solid 1px #d3d2de;
    box-shadow: 2px 5px 12px rgba(0, 0, 0, 0.2);
    font: 400 15px 'Rubik', sans-serif;
    padding: 8px 15px;
    white-space: nowrap;
    display: none;
}

.ui.checkbox.linking:hover label {
    display: block;
}

.ui.checkbox.linking.checked label {
    left: -82px;
}

.bdi-row .ui.input {
    width: 140px;
    margin: 0 15px 0 0;
}

.ui.button.trash-icon {
    padding: 3px;
    background: none;
}

.ui.button.trash-icon img {
    width: 14px;
}

/**** welcome moda ****/

.ui.modal.welcome-modal {
    width: 730px;
    padding: 240px 90px 20px;
    text-align: center;
}

.ui.modal.welcome-modal:before {
    content: '';
    width: 100%;
    height: 217px;
    background: url(/img/modal-pattern.png);
    position: absolute;
    top: 0;
    left: 0;
}

.ui.welcome-modal>.header {
    font: 400 28px 'Rubik', sans-serif !important;
    margin: 0 0 20px;
    border: none;
}

.ui.welcome-modal>.header>.ui.image {
    width: 100%;
    float: left;
    margin: 10px 0 30px;
}

.ui.welcome-modal>.header>.ui.image img {
    display: inline-block;
    width: 44px;
}

.ui.welcome-modal p {
    font: 400 18px/28px 'Rubik', sans-serif;
    color: #000;
}

.ui.welcome-modal p a {
    color: #4c66ff;
    text-decoration: underline;
}

.ui.welcome-modal>.actions {
    border: none;
    display: flex;
    justify-content: center;
    margin: 30px 0 40px;
    background: none;
}

.ui.welcome-modal>.actions .ui.button {
    padding: 10px 50px;
}

.ui.basic.header-error {
    background: #fff;
    width: 100%;
    padding: 0;
    margin: 0;
    border-bottom: solid 1px #e8eff1;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

.ui.basic.header-error .container {
    height: 77px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ui.button.button-light {
    height: 44px;
    padding: 0 33px;
    font: 400 15px 'Rubik', sans-serif;
    color: #334354;
    background: #fbfcfc;
    border: solid 1px #e8eff1;
    border-radius: 2px;
}

.ui.button.button-light:active {
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.ui.basic.error-body {
    width: 100%;
    float: left;
    height: calc(100vh - 62px);
    padding: 77px 15px 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background: #fff;
    text-align: center;
}

.ui.basic.error-body h5.ui.header {
    font: 300 32.5px 'Rubik', sans-serif;
    text-align: center;
    margin-top: auto;
}

.ui.basic.error-body p {
    font: 300 19px 'Rubik', sans-serif;
}

.ui.basic.error-body .ui.image {
    margin: 40px 0 0;
    margin-bottom: auto;
}

.ui.basic.error-body .ui.image img {
    max-width: 100%;
}

.ui.basic.error-body .footer {
    margin-top: auto;
}

.ui.basic.detail-box {
    background: #f3f3f3;
    width: 100%;
    float: left;
    padding: 15px 20px 0;
    position: relative;
    margin: 0 0 20px;
}

.ui.basic.detail-box .ui.basic.form-group>.content,
.ui.basic.detail-box .ui.basic.form-group>.content p {
    max-width: 100%;
    overflow: hidden;
    word-break: break-word;
}

.ui.button.edit-btn {
    background: none;
    padding: 10px;
    position: absolute;
    right: 10px;
    top: 10px;
    font: 500 13px 'Rubik', sans-serif;
    color: #3b6ffd;
    display: flex;
    align-items: center;
}

.ui.button.edit-btn img {
    width: 14px;
    margin: 0 0 0 5px;
}

.ui.basic.no-result {
    text-align: center;
    padding: 30px 20px;
}

.ui.basic.no-result p {
    width: 100%;
    float: left;
    font: 400 18px 'Rubik', sans-serif;
    color: #646464;
}

.map-container {
    width: 100%;
    height: 100%;
}


.map-container-split-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}


.map-container-split {
    width: 50%;
    height: 100%;
}

.map-container-split:first-child {
    border-right: solid 1px #000;
}

.map-container-split100 {
    width: 100%;
    height: 100%;
}

.map-container-split0 {
    width: 0%;
    height: 100%;
}

.map-edit-active .ol-attribution.ol-uncollapsible {
    right: auto;
}

.map-attribution {
    text-align: center;
    white-space: nowrap;
    padding: 0 5px;
    display: flex;
}

.map-attribution p {
    padding: 0 15px 0 0;
    margin: 0;
    font: 400 12px 'Rubik', sans-serif;
    color: #000;
}

.map-attribution a {
    color: #000;
}

/***** Upload Image Modal ******/

.ui.modal.upload-image-modal {
    width: 616px;
    border-radius: 2px;
    border: solid 1px #d3d2de;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    padding: 0 40px;
    padding-bottom: 40px;
}

.ui.modal.upload-image-modal .aligner {
    padding: 0;
}

.ui.modal.upload-image-modal .aligner>.header {
    margin: 35px 0 15px;
    font: 500 18px 'Rubik', sans-serif;
    color: #222;
}

.ui.modal.upload-image-modal .p-modal {
    font: 400 15px 'Rubik', sans-serif;
    color: #222;
}

.upload-tab-menu .ui.secondary.pointing.menu {
    padding: 0;
    border-bottom: solid 1px #d3d2de;
    margin: 0;
}

.ui.secondary.pointing.menu .item {
    font: 400 15px 'Rubik', sans-serif !important;
    color: #000 !important;
    padding: 7px 0;
    border: none;
    margin: 0 60px -1px 0;
}

.ui.secondary.pointing.menu .active.item {
    box-shadow: inset 0 -3px 0 0 #727aff;
}

.upload-tab-menu>.ui.segment {
    width: 100%;
    float: left;
    min-height: 267px;
    padding: 15px 0;
    margin: 0;
    border: none;
    border-radius: 0;
}

.ui.placeholder.brand-upload {
    border-radius: 0;
    border: dashed 1px #b8b5ce;
    background: #f8f7fa;
    box-shadow: none;
    height: 236px;
    min-height: 0;
}

/* .ui.upload-logo-modal .ui.placeholder.brand-upload{
    width:200px;
    height:200px;
} */

.ui.placeholder.brand-upload .header {
    font: 500 18px 'Rubik', sans-serif;
    color: #a1a1ae;
}

.ui.placeholder.brand-upload .header .sub.header {
    font: 400 15px 'Rubik', sans-serif;
    margin: 20px 0 0;
}

.ui.modal .ui.basic.large.primary.button {
    font: 500 15px/36px 'Rubik', sans-serif !important;
    padding: 5px 34px !important;
    width: auto;
    height: auto;
    max-width: 100%;
    border-radius: 2px !important;
    border-color: #727aff !important;
    color: #727aff !important;
    box-shadow: none;
}

.ui.input.upload-url {
    width: 100%;
    margin-bottom: 15px;
}

.ui.input.upload-url>input {
    background: #f8f7fa;
    border: solid 1px #b8b5ce !important;
    border-radius: 2px;
    height: 47px;
    padding: 0 18px;
    font: 300 15px 'Rubik', sans-serif;
    color: #636364;
}

.ui.input.upload-url>input:focus {
    border: solid 1px #727aff !important;
}

.ui.modal>.actions {
    width: 100%;
    float: left;
    border: none;
    background: none;
    margin: 14px 0 29px;
    padding: 0;
}

.ui.modal.crop-image-modal {
    width: 774px;
    border-radius: 2px;
    padding: 0;
}

.ui.modal.crop-logo-modal {
    width: 550px;
}

.ui.modal.crop-image-modal .aligner {
    padding: 0 40px;
}

.ui.modal.crop-image-modal .aligner>.header {
    margin: 30px 0 15px;
    font-weight: 500;
}

.ui.modal.crop-image-modal.deleteImg {
    padding: 0 40px !important;
}

.ui.modal.crop-image-modal.deleteImg .actions {
    padding: 30px 0 30px !important;
}

.ui.basic.label-crop {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    margin: 0 0 32px;
    padding: 0 20px 0 0;
}

.ui.basic.label-crop>span {
    background: #ebebeb;
    padding: 0 12px;
    height: 34px;
    font: 400 13px/34px 'Rubik', sans-serif;
    color: #000;
    flex: 1;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ui.basic.label-crop .ui.button.link-btn {
    margin: 0 0 0 30px;
}

.ui.basic.label-crop .ui.button.link-btn img {
    max-width: 17px;
}

.ui.modal.crop-image-modal .actions {
    padding: 0 40px;
}

.ui.modal.crop-image-modal .actions .ui.button.link-btn {
    float: left;
    margin: 0;
    white-space: nowrap;
    align-items: center;
}

.ui.button.link-btn {
    min-width: 50px;
    display: flex;
    background: none;
    padding: 0 !important;
    font: 400 15px 'Rubik', sans-serif;
    color: #727aff;
}

.ui.button.link-btn img {
    max-width: 23px;
    margin: 0 5px 0 0;
}

.crop-image-modal.modal .actions .ui.button.btn-undo.btn-undo--mod {
    width: auto;
    min-width: 0 !important;
    float: left;
    background: none;
    padding: 0 !important;
    border-radius: 0;
    height: 28px;
    font: 500 15px 'Rubik', sans-serif !important;
    color: #727aff;
}

.ui.button.btn-undo.btn-undo--mod img {
    float: left;
    height: 17px;
    margin: 0 5px;
}

.divide {
    float: left;
    height: 23px;
    width: 1px;
    margin: 6px 0 0 20px;
    border-right: solid 2px #d3d2de;
}

.ui.basic.crop-bg {
    width: 100%;
    float: left;
    min-height: 360px;
    /* background:#b1b1b1; */
    background: #ccc;
    margin: 0 0 25px;
    position: relative;
}

.ui.basic.crop-bg>div:first-child {
    width: 100% !important;
}

.ui.modal.crop-logo-modal .ui.basic.crop-bg {
    min-height: 260px;
}

.ui.mini.modal {
    width: 400px;
    padding: 0 40px;
    border-radius: 2px;
    border: solid 1px #d3d2de;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    text-align: center;
}

.ui.mini.modal .aligner {
    padding: 0;
}

.ui.mini.modal .aligner>.header {
    margin: 35px 0 25px;
    text-align: left;
}

.ui.modal.missing-floor .actions {
    margin: 30px 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ui.modal.missing-floor .actions .ui.button {
    float: none;
    min-width: 118px;
    margin: 0;
}

.ui.popup.map-building-popup {
    width: 262px;
    padding: 0;
    border-radius: 2px;
    border: solid 1px #d3d2de;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.ui.popup.map-building-popup .ui.close {
    z-index: 5;
}

.ui.popup.map-building-popup .ui.close:before,
.ui.popup.map-building-popup .ui.close:after {
    background-color: #000;
}

.ui.popup.map-building-popup>.ui.image {
    width: 100%;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 3;
}

.ui.popup.map-building-popup>.ui.image img {
    max-width: 100%;
}

.ui.popup.map-building-popup .content {
    width: 100%;
    float: left;
    padding: 15px 20px;
}

.ui.popup .content h4.ui.header {
    font: 500 15px 'Rubik', sans-serif;
    color: #000;
    border: none;
    padding: 0;
    margin: 0 0 5px;
}

.p-popup {
    width: 100%;
    float: left;
    font: 400 13px/22px 'Rubik', sans-serif;
    color: #898d91;
}

.p-popup strong {
    font-weight: 500;
}

.default-flex {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.default-flex>.ui.button {
    margin-right: 10px;
}

.default-flex>div {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ui.basic.modal.finish-modal {
    width: 570px;
    padding: 131px 0 0;
    border: none;
    border-radius: 0;
    text-align: center;
}

.ui.modal.finish-modal>.actions {
    margin-bottom: 5px;
}

.loading-m {
    width: 76px;
    margin-bottom: 50px;
}

/** progress **/

.ui.progress {
    width: 338px;
    border-radius: 100px;
    display: inline-block;
    margin-top: 28px;
}

.ui.progress .bar {
    height: 12px;
    border-radius: 100px;
    background: #dcdfe1;
}

.ui.indicating.progress[data-percent^="100"] .bar,
.ui.indicating.progress[data-percent^="9"] .bar {
    background: #09bd88;
}

.ui.progress .bar>.progress {
    color: #09bd88;
    font: 400 14px 'Rubik', sans-serif;
    top: 100%;
    margin-top: 10px;
}

.ui.modal.delete-crop-modal {
    width: 400px;
    padding: 0 40px;
    border: solid 1px #d3d2de;
}

.ui.modal.delete-crop-modal .aligner {
    padding: 0;
}

.ui.modal.deleteImg .aligner>.header {
    margin: 35px 0 15px;
}

.ui.modal.delete-crop-modal p {
    width: 100%;
    float: left;
    margin: 0 0 10px;
    font: 400 15px 'Rubik', sans-serif;
    letter-spacing: -0.4px;
    color: #333;
}

.ui.modal.delete-crop-modal .actions {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin-bottom: 30px;
}

.ui.modal.delete-crop-modal .actions .ui.button {
    padding: 0 20px;
    margin: 0;
}

.ui.modal.delete-crop-modal .actions .ui.button.grey {
    background: #babbcb;
    color: #fff;
}

.disabled-content strong {
    font-weight: 500;
}

.disabled-content span {
    color: #a31b00;
    display: block;
    margin: 0 0 10px;
}

.ui.button.link-delete {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font: 400 12px 'Rubik', sans-serif;
    color: #727aff;
    z-index: 1;
}

.ui.button.link-delete img {
    width: 12px;
    margin: -1px 3px 0px 0;
}

.ui.label.labelTag {
    float: left;
    border: solid 1px #d3d2de !important;
    border-radius: 100px !important;
    background: #fff !important;
    font: 400 14px 'Rubik', sans-serif !important;
    color: #000 !important;
    padding: 2px 10px !important;
    margin: 0 10px 10px 0 !important;
}

.ui.buttonCross {
    padding: 0;
    background: none;
    box-shadow: none !important;
    border: none;
    border-radius: 0;
    margin: 0 0 0 10px;
}

.ui.label.labelTag .buttonCross img {
    width: 10px;
}


.ui.segment.dimmableLoader {
    border-radius: 0;
    box-shadow: none;
    border: none;
    padding: 0;
}

.archibusLogo {
    width: 100%;
    float: left;
    margin: 10px 0 20px;
    padding: 10px;
    border: solid 1px #B8B5CE;
    border-radius: 3px;
    text-align: center;
}

.archibusLogo img {
    display: inline-block !important;
}

.archibusModal .actions .ui.button,
.archibusInactive .ui.button,
.archibusModalFinish .ui.button {
    min-width: 107px;
    height: 40px;
    margin: 0;
    border-radius: 3px;
}

.archibusModalFinish .actions .ui.button.grey,
.archibusInactive .ui.button {
    background: #667581;
}

.archibusModalFinish {
    text-align: center;
}

.archibusInactive {
    text-align: center;
}

.ui.modal .archibusInactive .ui.aligned.header,
.ui.modal .archibusModalFinish .ui.aligned.header {
    border: none;
}

.ui.modal .archibusInactive .ui.aligned.header .content,
.ui.modal .archibusModalFinish .ui.aligned.header .content {
    margin: 25px 0 10px;
}

.archibusInactive .p-modal {
    width: 100%;
    float: left;
}

.ui.image.archibusFinishIcon {
    background: #EAF9F0;
    width: 78px !important;
    height: 78px !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
}

.ui.image.archibusFinishIcon img {
    margin: 0;
}

.ui.tagLabel {
    width: auto;
    height: 29px;
    display: inline-block;
    position: relative;

    border-radius: 2px;
    background: #F3F6FB;
    padding: 0 30px 0 7px;
    margin: 0 5px 3px 0;
    font: 400 14px/29px 'Rubik', sans-serif;
    color: #000;

    max-width: 190px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ui.tagLabel .ui.buttonIcon {
    position: absolute;
    right: 8px;
    top: 7px;
}

.ui.tagLabel .ui.buttonIcon img {
    width: 9px;
}

.dividerLine {
    width: 100%;
    float: left;
    height: 1px;
    margin: 0 0 20px;
    border-bottom: solid 1px #D3D2DE;
}

.dividerLine.isEmpty {
    border: none;
}

.ui.grid.analyticsMsgGrid {
    opacity: 1;
    margin-top: 16px;
}

.ui.grid.withoutLinkHeader {
    margin-top: 22px;
}

.analyticsDataMissingMsg {
    width: 100%;
    text-align: center;
    font-size: medium;
    font-weight: 600;
}

.analyticsDataPlaceHolder {
    margin-top: 4rem;
    max-height: 8rem;
    width: 100%;
}

.ui.image-block {
    width: 70px;
    height: 70px;
    top: 0 !important;
    left: 0 !important;
}

.image-block-bx {
    width: 70px;
    height: 70px;
    position: absolute;
    left: 22px;
    top: 138px;
    z-Index: 3;
}

.image-block-bx--mod {
    top: 90px;
}

.image-block-bx .ui.placeholder.display-image.image-block-placeholder {
    position: absolute;
    left: 0;
    top: 0;
}

.locationIdDisplay {
    background-color: #dddddd;
    padding: 2px;
    border-radius: 2px;
    width: 44px;
    text-align: center
}

.locationDropdownItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.Toastify__close-button{
    align-self: center !important;
    display: flex;
}

.Toastify__toast{
    min-height: auto !important;
    gap: 4px;
}