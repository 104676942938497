.images-sort-selector {
    display: flex;
    align-items: center;
    gap: 8px;

}

.images-sort-selector-title {
    font: 500 14px "Rubik", sans-serif;
    flex-shrink: 0;
}


.images-sort-selector .gms-dropdown {
   width: 147px;
}
