.keyword-section-subtitle {
    padding: 20px 0px;
    width: 319.33px;
    height: 17px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    color: #667581;
  }
  
  .keyword-section-header{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  
  .keyword-section-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    padding: 10px;
    margin-top: 5px;
    height: 550px;
    width: 78.7%;
  }
  
  .keyword-section-no-categories{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    padding: 10px;
    margin-top: 20px;
    height: 550px;
  }
  
  
  .keyword-section-add-btn {
    width: 130px;
    height: 40px;
    background-color: #E75330; 
    color: white;
    font-family: 'Rubik', sans-serif; 
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 4px; 
    cursor: pointer; 
    display: inline-block; 
    text-align: center; 
    line-height: 40px; 
    padding: 0 20px; 
  }
  
  .keyword-section-no-data .ui.image img,
  .keyword-section-no-data .ui.image svg {
    display: block;
    max-width: 50px; 
    height: 50px;   
    margin-bottom: 16px; 
  }
  
  .keyword-section-no-data{
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;
    margin: auto;
  }
  
  .keywordSection-cateogory {
    margin-top: 20px;
    width: 460px;
    border: 1px solid;
    padding: 15px;
  }
  
  
  /* .groupCategoryCover > .keywordCategoryItemRoot:not(:first-child) {
    padding: 10px;
  } */
  .keywordCategoryItemRoot:not(:first-child) {
    padding-top: 10px;
  }
  
  .keywordCategoryItem-content.active {
    background: #EFEFEF;
    border-radius: 5px;
  }
  .keywordCategoryItemRoot-icon {
    border-radius: 30px;
    width: 25px;
    height: 23px;
  }
  
  .keywordCategoryItem-name {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
  }
  
  .keywordCategoryItemRoot .keywordCategoryItem-content {
    display: flex;
    gap: 10px;
    width: calc(100% - 15px);
    padding: 10px;
    cursor: pointer;
  }

  .keywordCategoryItem-name p {
    margin: 0;
  }
  
  .keyword-subCategoryCover {
    padding-left: 20px;
    margin-top: 5px;
  }
  
  .keyword-chevron-icons {
    margin-left: auto !important;
  }
  .keyword-management-container {
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    margin-top: 5px;
    width: 78.7%;
  }
  
  .keyword-management {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    border-bottom: 3px solid #e0e0e0;
  }
  
  .assigned-keywords-wrapper {
    background-color: #F7F8FA;
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: 46%;
    height: 100%;
  
  }
  
  .keyword-arrow-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  
  
  .keyword-arrow-button {
    background-color: rgba(239, 240, 255, 1) !important;
    border-radius: 8px;
    width: 40px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 1px solid #e5e5ff; /* Subtle border */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
    color: 757DFF;
  }
  
  .keywordHeading {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    margin: 10px;
    color: #000000;
  }
  .keywordSection-category{
    margin-top: 5px;
    width: 360px;
    border: 1px solid;
    padding: 15px;
    height: 550px;
  }
  
  .keywords-subTitle {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 15.5px;
    line-height: 20px;
    color: #667581;
    margin-bottom: 10px;
    height: 20px;
  }
  
  .keyword-separator {
    margin: 5px 0;
    width: 80%;
    border: 0.89px solid #CFD6EA;
  }
  
  .keyword-item-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  
  .keyword-warning-text {
    color: #E75330 !important;
    font-size: 15px;
    font-family: 'Rubik';
    margin-left: 10px;
    font-weight: 500;
  }
  
  /* Update button style */
  
  .button-container{
    margin: 10px;
    display: flex;
    justify-content: flex-end;
  }
  
  .ui.red.basic.button {
    border: 1px solid #E75330 ;  
    color: #E75330 ;
    padding: 10px 44px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    margin-right:3%;
  }
  
  .groupCategoryItemButton>p {
    font: 400 14px 'Rubik', sans-serif;
    padding: 0px 10px 0 0;
    margin: 0;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
    color: #667090;
  }
  .keywordCategoryItemRoot {
    cursor: pointer;
  }  
  
  .header-section{
    display: flex;
    align-items: center;
    width: 36%;
    justify-content: flex-start;
  }
    
  .header-text{
    width: 20%;
  }
      
  .header-right {
    justify-content: flex-end;
    gap: 10px;
  }