.confirmation-modal.ui.modal{
  width: 329px;
  font-family:Rubik, sans-serif;
  padding: 24px ;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}



.confirmation-modal.ui.modal .confirmation-modal-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: inherit;
    justify-content: center;
    font-family: "Rubik";
    text-align: center;
    gap: 8px;
    padding: 0;
      font-family:Rubik, sans-serif;
    padding-bottom: 16px;
    font-weight: 600;
    font-size: 18px;
}

.confirmation-modal.ui.modal .confirmation-modal-header .confirmation-modal-header-icon-bx{
    padding: 8px;
    display: flex;
    border-radius: 50%;
    border:  1px solid #F15944;
}

.confirmation-modal.ui.modal .confirmation-modal-content{
    padding-top: 16px;
    text-align: center;
    padding-bottom: 24px;
    color: black;
    font-family: inherit;
} 

.confirmation-modal.ui.modal .confirmation-modal-actions{
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: inherit;
}

.confirmation-modal.ui.modal .confirmation-modal-actions .ui.button{
    font: 16px "Rubik";
    padding: 10px 32px;
    border-radius: 6px;
    font-weight: 500;
}

.confirmation-modal.ui.modal .close{
    top: 0px;
    right: 0px;
}