.branding-map {
    width: 100%;
    float: left;
    height: inherit;
    overflow: hidden;
    z-index: 0;
    bottom: 0;
    position: fixed;
}

.maintenance-map {
    /* width: 95%;
    height: calc(100vh - 110px);
    float: left;
    overflow: hidden;
    z-index: 0;
    position: fixed; */

    width: 100%;
    float: left;
    height: calc(100vh - 109px);
    bottom: 0;
    overflow: hidden;
    z-index: 0;
    position: fixed;
}

.styles-themes-map {
    width: 100%;
    float: left;
    height: calc(100vh - 110px);
    overflow: hidden;
    z-index: 0;
    bottom: 0;
    position: fixed;
}

.ui.basic.segment.subheader .subheader-left .ui.label.dropdown-submenu:last-child {
    border: none;
}

/* radio */

.ui.radio.checkbox label:before {
    width: 20px;
    height: 20px;
    border: solid 1px #000;
    border-radius: 4px;
}

.ui.radio.checkbox input:checked~label:before {
    border-color: #c3ff9c;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* .ui.radio.checkbox input:checked~label:after {
    background: url(/img/icon-checkbox.svg) no-repeat 3px 4px;
    background-size: 11px auto;
    background-color: #21d558!important;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    transform: scale(1);
    top: 2px;
    left: 1px;
} */

.ui.checkbox.building-check {
    width: 100%;
    float: left;
    font: 400 14px/20px 'Rubik', sans-serif;
    margin: 0 0 24px;
}

.ui.checkbox.building-check-gap {
    margin-bottom: 35px;
}

.ui.checkbox.building-check label {
    line-height: 22px;
    color: #828e97;
}

/* Toolbar ends */

.ui.segment.sidebar-box {
    background: #fff;
    width: 251px;
    padding: 0;
    border-right: solid 1px #c0cbda;
    border-top: none;
    top: 110px;
    z-index: 40;
}

.ui.basic.settings-layer-box {
    width: 287px;
    height: calc(100vh - 109px);
    border: none;
    border-radius: 0;
    border-right: solid 1px #c0cbda;
    left: 251px;
    top: 110px;
    position: fixed;
    background: #f3f6fb;
    z-index: 2;
    margin: 0;
    padding: 0;
}

.ui.button.toggle-sidebar {
    background: #fff;
}

.ui.sidebar-box>h3.ui.header {
    width: 100%;
    float: left;
    background: #f3f6fb;
    height: 40px;
    margin: 0;
    font: 500 15px 'Rubik', sans-serif;
    color: #000;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 1px 3px #ced3e2;
}

.ui.sidebar-box>h3.ui.header .notify-back {
    position: absolute;
    right: 10px;
    top: 7px;
}

.sidebar-tab {
    width: 100%;
    float: left;
}

.sidebar-tab .ui.secondary.pointing.menu {
    display: flex;
    border: none;
    margin: 0;
}

.sidebar-tab .ui.secondary.pointing.menu .item {
    flex: 1;
    font: 500 12px 'Rubik', sans-serif;
    color: #b1b6bb;
    padding: 10px 0;
    margin: 0;
    display: flex;
    justify-content: center;
    border-bottom: solid 3px #f3f6fb;
}

.sidebar-tab .ui.secondary.pointing.menu .active.item,
.sidebar-tab .ui.secondary.pointing.menu .active.item:hover {
    border-color: #757dff;
    color: #000;
}

.sidebar-tab>.ui.segment.tab {
    padding: 0 1px 0 0;
    margin: 0;
    border: none;
}

.sidebar-tab .ui.segment.aligner {
    padding: 0 11px;
}

.ui.basic.map-styles {
    padding: 0;
    margin: 0 0 25px;
    overflow: hidden;
    border: solid 1px #c0cbda;
    border-radius: 2px;
    position: relative;
}

.ui.basic.map-styles.active {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.ui.basic.map-styles:first-child {
    margin-top: 25px;
}

.ui.basic.map-styles .ui.checkbox {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 5;
}

.ui.basic.map-styles .ui.header {
    font: 500 14px 'Rubik', sans-serif;
    margin: 20px 0 8px;
    padding: 0 10px;
}

.ui.basic.map-styles p {
    font: 400 13px/18px 'Rubik', sans-serif;
    margin: 0 0 10px;
    padding: 0 8px 0 10px;
}

/* Map layers */

.ui.basic.layer-default {
    width: 100%;
    float: left;
    padding: 25px 0 0;
    margin: 0;
    border-bottom: solid 11px #f4f7fc;
}

.ui.basic.layer-default:last-child {
    border: none;
}

.ui.basic.layer-default>h4.ui.header {
    font: 500 12px 'Rubik', sans-serif;
    text-transform: uppercase;
    margin: 0 0 14px;
}

.ui.basic.layer-default>h4.ui.header span {
    display: block;
    color: #667581;
    font-size: 13px;
    font-weight: 400;
    text-transform: initial;
    margin: 6px 0 0;
}

.ui.button.layer-buttons {
    width: 100%;
    float: left;
    padding: 7px 0;
    margin: 0 0 2px;
    background: none;
    border-radius: 0;
    text-align: left;
    font: 400 14px 'Rubik', sans-serif;
    color: #000;
    transition: all .3s ease;
    position: relative;
    display: flex;
    align-items: center;
}

.ui.button.layer-buttons:last-child {
    margin: 0 0 5px;
}

.custom-icon {
    width: 15px;
    min-width: 15px;
    margin: -1px 10px 0 0;
    position: relative;
}

.custom-icon.bars {
    height: 2px;
    background: #000;
}

.custom-icon.bars:before,
.custom-icon.bars:after {
    content: '';
    width: 100%;
    height: 2px;
    background: #000;
    position: absolute;
    left: 0;
}

.custom-icon.bars:before {
    top: -4px;
}

.custom-icon.bars:after {
    bottom: -4px;
}

.ui.button.layer-buttons>img {
    width: 15px;
    margin: 0 10px 0 0;
}

.ui.button.layer-buttons .layer {
    min-width: 20px;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color: #cccbbf;
    border: solid 1px #8a8984;
    margin: 0 10px 0 0;
}

.ui.button.disabled.layer-buttons {
    background: #e5edf9;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2) !important;
    opacity: 1 !important;
    padding: 7px 5px;
}

.ui.button.disabled.layer-buttons:after {
    content: '';
    width: 18px;
    height: 9px;
    background: url(/img/icon-blue-eye.svg) no-repeat;
    background-size: 18px auto;
    position: absolute;
    right: 5px;
    bottom: 5px;
}

/* settings layer box */

.settings-layer-box h5.ui.header,
.settings-layer-box h6.ui.header {
    width: 100%;
    float: left;
    font: 400 14px 'Rubik', sans-serif;
    letter-spacing: -0.5px;
    display: flex;
    align-items: center;
}

.settings-layer-box h5.ui.header {
    margin: 20px 0 0;
    padding: 0 13px;
}

.settings-layer-box h5.ui.header>strong {
    font-size: 16px;
    font-weight: 500;
}

.settings-layer-box h5.ui.header>strong>span {
    font-size: 14px;
    font-weight: 400;
    margin-left: 4px;
}

.settings-layer-box h5.ui.header>img {
    height: 14px;
    width: auto;
    margin: 0 5px 0 0;
}

.ui.button.resetBtn {
    border-radius: 0;
    padding: 0;
    margin-left: auto;
    background: none;
    opacity: 0.4;
}

.ui.button.resetBtn img {
    width: 15px;
}

.ui.button.resetBtn:hover {
    opacity: 1;
}

.ui.popup.top.center.reset-popup,
.ui.popup.bottom.center.reset-popup {
    left: 33px !important;
}

.ui.popup.top.center.reset-popup-big,
.ui.popup.bottom.center.reset-popup-big,
.ui.popup.top.center.reset-popup,
.ui.popup.bottom.center.reset-popup {
    padding: 2px 15px 4px;
    border-radius: 3px;
}

.settings-layer-box h6.ui.header {
    width: 100%;
    float: left;
    margin: 0 0 5px;
}

.settings-layer-box h6.ui.header .heading {
    padding: 0 0 2px;
    display: flex;
    align-items: center;
}

.settings-layer-box h6.ui.header .heading i.icon {
    font-size: 15px;
    margin: 0 0 0 6px;
    cursor: pointer;
}

.settings-layer-box h6.ui.header .heading>.span {
    margin-left: 10px;
}

.settings-layer-box h6.ui.header .heading.stroked {
    padding: 0 0 5px;
    border-bottom: solid 1px #d3d2de;
}

.layer-section {
    width: 100%;
    float: left;
    padding: 0 0 25px;
}

.layer-section.bordered {
    border-bottom: solid 1px #d3d2de;
    margin: 0 0 25px;
}

.layer-section.noGap {
    padding-bottom: 5px;
}

.layer-section:first-child {
    bottom: 0;
}

.settings-layer-box .ui.segment.aligner,
.settings-layer-box .aligner {
    padding: 0 13px;
}

.layer-section h6.ui.header .ui.button.resetBtn {
    padding: 5px;
}

.layer-section h6.ui.header .ui.button.resetBtn img {
    width: 11px;
}

.ui.input.search-font,
.ui.dropdown.dropdown-font {
    width: 100%;
    float: left;
}

.ui.dropdown.dropdown-font .default {
    margin: 0;
}

.ui.input.search-font>input,
.ui.basic.size-settings-box,
.font-scroll-box,
.ui.dropdown.dropdown-font,
.pattern-cover,
.symbol-cover,
.ui.button.visibility.active,
.ui.input.search-symbol div.label {
    width: 100%;
    background: #fff;
    border: solid 1px #d3d2de;
    border-radius: 2px;
}

.ui.input.search-font>input,
.ui.dropdown.dropdown-font>input.search {
    height: 33px;
    padding-left: 10px;
    font: 400 14px 'Rubik', sans-serif;
    color: #667581;
}

.ui.input.search-font>input:focus {
    border-color: #757dff;
}

.ui.basic.size-settings-box {
    float: left;
    min-height: 33px;
    padding: 4px 10px 4px 8px;
    margin: 0;
    display: flex;
    align-items: center;
}

.ui.basic.size-settings-box>p {
    margin: 0;
    font: 400 14px 'Rubik', sans-serif;
    color: #667581;
}

.colors-cover {
    width: 100%;
    float: left;
    margin: 15px 0 0;
}

.settings-layer-box h6.ui.header .heading .span,
.ui.button.colors {
    width: 20px;
    height: 20px;
    padding: 0;
    border-radius: 4px;
    border: solid 1px #fff;
}

.ui.button.colors {
    float: left;
    margin: 0 10px 10px 0;
    position: relative;
}

.ui.button.color1,
.span.color1 {
    background-color: #000000;
}

.ui.button.color2,
.span.color2 {
    background-color: #363636;
}

.ui.button.color3,
.span.color3 {
    background-color: #626262;
}

.ui.button.color4,
.span.color4 {
    background-color: #898989;
}

.ui.button.color5,
.span.color5 {
    background-color: #acacac;
}

.ui.button.color6,
.span.color6 {
    background-color: #d7d7d7;
}

.ui.button.color7,
.span.color7 {
    background-color: #ebebeb;
}

.ui.button.color8,
.span.color8 {
    background-color: #ffffff;
}

.ui.button.color9,
.span.color9 {
    background-color: #f09300;
}

.ui.button.color10,
.span.color10 {
    background-color: #f40000;
}

.ui.button.color11,
.span.color11 {
    background-color: #e2008d;
}

.ui.button.color12,
.span.color12 {
    background-color: #023473;
}

.ui.button.color13,
.span.color13 {
    background-color: #0073be;
}

.ui.button.color14,
.span.color14 {
    background-color: #00a64d;
}

.ui.button.color15,
.span.color15 {
    background-color: #4eb543;
}

.ui.button.color16,
.span.color16 {
    background-color: #ffd800;
}

.ui.button.colors:before {
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    left: -3px;
    top: -3px;
    border-radius: 6px;
    border: solid 2px #f3f6fb;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    display: none;
}

.ui.button.colors:hover:before,
.ui.button.colors.selected:before {
    display: block;
}

.ui.button.linked {
    font: 500 14px 'Rubik', sans-serif;
    color: #757dff;
    text-decoration: underline;
    padding: 3px 0;
    border-radius: 0;
    background: none;
}

.ui.button.linked:hover {
    color: #000;
}

.font-scroll-box {
    float: left;
    margin: 15px 0 0;
    padding: 5px;
}

/** Color Picker **/

.ui.basic.segment.color-picker-portal {
    width: 300px;
    padding: 20px;
    background: #f3f6fb;
    border: solid 1px #c0cbda;
    box-shadow: 0 4px 9px rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 20%;
    left: 50%;
    margin-left: -150px;
    z-index: 1000;
}

.ui.basic.segment.color-picker-portal>.ui.header {
    font: 400 14px 'Rubik', sans-serif;
    color: #000;
    margin: 0 0 10px;
}

.flex-picker {
    width: 100%;
    float: left;
    display: flex;
}

.flex-picker-left {
    min-width: 137px;
    width: 137px;
}

.flex-picker-right {
    flex: 1;
    padding-left: 12px;
}

.flex-picker-right b {
    float: left;
    font: 500 14px 'Rubik', sans-serif;
    color: #667581;
    margin: 0 0 10px;
}

.custom-picker {
    width: 100%;
    float: left;
    margin: 0 0 10px;
    border: solid 1px #d3d2de;
    border-radius: 2px;
    overflow: hidden;
}

/** delete later **/

.custom-picker img {
    float: left;
}

/** ends **/

.history-colors {
    width: 100%;
    float: left;
    display: flex;
}

.history-colors>div {
    flex: 1;
    text-align: center;
    font: 400 13px 'Rubik', sans-serif;
    color: #000;
}

.history-colors>div span {
    width: 100%;
    float: left;
    height: 26px;
    margin: 0 0 1px;
    border: solid 1px #d3d2de;
}

.history-colors>div:first-child span {
    border-right: none;
}

.flex-picker-right .ui.basic.segment.form-group {
    margin: 0 0 5px;
    padding: 0;
}

.flex-picker-right .ui.basic.segment.form-group>p {
    width: 19px;
    margin: 0;
    font: 400 14px 'Rubik', sans-serif;
    color: #000;
}

.flex-picker-right .ui.basic.segment.form-group .ui.input>input {
    width: 100%;
    height: 28px !important;
    padding: 0 0 0 9px;
    border-color: #d3d2de;
    font: 400 14px 'Rubik', sans-serif;
    color: #667581 !important;
    opacity: 1 !important;
}

.flex-picker-right .ui.basic.segment.form-group .ui.input>input:focus {
    border-color: #727aff;
}

.flex-picker-right .ui.basic.segment.form-group .ui.input>input::placeholder {
    color: #667581 !important;
    opacity: 1 !important;
}

.flex-picker-right .ui.basic.segment.form-group {
    margin: 0 0 5px;
    padding: 0;
}

.ui.basic.segment.form-group input:not(:focus)[type=number] {
    -moz-appearance: textfield !important;
}

/* Layer Tabs */

.layer-tab {
    width: 100%;
    float: left;
}

.layer-tab .ui.secondary.pointing.menu {
    border-bottom: solid 1px #d3d2de;
    margin: 0 13px;
}

.layer-tab .ui.secondary.pointing.menu .item {
    font: 400 14px 'Rubik', sans-serif !important;
    color: #000 !important;
    padding: 3px 0;
    margin: 0 30px -1px 0;
    border-bottom: solid 1px #d3d2de;
    box-shadow: none !important;
}

.store-tab .ui.secondary.pointing.menu {
    margin: 0 0 20px;
    min-height: 0;
}

.store-tab .ui.secondary.pointing.menu .item {
    font: 400 13px 'Rubik', sans-serif !important;
    margin: 0 20px -1px 0;
}

.layer-tab .ui.secondary.pointing.menu .active.item,
.layer-tab .ui.secondary.pointing.menu .active.item:hover {
    border-color: #000;
    font-weight: 500 !important;
}

.store-tab .ui.secondary.pointing.menu .active.item,
.ui.secondary.pointing.menu .active.item:hover {}

.layer-tab>.ui.segment.tab {
    padding: 0 1px 0 0;
    margin: 0;
    border: none;
    background: none;
}

.layer-tab .ui.basic.aligner {
    margin-top: 25px;
}

.store-tab {
    margin-top: 0;
}

.pattern-cover,
.symbol-cover {
    width: 100%;
    float: left;
    margin: 15px 0 0;
    padding: 12px 6px 6px 12px;
}

.ui.button.pattern,
.ui.button.symbols {
    float: left;
    margin: 0 6px 6px 0;
}

.ui.button.pattern {
    background: #fff;
    border-radius: 0;
    padding: 5px;
    margin: 0 6px 6px 0;
}

.ui.button.pattern img {
    float: left;
    width: 63px;
    border: solid 1px #fff;
}

.ui.button.pattern.selected {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.ui.button.pattern.selected img {
    border: solid 1px #ebebeb;
}

.symbol-cover {
    padding: 14px 6px 6px 12px;
}

.ui.button.symbols {
    width: 28px;
    min-width: 28px;
    height: 28px;
    padding: 0;
    margin: 0 10px 12px 0;
    background: #b9c7de;
    border: solid 3px #fff;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ui.button.symbols img {
    float: left;
    height: 10px;
    filter: invert(100);
}

.ui.button.symbols.selected {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    background: #e97247;
}

.ui.button.visibility {
    float: left;
    background: none;
    width: 34px !important;
    height: 34px;
    border: solid 1px #f3f6fb;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
}

.ui.button.visibility:hover,
.ui.button.visibility.active {
    opacity: 1;
}

.ui.button.visibility img {
    width: 18px;
}

.default-flex {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
}

.ui.input.search-symbol div.label {
    width: 33px;
    min-width: 33px;
    height: 33px;
    margin: 0 10px 0 0;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ui.input.search-symbol span img {
    height: 9px;
}

span.ui.button.symbols {
    min-width: 19px;
    width: 19px;
    height: 19px;
    padding: 0;
    margin: 0;
    border: none;
    background: #e97247;
}

.ui.input.search-symbol div.label,
.ui.input.search-symbol>input {
    border-radius: 2px !important;
    border: solid 1px #d3d2de !important;
}

.ui.input.search-symbol>input {
    width: auto;
}

.ui.input.search-symbol>input:focus {
    border-color: #757dff !important;
}

.ui.popup.reset-popup-big {
    width: 313px;
    max-width: 313px;
    border-radius: 2px;
    border: solid 1px #d3d2de;
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.2);
    padding: 25px 30px;
}

.ui.popup.reset-popup-big:before {
    box-shadow: none !important;
}

.ui.popup.reset-popup-big p {
    font: 400 15px/20px 'Rubik', sans-serif;
    color: #000;
}

.ui.popup.reset-popup-big .actions {
    display: flex;
    padding: 6px 0 0;
}

.ui.popup.reset-popup-big .actions .ui.button {
    min-width: 98px;
    float: left;
    margin: 0 20px 0 0;
    color: #fff;
}

.ui.popup.reset-popup-big .actions .ui.button.grey {
    background: #babbcb;
}

/** EditSidebar **/

.ui.basic.store-detail-box {
    width: 420px;
    height: calc(100vh - 109px);
    padding: 0;
    margin: 0;
    background: #fff;
    box-shadow: -3px 10px 12px rgba(0, 0, 0, 0.2);
    position: fixed;
    right: 0;
    top: 109px;
    z-index: 4;
}

.ui.header.portal-header {
    width: 100%;
    float: left;
    margin: 0;
    padding: 0 10px 0 44px;
    background: #fff;
    border-bottom: solid 3px #f5f6f6;
    height: 45px;
    font: 400 15px/40px 'Rubik', sans-serif;
    color: #000;
    position: relative;
    display: flex;
    align-items: center;
}

.ui.header.history-header {
    font: 400 15px 'Rubik', sans-serif;
    color: #000;
    background: none;
    padding: 10px 10px 10px 40px;
    margin: 0 0 15px;
    display: flex;
    align-items: center;
    border-bottom: solid 3px #f5f6f6;
}

.ui.button.back-arrow {
    position: absolute;
    left: 15px;
    top: 10px;
    padding: 5px;
    background: none;
}

.ui.button.back-arrow img {
    margin: 0;
    width: 15px;
    transform: rotate(180deg);
}

.ui.basic.store-detail-box .aligner {
    padding: 0 22px;
    position: relative;
    margin-top: 12px;
}

.ui.basic.default-section {
    width: 100%;
    float: left;
    padding: 0 0 15px;
    margin: 0 0 20px;
    border-bottom: solid 11px #f4f7fc;
}

.ui.basic.default-section:last-child {
    border: none;
}

.ui.basic.default-section:last-child {
    border: none;
}

.ui.basic.store-detail-box .ui.dropdown>i.icon {
    position: absolute;
    right: -17px;
    color: #4d4e65;
    width: auto;
}

.ui.basic.default-section>.ui.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font: 500 18px 'Rubik', sans-serif;
    margin: 0 0 18px;
}

.ui.button.button-more {
    float: left;
    width: auto;
    display: flex;
    align-items: center;
    background: none;
    padding: 0;
    font: 400 13px 'Rubik', sans-serif;
    color: #727aff;
}

.ui.button.button-more img {
    width: 20px;
    margin: 0 5px 0 0;
}

.ui.button.button-more:hover {
    text-decoration: underline;
}

.ui.basic.default-section>.ui.orange.button {
    float: right;
    margin: 15px 0 0;
}

.ui.button.undoAction {
    padding: 0;
    margin: 0 0 0 10px;
    display: inline-block;
    font: 400 12.5px 'Rubik', sans-serif;
    color: #000;
    background: none;
    pointer-events: none;
}

.ui.button.undoAction img {
    height: 14px;
    margin: 5px 5px -2px 0;
}

.ui.button.undoAction:nth-last-child(2) {
    margin-left: auto;
}

.ui.button.undoAction.active {
    pointer-events: initial;
}

.ui.button.undoAction.active img {
    opacity: 1;
}

.ui.popup.branding-popup {
    min-width: 0;
    width: 737px;
    max-width: 737px;
    border-radius: 2px;
    border: solid 1px #d3d2de;
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.3);
    padding: 40px 0 20px;
}

.ui.popup.branding-popup .aligner {
    padding: 0 40px;
}

.para,
.para-line {
    color: #000;
    font: 400 15px/22px 'Rubik', sans-serif;
}

.para-line {
    width: 100%;
    float: left;
    font-size: 14px !important;
    margin: 12px 0 25px;
}

.para strong,
.para-line strong {
    font-weight: 500;
}

.para a,
.para-line a,
.description-box p a {
    color: #727aff;
    font-weight: 500;
}

.para a:hover,
.para-line a:hover,
.description-box p a:hover {
    text-decoration: underline;
}

.ui.button.orange.large.big-save {
    float: right;
    margin: 0 0 20px;
    padding: 0 20px;
    width: 114px;
    height: 40px;
    font: 500 16.5px/40px 'Rubik', sans-serif;
    border-radius: 4px;
}

.ui.button.edit-icon-inside {
    position: absolute;
    right: 22px;
    top: 0px;
    z-index: 10;
}

.ui.button.lockToggle {
    padding: 0;
    min-width: 20px;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background: url(/img/icon-unlocked.svg) no-repeat center #fff;
    border: solid 1px #d3d2de;
}

.ui.mini.unlock-modal {
    width: 410px;
}

.lastUpdated {
    margin: 3px auto 0;
    font: 400 13px 'Rubik', sans-serif;
    color: #999;
    padding-right: 32px;
}

.ui.buttonIcon.buttonOutside {
    position: fixed;
    right: 20px;
    top: 126px;
    z-index: 1000;
}

.ui.popup.shareStorePopover {
    font: 400 13px/18px 'Rubik', sans-serif;
    border-radius: 2px;
    padding: 10px;
    box-shadow: 0 0 12px rgba(0, 0, 0, .1);
}

.ui.unlock-modal .aligner>.header {
    text-align: center !important;
}

.ui.unlock-modal>.actions {
    text-align: center;
    margin-top: 20px;
}

.ui.unlock-modal>.actions .ui.button {
    display: inline-block;
    margin: 0;
    min-width: 100px;
    height: 36px;
    padding: 0 20px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 15px;
    line-height: 36px;
    text-align: center;
}

.ui.popup.smallPopup,
.customPopup {
    min-width: 200px;
    padding: 17px 20px 20px;
    border-radius: 2px;
    border: solid 1px #d3d2de;
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.2);
}

.ui.popup.smallPopup .ui.close,
.customPopup .ui.close {
    right: 5px;
    top: 5px;
    padding: 0;
    padding: 0;
}

.ui.popup.smallPopup .ui.primary.button,
.ui.popup.smallPopup .ui.secondary.button,
.customPopup .ui.primary.button,
.customPopup .ui.secondary.button {
    min-width: 62px;
    width: auto;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    font: 400 11.85px 'Rubik', sans-serif;
    color: #fff;
    border-radius: 2px;
}

.ui.popup.smallPopup .header,
.customPopup .header {
    width: 100%;
    float: left;
    padding: 0 0 6px;
    font: 500 15px 'Rubik', sans-serif;
    text-align: center;
    color: #000;
    border-bottom: solid 1px #d3d2de;
    margin: 0 0 15px;
}

.ui.popup.storePopup,
.customStore {
    width: 222px;
    max-width: 222px;
}

.ui.popup.storePopup .actionsGroup,
.customStore .actionsGroup {
    justify-content: center;
}

.disabledLayer:before {
    content: "";
    background: rgba(0, 0, 0, 0.3);
    width: 500vh;
    height: 500vh;
    position: absolute;
    top: -250vh;
    left: -250vh;
    bottom: 0;
    right: 0;
    z-index: 0;
    pointer-events: none;
}

.ui.popup.storePopup {
    width: 222px;
    max-width: 222px;
}

.ui.popup.rotatePopup,
.customRotate {
    max-width: 240px;
}

.customRotate .actionsGroup .ui.button {
    height: 20px !important;
    width: auto;
    border-radius: 2px;
    font: 400 13px/19px 'Rubik', sans-serif;
    padding: 0 15px;
}

.customRotate label{
    max-width: 170px;
    white-space: initial;
    overflow: hidden;
    text-overflow: ellipsis;
}


.zIndex {
    z-index: 10 !important;
}

.customPopup {
    background: #fff;
    position: absolute;
    bottom: auto;
    top: 0;
    left: 0;
    z-index: 100;
}

.customPopup:before {
    content: '';
    background: #fff;
    position: absolute;
    top: auto;
    right: auto;
    bottom: -5px;
    left: 50%;
    width: 10px;
    height: 10px;
    margin-left: -2.5px;
    transform: rotate(45deg);
    box-shadow: 1px 1px 0 0 #bababc;
}

.customPopup > .ui.ui.checkbox{
    line-height: 21px !important;
}

.ui.basic.rotateGroup>.content>div:first-child {
    width: 34px;
    margin: 0 10px 0 0;
    position: relative;
}

.ui.basic.rotateGroup>.content>div:first-child:before {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background: #000;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -4px 0 0 -2px;
}

.ui.basic.rotateGroup>.content>div>div>svg {
    color: #737bff;
    transform: scale(0.4);
}

.ui.basic.rotateGroup>.content>div>div>svg>circle:first-child {
    display: none;
}

.ui.basic.rotateGroup>.content>div>div>svg>circle {
    fill: #737bff;
}

.ui.basic.rotateGroup input {
    font: 400 14px 'Rubik', sans-serif;
    color: #000;
    width: 61px;
    height: 22px;
    border: solid 1px #d3d2de !important;
    padding: 0 0 0 5px !important;
    min-width: 0px;
}

.ui.basic.rotateGroup .ui.input {
    width: auto;
}

.ui.basic.rotateGroup input:focus {
    border-color: #757dff !important;
}

.ui.basic.rotateGroup>p {
    color: #000;
}

.ui.basic.dropdownFlexed {
    display: inline-block;
    margin-bottom: 30px;
}

.ui.dropdownFlexed p {
    width: 100% !important;
    float: left;
    margin-bottom: 10px !important;
    font: 400 15px 'Rubik', sans-serif;
    color: #667581;
}

.ui.dropdownFlexed>.content {
    width: 100%;
    float: left;
}

.ui.dropdownFlexed .ui.search.dropdown {
    padding: 0 !important;
    height: 20px;
    min-height: 0 !important;
    min-width: auto;
}

.ui.dropdownFlexed .ui.search.dropdown i.icon {
    display: none;
}

.ui.dropdownFlexed .ui.search.dropdown input {
    padding: 0 !important;
    box-shadow: none !important;
    font: 400 13px 'Rubik', sans-serif !important;
}

.ui.dropdownFlexed .ui.search.dropdown input::placeholder {
    color: #7f7f7f !important;
    opacity: 1 !important;
}

.ui.dropdownFlexed .ui.search.dropdown input:focus {
    border-color: #757dff !important;
}

.ui.dropdownFlexed>.content>.ui.selection.active.dropdown {
    box-shadow: none;
}

.actionsGroup {
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
}

.actionsGroup .ui.button {
    width: 48%;
    height: 36px;
    padding: 0 12px;
    font: 500 13px/36px 'Rubik', sans-serif;
}

.actionsGroup .ui.button.basic.blue {
    border: none !important;
    border-radius: 4px !important;
    box-shadow: inset 0 0 0 2px #d5e4ef !important;
    color: #708096 !important;
}

.ui.defaultCover {
    width: 100%;
    height: 131px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
}

.ui.defaultCover:before {
    content: '';
    height: 100%;
    width: 100%;
    background: url(/img/icon-img-placeholder.svg) no-repeat center #000;
    background-size: 40px auto;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transition: all .3s ease;
}

.ui.defaultCover:hover:before {
    opacity: .4;
}

.ui.defaultLogo {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.ui.defaultLogo img {
    width: 20px !important;
    height: auto !important;
}

.building-box .ui.defaultLogo img {
    margin: 0;
}

.editSidebarBox .ui.defaultLogo:before {
    content: '';
    width: 100%;
    height: 100%;
    background: url(/img/icon-img-placeholder.svg) no-repeat center #000;
    background-size: 32px auto;
    transition: all 0.05s ease;
    position: absolute;
    filter: saturate(0);
    opacity: 0;
    top: 0;
    left: 0;
    z-index: 2;
    transition: all .3s ease;
}

.editSidebarBox .ui.defaultLogo:hover:before {
    opacity: .4;
}

/** after settings **/

.ui.primary.button {
    min-width: 100px;
    height: 36px;
    text-align: center;
    padding: 0 20px;
    font: 500 13px/36px 'Rubik', sans-serif;
}

.ui.radio.checkbox.radioItem {
    display: inline-block;
    margin: 0;
}

.ui.radio.checkbox.radioItem label:before {
    width: 18px;
    height: 18px;
    border: solid 1px #d5d4df;
    border-radius: 50px;
}

.ui.radio.checkbox.radioItem label:after {
    width: 18px;
    height: 18px;
    font-size: 13px;
}

.ui.radio.checkbox.radioItem input:checked~label:before {
    border-color: #727aff;
    box-shadow: none !important;
}

.ui.radio.checkbox.radioItem input:checked~label:after {
    background: #727aff !important;
    border-radius: 50px !important;
    width: 10px;
    height: 10px;
    left: 4px;
    top: 5px;
}

.ui.button.buttonIcon {
    font: 400 12px 'Rubik', sans-serif;
    color: #727aff !important;
    display: flex;
    align-items: center;
    width: auto;
    padding: 0;
    margin: 0;
    background: none !important;
    border: none;
}

.ui.button.buttonIcon img {
    width: 12px;
    margin: 0 3px;
}

.ui.button.buttonIcon:hover {
    text-decoration: underline;
}

.divideLine {
    display: inline-block;
    margin: 0 5px;
    min-height: 14px;
    border-right: solid 1px #d7d6e1;
}

.headingSmall {
    width: 100%;
    float: left;
    display: flex;
}

.headingSmall .span {
    float: right;
    width: 18px;
    height: 18px;
    padding: 0;
    border-radius: 4px;
}

.headingSmall p {
    display: flex;
    align-items: center;
}

.headingSmall p .span {
    margin-left: 10px;
}

.headingSmall.stroked p {
    padding: 0 0 2px;
    border-bottom: 1px solid #d3d2de;
}

.mb15 {
    margin-bottom: 15px !important;
}

.mb26 {
    margin-bottom: 25px !important;
}

.ui.tooltipPopup {
    text-transform: capitalize;
    padding: 6px 15px 8px !important;
    font: 300 12px 'Rubik', sans-serif;
}

.ui.tooltipPopup strong {
    font-weight: 500;
}

.ui.tooltipPopup.tooltipPopupBig {
    font: 400 14px/18px 'Rubik', sans-serif !important;
    margin: 0 0 0 21px !important;
    left: 20px !important;
}

/** branding responsive **/

.subheader-right .ui.button.lockToggle {
    display: none;
}

@media (max-width:767px) {
    .subheader-right {
        flex: 1;
    }

    .subheader-right .ui.button.lockToggle {
        display: block;
        margin-right: auto;
    }

    .ui.basic.store-detail-box,
    .ui.basic.historyPortal {
        z-index: 40;
    }

    .ui.basic.histoyPortal {
        box-shadow: -3px 10px 12px rgba(0, 0, 0, 0.2), inset 0 2px 5px #ced3e2;
    }

    .ui.basic.store-detail-box .ui.header.portal-header {
        box-shadow: inset 0 1px 3px #ced3e2;
    }
}

/*** v3 ***/

.modeButtonGroup {
    position: absolute;
    top: 130px;
    right: 20px;
    z-index: 4;
    box-shadow: 0 4px 10px rgba(0, 0, 0, .1) !important;
    border-radius: 4px;
}

.modeButtonGroupListing {
    left: auto;
    top: 130px;
    right: 20px;
}

.modeButtonGroup .ui.button.buttonIcon {
    background: #fff !important;
    float: left;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.modeButtonGroup div:first-child .ui.button.buttonIcon {
    border-radius: 4px 0 0 4px;
}

.modeButtonGroup div:last-child .ui.button.buttonIcon {
    border-radius: 0 4px 4px 0;
}

.modeButtonGroup .ui.button.buttonIcon.active {
    background: #727AFF !important;
}

.modeButtonGroup .ui.button.buttonIcon.active img {
    filter: saturate(0) brightness(100);
}

.ui.popup.modeButtonsPopup {
    box-shadow: 0 0 12px rgba(0, 0, 0, .3);
    border: none;
    border-radius: 2px;
    padding: 10px;
    font: 400 13px 'Rubik', sans-serif;
}

.ui.popup.modeButtonsPopup:before {
    box-shadow: none !important;
}

.sidebar-open {
    left: auto;
    right: 410px;
}

.dashedBox {
    width: 100%;
    float: left;
    border: dashed 1px #727AFF;
}

.uploadDashedBox {
    height: 130px;
    background: #F3F6FB;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font: 400 13px 'Rubik', sans-serif;
    margin: 5px 0 10px;
}

.uploadDashedBox .ui.header {
    font: 400 13px 'Rubik', sans-serif;
    color: #667581;
    margin: 0 0 5px;
}

.dashedBox .ui.button.basic.primary {
    border: solid 1px #727AFF;
    background: #fff !important;
    color: #727AFF !important;
    box-shadow: none !important;
    border-radius: 2px;
    font: 600 13px 'Rubik', sans-serif;
}

.filePlaceholderCover {
    width: 100%;
    float: left;
    margin: 0 0 10px;
    display: flex;
    align-items: center;
}

.placeholderFile {
    min-width: 30px;
    width: 30px;
    height: 30px;
    border-radius: 2px;
    background: #F3F6FB;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
}

.placeholderFile img {
    max-width: 100%;
}

.filePlaceholderBody {
    width: 100%;
    height: 30px;
    border: dashed 1px #727AFF;
    padding: 0 65px 0 10px;
    position: relative;
    font: 400 13px 'Rubik', sans-serif !important;
    display: flex;
    align-items: center;
}

.filePlaceholderBody span {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.filePlaceholderCover .ui.button.basic {
    border: none;
    box-shadow: none;
    padding: 0;
    position: absolute;
    right: 10px;
    color: #727AFF !important;
}

.filePlaceholderCover .ui.button.basic:hover {
    text-decoration: underline;
}

.nonEdited {
    flex-direction: column;
    align-items: flex-start;
}

.nonEdited>p {
    width: 100% !important;
    margin: 0 0 10px !important;
}


.nonOlOverlay {
    position: absolute;
    top: 0;
    width: 50%;
    left: 25%;
    display: flex;
}

.nonOlOverlay>* {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.setZoomSlider {
    width: 100%;
    flex-grow: 1;
}

.setZoomSlider input {
    padding: 0px !important;
}