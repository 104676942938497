.container {
  display: flex;
  align-items: stretch;
  margin-bottom: 17px;
}

.input_box {
  margin-bottom: 0 !important;
}

.input_box > input {
  height: 34px !important;
}

.upload_btn {
  margin-left: 14px !important;
}
