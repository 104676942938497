.shareUrlCover{
    width:100%; float:left;
    margin:0 0 20px;
    display:flex;
    align-items:center;
}

.shareUrlBox{
    width:100%; float:left;
    height:40px;
    padding:0 10px;
    margin:0 0 20px;
    background:#F3F6FB;
    border:dashed 2px #D3D2DE;
    border-radius:4px;
    display:flex;
    align-items:center;
    font:400 15px 'Rubik',sans-serif;
}
.shareUrlBox > img:first-child{
    margin:0 10px 0 0;
}
.shareUrlBox span{
    flex:1;
    color:#6E6E6E;
    padding:0 5px;
    max-width:100%;
    white-space: nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
}
.shareUrlBox .ui.buttonIcon{
    font-size:15px;
    color:#F15944!important;
    margin-left:auto;
}
.shareUrlBox .ui.buttonIcon.copied{
    color:#000!important;
}
.shareUrlCover .shareUrlBox{
    margin:0;
    max-width:435px;
}

.ui.buttonIcon.unlockButton{
    min-width:40px;
    height:40px;
    margin:0 0 0 20px;
    background:#F3F6FB!important;
    border-radius:4px;
    border:solid 1px #D3D2DE;
    display:flex;
    align-items:center;
    justify-content:center;
}
.ui.buttonIcon.unlockButton img{
    margin:0;
}

/** store groups **/

.ui.storeGroupsModal{
    width:820px;
    padding-left:0;
    padding-right:0;
}

.ui.storeGroupsModal >.actions{
    padding:10px 40px 0;
}

.storeModalContent{
    width:100%; float:left;
    margin:20px 0 10px;
    padding:15px 40px;
    background:#F3F6FB;
}
.createGroupHeadbar{
    width:100%; float:left;
    margin:0 0 20px;
    display:flex;
    align-items:center;
}
.createGroupHeadbar .ui.input{
    flex:1;
    position:relative;
}
.createGroupHeadbar .ui.input>input{
    height:30px;
    padding:0;
    border-radius:0;
    border:none;
    border-bottom:solid 1px #D3D2DE;
    background:none;
    font:400 13px 'Rubik',sans-serif;
}
.createGroupHeadbar .ui.input img{
    position:absolute;
    top:10px;
    right:10px;
}
.createGroupHeadbar .ui.input>input::placeholder{
    color:#6E6E6E;
}
.createGroupHeadbar .ui.input>input:focus{
    border-color:#727AFF;
}

.createGroupHeadbar .ui.primary.button{
    min-width:80px;
    height:30px;
    padding:0 10px;
    margin-left:20px;
    font:400 14px 'Rubik',sans-serif;
}

.accordionStoreGroups{
    width:100%; float:left;
    margin:0 0 20px;
}
.ui.accordionStoreGroups .title{
    width:100%; float:left;
    height:60px;
    background:#fff;
    border:solid 1px #D3D2DE;
    border-radius:3px;
    font:400 15px 'Rubik',sans-serif!important;
    padding:0 50px 0 0!important;
    margin:0 0 5px;

    position:relative;
    display:flex;
    align-items:center;
}
.ui.accordionStoreGroups .active.title{
    border-bottom-color:#F15944;
    border-radius:3px 3px 0 0;
    margin:0;
}
.ui.accordionStoreGroups .title:after{
    content:'';
    background:url(/img/icon-chevron-down.svg) no-repeat center;
    filter:saturate(1) brightness(.4);
    width:11px;
    height:6px;
    position:absolute;
    right:15px;
}
.ui.accordionStoreGroups .active.title:after{
    transform:rotate(-180deg);
}
.accordionTitleContent{
    flex:1;
}
.accordionTitleContent .ui.image{
    margin:0 15px 0 10px;
}
.accordionTitleAction{
    display:flex;
    align-items:center;
}
.accordionTitleAction span{
    color:#727AFF;
    margin-right:30px;
}

.accordionStoreGroups .content{
    width:100%; float:left;
    max-height:150px;
    overflow-y:auto;
    padding:5px!important;
    margin:0 0 5px;
    border:solid 1px #D3D2DE;
    border-top:0;
    border-radius:0 0 3px 3px;
}

.storeBoxItemCover{
    width:100%; float:left;
    margin:0 5px 5px;
    position:relative;
}
.ui.groupStoreBox{
    width:100%;
    background:#fff!important;
    border:dashed 1.5px #D3D2DE;
    padding:10px;
    display:inline-flex;
    align-items:center;
}
.ui.groupStoreBox.active{
    border-color:#727AFF;
}
.ui.groupStoreBox .ui.image{
    margin:0 15px 0 0;
}
.storeBoxItemCover .ui.buttonIcon{
    position:absolute;
    top:23px;
    right:10px;
}
.storeBoxItemCover p{
    font:400 15px 'Rubik',sans-serif;
    color:#000;
}
.storeBoxItemCover p span{
    display:block;
    color:#99A9C4;
}

.accordionStoreGroups .content .storeBoxItemCover{
    margin:0 1% 5px;
    max-width:48%;
}

.storeBoxItem{
    width:100%;
    height:60px;
    padding:10px;
    margin:0 0 15px;
    background:#F3F6FB!important;
    border:dashed 1.5px #D3D2DE;
    border-radius:3px;
    display:inline-flex;
    align-items:center;
    position:relative;
}
.storeBoxItem .ui.image{
    margin:0 15px 0 0;
}
.storeBoxItem .ui.buttonIcon{
    width:20px;
    height:20px;
    background:#fff!important;
    border-radius:30px;
    border:solid 1px #D3D2DE;
    position:absolute;
    top:-9px;
    right:-10px;
    
    display:flex;
    align-items:center;
    justify-content:center;
}
.storeBoxItem .ui.buttonIcon img{
    width:8px;
    margin:0;
}
.storeBoxItem p{
    font:400 15px 'Rubik',sans-serif;
    color:#000;
    margin:0;
}
.storeBoxItem p span{
    display:block;
    color:#99A9C4;
}

/*** create group ***/

.createGroupModal .textGroup .ui.dropdownForm > img{
    filter:brightness(.4) saturate(1);
}

.createGroupStoreCover{
    width:100%; float:left;
    display:flex;
    align-items:flex-start;
    justify-content:space-between;
    flex-wrap:wrap;
}
.createGroupStoreCover .storeBoxItem{
    max-width:48%;
}