.icons-info-section{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.icons-management-sidebar{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
