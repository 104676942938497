.ui.modal.syncStoreModal{
    max-width:566px!important;
    padding-bottom:0;
}
.ui.modal.syncStoreModal .aligner{
    padding:0 40px;
}

.ui.modal.syncStoreModal>.actions{
    padding:25px 40px 0;
    display:flex;
    align-items:center;
    justify-content:space-between;
}
.ui.modal.syncStoreModal>.actions .ui.button.button-more{
    margin:0;
}

.syncStoreModal .ui.checkbox{
    font:400 14px/20px 'Rubik',sans-serif;
}
.syncListingBox{
    width:100%; float:left;
    border:1px solid #d3d2de!important;
    padding:15px 20px 15px;
    margin:10px 0 20px;
    max-height:234px;
    overflow-y:auto;
}
.syncListingItem{
    width:100%; float:left;
    display:flex;
    align-items:center;
    margin:0 0 15px;
    cursor:pointer;
}
.syncListingItem:last-child{
    margin:0;
}
.syncListingItem .ui.checkbox{
    margin:-2px 2px 0 0;
}
.syncListingImg{
    min-width:43px;
    width:43px;
    margin:0 15px;
}
.syncListingImg img{
    max-width:43px;
    float:left;
}
.syncListingBody{
    flex:1;
    font:500 14px 'Rubik',sans-serif;
    color:#000;
}
.syncListingBody span{
    display:block;
    margin:3px 0 0;
    color:#667581;
    font-weight:400;
}

.defaultSyncListing{
    width:100%; float:left;
    border-bottom:1px solid #d3d2de;
    padding:0 0 5px;
    margin:15px 0 0;
}
.defaultSyncListing .ui.header{
    width:100%; float:left;
    padding:0 0 5px;
    border-bottom:1px solid #d3d2de;
    color:#222;
    font:400 15px 'Rubik',sans-serif;
}
.defaultSyncListing .ui.header span{
    font-weight:500;
}

.tabStoreCover{
    width:100%; float:left;
    display:flex;
}
.tabStoreColumn{
    flex:1;
    min-width:50%;
    max-width:50%;
    font:400 14px 'Rubik',sans-serif;
}
.tabStoreColumn strong{
    font-weight:500;
}
.tabStoreColumn:first-child{
    max-height:330px;
    overflow-y:auto;
}
.tabStoreColumn:nth-child(2){
    background:#f2f2f2;
    padding:10px 2px 0;
}
.tabStoreColumn:nth-child(2) > .ui.checkbox{
    width:87%;
    border-bottom:solid 1px #d3d2de;
    padding:0 0 10px;
}


.syncListTypeItem{
    padding:8px 10px;
    margin:0;
}
.syncListTypeItem.active .syncListingBody{
    font-weight:500;
}
.tabStoreScroll{
    width:100%; float:left;
    padding:0 10px;
    max-height:288px;
    overflow-y:auto;
}
.tabStoreScroll .syncListTypeItem{
    padding:8px 0;
}

.syncListTypeItem.active{
    background:#f2f2f2;
}

.syncListTypeItem .syncListingBody{
    font-weight:400;
}
.ui.checkbox.selectStoreType{
    width:100%; float:left;
    padding:0 0 10px;
    margin:0 0 8px;
    border-bottom:solid 1px #d3d2de;
}

.tabStoreScroll .syncListTypeItem >.ui.icon.button{
    padding:5px;
}
.ui.modal.syncStoreTypeModal{
    max-width:900px!important;
}
.ui.modal.syncStoreTypeModal>.actions .ui.button{
    margin-left:auto;
}

.ui.modal.syncStoreModal2{
    max-width:565px!important;
}
.ui.modal.syncStoreModal2 .defaultSyncListing{
    border:none;
}

.ui.modal.syncStoreLastModal{
    padding-left:40px;
    padding-right:40px;
}
.ui.modal.syncStoreLastModal .aligner{
    padding:0;
}
.ui.modal.syncStoreLastModal .actions{
    padding:20px 0 0;
}
.ui.modal.syncStoreLastModal .actions .ui.button{
    margin-left:auto;
}

.storeInput{
    width:100%; float:left;
    position:relative;

}
.storeInput p{
    width:100%; float:left;
    font:400 14px 'Rubik',sans-serif!important;
    color:#000;
}
.storeInput .ui.input{
    width:100%;
}
.storeInput .ui.input>input{
    width:100%;
    height:50px;
    border:solid 1px #d3d2de;
    border-radius:2px;
    padding:0 10px;
    font:400 15px 'Rubik',sans-serif;
    color:#000;
}
.storeInput .ui.button.buttonIcon{
    position:absolute;
    right:10px;
    bottom:17px;
}
.storeInput .ui.button.buttonIcon img{
    width:17px;
}

.ui.button.shareStoreTrigger{
    margin:0 20px 0 0;
}

.ui.modal.modalKeyAccess{
    width:100%;
    max-width:610px!important;
}
.ui.modal.modalKeyAccess .header{
    text-align:center!important;
}
.ui.modal.modalKeyAccess .actions .ui.button{
    margin:0 10px 0 0!important;
}
.ui.modal.modalKeyAccess .actions .ui.button:last-child{
    margin:0 0 0 auto!important;
    float:right;
}
.subHeading3{
    width:100%; float:left;
    text-align:center;
    font:500 15px 'Rubik',sans-serif;
    color:#000;
}
.quoteBox{
    display:inline-block;
    width:auto;
    margin:0 0 20px;
    background:#f8f8f9;
    border:solid 1px #d4d4d4;
    border-radius:2px;
    font:400 12px 'Rubik',sans-serif;
    padding:10px 15px;
    word-wrap:break-word;
}
.ui.modal.modalKeyAccess .actions{
    text-align:left;
}
.ui.modal.modalKeyAccess .actions .ui.green.button{
    margin-left:auto;
    float:right;
}

.ui.basic.actionButtonGroup{
    float:right;
    margin:-5px 0 10px;
}
.alignLeft{
    text-align:left;
}

.syncStoreModalGroup .syncListingItem{
    padding:0 15px;
    cursor:default;
}
.syncStoreModalGroup .actions{
    margin-top:0;
}
.syncStoreModalGroup .actions .ui.button{
    margin-left:auto;
}

.groupSearch{
    width:100%; float:left;
    margin:0 0 20px!important;
    background:none!important;
    padding:0 0 1px;
    height:25px;
    border-bottom: solid 1px #d3d2de!important;
    position:relative;
}
.groupSearch .text{
    font:400 14px 'Rubik',sans-serif;
}
.groupSearch i.icon{
    position:absolute;
    right:0;
}
.ui.search.dropdown.groupSearch .menu{
    width:100%;
    border-radius:0;
    box-shadow:0 0 12px rgba(0,0,0,.2);
}
