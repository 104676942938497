.cacheControl {
    background-color:#fff!important;
    border-radius: 8px;
    box-shadow:0 0 12px rgba(0,0,0,.5);
    color:#fff!important;
    position: fixed;
    cursor: move;
    white-space:nowrap;
    z-index:100000;
    padding:0;

    opacity:.3;
    /* Makes dragging slow */
    /* transition:all .3s ease; */
}
.cacheControl:hover{
    opacity:1;
}
.cacheControl .ui.button,
.cacheControl .ui.checkbox{
    width:100%; float:left;
    min-height:34px;
    text-align:center;
    border-radius:0!important;
}
.cacheControl .ui.button:first-child{
    border-radius:8px 8px 0 0!important;
}
.cacheControl .ui.button:last-child{
    border-radius:0 0 8px 8px!important;
}
.cacheControl .ui.checkbox{
    font:400 13px 'Rubik',sans-serif;
    padding:8px 10px 8px;
    margin:0;
}
