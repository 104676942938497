.image-management-upload-from-url-bx{
    margin-bottom: 16px;
}

.image-management-upload-from-url-bx .ui.fluid input{
    background: #f8f7fa;
    border: 1px solid #b8b5ce !important;
    border-radius: 2px;
    padding: 0 18px;
    height: 34px;
    font: 300 15px "Rubik", sans-serif;
    color: #636364;
}