.ag-theme-quartz {
  --ag-header-column-separator-display: none;
  --ag-header-column-resize-handle-display: none;
  --ag-header-height: 30px;
  --ag-header-foreground-color: #667090;
  --ag-header-background-color: #6670901a;
  --ag-cell-horizontal-border: 1px solid #dddddd81;
}

.ag-theme-quartz .ag-header {
  font-family: "Rubik", sans-serif;
}

.ag-theme-quartz .ag-header-cell {
  font: 400 14px "Rubik", sans-serif;
}

/* .listview-n-table {
  min-height: 40vh;
  height: calc(100vh - 290px);
  margin-bottom: 12px;
} */

.listview-n-table-wrapper .paginationBar {
  margin: 14px 0 !important;
  float: none !important;
}
.listview-n-table .ag-row {
  color: black;
  font: 400 14px "Rubik", sans-serif;
}

.entityEditButton {
  margin-top: 12px !important;
  display: "flex";
  flex-direction: column;
  align-items: center;
}

.listview-n-table-wrapper .block {
  pointer-events: none !important;
}

.listview-n-table-wrapper .footer {
  margin-bottom: 14px;
}

.list-view-logo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-view-logo img {
  width: 100%;
  height: 80%;
  object-fit: contain;
}

.listview-n-table-wrapper .ag-root-wrapper{
  overflow: visible;
}

.listview-n-table-wrapper .ag-select-list.ag-ltr{
  max-height: 300px !important;
}