.listview-n-basic-filteration {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 24px;
  margin-right: 82px;
}

.listview-n-basic-filteration .filter-dropdown.ui.dropdown.dropdownForm {
  padding: 8px 8px !important;
  padding-right: 35px !important;
  align-self: stretch !important;
}

.listview-n-basic-filteration .filter-dropdown.ui.dropdown.dropdownForm .chip-bx div:first-child{
  max-width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.listview-n-basic-filteration .filter-dropdown.ui.dropdown.dropdownForm .chip-bx{
  flex: 1;
}

.listview-n-basic-filteration .list-view-filteration-search-input {
  width: 35%;
}
.listview-n-basic-filteration .list-view-filteration-search-input input {
  width: 100% !important;
  border: 0px !important;
  font: 400 14px "Rubik", sans-serif;
  color: #667581;
  border-radius: 4px;
}
.listview-n-basic-filteration
  .list-view-filteration-search-input
  input::placeholder {
  color: #667581 !important;
}
.listview-n-basic-filteration .filter-dropdown.ui.dropdown .text {
  display: flex;
  align-items: center;
  height: 100%;
  font: 400 14px "Rubik", sans-serif;
  color: #667581;
}
.column-filteration.ui.dropdown .menu {
  right: 0 !important;
  left: initial !important;
}
.listview-n-basic-filteration .filter-btn.ui.button {
  background-color: white !important;
  align-self: stretch;
  padding: 8px;
  border-radius: 6px;
  font: 400 14px "Rubik", sans-serif;
  display: flex;
  gap: 8px;
  color: #667581 !important;
}
.listview-n-basic-filteration .filter-btn.ui.button img {
  margin: 0 !important;
}
.listview-n-basic-filteration .header-title {
  font: 400 12px "Rubik", sans-serif;
  color: #667581;
  width: max-content;
}

.listview-n-basic-filteration-options-section {
  display: flex;
  gap: 12px;
  align-items: stretch;
}
.listview-n-basic-filteration .ui.dropdown.dropdownForm.dropdown {
  position: relative;
  border-radius: 6px;
  border-bottom: 0px !important;
  align-self: stretch;
}

.listview-n-basic-filteration .ui.dropdown.dropdownForm .menu {
  border-radius: 6px !important;
  border: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.listview-n-basic-filteration .ui.dropdown.dropdownForm .menu .item {
  border-bottom: 0 !important;
}
.listview-n-basic-filteration .divider.text {
  width: 100%;
}
.listview-n-basic-filteration .divider.text .chip-bx {
  width: 90%;
  display: flex !important;
  justify-content: space-between;
  gap: 12px;
}

.listview-n-basic-filteration .chip-bx {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.listview-n-basic-filteration .chip {
  background-color: #ddd;
  padding: 1.5px 4px;
  font-size: 12px;
  border-radius: 4px;
}

.listview-n-basic-filteration .ui.dropdown.dropdownForm.dropdown img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
}

.listview-n-basic-filteration-lang-bx {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 8px;
}

.listview-n-basic-filteration .listview-n-basic-filteration-lang-bx .dropdown {
  align-self: stretch;
}

.listview-n-basic-filteration .column-visibility-bx {
  display: flex;
  align-items: center;
  gap: 8px;
}

.listview-n-basic-filteration .column-visibility-bx .img-bx {
  display: flex;
  justify-content: center;
  align-items: center;
}

.listview-n-basic-filteration .column-filteration {
  width: max-content !important;
}
.listview-n-basic-filteration .column-visibility-bx .img-bx img {
  position: initial !important;
  margin: 0 !important;
  transform: translate(0) !important;
  margin-top: 12px;
}

.listview-n-basic-filteration .ui.dropdown .menu .selected.item, .ui.dropdown.selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.listview-n-basic-filteration .ui.dropdown .menu .selected.item .text, .ui.dropdown.selected .text  {
  font-weight: bold;
}
